import InfoBox from 'components/elements/InfoBox';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

type Props = {
  message: keyof typeof messages;
  onDismiss?(): void;
};

function TipBox({ message, onDismiss }: Props) {
  return (
    <InfoBox isClosable showIcon={false} onDismiss={onDismiss} testId="tipBox">
      <FormattedMessage
        id={messages[`${message}Title`].id}
        defaultMessage={messages[`${message}Title`].defaultMessage}
        tagName="strong"
      />
      <FormattedMessage
        id={messages[message].id}
        defaultMessage={messages[message].defaultMessage}
      />
    </InfoBox>
  );
}

TipBox.defaultProps = {
  onDismiss: undefined,
};

export default TipBox;
