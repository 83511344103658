import userInfoRoute from './UserInfo/route';
import addressesRoute from './Addresses/route';
import loyaltyCardsRoute from './LoyaltyCards/route';
import travelDocRoute from './TravelDoc/route';
import paymentsRoute from './Payments/route';
import vehiclesRoute from './Vehicles/route';
import integrationsRoute from './Integrations/route';
import delegationsRoute from './Delegations/route';
import { ROUTE_NAME_PROFILE, ROUTE_PATH_PROFILE } from './constants';

export { adminProfileRoute } from './UserInfo/route';

const companyUserProfileRoute = {
  path: ROUTE_PATH_PROFILE,
  name: ROUTE_NAME_PROFILE,
  children: [
    userInfoRoute,
    addressesRoute,
    travelDocRoute,
    loyaltyCardsRoute,
    paymentsRoute,
    vehiclesRoute,
    integrationsRoute,
    delegationsRoute,
  ],
};

export default companyUserProfileRoute;
