import { UUID } from '@neo1/client';

export type State = {
  ids: UUID[];
};

export enum Actions {
  SET_RECEIPT = 'one/receipt/spend/SET_RECEIPT',
  SET_RECEIPTS = 'one/receipt/spend/SET_RECEIPTS',
  DELETE_RECEIPT = 'one/receipt/spend/DELETE_RECEIPT',
}

export type SetReceipt = {
  type: typeof Actions.SET_RECEIPT;
  id: UUID;
};

export type SetReceipts = {
  type: typeof Actions.SET_RECEIPTS;
  ids: UUID[];
};

export type DeleteReceipt = {
  type: typeof Actions.DELETE_RECEIPT;
  id: UUID;
};

export type Action = SetReceipt | SetReceipts | DeleteReceipt;
