import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectReturnPath } from 'modules/Authentification/redux/selectors';
import { logoutAs } from 'modules/Authentification/redux/thunks';
import useAsyncState from 'hooks/asyncState';

const useLogoutAs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const returnPath = useSelector(selectReturnPath);

  const onLogoutAs = useCallback(async () => {
    await dispatch(logoutAs());
    history.replace(returnPath);
  }, [dispatch, history, returnPath]);

  return useAsyncState(onLogoutAs);
};

export default useLogoutAs;
