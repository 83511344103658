import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './Tag.module.css';

const tagClassByType = {
  default: styles.default,
  active: styles.active,
  success: styles.success,
  error: styles.error,
  warn: styles.warn,
};

export type TagType = 'default' | 'active' | 'success' | 'error' | 'warn';

type Props = {
  type?: TagType;
  revert?: boolean;
  whiteBg?: boolean;
  className?: string;
  children: ReactNode;
  testId?: string;
};

const ColorTag = ({
  children,
  type,
  revert,
  className,
  testId,
  whiteBg,
}: Props) => (
  <div
    aria-label="Tag"
    className={classNames(
      styles.tag,
      tagClassByType[type],
      { [styles.revert]: revert },
      { [styles.whiteBg]: whiteBg },
      className,
    )}
    data-testid={testId}
  >
    <span className={styles.tagContainer}>{children}</span>
  </div>
);

ColorTag.defaultProps = {
  type: 'default',
  revert: false,
  whiteBg: false,
  className: undefined,
  testId: undefined,
};

export default ColorTag;
