import { ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip from 'components/elements/Tooltip';
import Icon from 'components/elements/Icon/index';

import styles from './FieldTooltip.module.css';

type Props = {
  message: ReactNode | string;
  type?: 'normal' | 'warning' | 'error';
  className?: string;
  testId?: string;
};

const LabelTooltip = ({ message, type, className, testId }: Props) => {
  const icon = type === 'warning' || type === 'error' ? 'warning' : 'info';

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid={testId}
    >
      <Tooltip content={message} testId={`${testId}Tooltip`} theme={type}>
        <Icon name={icon} className={classNames(styles.icon, styles[type])} />
      </Tooltip>
    </div>
  );
};

LabelTooltip.defaultProps = {
  type: 'normal',
  className: undefined,
  testId: undefined,
};

export default LabelTooltip;
