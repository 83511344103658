import { Action, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import {
  postLoginTravelSSO,
  isTravelSSOActive,
} from '@neo1/core/utils/travelSso';
import { getClient, getSafeClient } from '@neo1/client/lib/rpc/client';
import * as api from '@neo1/client/lib/entities/auth/api';
import * as delegationApi from '@neo1/client/lib/entities/delegation/api';
import { tokenNotExpired } from '@neo1/client/lib/entities/auth/utils';
import { getUserData } from '@neo1/client/lib/entities/persistedUserData/api';
import * as companyApi from '@neo1/client/lib/entities/company/api';
import { COMPANY_ENTITY_NAME } from '@neo1/client/lib/entities/company/constants';
import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import { reify } from '@neo1/client';
import {
  CompanyGroupUser,
  CompanyUser,
  DomainUser,
  UpdatePasswordParams,
  UpdateUserParams,
  User,
} from '@neo1/client/lib/entities/user/types';
import {
  COMPANY_GROUP_USER_ENTITY_NAME,
  USER_ENTITY_NAME,
} from '@neo1/client/lib/entities/user/constants';
import {
  domainToCompanyUser,
  isCompanyUser,
  normalizeCompanyUser,
  normalizeUser,
} from '@neo1/client/lib/entities/user/utils';
import {
  acceptEula,
  updateUser,
  updateUserPassword,
} from '@neo1/client/lib/entities/user/api';
import {
  selectActingUser,
  selectCompanyGroupUsers,
  selectCurrentUser,
} from 'modules/Authentification/redux/selectors';
import { AppCoreState } from 'redux/types';
import { setEntities, setEntity } from 'redux/entities/thunks';
import { FAKE_USER_ID } from 'redux/fakeState';
import { setMulti } from 'modules/App/redux/persisted/actions';
import * as counterActions from 'redux/counter/actions';
import { Company, CompanyData } from '@neo1/client/lib/entities/company/types';
import * as authActions from './actions';

type Dispatch = ThunkDispatch<AppCoreState, void, Action>;
type GetState = Store<AppCoreState>['getState'];

const loadCompanyEntities =
  (companyId: Company['id']) => async (dispatch: Dispatch) => {
    const { company, companyGroupUsers, fundingApproversData } =
      await api.loadAuthCompany(companyId);
    const companyChildren = await companyApi.fetchCompanyChildren(company);

    setEntities<Company>({
      entityName: COMPANY_ENTITY_NAME,
      entities: [company, ...companyChildren],
    })(dispatch);

    setEntities<CompanyGroupUser>({
      entityName: COMPANY_GROUP_USER_ENTITY_NAME,
      entities: companyGroupUsers,
    })(dispatch);

    dispatch(
      authActions.setAuthCompany({
        id: companyId,
        groupUsersIds: companyGroupUsers.map(({ id }) => id),
        fundingApproversData,
      }),
    );
  };

export const loadPersistedData =
  (user: CompanyUser) => async (dispatch: Dispatch) => {
    try {
      const userData = await getUserData(user.id);
      dispatch(setMulti(user.id, userData));
      return userData;
    } catch (err) {
      return {};
    }
  };

type Credentials = {
  login: string;
  password: string;
};

export const login =
  (credentials: Credentials) => async (dispatch: Dispatch) => {
    const { token, whoami } = await api.login(credentials);
    const user = normalizeUser(whoami);

    dispatch(authActions.setToken(token));
    getClient().setConfigKey('authToken', token);
    getSafeClient().setConfigKey('authToken', token);

    const delegations = await delegationApi.getDelegationsToMe(user.id);

    setEntity<User>({
      entityName: USER_ENTITY_NAME,
      entity: user,
    })(dispatch);

    dispatch(
      authActions.login(
        user.id,
        delegations.map(({ userId }) => userId),
      ),
    );

    if (isCompanyUser(user)) {
      await dispatch(loadCompanyEntities(user.companyId));
      await dispatch(counterActions.fetchCounters());
      await dispatch(loadPersistedData(user));
    }

    if (isTravelSSOActive()) {
      postLoginTravelSSO(token);
    }
    return user;
  };

/**
 * Disconnects user from the UI and forgets its authentication info
 */
export const logout = () => async (dispatch: Dispatch) => {
  dispatch(authActions.setProcessing());
  await getClient()
    .waitForPendingRequests()
    .then(async () => {
      dispatch(authActions.logout());
      const authToken = getClient().getConfigKey('authToken');
      const isTokenValid = authToken != null && tokenNotExpired(authToken);
      if (isTokenValid) {
        await api.logout();
      }

      getClient().unsetConfigKey('authToken');
      getSafeClient().unsetConfigKey('authToken');
      getClient().unsetConfigKey('euid');
      getSafeClient().unsetConfigKey('euid');
    });
};

export const loginAs =
  (id: User['id'], returnPath: string = '/') =>
  async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    try {
      dispatch(authActions.loginAs(id, returnPath));
      getClient().setConfigKey('euid', id);
      getSafeClient().setConfigKey('euid', id);
      const whoami = await api.whoAmI();
      const user = normalizeCompanyUser(reify(whoami));

      if (user.id !== id) {
        throw new Error(`Unable to log in as this user`);
      }

      setEntity<User>({
        entityName: USER_ENTITY_NAME,
        entity: user,
      })(dispatch);

      await dispatch(loadCompanyEntities(user.companyId));
      await dispatch(loadPersistedData(user));
      dispatch(authActions.loginAsSuccess());
      dispatch(counterActions.fetchCounters());
      if (isTravelSSOActive()) {
        postLoginTravelSSO(state.auth.authToken, id);
      }
    } catch (error) {
      dispatch(authActions.loginAsFail());
      throw error;
    }
  };

export const loginAsCompany =
  (companyData: CompanyData, returnPath: string = '/') =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch(authActions.loginAsCompany(companyData.id, returnPath));
      const currentUser = selectCurrentUser<DomainUser>(getState());
      const user = domainToCompanyUser(currentUser, companyData);
      setEntity<User>({
        entityName: USER_ENTITY_NAME,
        entity: user,
      })(dispatch);

      await dispatch(loadCompanyEntities(user.companyId));
      dispatch(counterActions.fetchCounters());
      dispatch(authActions.loginAsCompanySuccess());
    } catch (error) {
      dispatch(authActions.loginAsCompanyFail());
      throw error;
    }
  };

export const logoutAs = () => async (dispatch: Dispatch) => {
  dispatch(authActions.setProcessing());
  dispatch(authActions.logoutAs());

  getClient().unsetConfigKey('euid');
  getSafeClient().unsetConfigKey('euid');

  const whoami = await api.whoAmI();
  const user = normalizeUser(whoami);

  setEntity<User>({
    entityName: USER_ENTITY_NAME,
    entity: user,
  })(dispatch);

  if (isCompanyUser(user)) {
    await dispatch(loadCompanyEntities(user.companyId));
    await dispatch(counterActions.fetchCounters());
    await dispatch(loadPersistedData(user));
  }

  dispatch(authActions.unsetProcessing());
};

export const resume = () => async (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const {
    currentUserId,
    effectiveUserId,
    loggedCompanyId,
    returnPath,
    authToken,
  } = state.auth;

  getClient().setConfigKey('authToken', authToken);
  getSafeClient().setConfigKey('authToken', authToken);

  if (currentUserId === FAKE_USER_ID) return;

  const whoami = await api.whoAmI();
  const user = normalizeUser(whoami);
  setEntity<User>({
    entityName: USER_ENTITY_NAME,
    entity: user,
  })(dispatch);

  if (loggedCompanyId) {
    const company = await companyApi.fetchById(loggedCompanyId);
    await dispatch(loginAsCompany(company, returnPath));
    return;
  }

  if (effectiveUserId) {
    await dispatch(loginAs(effectiveUserId, returnPath));
    return;
  }

  if (isCompanyUser(user)) {
    await dispatch(loadCompanyEntities(user.companyId));
    await dispatch(counterActions.fetchCounters());
    await dispatch(loadPersistedData(user));
  }

  if (isTravelSSOActive()) {
    postLoginTravelSSO(state.auth.authToken);
  }
};

export const updateProfile =
  (user: UpdateUserParams, applyAllUpdates = false) =>
  async (dispatch: Dispatch) => {
    const updatedUser = await updateUser(user, applyAllUpdates);

    setEntity<User>({
      entityName: USER_ENTITY_NAME,
      entity: updatedUser,
    })(dispatch);
  };

export const updatePassword = (params: UpdatePasswordParams) => async () => {
  const res = await updateUserPassword(params);
  return res;
};

export const acceptTermsAndConditions =
  (version: string) => async (dispatch: Dispatch, getState: GetState) => {
    const user = selectActingUser(getState());
    const updatedUser = await acceptEula(user, version);

    setEntity<User>({
      entityName: USER_ENTITY_NAME,
      entity: updatedUser,
    })(dispatch);
  };

export const getActingUserSupervisees =
  () => async (_: never, getState: GetState) => {
    const state = getState();
    const actingUser = selectActingUser(state);
    const companyGroupUsers = selectCompanyGroupUsers(state);
    if (!actingUser) {
      return [];
    }

    return companyGroupUsers.filter(
      ({ supervisorId }) => supervisorId === actingUser.id,
    );
  };

export const activateCompany =
  (data: CompanyActivationData) => async (dispatch: any) => {
    const company = await api.activateCompany(data);

    setEntity<Company>({
      entityName: COMPANY_ENTITY_NAME,
      entity: company,
    })(dispatch);

    return company;
  };
