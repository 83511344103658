import { DEFAULT_LOCALE } from 'config/i18n/constants';
import { I18nActions, InternationalizationAction } from './actions';

export type InternationalizationStateType = {
  locale: string;
  messages: {
    loading: boolean;
    data: Record<string, string>;
  };
};

const initialState: InternationalizationStateType = {
  locale: DEFAULT_LOCALE,
  messages: {
    loading: false,
    data: {},
  },
};
export default (
  state: InternationalizationStateType = initialState,
  action: I18nActions = null,
): InternationalizationStateType => {
  switch (action?.type) {
    case InternationalizationAction.SetLocale:
      return {
        ...state,
        locale: action.locale,
      };
    case InternationalizationAction.LoadMessages:
      return {
        ...state,
        messages: {
          ...state.messages,
          loading: true,
        },
      };
    case InternationalizationAction.LoadMessagesOk:
      return {
        ...state,
        messages: {
          loading: false,
          data: { ...action.messages },
        },
      };

    case InternationalizationAction.LoadMessagesErr:
      return {
        ...state,
        messages: { loading: false, data: {} },
      };

    default:
      return state;
  }
};
