import { useState, useEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

type Props = {
  children: ReactNode;
  element?: HTMLElement;
};

const Portal = ({ children, element }: Props) => {
  const [container] = useState(() => element ?? document.createElement('div'));

  useEffect(() => {
    if (!element) {
      document.body.appendChild(container);
    }
    return () => {
      if (!element) {
        document.body.removeChild(container);
      }
    };
  }, []);

  return createPortal(children, container);
};

Portal.defaultProps = {
  element: undefined,
};

export default Portal;
