import { useEffect } from 'react';
import { useAmplitude } from 'contexts/instrumentation';
import { useIntl } from 'react-intl';
import Button from 'components/elements/Button';
import { getConfigValue } from '../../../../../config';

import messages from '../../messages';
import styles from './index.module.css';

enum Event {
  SurveyViewed = 'survey_viewed',
}

function CompanyActivationFinish() {
  const { logEvent } = useAmplitude();

  useEffect(() => {
    logEvent({ event: Event.SurveyViewed });
  }, []);

  return (
    <div className={styles.iframeWrapper}>
      <iframe
        title="Activation Business Finished"
        width="100%"
        height="520px"
        src={getConfigValue('didUEnjoySurveyURL')}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        scrolling="no"
      />
    </div>
  );
}
export const FinishActions = () => {
  const intl = useIntl();
  return (
    <div className={styles.finishAction}>
      <Button
        label={intl.formatMessage(messages.footer.btnClose)}
        type="submit"
        id="btnContinue"
      />
    </div>
  );
};
export default CompanyActivationFinish;
