import { defineMessages } from 'react-intl';

const messages = defineMessages({
  confirmLeaveTitle: {
    id: 'confirmLeaveTitle',
    defaultMessage: 'Do you want to leave this page?',
  },
  confirmLeaveText: {
    id: 'confirmLeaveText',
    defaultMessage: "Any information you've entered will be lost.",
  },
});

export default messages;
