import styles from './Spinner.module.css';

const Spinner = () => (
  <div className={styles.container}>
    <div className={styles.bar1} />
    <div className={styles.bar2} />
    <div className={styles.bar3} />
    <div className={styles.bar4} />
    <div className={styles.bar5} />
    <div className={styles.bar6} />
    <div className={styles.bar7} />
    <div className={styles.bar8} />
  </div>
);

export default Spinner;
