import { memo, ReactNode } from 'react';

type Props = {
  formattedValue: ReactNode;
  className?: string;
};

const defaultProps: Partial<Props> = {
  className: '',
};

const TableCellContent = ({ formattedValue, className }: Props) => (
  <div
    className={className}
    title={typeof formattedValue === 'string' ? formattedValue : undefined}
  >
    {formattedValue}
  </div>
);

TableCellContent.defaultProps = defaultProps;

export default memo(TableCellContent);
