import { defineMessages } from 'react-intl';

const messages = defineMessages({
  step: {
    id: 'MultistepTracker_step',
    defaultMessage: 'Step',
  },
});

export default messages;
