const messages = {
  modalTitle: {
    id: 'LoggingAsModal__modalTitle',
    defaultMessage: 'Log in as another user',
  },
  helperText: {
    id: 'LoggingAsModal__helperText',
    defaultMessage: 'Choose the user as who you wish log in.',
  },
  errorRequiredUserId: {
    id: 'LoggingAsModal__errorRequiredUserId',
    defaultMessage: 'This field is required',
  },
  labelUserId: {
    id: 'LoggingAsModal__labelUserId',
    defaultMessage: 'User',
  },
};

export default messages;
