import { forwardRef, RefObject } from 'react';
import classNames from 'classnames';
import styles from './SmallLoader.module.css';

type Props = {
  label?: string;
  className?: string;
};

const SmallLoader = forwardRef(
  ({ label, className }: Props, ref: RefObject<HTMLDivElement>) => (
    <div ref={ref} className={classNames(styles.container, className)}>
      <div className={styles.icon} />
      {label ? <p className={styles.label}>{label}</p> : null}
    </div>
  ),
);

SmallLoader.defaultProps = {
  label: undefined,
  className: undefined,
};

export default SmallLoader;
