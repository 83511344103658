import {
  ElementType,
  HTMLAttributes,
  useState,
  FocusEvent,
  createElement,
} from 'react';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import Dropbox from 'components/composed/Dropbox';
import TextInput from 'components/elements/form/fields/TextInput';
import styles from './DropboxInput.module.css';

interface Props extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> {
  value?: string;
  position?: 'up' | 'down';
  maxLength?: number;
  InputComponent?: ElementType;
  onChange?: (value: string) => void;
  name?: string;
}

function DropboxInput({
  className,
  children,
  onFocus,
  onBlur,
  position,
  InputComponent,
  name,
  ...props
}: Props) {
  const [isDropboxOpen, setDropboxOpen] = useState(false);

  const handleSetDropboxOpen = debounce(setDropboxOpen, 300);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(e);
    }
    handleSetDropboxOpen(true);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(e);
    }
    handleSetDropboxOpen(false);
  };

  const inputProps = {
    ...props,
    name,
    autoComplete: 'off',
    onFocus: handleFocus,
    onBlur: handleBlur,
    className: classNames(styles.input, className),
    theme: 'noBorders',
  };

  return (
    <div data-testid="dropboxInput">
      {createElement(InputComponent, inputProps)}
      <Dropbox
        isOpen={isDropboxOpen}
        position={position}
        className={styles.dropbox}
      >
        {children}
      </Dropbox>
    </div>
  );
}

DropboxInput.defaultProps = {
  value: '',
  position: 'down',
  maxLength: undefined,
  InputComponent: TextInput,
  onChange: undefined,
  name: undefined,
};

export default DropboxInput;
