import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import { useFormikContext } from 'formik';

const useGoback = (onBack: (values: CompanyActivationData) => void) => {
  const { setFieldValue, values } = useFormikContext<CompanyActivationData>();

  const handleOnBack = () => {
    setFieldValue('editedOwnerIndex', null);
    onBack(values);
  };

  return { handleOnBack };
};

export default useGoback;
