import get from 'lodash/get';
import isNil from 'lodash/isNil';

export const getNotNil = (value: any, prop: string, defaultValue?: any) => {
  const resolvedValue = get(value, prop);
  return isNil(resolvedValue) ? defaultValue : resolvedValue;
};

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
