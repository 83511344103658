import { TripScope } from './types';

export const ROUTE_NAME_TRAVEL = 'travel';
export const ROUTE_NAME_TRIPS = 'trips';
export const ROUTE_NAME_GUESTS = 'guests';
export const ROUTE_PATH_TRAVEL = `/${ROUTE_NAME_TRAVEL}`;

export const ROUTE_PATH_TRIPS_BASE = `${ROUTE_NAME_TRAVEL}/${ROUTE_NAME_TRIPS}`;
export const ROUTE_PATH_TRIPS = `/${ROUTE_PATH_TRIPS_BASE}/:tripScope(${Object.values(
  TripScope,
).join('|')})`;

export const ROUTE_PATH_USER_TRIPS = `/${ROUTE_PATH_TRIPS_BASE}/${TripScope.UserTrips}`;
export const ROUTE_PATH_GUEST_TRIPS = `/${ROUTE_PATH_TRIPS_BASE}/${TripScope.GuestTrips}`;
export const ROUTE_PATH_TEAM_TRIPS = `/${ROUTE_PATH_TRIPS_BASE}/${TripScope.TeamTrips}`;
export const ROUTE_PATH_COMPANY_TRIPS = `/${ROUTE_PATH_TRIPS_BASE}/${TripScope.CompanyTrips}`;

export const ROUTE_PATH_GUESTS = `/${ROUTE_NAME_TRAVEL}/${ROUTE_NAME_GUESTS}`;

export const TRIPS_PENDING_BANNER_USER = {
  label:
    'Travel booking will be enabled as soon as your business is fully active',
};
export const TRIPS_PENDING_BANNER_ADMIN = {
  label:
    'Travel booking will be enabled as soon as your business setup is completed',
  description:
    'You will be able to book a flight, a room or ground transportation as soon as your business is fully active.',
};
