import { useRef } from 'react';

import IconText from 'components/elements/IconText';
import Popover, { Ref as PopoverRef } from 'components/elements/Popover';
import Button, { ButtonTheme } from 'components/elements/Button';

import { TableExportActions } from '../types';
import styles from './ExportButton.module.css';

type Props = {
  exportActions: TableExportActions;
};

const ExportButton = ({ exportActions }: Props) => {
  const popoverRef = useRef<PopoverRef>();
  const title = 'Show export actions for table';
  return (
    <Popover
      ref={popoverRef}
      className={styles.popover}
      content={exportActions.map(({ id, text, icon, onClick }) => {
        const handleClick = () => {
          if (popoverRef.current) {
            popoverRef.current.hide();
          }
          onClick();
        };

        return (
          <button
            type="button"
            key={id}
            title={text}
            aria-label={text}
            onClick={handleClick}
            className={styles.actionButton}
          >
            <IconText icon={icon} iconStyles={styles.icon} text={text} />
          </button>
        );
      })}
      placement="bottom-end"
      showOnClick
    >
      <Button
        label={title}
        theme={ButtonTheme.Ghost}
        iconName="export"
        iconOnly
      />
    </Popover>
  );
};

export default ExportButton;
