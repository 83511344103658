import classNames from 'classnames';
import Button, { ButtonTheme } from 'components/elements/Button';
import { TableRowActions } from '../types';
import styles from './Actions.module.css';

type Props = {
  actions: TableRowActions;
  className?: string;
};

const defaultProps: Partial<Props> = {
  className: '',
};

const Actions = ({ actions, className }: Props) => (
  <div className={classNames(styles.container, className)}>
    {actions.map(
      ({
        title,
        name,
        icon,
        onClick,
        isDisabled = false,
        theme = ButtonTheme.Ghost,
        kind = undefined,
        testId,
      }) => (
        <div key={name} className={styles.btnWrapper}>
          <Button
            id={title}
            label={title}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            data-testid={testId}
            disabled={isDisabled}
            theme={theme}
            kind={kind}
            iconName={icon}
            iconOnly
            testId={testId}
          />
        </div>
      ),
    )}
  </div>
);

Actions.defaultProps = defaultProps;

export default Actions;
