import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UUID } from '@neo1/client';
import {
  notifyError,
  notifySuccess,
} from 'modules/App/redux/notifications/toaster/thunks';
import { loginAs } from 'modules/Authentification/redux/thunks';
import useAsyncState from 'hooks/asyncState';
import { selectReturnPath } from './redux/selectors';

const useLogAs = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentReturnPath = useSelector(selectReturnPath);

  const onLogAs = useCallback(
    async (userId: UUID, userEmail: string) => {
      const returnPath = currentReturnPath ?? history.location.pathname;
      try {
        await dispatch(loginAs(userId, returnPath));
        history.replace('/');
        dispatch(notifySuccess(`Successfully logged in as ${userEmail}`));
      } catch (error) {
        dispatch(notifyError(error));
      }
    },
    [dispatch, history],
  );

  return useAsyncState(onLogAs);
};

export default useLogAs;
