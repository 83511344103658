import { useRef, useEffect } from 'react';

import LoaderObserver from 'utils/LoaderObserver';
import SmallLoader from '../SmallLoader';
import styles from './index.module.css';

interface Props {
  onTrigger: () => void;
}

const InfiniteScrollLoader = ({ onTrigger }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;
    LoaderObserver.get().observe(element, onTrigger);
    return () => {
      LoaderObserver.get().unobserve(element);
    };
  }, [ref.current]);

  return (
    <div className={styles.container}>
      <SmallLoader ref={ref} />
    </div>
  );
};

export default InfiniteScrollLoader;
