import Badge from 'components/elements/Badge';
import Icon, { IconName } from 'components/elements/Icon';
import styles from './Tabs.module.css';

type TabTitleProps = {
  title: string;
  iconName?: IconName;
  count?: number;
};

const TabTitleWithBadge = ({ title, count = 0, iconName }: TabTitleProps) => (
  <div className={styles.badgeContainer}>
    {iconName && <Icon name={iconName} className={styles.icon} />}
    {title}
    {count > 0 && <Badge count={count} />}
  </div>
);

TabTitleWithBadge.defaultProps = {
  iconName: undefined,
  count: 0,
};

export default TabTitleWithBadge;
