import { AppCoreState } from 'redux/types';
import { selectActingUser } from 'modules/Authentification/redux/selectors';

const selectActingUserPersistedData = (
  state: AppCoreState,
): AppCoreState['app']['persisted'][string] => {
  const actingUser = selectActingUser(state);
  return actingUser ? state.app.persisted[actingUser.id] : null;
};

export default selectActingUserPersistedData;
