import uniq from 'lodash/uniq';
import { CollectionFilters, SortTypes } from '@neo1/core/utils/collections';
import { Tax } from '@neo1/client/lib/entities/tax/types';
import { TaxState } from './types';
import { Actions, TaxesActions } from './actions';

const initialFilters: CollectionFilters<Tax> = {
  search: null,
  sort: {
    prop: 'taxCode',
    dir: SortTypes.ASC,
  },
};

export const initialState: TaxState = {
  ids: [],
  filters: initialFilters,
  config: {
    companyId: '',
    naTaxCode: '',
    mileageTaxCode: '',
    editableTaxAmount: false,
    reportedTaxAmountCode: '',
  },
};

export default (
  state: TaxState = initialState,
  action: TaxesActions = null,
): TaxState => {
  switch (action?.type) {
    case Actions.SET_TAXES:
      return {
        ...state,
        ids: action.taxesIds,
      };

    case Actions.SET_TAX:
      return {
        ...state,
        ids: uniq([...state.ids, action.taxId]),
      };

    case Actions.DEL_TAX:
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.taxId),
      };

    case Actions.SET_TAX_CONFIG:
      return {
        ...state,
        config: {
          ...state.config,
          ...action.config,
        },
      };

    case Actions.SET_SORT_OPTIONS:
      return {
        ...state,
        filters: {
          ...state.filters,
          sort: action.options,
        },
      };

    case Actions.SET_SEARCH_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.search,
        },
      };

    default:
      return state;
  }
};
