import { AnyAction, applyMiddleware, createStore } from 'redux';
import omit from 'lodash/omit';
import thunk from 'redux-thunk';
import { createEpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from '@redux-devtools/extension';
import throttle from 'lodash/throttle';
import { AppCoreState } from 'redux/types';
import reducers from './reducers';
import epics from './epics';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('redux');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: Partial<AppCoreState>) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('redux', serializedState);
  } catch {
    // ignore write errors
  }
};

export default (state?: AppCoreState) => {
  const epicMiddleware = createEpicMiddleware<
    AnyAction,
    AnyAction,
    AppCoreState
  >();

  const initialState = state ?? loadState();

  const enhancers = composeWithDevTools(applyMiddleware(epicMiddleware, thunk));

  const store = createStore(reducers, initialState, enhancers);
  store.subscribe(
    throttle(() => {
      const { auth } = store.getState();

      saveState({ auth: omit(auth, 'company') });
    }, 200),
  );

  epicMiddleware.run(epics);

  return store;
};
