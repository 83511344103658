import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { Actions, ReconciliationActions } from './actions';
import { ReconciliationState } from './types';

const initialState: ReconciliationState = {
  extractedFiles: {
    isLoaded: false,
    isLoading: false,
    files: [],
    error: null,
  },
  accountIds: [],
  accountReports: {
    isLoaded: false,
    isLoading: false,
    byId: {},
    error: null,
  },
  emittedExtracts: {
    byId: {},
    error: null,
  },
};

export default function reconciliationReducer(
  state: ReconciliationState = initialState,
  action: ReconciliationActions = null,
): ReconciliationState {
  switch (action?.type) {
    case Actions.GetAccountsReports:
      return {
        ...state,
        accountReports: {
          ...state.accountReports,
          isLoading: true,
        },
      };
    case Actions.GetAccountsReportsOk:
      return {
        ...state,
        accountReports: {
          ...state.accountReports,
          isLoading: false,
          isLoaded: true,
          byId: action.reports,
        },
      };
    case Actions.GetAccountsReportsErr:
      return {
        ...state,
        accountReports: {
          ...state.accountReports,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case Actions.SetAccounts:
      return {
        ...state,
        accountIds: action.accountIds,
      };

    case Actions.GetExtractedFiles:
      return {
        ...state,
        extractedFiles: {
          ...state.extractedFiles,
          isLoading: true,
        },
      };
    case Actions.GetExtractedFilesOk:
      return {
        ...state,
        extractedFiles: {
          ...state.extractedFiles,
          isLoading: false,
          isLoaded: true,
          files: uniqWith(
            [...state.extractedFiles.files, ...action.extractedFiles],
            isEqual,
          ),
        },
      };
    case Actions.GetExtractedFilesErr:
      return {
        ...state,
        extractedFiles: {
          ...state.extractedFiles,
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case Actions.BuildExtract:
      return {
        ...state,
        emittedExtracts: {
          ...state.emittedExtracts,
          byId: {
            ...state.emittedExtracts.byId,
            [action.id]: {
              ...state.emittedExtracts.byId[action.id],
              isExtracting: true,
            },
          },
        },
      };

    case Actions.BuildExtractOk:
      return {
        ...state,
        emittedExtracts: {
          ...state.emittedExtracts,
          byId: {
            ...state.emittedExtracts.byId,
            [action.id]: {
              ...state.emittedExtracts.byId[action.id],
              isExtracting: false,
            },
          },
        },
      };

    case Actions.BuildExtractErr:
      return {
        ...state,
        emittedExtracts: {
          ...state.emittedExtracts,
          byId: {
            ...state.emittedExtracts.byId,
            [action.id]: {
              ...state.emittedExtracts.byId[action.id],
              isExtracting: false,
            },
          },
        },
      };

    case Actions.GetTxsInErrorSuccess:
      return {
        ...state,
        accountReports: {
          ...state.accountReports,
          byId: {
            ...state.accountReports.byId,
            [action.extract.account.id]: state.accountReports.byId[
              action.extract.account.id
            ].map((extract) => {
              if (extract.id !== action.extract.id) {
                return extract;
              }

              return {
                ...extract,
                errorDetails: {
                  ...extract.errorDetails,
                  txErrorsList: action.txsInError,
                },
              };
            }),
          },
        },
      };

    default:
      return state;
  }
}
