import ScopeType from 'redux/scopeType';

export type ScopeOption = {
  label: string;
  value: ScopeType;
};

export type State = {
  scope: ScopeType;
};

export enum Actions {
  CHANGE_SCOPE = 'one/home/CHANGE_SCOPE',
}

export type Action = {
  type: Actions.CHANGE_SCOPE;
  scope: ScopeType;
};
