import { UserLevel } from '@neo1/client/lib/entities/user/constants';
import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';
import { AppMenuRoute } from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import Page from 'components/layout/Page';
import {
  ROUTE_NAME_RECEIPTS,
  ROUTE_PATH_RECEIPTS,
  ROUTE_PATH_RECEIPTS_TRAVEL,
  ROUTE_NAME_RECEIPTS_TRAVEL,
  ROUTE_PATH_RECEIPTS_SPEND,
  ROUTE_NAME_RECEIPTS_SPEND,
} from './constants';
import { hasSpendReceiptPage, hasTravelReceiptPage } from './utils';

const loadingPlaceholder = <Page isLoading />;

const receiptsRoute: AppMenuRoute = {
  path: ROUTE_PATH_RECEIPTS,
  name: ROUTE_NAME_RECEIPTS,
  menu: {
    iconClass: 'receipts',
    userLevels: [UserLevel.COMPANY],
    companyFeatures: [CompanyFeature.Spend, CompanyFeature.Travel],
    hasBadge: true,
    show: ({ company, user }) =>
      hasSpendReceiptPage(company) || hasTravelReceiptPage(company, user),
  },
  children: [
    {
      title: 'Spend receipts',
      path: ROUTE_PATH_RECEIPTS_SPEND,
      name: ROUTE_NAME_RECEIPTS_SPEND,
      strict: true,
      exact: true,
      show: ({ company }) => hasSpendReceiptPage(company),
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "spendReceipts" */
            './pages/Spend'
          ),
        loadingPlaceholder,
      ),
    },
    {
      title: 'Travel receipts',
      path: ROUTE_PATH_RECEIPTS_TRAVEL,
      name: ROUTE_NAME_RECEIPTS_TRAVEL,
      strict: true,
      exact: true,
      show: ({ company, user }) => hasTravelReceiptPage(company, user),
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "travelReceipts" */
            './pages/Travel'
          ),
        loadingPlaceholder,
      ),
    },
  ],
};

export default receiptsRoute;
