import { defineMessages } from 'react-intl';

const messages = defineMessages({
  termsAndConditions: {
    id: 'TermsAndConditions__termsAndConditions',
    defaultMessage: 'Terms & Conditions',
  },
  IAgree: {
    id: 'TermsAndConditions__iAgree',
    defaultMessage: 'I agree to the ',
  },
});
export default messages;
