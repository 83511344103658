export const COMPANY_ENTITY_NAME = 'Company';

export enum CompanyFeature {
  Spend = 'spend_management',
  AmexVirtualAccounts = 'virtual_card_management',
  Travel = 'neo_travel',
  AmazonPurchase = 'amazon_purchase',
}

export enum CompanyPurchaseService {
  Amazon = 'amazon',
  Hotel = 'hotel',
}

export const COMPANY_FEATURES = [
  CompanyFeature.Spend,
  CompanyFeature.AmexVirtualAccounts,
  CompanyFeature.Travel,
  CompanyFeature.AmazonPurchase,
];

export enum CompanyStatus {
  ActivationInProgress = 'activation_in_progress',
  Active = 'active',
  PendingActivation = 'pending_activation',
  Locked = 'locked',
  DueDiligenceFailed = 'due_diligence_failed',
}

export enum CompanyKind {
  SoleTrader = 'sole_trader',
  LimitedCompany = 'limited_company',
  UnlimitedCompany = 'unlimited_company',
  Partnership = 'partnership',
  LimitedLiabilityPartnership = 'limited_liability_partnership',
  ClubOrAssociation = 'club_or_association',
  Society = 'society',
  SoleProprietorship = 'sole_proprietorship',
  LimitedLiabilityCompany = 'limited_liability_company',
  BusinessCorporation = 'business_corporation',
  Other = 'other',
}

export enum CompanyIndustry {
  AerospaceAndDefense = 'aerospace_and_defense',
  Agriculture = 'agriculture',
  AutomotiveAndTransport = 'automotive_and_transport',
  Banking = 'banking',
  Beverages = 'beverages',
  BusinessServices = 'business_services',
  CharitableOrganizations = 'charitable_organizations',
  Chemicals = 'chemicals',
  ComputerHardware = 'computer_hardware',
  ComputerServices = 'computer_services',
  ComputerSoftware = 'computer_software',
  Construction = 'construction',
  ConsumerProductsManufacturers = 'consumer_products_manufacturers',
  ConsumerServices = 'consumer_services',
  CulturalInstitutions = 'cultural_institutions',
  Education = 'education',
  Electronics = 'electronics',
  EnergyAndUtilities = 'energy_and_utilities',
  EnvironmentalServicesAndEquipment = 'environmental_services_and_equipment',
  FinancialServices = 'financial_services',
  Food = 'food',
  Foundations = 'foundations',
  government = 'government',
  Healthcare = 'healthcare',
  IndustrialManufacturing = 'industrial_manufacturing',
  Insurance = 'insurance',
  Leisure = 'leisure',
  Media = 'media',
  MembershipOrganizations = 'membership_organizations',
  MetalsAndMining = 'metals_and_mining',
  Pharmaceuticals = 'pharmaceuticals',
  RealEstate = 'real_estate',
  Retail = 'retail',
  SecurityProductsAndServices = 'security_products_and_services',
  TelecommunicationsEquipment = 'telecommunications_equipment',
  TelecommunicationsServices = 'telecommunications_services',
  TransportationServices = 'transportation_services',
}

export enum AccountingConfigStatus {
  OngoingSynchronization = 'ongoing_synchronization',
  OngoingTaxConfiguration = 'ongoing_tax_configuration',
  OngoingCashTxConfiguration = 'ongoing_cash_tx_configuration',
  OngoingCardTxConfiguration = 'ongoing_card_tx_configuration',
  CompletedCardTxConfiguration = 'completed_card_tx_configuration',
  NotStarted = 'not_started',
}
export enum CashAccountMappingPreferences {
  SingleVendor = 'single_vendor',
  UserMapping = 'user_mapping',
}
export enum CreditCardMappingPreferences {
  SingleVendor = 'single_vendor',
  MerchantAsVendor = 'merchant_as_vendor',
}
export enum UserMappingType {
  ExistingVendor = 'existing_vendor',
  NewVendor = 'new_vendor',
}

export enum CompanyExtractService {
  Standard = 'standard',
  Xero = 'xero',
  QuickBooks = 'quickbooks',
  Sage = 'sage',
}

export enum CompanyNumberType {
  RegistrationNumber = 'registration_number',
  Duns = 'duns',
  Ein = 'ein',
}

export const COMPANY_EXTERNAL_ACCOUNTING_LINKS: Array<CompanyExtractService> = [
  CompanyExtractService.Xero,
  CompanyExtractService.QuickBooks,
  CompanyExtractService.Sage,
];

export const COMPANY_PURCHASE_SERVICES: Array<CompanyPurchaseService> = [
  CompanyPurchaseService.Amazon,
];
