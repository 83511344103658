import { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './Dropbox.module.css';

export type DropBoxPosition = 'up' | 'down' | 'sticked';

type Props = {
  children: ReactNode;
  position?: DropBoxPosition;
  isOpen?: boolean;
  className?: string;
};

const DropBox = ({ children, position, isOpen, className }: Props) => (
  <div
    className={classNames(className, styles.container, styles[position], {
      [styles.show]: isOpen,
    })}
  >
    {isOpen ? children : null}
  </div>
);

DropBox.defaultProps = {
  position: 'up',
  isOpen: false,
  className: undefined,
};

export default DropBox;
