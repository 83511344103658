import { MileageUserVehicle } from '@neo1/client/lib/entities/mileage/types';

export interface MileageUserVehicleWithId extends MileageUserVehicle {
  id: string;
}

export enum Actions {
  SET_VEHICLE = 'one/profile/SET_VEHICLE',
  SET_VEHICLES = 'one/profile/SET_VEHICLES',
  DELETE_VEHICLE = 'one/profile/DELETE_VEHICLE',
}

export type SetVehicleAction = {
  type: typeof Actions.SET_VEHICLE;
  id: string;
};

export type SetVehiclesAction = {
  type: typeof Actions.SET_VEHICLES;
  ids: string[];
};

export type DeleteVehicleAction = {
  type: typeof Actions.DELETE_VEHICLE;
  id: string;
};

export type State = {
  ids: string[];
};

export type Action = SetVehicleAction | SetVehiclesAction | DeleteVehicleAction;
