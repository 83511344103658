import omit from 'lodash/omit';
import { PersistedState } from './types';
import { Actions, PersistanceActions } from './actions';

const initialState: PersistedState = {};

export default function reducer(
  persistedState = initialState,
  action: PersistanceActions = null,
): PersistedState {
  switch (action?.type) {
    case Actions.SetData:
      return {
        ...persistedState,
        [action.userId]: {
          ...persistedState[action.userId],
          [action.key]: action.val,
        },
      };

    case Actions.DeleteData:
      return {
        ...persistedState,
        [action.userId]: omit(persistedState[action.userId], action.key),
      };

    case Actions.SetMulti:
      return {
        ...persistedState,
        [action.userId]: {
          ...persistedState[action.userId],
          ...action.val,
        },
      };

    default:
      return persistedState;
  }
}
