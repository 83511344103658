import { defineMessages } from 'react-intl';

const messages = {
  tabTitles: defineMessages({
    authorizeBudget: {
      id: 'TabTitle__authorizeBudget',
      defaultMessage: 'Budget',
    },
    authorizePurchase: {
      id: 'TabTitle__authorizePurchase',
      defaultMessage: 'Purchase',
    },
    authorizeSpend: {
      id: 'TabTitle__authorizeSpend',
      defaultMessage: 'Spend',
    },
  }),
};

export default messages;
