export const USER_ENTITY_NAME = 'User';
export const COMPANY_GROUP_USER_ENTITY_NAME = 'CompanyGroupUser';

export enum CompanyUserRole {
  Finance = 'finance',
  Admin = 'admin',
  Arranger = 'arranger',
  TravelHost = 'travel_host',
}

export enum UserLevel {
  SYSTEM = 'system',
  DOMAIN = 'domain',
  COMPANY = 'company',
}

export const USER_LEVELS = [
  UserLevel.SYSTEM,
  UserLevel.DOMAIN,
  UserLevel.COMPANY,
];

export enum UserStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  LOCKED = 'locked',
  PENDING_ACTIVATION = 'pending_activation',
}

export const COMPANY_USER_STATUSES = [
  UserStatus.ACTIVE,
  UserStatus.DEACTIVATED,
  UserStatus.LOCKED,
  UserStatus.PENDING_ACTIVATION,
];

enum UserJob {
  OWNER = 'owner',
  FINANCE_TEAM = 'finance_team',
  OFFICE_MANAGER = 'office_manager',
  VALUABLE_EMPLOYEE = 'valuable_employee',
}

export const USER_JOBS = [
  UserJob.OWNER,
  UserJob.FINANCE_TEAM,
  UserJob.OFFICE_MANAGER,
  UserJob.VALUABLE_EMPLOYEE,
];

export enum UserTitle {
  MR = 'mr',
  MRS = 'mrs',
  MS = 'ms',
  MISS = 'miss',
  DR = 'dr',
  MX = 'mx',
}

export const COMPANY_USER_TITLES = [
  UserTitle.MR,
  UserTitle.MRS,
  UserTitle.MS,
  UserTitle.MISS,
  UserTitle.DR,
  UserTitle.MX,
];

export enum TravelerGroup {
  Employees = 'employees',
  Executives = 'executives',
}
