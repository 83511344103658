import ScopeType from 'redux/scopeType';
import { State, Action, Actions } from './types';

const initialState: State = {
  scope: ScopeType.User,
};

const reducer = (state: State = initialState, action?: Action): State => {
  switch (action.type) {
    case Actions.CHANGE_SCOPE:
      return {
        ...state,
        scope: action.scope,
      };

    default:
      return state;
  }
};

export default reducer;
