import { useIntl } from 'react-intl';
import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import { useWindowContext, DeviceSize } from 'contexts/window';
import useLogout from 'modules/Authentification/useLogout';
import messages from './messages';
import styles from './styles.module.css';

const Footer = () => {
  const intl = useIntl();
  const { deviceSize } = useWindowContext();
  const { execute: logout } = useLogout();

  return (
    <footer className={styles.container}>
      <Button
        label={intl.formatMessage(messages.logOut)}
        theme={ButtonTheme.Outlined}
        kind={ButtonKind.Neutral}
        dark={deviceSize !== DeviceSize.desktop}
        onClick={() => logout()}
        iconName="logout"
      />
    </footer>
  );
};

export default Footer;
