import { Component } from 'react';

function withDocumentTitle(title, WrappedComponent) {
  return class DocumentTitle extends Component {
    componentDidMount() {
      document.title = `Neo1 - ${title}`;
    }

    render() {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withDocumentTitle;
