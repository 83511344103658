/**
 * Auth Reducer
 * Manages authentication state and authenticated-user related features
 */
import { AuthActions, AuthActionsType } from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  authToken: null,
  returnPath: undefined,
  currentUserId: undefined,
  delegatedUsersIds: [],
  effectiveUserId: undefined,
  isLoggedInAs: false,
  loggedCompanyId: undefined,
  company: undefined,
  isProcessing: false,
};

export default function reducer(
  state: AuthState = initialState,
  action: AuthActionsType = null,
): AuthState {
  switch (action?.type) {
    case AuthActions.LOGIN:
      return {
        ...state,
        currentUserId: action.userId,
        delegatedUsersIds: action.delegatedUsersIds,
      };

    case AuthActions.LOGOUT:
      return initialState;

    case AuthActions.LOGIN_AS:
      return {
        ...state,
        returnPath: action.returnPath,
        effectiveUserId: action.userId,
        isProcessing: true,
      };

    case AuthActions.LOGIN_AS_SUCCESS:
      return {
        ...state,
        isLoggedInAs: true,
        isProcessing: false,
      };

    case AuthActions.LOGIN_AS_FAIL:
      return {
        ...state,
        effectiveUserId: undefined,
        isLoggedInAs: false,
        isProcessing: false,
      };

    case AuthActions.LOGIN_AS_COMPANY:
      return {
        ...state,
        returnPath: action.returnPath,
        effectiveUserId: state.currentUserId,
        loggedCompanyId: action.companyId,
        isProcessing: true,
      };

    case AuthActions.LOGIN_AS_COMPANY_SUCCESS:
      return {
        ...state,
        isLoggedInAs: true,
        isProcessing: false,
      };

    case AuthActions.LOGIN_AS_COMPANY_FAIL:
      return {
        ...state,
        effectiveUserId: undefined,
        isLoggedInAs: false,
        loggedCompanyId: undefined,
        isProcessing: false,
      };

    case AuthActions.LOGOUT_AS:
      return {
        ...state,
        effectiveUserId: undefined,
        isLoggedInAs: false,
        loggedCompanyId: undefined,
        returnPath: undefined,
        company: undefined,
      };

    case AuthActions.SET_TOKEN:
      return {
        ...state,
        authToken: action.token,
      };

    case AuthActions.SET_AUTH_COMPANY:
      return {
        ...state,
        company: action.payload,
      };

    case AuthActions.SET_COMPANY_FUNDING_APPROVERS_DATA:
      return {
        ...state,
        company: { ...state.company, fundingApproversData: action.payload },
      };

    case AuthActions.ADD_COMPANY_USER:
      return {
        ...state,
        company: {
          ...state.company,
          groupUsersIds: [...state.company.groupUsersIds, action.userId],
        },
      };

    case AuthActions.DELETE_COMPANY_USER:
      return {
        ...state,
        company: {
          ...state.company,
          groupUsersIds: state.company.groupUsersIds.filter(
            (id) => id !== action.userId,
          ),
        },
      };

    case AuthActions.SET_PROCESSING:
      return {
        ...state,
        isProcessing: true,
      };

    case AuthActions.UNSET_PROCESSING:
      return {
        ...state,
        isProcessing: false,
      };

    default:
      return state;
  }
}
