import { ReactNode, Component } from 'react';

type Props = {
  children: ReactNode;
  renderComponent: () => ReactNode;
};

type State = {
  error: Error;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: Error) {
    this.setState({
      error,
    });

    if (window.cwr) {
      window.cwr('recordError', error);
    }
  }

  render() {
    const { error } = this.state;
    const { children, renderComponent } = this.props;
    if (error) {
      return renderComponent();
    }
    return children;
  }
}

export default ErrorBoundary;
