import { defineMessages } from 'react-intl';

const messages = defineMessages({
  modalCancelTitle: {
    id: 'modalCancelTitle',
    defaultMessage: 'Cancel connection',
  },
  formTitle: {
    id: 'formTitle',
    defaultMessage:
      'Congratulations your Neo1 account is now connected to Quickbooks!',
  },
  formDescription1: {
    id: 'formDescription1',
    defaultMessage:
      'To finalize your setup, select the tax codes to be used for international and zero rated domestic expenses.',
  },
  internationalTax: {
    id: 'internationalTax',
    defaultMessage: 'Non-domestic tax',
  },
  internationalTaxInfo: {
    id: 'internationalTaxInfo',
    defaultMessage:
      'This is the tax code used for international / non domestic transactions.',
  },
  zeroRatedTax: {
    id: 'zeroRatedTax',
    defaultMessage: 'Domestic 0% tax',
  },
  zeroRatedTaxInfo: {
    id: 'zeroRatedTaxInfo',
    defaultMessage:
      'This is the tax code used for domestic transactions where no tax is reimbursable. Either no tax is applicable, or evidence used to substantiate a reimbursement is not available.',
  },
  mileageZeroRatedTax: {
    id: 'milageZeroRatedTax',
    defaultMessage: 'Mileage 0% tax',
  },
  mileageZeroRatedTaxInfo: {
    id: 'mileageZeroRatedTaxInfo',
    defaultMessage: 'This is the tax code used for Mileage transactions.',
  },
  naTax: {
    id: 'naTax',
    defaultMessage: 'No tax',
  },
  naTaxInfo: {
    id: 'naTaxInfo',
    defaultMessage:
      'This is the tax code used when tax is not applicable for the transaction.',
  },
  selectPlaceholder: {
    id: 'selectPlaceholder',
    defaultMessage: '- Select a value -',
  },
  loadingConnectionDescription: {
    id: 'loadingConnectionDescription',
    defaultMessage: 'Wait a moment please, we are loading your taxes.',
  },
  NoTaxes: {
    id: 'noTaxes',
    defaultMessage: 'No taxes were retrieved from Quickbooks.',
  },

  taxConfigurationFormTitle: {
    id: 'taxConfigurationFormTitle',
    defaultMessage: 'Defaults',
  },
});

export default messages;
