import get from 'lodash/get';
import { formatBoolean } from '@neo1/core/utils/strings';
import { csvValue } from '@neo1/core/utils/csv';
import { exportCsvArray } from 'utils/files';
import { TableColumns } from 'components/elements/DataTable/types';

const exportCsvFromTable = (
  columns: TableColumns,
  rows: Array<Object>,
  // TODO Deprecated, use column prop renderCSV
  formatCsvValue?: (prop: string, data: Object) => string,
  fileName?: string,
  exportExtraData?: (row: Object) => Record<string, string | number>,
) => {
  const csvArray = rows.map((row) => {
    const columnsData = columns.reduce((line, column) => {
      let value = '';
      // TODO Deprecated, use column prop renderCSV
      if (formatCsvValue) {
        value = formatCsvValue(column.prop, row);
      } else {
        value = get(row, column.prop);
      }

      if (typeof value === 'boolean') {
        value = formatBoolean(value);
      } else if (!value) {
        value = column.default;
      }

      return {
        ...line,
        [column.title ?? column.prop]: column.renderCSV
          ? column.renderCSV(value, row, column)
          : csvValue(value),
      };
    }, {});
    const extraData = exportExtraData ? exportExtraData(row) : {};
    return { ...columnsData, ...extraData };
  });

  return exportCsvArray(csvArray, fileName);
};

export default exportCsvFromTable;
