import { ReactNode } from 'react';
import classNames from 'classnames';
import Icon, { IconName } from 'components/elements/Icon';
import Button, { ButtonTheme } from 'components/elements/Button';

import styles from './Tile.module.css';

export type TileType = 'twoCol' | 'fourCol' | 'threeCol' | 'fourEqual';

type Props = {
  className?: string;
  title: ReactNode;
  onEdit?(): void;
  onDelete?(): void;
  iconName?: IconName;
  iconPlaceholder?: ReactNode;
  contentClassName?: string;
  iconClassName?: string;
  shadowType?: 'blurry' | 'normal';
  disabled?: boolean;
};

const Tile = ({
  className,
  iconName,
  title,
  onEdit,
  onDelete,
  iconPlaceholder,
  iconClassName,
  contentClassName,
  shadowType,
  disabled,
}: Props) => {
  const shadowsClassName =
    styles[shadowType === 'normal' ? 'normalShadows' : 'blurryShadows'];
  return (
    <div className={classNames(styles.simpleMain, className, shadowsClassName)}>
      <div className={classNames(styles.iconContainer, iconClassName)}>
        {iconName ? (
          <Icon className={styles.icon} name={iconName} />
        ) : (
          <span className={styles.icon}>{iconPlaceholder}</span>
        )}
      </div>
      <div className={classNames(styles.contentContainer, contentClassName)}>
        <h4 className={styles.title}>{title}</h4>
        <div className={styles.actions}>
          {onEdit && (
            <Button
              label="Edit"
              ariaLabel={`Edit ${title}`}
              onClick={onEdit}
              theme={ButtonTheme.Outlined}
              disabled={disabled}
            />
          )}
          {onDelete && (
            <Button
              label="Delete"
              ariaLabel={`Delete ${title}`}
              onClick={onDelete}
              theme={ButtonTheme.Outlined}
              disabled={disabled}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Tile.defaultProps = {
  className: undefined,
  iconName: undefined,
  iconPlaceholder: null,
  contentClassName: undefined,
  iconClassName: undefined,
  shadowType: undefined,
  onEdit: undefined,
  onDelete: undefined,
  disabled: false,
};

export default Tile;
