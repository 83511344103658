import Icon from 'components/elements/Icon';
import Button from 'components/elements/Button';

import { CompanyHouseRegistrationDetails } from './utils';
import styles from './RegistrationDetails.module.css';

interface Props {
  details: CompanyHouseRegistrationDetails;
  onSelect: Function;
}

function RegistrationDetails({
  details,
  details: { addressLine1, city, zipCode, name: companyName },
  onSelect,
}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>
        <Icon name="organization" className={styles.icon} />
      </div>
      <div className={styles.details}>
        <h4>{companyName}</h4>
        <span>{addressLine1}</span>
        <br />
        <span>
          {zipCode} {city}
        </span>
      </div>
      <Button
        label="Confirm"
        ariaLabel={`Confirm select ${companyName}`}
        onClick={() => onSelect(details)}
      />
    </div>
  );
}

export default RegistrationDetails;
