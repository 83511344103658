import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import useAsyncState from 'hooks/asyncState';
import { ThunkDispatch } from 'redux-thunk';
import { AppCoreState } from 'redux/types';
import classNames from 'classnames';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import {
  getAccountingConfig,
  startQuickBooksConnection,
} from 'redux/accountingIntegrations/thunks';
import * as companiesApi from '@neo1/client/lib/entities/company/api';
import {
  AccountingConfigStatus,
  CashAccountMappingPreferences,
  CompanyExtractService,
  UserMappingType,
} from '@neo1/client/lib/entities/company/constants';
import { selectAccountingConfig } from 'redux/accountingIntegrations/selector';
import { setTaxConfig } from 'modules/Settings/Taxes/redux/thunks';
import MultiStepForm from 'components/composed/MultiStepModalForm';
import { Theme } from 'components/composed/MultiStepModalForm/Content';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';
import { ReactComponent as QuickbooksLogo } from '../../../../../../../public/images/integrations/quickbooks.svg';
import styles from './QuickbooksModal.module.css';
import GetStartedStep, {
  validation as getStartedValidation,
  initialValues as getStartedInitialValues,
} from './Steps/GetStarted';
import DataSychronizationComp from './Steps/DataSynchronization';
import TaxConfiguration, {
  ConfigureAccountingValues,
  INITIAL_VALUES,
} from './Steps/TaxConfiguration';
import CashTxsConf, {
  OOPValues,
  validationCashConf,
  INITIAL_VALUES as oopInitialValues,
} from './Steps/CashTxsConfiguration';
import CardTxsConf, {
  CardValues,
  validationCardConf,
  INITIAL_VALUES as cardMappingInitialValues,
} from './Steps/CardTxsConfiguration';
import Finish from './Steps/FinishStep';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export enum ConfigurationStep {
  GetStarted,
  QuickbooksConnection,
  DataSychronization,
  TaxMapping,
  OopVendorMapping,
  CardMapping,
  FinishStep,
}
export const MappingStatusSteps = {
  [AccountingConfigStatus.NotStarted]: ConfigurationStep.GetStarted,
  [AccountingConfigStatus.OngoingSynchronization]:
    ConfigurationStep.DataSychronization,
  [AccountingConfigStatus.OngoingTaxConfiguration]:
    ConfigurationStep.TaxMapping,
  [AccountingConfigStatus.OngoingCashTxConfiguration]:
    ConfigurationStep.OopVendorMapping,
  [AccountingConfigStatus.OngoingCardTxConfiguration]:
    ConfigurationStep.CardMapping,
  [AccountingConfigStatus.CompletedCardTxConfiguration]:
    ConfigurationStep.DataSychronization,
};

const SideContent = () => (
  <div className={classNames(styles.title, 'header3')}>
    QuickBooks integration
  </div>
);

const QuickBooksModal = ({ isOpen, onClose }: Props) => {
  const [failedRecords, setFailedRecords] = useState<string[]>([]);
  const location = useLocation();
  const dispatch: ThunkDispatch<AppCoreState, void, any> = useDispatch();
  const company = useSelector(selectActingCompany);
  const { id } = company;

  const onGetAccountingConfig = useCallback(async () => {
    try {
      await dispatch(getAccountingConfig(id));
    } catch (err) {
      dispatch(notifyError(err));
    }
  }, [dispatch, id]);

  const config = useSelector(
    selectAccountingConfig(CompanyExtractService.QuickBooks),
  );

  const step = MappingStatusSteps[config.configStatus];

  const [currentStep, setCurrentStep] = useState(step);

  const { execute } = useAsyncState(onGetAccountingConfig);

  const onCloseQbo = () => {
    execute();
    onClose();
  };

  useEffect(() => {
    execute();
  }, [execute]);

  useEffect(() => {
    setCurrentStep(step);
  }, [step]);

  const handleOnChangeStep = (newStep: ConfigurationStep) =>
    setCurrentStep(newStep);

  const handleSubmitError = (e: Error) => {
    if (e.message) dispatch(notifyError(e));
  };
  const steps = [
    {
      key: ConfigurationStep.GetStarted,
      sideContent: <SideContent />,
      label: 'About',
      content: <GetStartedStep />,
      initialValues: getStartedInitialValues,
      validate: getStartedValidation,
      onSubmit: async () => {
        await startQuickBooksConnection(id, location);
      },
    },
    {
      key: ConfigurationStep.QuickbooksConnection,
      sideContent: <SideContent />,
      label: 'Connect Neo1 to Quickbooks online',
      content: (
        <StepContent
          isLoading
          loadingMsg="You are being redirected to Quickbooks online"
        />
      ),
    },
    {
      key: ConfigurationStep.DataSychronization,
      sideContent: <SideContent />,
      label: 'Synchronize your data',
      content: <DataSychronizationComp />,
    },
    {
      key: ConfigurationStep.TaxMapping,
      label: 'Map your taxes',
      sideContent: <SideContent />,
      initialValues: INITIAL_VALUES,
      content: (
        <TaxConfiguration
          onSkip={() => handleOnChangeStep(ConfigurationStep.OopVendorMapping)}
        />
      ),
      onSubmit: async (values: ConfigureAccountingValues) => {
        await dispatch(
          setTaxConfig({
            companyId: company.id,
            internationalTaxCode: values.internationalTax,
            naTaxCode: values.naTax,
            domesticZeroRatedTaxCode: values.zeroRatedTax,
            mileageTaxCode: values.mileageZeroRatedTax,
            editableTaxAmount: values.editableTaxAmount,
            reportedTaxAmountCode: values.reportedTaxAmountCode,
          }),
        );
      },
    },
    {
      key: ConfigurationStep.OopVendorMapping,
      sideContent: <SideContent />,
      label: 'Map the Out of pocket operations to vendors',
      content: <CashTxsConf failedRecords={failedRecords} />,
      initialValues: {
        ...oopInitialValues,
        mappingPreferences:
          config.cashTxMappingPreferences ||
          oopInitialValues.mappingPreferences,
        vendorId: config.singleCashTxVendorId || oopInitialValues.vendorId,
      },
      isPartialSubmit: () => true,
      validate: validationCashConf,
      onSubmit: async (values: OOPValues) => {
        const {
          mappingPreferences,
          vendorId,
          mappings: indexedMappings,
        } = values;
        let payload = {
          mappingPreferences,
          vendorId: null,
          usersMapping: [],
        };

        if (mappingPreferences === CashAccountMappingPreferences.UserMapping) {
          const mappings = Object.values(indexedMappings);
          const userMappings = mappings.map((u) => ({
            userId: u.userId,
            mapping: {
              mappingType: u.vendorId
                ? UserMappingType.ExistingVendor
                : UserMappingType.NewVendor,
              vendorId: u.vendorId || null,
            },
          }));
          payload = {
            ...payload,
            usersMapping: userMappings,
          };
        } else {
          payload = {
            ...payload,
            vendorId,
          };
        }

        const { failed } = await companiesApi.createCashTxConfig(
          company.id,
          payload,
        );
        if (!isEmpty(failed)) {
          setFailedRecords(failed);
          dispatch(notifyError('There are some failed mappings.'));
        } else handleOnChangeStep(ConfigurationStep.CardMapping);
      },
    },
    {
      key: ConfigurationStep.CardMapping,
      sideContent: <SideContent />,
      label: 'Map the Card transactions to vendors',
      content: <CardTxsConf />,
      initialValues: {
        ...cardMappingInitialValues,
        mappingPreferences:
          config.cardTxMappingPreferences ||
          cardMappingInitialValues.mappingPreferences,
        vendorId:
          config.singleCardTxVendorId || cardMappingInitialValues.vendorId,
      },
      validate: validationCardConf,
      onSubmit: async (values: CardValues) => {
        await companiesApi.createCardTxConfig(company.id, values);
      },
    },
    {
      key: ConfigurationStep.FinishStep,
      sideContent: <SideContent />,
      label: 'Finish step',
      content: <Finish />,
      onSubmit: onCloseQbo,
    },
  ];

  return (
    <MultiStepForm
      isOpen={isOpen}
      onClose={onCloseQbo}
      steps={steps}
      contentDesktopWidth={720}
      theme={Theme.Green}
      currentStep={currentStep}
      onChangeStep={handleOnChangeStep}
      onSubmitError={handleSubmitError}
      logo={<QuickbooksLogo height={72} width={72} />}
    />
  );
};

export default QuickBooksModal;
