import classNames from 'classnames';
import { CompanyActivationOwner } from '@neo1/client/lib/entities/persistedUserData/types';
import Tile from 'components/composed/Tile';

import styles from './OwnerDetails.module.css';

const hasFullName = (owner: CompanyActivationOwner) =>
  owner && owner.firstName && owner.lastName;

interface Props {
  className?: string;
  owner: CompanyActivationOwner;
  no: number;
  onEdit: () => void;
  onDelete: () => void;
  disabled?: boolean;
}

function OwnerDetails({
  className,
  owner,
  no,
  onEdit,
  onDelete,
  disabled,
}: Props) {
  const ownerDisplayName = hasFullName(owner)
    ? `${owner.firstName} ${owner.lastName}`
    : null;
  return (
    <Tile
      className={classNames(className, styles.container)}
      iconClassName={styles.iconClassName}
      contentClassName={styles.content}
      title={ownerDisplayName}
      iconPlaceholder={no}
      onEdit={onEdit}
      onDelete={onDelete}
      disabled={disabled}
    />
  );
}

OwnerDetails.defaultProps = {
  className: undefined,
  disabled: false,
};

export default OwnerDetails;
