import { createContext, useContext, ReactNode } from 'react';
import { AppRoute } from 'config/routes/utils';

const defaultValue = [];

type State = AppRoute[];

type Props = {
  routes: AppRoute[];
  children: ReactNode;
};

export const RoutesContext = createContext<State>(defaultValue);

export const RoutesContextProvider = ({ children, routes }: Props) => (
  <RoutesContext.Provider value={routes}>{children}</RoutesContext.Provider>
);

export const useRoutesContext = () => useContext(RoutesContext);
