import { UUID } from '@neo1/client';
import {
  AmexEnrollmentStatus,
  AmexEnrollmentError,
} from '@neo1/client/lib/entities/amexVirtualAccount/contants';
import { EnabledUser } from '@neo1/client/lib/entities/amexVirtualAccount/types';

export enum AmexVitualAccountsActionsType {
  SetEnrollmentStatus = 'one/settings/myCompany/SET_ENROLLMENT_STATUS',
  SetEnrollmentErrors = 'one/settings/myCompany/SET_ENROLLMENT_ERRORS',
  SetAccounts = 'one/settings/myCompany/SET__ACCOUNT_IDS',
  SetEnabledUsers = 'one/settings/myCompany/SET_AMEX_VIRTUAL_ACCOUNT_ENABLED_USERS',
  DisconnectOK = 'one/settings/myCompany/LOAD_AMEX_VIRTUAL_ACCOUNT_DISCONNECT_OK',
}

export const setEnrollmentStatus = (status: AmexEnrollmentStatus) => ({
  type: AmexVitualAccountsActionsType.SetEnrollmentStatus,
  status,
});

export const setEnrollmentErrors = (errors: AmexEnrollmentError[]) => ({
  type: AmexVitualAccountsActionsType.SetEnrollmentErrors,
  errors,
});

export const setAccounts = (ids: string[]) => ({
  type: AmexVitualAccountsActionsType.SetAccounts,
  ids,
});

export const setEnabledUsers = (enabledUsers: EnabledUser[]) => ({
  type: AmexVitualAccountsActionsType.SetEnabledUsers,
  enabledUsers,
});

export const disconnectSuccess = () => ({
  type: AmexVitualAccountsActionsType.DisconnectOK,
});

export type AmexVitualAccountsAction =
  | {
      type: AmexVitualAccountsActionsType.SetEnrollmentStatus;
      status: AmexEnrollmentStatus;
    }
  | {
      type: AmexVitualAccountsActionsType.SetEnrollmentErrors;
      errors: AmexEnrollmentError[];
    }
  | {
      type: AmexVitualAccountsActionsType.SetAccounts;
      ids: string[];
    }
  | {
      type: AmexVitualAccountsActionsType.SetEnabledUsers;
      enabledUsers: EnabledUser[];
    }
  | {
      type: AmexVitualAccountsActionsType.DisconnectOK;
      userIds: UUID[];
    };
