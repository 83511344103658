export enum Actions {
  SET_VEHICLE_TYPE = 'one/settings/SET_VEHICLE_TYPE',
  SET_VEHICLE_TYPES = 'one/settings/SET_VEHICLE_TYPES',
  DELETE_VEHICLE_TYPE = 'one/settings/DELETE_VEHICLE_TYPE',
}

export type SetVehicleTypeAction = {
  type: Actions.SET_VEHICLE_TYPE;
  id: number;
};

export type SetVehicleTypesAction = {
  type: Actions.SET_VEHICLE_TYPES;
  ids: number[];
};

export type DeleteVehicleTypeAction = {
  type: Actions.DELETE_VEHICLE_TYPE;
  id: number;
};

export type State = {
  ids: number[];
};

export type Action =
  | SetVehicleTypeAction
  | SetVehicleTypesAction
  | DeleteVehicleTypeAction;
