import { ROUTE_NAME_SPEND } from 'modules/Spend/constants';
import { ROUTE_NAME_PURCHASE_BUDGETS } from 'modules/Budgets/constants';
import { ROUTE_NAME_PURCHASE_SUPPLIES } from 'modules/Supplies/constants';
import { ROUTE_NAME_AUTHORIZE } from 'modules/Authorize/constants';
import { State as CounterState } from 'redux/counter/types';
import ScopeType from 'redux/scopeType';

const getBadgeCountForRouteName = (
  state: CounterState,
  name: string,
): number => {
  switch (name) {
    case ROUTE_NAME_SPEND:
      return state[ScopeType.User]?.tx?.inProgress?.count || 0;
    case ROUTE_NAME_PURCHASE_BUDGETS:
      return state[ScopeType.User]?.budget?.inProgress?.count || 0;
    case ROUTE_NAME_PURCHASE_SUPPLIES:
      return state[ScopeType.User]?.purchase?.inProgress?.count || 0;
    case ROUTE_NAME_AUTHORIZE: {
      const supervision = state[ScopeType.Supervisor];
      if (!supervision) return 0;
      return (
        supervision.tx.toApprove.count +
        supervision.tx.toReview.count +
        supervision.budget.toApprove.count +
        supervision.purchase.toApprove.count
      );
    }
    default:
      return 0;
  }
};

export default getBadgeCountForRouteName;
