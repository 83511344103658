import { defineMessages } from 'react-intl';

const messages = defineMessages({
  synchronizing: {
    id: 'DataSychronization_synchronizing',
    defaultMessage: 'Wait a moment please, we are sychronizing your data.',
  },
  error: {
    id: 'DataSychronization_error',
    defaultMessage: 'An error came up. Please try again.',
  },
  assets: {
    id: 'DataSychronization_assets',
    defaultMessage: 'Assets: ',
  },
  expenses: {
    id: 'DataSychronization_expenses',
    defaultMessage: 'Expenses: ',
  },
});

export default messages;
