import uniq from 'lodash/uniq';
import { DelegationActions, Action, DelegationState } from './types';

export const initialState: DelegationState = {
  toMe: {
    ids: [],
  },
  toOthers: {
    ids: [],
  },
};

export default function delegationsReducer(
  state: DelegationState = initialState,
  action?: DelegationActions,
): DelegationState {
  switch (action.type) {
    case Action.SET_DELEGATIONS:
      return {
        ...state,
        toMe: {
          ids: action.toMe,
        },
        toOthers: {
          ids: action.toOthers,
        },
      };

    case Action.SET_MY_DELEGATION:
      return {
        ...state,
        toOthers: {
          ...state.toOthers,
          ids: uniq([...state.toOthers.ids, action.id]),
        },
      };

    case Action.DELETE_MY_DELEGATION: {
      return {
        ...state,
        toOthers: {
          ...state.toOthers,
          ids: state.toOthers.ids.filter((id) => id !== action.id),
        },
      };
    }

    default:
      return state;
  }
}
