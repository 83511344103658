const SSO_LOGIN_PATH = '/sso/login';

function submitTokenAsFormPost({
  url,
  userToken,
  euid = null,
}: {
  url: string;
  userToken: string;
  euid: string | undefined;
}) {
  const postForm = document.createElement('form');
  postForm.action = url;
  postForm.method = 'post';

  const inputUserToken = document.createElement('input');
  inputUserToken.type = 'hidden';
  inputUserToken.name = 'user_token';
  inputUserToken.value = userToken;
  postForm.appendChild(inputUserToken);

  if (euid) {
    const inputEuid = document.createElement('input');
    inputEuid.type = 'hidden';
    inputEuid.name = 'euid';
    inputEuid.value = euid;
    postForm.appendChild(inputEuid);
  }

  const bodyTag = document.getElementsByTagName('body')[0];
  bodyTag.appendChild(postForm);
  postForm.submit();
}

export function isTravelSSOActive() {
  return location.href.includes(SSO_LOGIN_PATH);
}

export function postLoginTravelSSO(authToken: string, euid?: string) {
  submitTokenAsFormPost({
    url: '/ext/1/samlp/complete',
    userToken: authToken,
    euid,
  });
}
