import { reify } from '../normalize';
import { FundingApproversData } from './types';

export const normalizeFundingApproversData = (
  inputData: any = [],
): FundingApproversData =>
  inputData.reduce(
    (result: FundingApproversData, data: any) => {
      const d = reify(data);
      const fundingApproverId = d.getString('funding_approver_id');
      const ownedAccountIds = d.getArray<string>('owned_account_ids');
      return {
        fundingApproversIds: [...result.fundingApproversIds, fundingApproverId],
        ownedAccountsIds: {
          ...result.ownedAccountsIds,
          [fundingApproverId]: ownedAccountIds,
        },
      };
    },
    {
      fundingApproversIds: [],
      ownedAccountsIds: {},
    },
  );
