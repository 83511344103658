import round from 'lodash/round';
import { metersToMile, DistanceUnit } from './geo';

const getGeocoderService = (): Promise<google.maps.Geocoder> =>
  new Promise((resolve, reject) => {
    const GeocoderService = window.google?.maps.Geocoder;

    if (GeocoderService) {
      return resolve(new GeocoderService());
    }

    return reject('Unable to instanciate GeocoderService');
  });

const getLatLng = (latlng: {
  lat: number;
  lng: number;
}): Promise<google.maps.LatLng> =>
  new Promise((resolve, reject) => {
    if (!latlng) {
      resolve(null);
    }
    const LatLng = window.google?.maps.LatLng;

    if (LatLng) {
      return resolve(new LatLng(latlng));
    }

    return reject('Unable to instanciate LatLng');
  });

export const getMapDefaultViewport = async (
  region: string | null,
  address: string | null,
): Promise<google.maps.LatLngBounds> => {
  return new Promise((resolve) => {
    getGeocoderService().then((service) => {
      service.geocode({ address, region }, (results, status) => {
        if (status === 'OK') {
          const { viewport } = results[0].geometry;
          resolve(viewport);
        } else {
          resolve(null);
        }
      });
    });
  });
};

const getPlacesService = (
  mapInstance: HTMLDivElement | google.maps.Map,
): Promise<google.maps.places.PlacesService> =>
  new Promise((resolve, reject) => {
    if (typeof mapInstance !== 'object') {
      return reject('Must provide a valid mapInstance');
    }

    const PlacesService = window.google?.maps.places.PlacesService;

    if (PlacesService) {
      return resolve(new PlacesService(mapInstance));
    }

    return reject('Unable to instanciate PlaceService');
  });

const getAutocompleteService =
  (): Promise<google.maps.places.AutocompleteService> =>
    new Promise((resolve, reject) => {
      const AutoCompleteService =
        window.google?.maps.places.AutocompleteService;

      if (AutoCompleteService) {
        return resolve(new AutoCompleteService());
      }

      return reject('Unable to instanciate AutoCompleteService');
    });

const getDirectionsService = (): Promise<google.maps.DirectionsService> =>
  new Promise((resolve, reject) => {
    const DirectionsService = window.google?.maps.DirectionsService;

    if (DirectionsService) {
      return resolve(new DirectionsService());
    }

    return reject('No DirectionService available');
  });

export const searchPlaces = (
  input: string,
  latlng: {
    lat: number;
    lng: number;
  } = undefined,
): Promise<google.maps.places.AutocompletePrediction[] | undefined> => {
  return new Promise((resolve) => {
    getLatLng(latlng).then((location) => {
      getAutocompleteService().then((service) => {
        const locationBias = location
          ? { center: location, radius: 500000 }
          : undefined;
        service.getPlacePredictions(
          {
            input,
            locationBias,
          },
          (results, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              return resolve(results);
            } else if (
              status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS
            ) {
              return resolve([]);
            }
            throw `Could not fetch results, response status code : ${status}`;
          },
        );
      });
    });
  });
};

export const getPlacesDetails = (
  mapInstance: HTMLDivElement | google.maps.Map,
  placeId: string,
  fields = undefined,
): Promise<google.maps.places.PlaceResult> =>
  new Promise((resolve, reject) =>
    getPlacesService(mapInstance).then((service) =>
      service.getDetails(
        {
          placeId,
          fields,
        },
        (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            return resolve(results);
          }
          return reject('Place not found');
        },
      ),
    ),
  );

/**
 * Fetches for a given list of mileage waypoints the google maps routes directions
 */
export const searchMileageRouteDirections = (
  waypoints: {
    lat: number;
    lng: number;
  }[],
  requestParams = { travelMode: 'DRIVING' },
) =>
  new Promise((resolve, reject) =>
    getDirectionsService().then((service) => {
      // We need to convert mileage waypoints to Google LatLng objects
      const waypointsLatLng = waypoints.map(
        (waypoint) => new google.maps.LatLng(waypoint.lat, waypoint.lng),
      );

      const routingRequest: google.maps.DirectionsRequest = {
        origin: waypointsLatLng[0],
        destination: waypointsLatLng[waypointsLatLng.length - 1],
        travelMode: google.maps.TravelMode[requestParams.travelMode],
      };

      const intermediateWaypoints = waypointsLatLng.slice(
        1,
        waypointsLatLng.length - 1,
      );

      if (intermediateWaypoints.length > 0) {
        routingRequest.waypoints = intermediateWaypoints.map((latLng) => ({
          location: latLng,
          stopover: true,
        }));
      }

      service.route(routingRequest, (result, status) => {
        if (status === google.maps.DirectionsStatus.OK) {
          return resolve(result);
        }

        return reject('No route found yet');
      });
    }),
  );

/**
 * Computes total distance of given route for given google maps DirectionsResult
 */
export const getRouteTotalDistance = (
  directionsResult: google.maps.DirectionsResult,
  unit: string = DistanceUnit.KM,
  routeIndex = 0,
) => {
  const getLegDistance = (leg: google.maps.DirectionsLeg) => {
    const legDistance = leg?.distance.value || 0;
    if (unit === DistanceUnit.MILES) {
      return metersToMile(legDistance);
    } else if (unit === DistanceUnit.KM) {
      return legDistance / 1000;
    }
    return legDistance;
  };
  return directionsResult.routes[routeIndex].legs.reduce(
    (totalDistance, leg) => round(totalDistance + getLegDistance(leg), 2),
    0,
  );
};
