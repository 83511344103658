import { combineReducers, Reducer } from 'redux';
import update from './update/reducer';
import persisted from './persisted/reducer';
import notifications from './notifications/reducer';
import referential from './referential/reducer';
import i18n from './i18n/reducer';
import { AppConfigState } from './types';

export const appReducer: Reducer<AppConfigState> = combineReducers({
  referential,
  i18n,
  update,
  notifications,
  persisted,
});

export default appReducer;
