import {
  CompanyTrip,
  CurrentUserTrip,
} from '@neo1/client/lib/entities/trip/types';

export enum Actions {
  SET_GUESTS_TRIPS = 'one/trips/SET_GUESTS_TRIPS',
  SET_COMPANY_TRIPS = 'one/travel/SET_COMPANY_TRIPS',
  SET_TEAM_TRIPS = 'one/travel/SET_TEAM_TRIPS',
  SET_USER_TRIPS = 'one/travel/SET_USER_TRIPS',
}

export type Action =
  | {
      type: Actions.SET_GUESTS_TRIPS;
      ids: CompanyTrip['id'][];
    }
  | {
      type: Actions.SET_COMPANY_TRIPS;
      ids: CompanyTrip['id'][];
    }
  | {
      type: Actions.SET_TEAM_TRIPS;
      ids: CompanyTrip['id'][];
    }
  | {
      type: Actions.SET_USER_TRIPS;
      ids: CurrentUserTrip['id'][];
    };

export function setGuestsTrips(ids: CompanyTrip['id'][]): Action {
  return {
    type: Actions.SET_GUESTS_TRIPS,
    ids,
  };
}
export function setUserTrips(ids: CurrentUserTrip['id'][]): Action {
  return {
    type: Actions.SET_USER_TRIPS,
    ids,
  };
}
export function setTeamTrips(ids: CompanyTrip['id'][]): Action {
  return {
    type: Actions.SET_TEAM_TRIPS,
    ids,
  };
}
export function setCompanyTrips(ids: CompanyTrip['id'][]): Action {
  return {
    type: Actions.SET_COMPANY_TRIPS,
    ids,
  };
}
