import { Address } from './types';

export const ADDRESS_ENTITY_NAME = 'Address';

export const HOME_ADDRESS_ID = '00000000-0000-0000-0000-000000000000';

export const ADDRESS_MANDATORY_FIELDS: Array<keyof Address> = [
  'city',
  'countryCode',
  'streetLine1',
  'zipCode',
];
