import has from 'lodash/has';

import omit from 'lodash/omit';

import { NotificationAction, Actions } from './actions';
import { UNIQ_NOTIFICATION, ToastersState } from './types';

const initialState: ToastersState = {};
export default function reducer(
  notifications: ToastersState = initialState,
  action: NotificationAction = null,
) {
  switch (action?.type) {
    case Actions.PUSH:
      return has(notifications, UNIQ_NOTIFICATION)
        ? notifications
        : {
            ...notifications,
            [action.notification.uuid]: action.notification,
          };

    case Actions.HIDE:
      return omit(notifications, [action.uuid]);

    case Actions.SET_PROGRESS:
      return notifications[action.uuid]
        ? {
            ...notifications,
            [action.uuid]: {
              ...notifications[action.uuid],
              progress: action.progress,
            },
          }
        : notifications;

    default:
      return notifications;
  }
}
