import { generatePath } from 'react-router';
import { UserLevel } from '@neo1/client/lib/entities/user/constants';
import { AppMenuRoute } from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';

import { ROUTE_NAME_SPEND, ROUTE_PATH_SPEND } from './constants';

const spendRoute: AppMenuRoute = {
  path: ROUTE_PATH_SPEND,
  name: ROUTE_NAME_SPEND,
  exact: true,
  defaultPath: `${generatePath(
    ROUTE_PATH_SPEND,
  )}?v=justify&order=desc&prop=date`,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "spendJustify" */
        './pages/Expenses'
      ),
  ),
  menu: {
    iconClass: 'spend',
    userLevels: [UserLevel.COMPANY],
    companyFeatures: [CompanyFeature.Spend],
    hasBadge: true,
    path: generatePath(ROUTE_PATH_SPEND),
  },
};

export default spendRoute;
