import { UUID_REGEX_AS_STRING } from '@neo1/client/lib/entities/index';

export const ROUTE_NAME_SPEND = 'spend';

export const ROUTE_PATH_SPEND = `/spend/:txId(${UUID_REGEX_AS_STRING})?`;

export const SPEND_PENDING_BANNER_USER = {
  label:
    'Spend validation flow will be enabled as soon as your business setup is completed',
  description:
    'Submitting a transaction for approval, review and process requires the business to be fully active. As soon as your business admin finalizes set up all features will be unlocked.',
};

export const SPEND_PENDING_BANNER_ADMIN = {
  description:
    'Spend validation flow (submitting a transaction for approval, review and process) requires the business to be fully active. Have a play justifying out of pocket transactions.',
};
