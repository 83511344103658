/**
 * Helpers for localStorage
 */
export const set = (key: string, value: string) => {
  window.localStorage[key] = value;
};

export const get = (key: string) => window.localStorage[key];

export const clear = (key: string) => {
  delete window.localStorage[key];
};

const HAS_LOGGED_IN_WITH_SSO = 'neologinWithSso';

export const hasLoggedInWithSso = () =>
  window.localStorage[HAS_LOGGED_IN_WITH_SSO] === 'true';
