import { lazy, Suspense } from 'react';
import LoaderImage from 'components/elements/Loader/Image';
import TextInput from 'components/elements/form/fields/TextInput';
import { Props } from './types';
import styles from './PhoneNumberInput.module.css';

const Input = lazy(
  () =>
    import(
      /* webpackChunkName: "phoneNumberInput" */
      './Input'
    ),
);
const PhoneNumberInput = ({ title, isRequired, ...props }: Props) => (
  <Suspense
    fallback={
      <div className={styles.placeholder}>
        <TextInput title={title} disabled isRequired={isRequired} />
        <LoaderImage width={20} height={20} className={styles.loader} />
      </div>
    }
  >
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <Input {...props} title={title} isRequired={isRequired} />
  </Suspense>
);
export default PhoneNumberInput;
