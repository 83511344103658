import { defineMessages } from 'react-intl';

const messages = defineMessages({
  backBtn: {
    id: 'Header__backBtn',
    defaultMessage: 'Back',
  },
});

export default messages;
