import { useIntl } from 'react-intl';
import { syncExtractService } from '@neo1/client/lib/entities/company/api';
import { setIntegrationSettings } from 'redux/accountingIntegrations/actions';
import Button from 'components/elements/Button';
import Icon from 'components/elements/Icon';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import { CompanyExtractService } from '@neo1/client/lib/entities/company/constants';
import useAsyncState from 'hooks/asyncState';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DataSynchronization.module.css';
import messages from './messages';

const DataSychronization = () => {
  const company = useSelector(selectActingCompany);
  const dispatch = useDispatch();
  const intl = useIntl();
  const onSync = useCallback(async (): Promise<{
    assets: number;
    expenses: number;
  }> => {
    const synchronized = await syncExtractService(company.id);
    dispatch(setIntegrationSettings(CompanyExtractService.QuickBooks, true));
    return synchronized;
  }, [company.id, dispatch]);

  const { isLoading, execute, error, data } = useAsyncState(onSync);
  useEffect(() => {
    execute();
  }, [execute]);
  return (
    <>
      {error ? (
        <div className={styles.errorContainer} data-testid="error">
          <Icon className={styles.errorIcon} name="warning" />
          <div> {error?.message || intl.formatMessage(messages.error)}</div>
          <Button label="Retry" id="retry" onClick={execute} />
        </div>
      ) : (
        <StepContent
          isLoading={isLoading}
          loadingMsg={intl.formatMessage(messages.synchronizing)}
          submitTitle="Continue"
        >
          <>
            <div
              className={styles.sychroResultContainer}
              data-testid="accountsResult"
            >
              <div className={styles.account}>
                <Icon name="checkCircle" className={styles.icon} />
                {data?.assets} assets were retrieved
              </div>
              <div className={styles.account}>
                <Icon name="checkCircle" className={styles.icon} />
                {data?.expenses} expenses were retrieved
              </div>
            </div>
          </>
        </StepContent>
      )}
    </>
  );
};

export default DataSychronization;
