import dayjs, { ConfigType as DateInput, ManipulateType } from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const newTimestamp = (date?: string) => {
  const newDate = date ? new Date(date) : new Date();
  return newDate.getTime();
};

export const formatDate = (date: DateInput) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('D MMM YYYY');
};

export const formatPrettyDateTime = (date: DateInput) => {
  if (!date) {
    return '';
  }
  return dayjs(date).format('D MMM YYYY [at] HH:mm');
};

export const formatDateTimeToUTC = (date: string, time: string) =>
  dayjs(`${date} ${time}`, 'YYYY-MM-DD HH:mm').utc().format();

/**
 * Generates a date range array with the right bound being now and left bound a date in the past
 */
export const getDateRangeToNow = (
  count = 1,
  timeUnit: ManipulateType = 'month',
  format = 'YYYY-MM-DD',
) => [
  dayjs().add(-Math.abs(count), timeUnit).format(format),
  dayjs().format(format),
];

/**
 * Formats given date string
 */
export const formatTimeAgo = (date: DateInput) => {
  if (date) {
    return dayjs(date).fromNow();
  }
};

export const DatePresetType = {
  DATE: 'date',
  RANGE: 'range',
};
