import { UUID } from '@neo1/client';

export enum Action {
  SET_DELEGATIONS = 'one/delegation/SET_DELEGATIONS',
  SET_MY_DELEGATION = 'one/delegation/SET_MY_DELEGATION',
  DELETE_MY_DELEGATION = 'one/delegation/DELETE_MY_DELEGATION',
}

export type SetDelegations = {
  type: typeof Action.SET_DELEGATIONS;
  toMe: UUID[];
  toOthers: UUID[];
};

export type SetMyDelegation = {
  type: typeof Action.SET_MY_DELEGATION;
  id: UUID;
};

export type DeleteMyDelegation = {
  type: typeof Action.DELETE_MY_DELEGATION;
  id: UUID;
};

export type DelegationActions =
  | SetDelegations
  | SetMyDelegation
  | DeleteMyDelegation;

export type DelegationState = {
  toMe: {
    ids: UUID[];
  };
  toOthers: {
    ids: UUID[];
  };
};
