import { useIntl } from 'react-intl';
import { FormikErrors } from 'formik';
import Link, { LinkFontSize } from 'components/elements/Link';
import FormField from 'components/elements/form/Field';
import CheckBox from 'components/elements/form/fields/CheckBox';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import messages from './messages';
import styles from './GetStarted.module.css';

export type Values = {
  isPolicyAccepted: boolean;
};

export const initialValues: Values = {
  isPolicyAccepted: false,
};

export const validation = ({
  isPolicyAccepted,
}: Values): FormikErrors<Values> => {
  const errors: FormikErrors<Values> = {};
  if (!isPolicyAccepted) {
    errors.isPolicyAccepted = 'This field is required';
  }

  return errors;
};
const GetStarted = () => {
  const intl = useIntl();
  return (
    <div className={styles.container}>
      <div>{intl.formatMessage(messages.description1)}</div>
      <div>{intl.formatMessage(messages.description2)}</div>
      <div>{intl.formatMessage(messages.description3)}</div>
      <div>{intl.formatMessage(messages.description4)}</div>
      <div>
        <FormField name="isPolicyAccepted" required>
          <CheckBox
            label={
              <span>
                I agree to the{' '}
                <Link
                  aria-label="viewTerms"
                  fontSize={LinkFontSize.Inherit}
                  title="view terms and conditions"
                  target="_blank"
                  rel="noopener noreferrer"
                  to="//privacy.neo1.com"
                >
                  privacy policy
                </Link>
              </span>
            }
          />
        </FormField>
      </div>
    </div>
  );
};

const GetStartedView = () => {
  const intl = useIntl();
  return (
    <StepContent
      title={intl.formatMessage(messages.title)}
      submitTitle="Continue"
    >
      <GetStarted />
    </StepContent>
  );
};
export default GetStartedView;
