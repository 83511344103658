import { selectActingUser } from 'modules/Authentification/redux/selectors';
import {
  deleteUserData,
  setUserData,
} from '@neo1/client/lib/entities/persistedUserData/api';
import {
  CompanyActivationData,
  UserPersistedData,
  UserPersitedDataKey,
} from '@neo1/client/lib/entities/persistedUserData/types';
import { deleteData, setData } from './actions';

export function set(
  key: UserPersitedDataKey,
  val: UserPersistedData[typeof key],
) {
  return async (dispatch: any, getState: any) => {
    const userId = selectActingUser(getState())?.id;
    if (!userId) throw new Error('No acting User');

    await setUserData(userId, key, val);
    dispatch(setData(userId, key, val));

    return val;
  };
}

export function del(
  key: UserPersitedDataKey,
  val: UserPersistedData[typeof key],
) {
  return async (dispatch: any, getState: any) => {
    const userId = selectActingUser(getState())?.id;
    if (!userId) throw new Error('No acting User');

    await deleteUserData(userId, key, val);

    dispatch(deleteData(userId, key, val));

    return key;
  };
}

export function saveCompanyPartialData(companyData: CompanyActivationData) {
  return (dispatch: Function) =>
    dispatch(set('companyActivation', companyData));
}

export function setCalendarProvider(credentials: any) {
  return set('calendarProvider', credentials);
}
