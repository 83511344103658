import { useHistory } from 'react-router';
import classNames from 'classnames';
import ErrorPage from 'components/composed/ErrorPage';

import styles from './NotFound.module.css';

const NotFound = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push('/');
  };
  return (
    <ErrorPage
      message="We can not seem to find the page you are looking for."
      title="404"
      buttonTitle="Back home"
      handleClick={handleClick}
      titleClassName={classNames(styles.title, 'header2')}
    />
  );
};

export default NotFound;
