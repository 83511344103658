import { forwardRef, RefObject, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useWindowContext } from 'contexts/window';
import ResizerObserver from 'utils/ResizerObserver';
import Portal from 'components/composed/Portal';
import styles from './DatePickerPortal.module.css';

type Props = {
  targetRef: RefObject<HTMLElement>;
  isOpen: boolean;
  testId: string;
};

const DatePickerPortal = forwardRef(
  ({ targetRef, isOpen, testId }: Props, ref: RefObject<HTMLDivElement>) => {
    const { winHeight, winWidth } = useWindowContext();

    const [pickerTop, setPickerTop] = useState(undefined);

    const [pickerLeft, setPickerLeft] = useState(undefined);

    const setPickerPosition = useCallback(() => {
      if (!targetRef.current) {
        return;
      }
      const {
        left,
        top: trgTop,
        height: trgHeight,
      } = targetRef.current.getBoundingClientRect();
      const { height: pickerHeight } = ref.current.getBoundingClientRect();

      const trgBtm = winHeight - trgTop - trgHeight;
      const trgBtmBigEnough = trgBtm >= pickerHeight;
      const trgTopBigEnough = trgTop >= pickerHeight;
      let top = 0;
      if (trgBtmBigEnough) {
        top = trgTop + trgHeight;
      } else if (trgTopBigEnough) {
        top = trgTop - pickerHeight;
      }

      setPickerTop(top);
      setPickerLeft(left);
    }, [ref, targetRef, winHeight]);

    useEffect(() => {
      if (ref.current) {
        const pickerElem = ref.current;
        ResizerObserver.get().observe(pickerElem, setPickerPosition);
        return () => {
          ResizerObserver.get().unobserve(pickerElem);
        };
      }
      return undefined;
    }, [ref, setPickerPosition]);

    useEffect(() => {
      setPickerPosition();
    }, [setPickerPosition, winHeight, winWidth]);

    return (
      <Portal>
        <div
          ref={ref}
          className={classNames(styles.wrapper, isOpen && styles.opened)}
          style={{
            top: pickerTop,
            left: pickerLeft,
          }}
          data-testid={testId}
        >
          <div className={styles.backdrop} />
        </div>
      </Portal>
    );
  },
);

export default DatePickerPortal;
