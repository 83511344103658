import styles from './styles.module.css';

const Logout = () => (
  <div className={styles.container}>
    <section className={styles.message}>
      <h1 className={styles.title}>
        You are no longer authenticated to the service.
      </h1>
    </section>
  </div>
);

export default Logout;
