import { combineReducers, Reducer } from 'redux';
import home from '../Home/redux/reducer';
import { HomeViewState } from '../Home/redux/types';
import { SearchViewState } from '../Search/redux/types';
import search from '../Search/redux/reducer';

export type AdminState = {
  home: HomeViewState;
  search: SearchViewState;
};

const adminReducer: Reducer<AdminState> = combineReducers({ home, search });

export default adminReducer;
