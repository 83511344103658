import { Company as CompanyType } from '@neo1/client/lib/entities/company/types';
import { Domain as DomainType } from '@neo1/client/lib/entities/domain/types';
import { SortTypes } from '@neo1/core/utils/collections';
import { CompanyGroupUser, User } from '@neo1/client/lib/entities/user/types';

export enum AdminView {
  System = 'system',
  Domain = 'domain',
  Company = 'company',
}
export type FiltersData = {
  sort: {
    prop: string;
    dir: SortTypes;
  };
  search: string | null;
};

export type HomeViewState = {
  systemAdmins: {
    ids: User['id'][];
    filters: FiltersData;
  };
  domainAdmins: {
    ids: User['id'][];
    filters: FiltersData;
  };
  domains: {
    ids: DomainType['id'][];
    filters: FiltersData;
  };
  companies: {
    ids: CompanyType['id'][];
    filters: FiltersData;
  };
  users: {
    ids: User['id'][];
    companyGroupUserIds: CompanyGroupUser['id'][];
    filters: FiltersData;
  };
  linkedCompanies: {
    filters: FiltersData;
  };
};
