import { CompanyFeatureUsage } from '@neo1/client/lib/entities/persistedUserData/constants';

export const COMPANY_FEATURE_USAGE_VALUES = [
  CompanyFeatureUsage.Immediately,
  CompanyFeatureUsage.NearFuture,
  CompanyFeatureUsage.NotNow,
];

export const COMPANY_FEATURE_USAGE_LABELS = {
  [CompanyFeatureUsage.Immediately]: 'Immediately',
  [CompanyFeatureUsage.NearFuture]: 'Near future',
  [CompanyFeatureUsage.NotNow]: 'Not now',
};
export const FEATURE_USAGE_FIELD_OPTIONS = COMPANY_FEATURE_USAGE_VALUES.map(
  (value) => ({
    value,
    label: COMPANY_FEATURE_USAGE_LABELS[value],
  }),
);
