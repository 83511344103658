import { Tax, TaxConfig } from '@neo1/client/lib/entities/tax/types';
import { CollectionSortFilters } from '@neo1/core/utils/collections';

export enum Actions {
  SET_TAXES = 'one/settings/taxes/SET_TAXES',
  SET_TAX = 'one/settings/taxes/SET_TAX',
  SET_TAX_CONFIG = 'one/settings/taxes/SET_TAX_CONFIG',
  DEL_TAX = 'one/settings/taxes/DEL_TAX',
  SET_SORT_OPTIONS = 'one/settings/taxes/SET_SORT_OPTIONS',
  SET_SEARCH_FILTER = 'one/settings/taxes/SET_SEARCH_FILTER',
}

export type SetTax = {
  type: Actions.SET_TAX;
  taxId: Tax['taxCode'];
};

export type SetTaxes = {
  type: Actions.SET_TAXES;
  taxesIds: Tax['taxCode'][];
};

export type DeleteTax = {
  type: Actions.DEL_TAX;
  taxId: Tax['taxCode'];
};

export type SetColumnSortOptionsAction = {
  type: Actions.SET_SORT_OPTIONS;
  options: CollectionSortFilters;
};

export type SetSearchFilterAction = {
  type: Actions.SET_SEARCH_FILTER;
  search: string;
};

export type SetTaxConfig = {
  type: Actions.SET_TAX_CONFIG;
  config: TaxConfig;
};

export function setColumnSortOptions(
  options: CollectionSortFilters,
): TaxesActions {
  return {
    type: Actions.SET_SORT_OPTIONS,
    options,
  };
}

export function setSearchFilter(search: string): TaxesActions {
  return { type: Actions.SET_SEARCH_FILTER, search };
}

export const setTaxes = (taxesIds: Tax['taxCode'][]): TaxesActions => ({
  type: Actions.SET_TAXES,
  taxesIds,
});

export const setTax = (taxId: Tax['taxCode']): TaxesActions => ({
  type: Actions.SET_TAX,
  taxId,
});

export const delTax = (taxId: Tax['taxCode']): TaxesActions => ({
  type: Actions.DEL_TAX,
  taxId,
});

export const setTaxConfig = (config: TaxConfig): TaxesActions => ({
  type: Actions.SET_TAX_CONFIG,
  config,
});

export type TaxesActions =
  | SetTaxes
  | SetTax
  | DeleteTax
  | SetColumnSortOptionsAction
  | SetSearchFilterAction
  | SetTaxConfig;
