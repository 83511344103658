import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useAsyncState from 'hooks/asyncState';
import { logout } from 'modules/Authentification/redux/thunks';
import { clearOutdatedBrowserBannerData } from 'components/layout/App/BrowserNotSuppportedBanner';
import { regenerateDeviceId } from 'contexts/instrumentation/amplitude';

const useLogout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onLogout = useCallback(async () => {
    clearOutdatedBrowserBannerData();
    await dispatch(logout());
    regenerateDeviceId();
    history.replace('/');
  }, [dispatch, history]);

  return useAsyncState(onLogout);
};

export default useLogout;
