/* eslint-disable import/prefer-default-export */

import { WinSize, State, DeviceSize } from './types';
import { lgWidth, mdWidth, smWidth, xsWidth } from './constants';

const getWinSize = (width: number) => {
  if (width < xsWidth) return WinSize.xs;
  if (width < smWidth) return WinSize.sm;
  if (width < mdWidth) return WinSize.md;
  if (width < lgWidth) return WinSize.lg;
  return WinSize.xl;
};

const getDeviceSize = (width: number) => {
  if (width < smWidth) return DeviceSize.mobile;
  if (width < mdWidth) return DeviceSize.tablet;
  return DeviceSize.desktop;
};

export const calculateState = (): State => ({
  winWidth: window.innerWidth,
  winHeight: window.innerHeight,
  winSize: getWinSize(window.innerWidth),
  deviceSize: getDeviceSize(window.innerWidth),
});
