import uniq from 'lodash/uniq';
import { Action, AddressState, AddressAction } from './types';

export const initialState: AddressState = {
  ids: [],
};

const addressReducer = (
  state: AddressState = initialState,
  action?: AddressAction,
): AddressState => {
  switch (action.type) {
    case Action.SET_ADDRESS:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    case Action.SET_ADDRESSES:
      return {
        ...state,
        ids: action.ids,
      };

    case Action.DELETE_ADDRESS: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };
    }

    default:
      return state;
  }
};

export default addressReducer;
