import { Actions, Action } from './actions';
import { TripState } from './types';

export const initialState: TripState = {
  companyTrips: { ids: [] },
  teamTrips: { ids: [] },
  userTrips: { ids: [] },
  guestsTrips: { ids: [] },
};

export default (
  state: TripState = initialState,
  action: Action = null,
): TripState => {
  switch (action?.type) {
    case Actions.SET_GUESTS_TRIPS:
      return { ...state, guestsTrips: { ids: action.ids } };
    case Actions.SET_COMPANY_TRIPS:
      return { ...state, companyTrips: { ids: action.ids } };
    case Actions.SET_TEAM_TRIPS:
      return { ...state, teamTrips: { ids: action.ids } };
    case Actions.SET_USER_TRIPS:
      return { ...state, userTrips: { ids: action.ids } };
    default:
      return state;
  }
};
