const RECEIPTS = 'receipts';
const TRAVEL = 'travel';
const SPEND = 'spend';

export const ROUTE_NAME_RECEIPTS = RECEIPTS;

export const ROUTE_PATH_RECEIPTS = `/${RECEIPTS}`;

export const ROUTE_PATH_RECEIPTS_TRAVEL = `/${RECEIPTS}/${TRAVEL}`;
export const ROUTE_NAME_RECEIPTS_TRAVEL = `${TRAVEL} ${RECEIPTS}`;

export const ROUTE_PATH_RECEIPTS_SPEND = `/${RECEIPTS}/${SPEND}`;
export const ROUTE_NAME_RECEIPTS_SPEND = `${SPEND} ${RECEIPTS}`;
