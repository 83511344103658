import wrapAsync from 'utils/wrapAsync';
import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';
import {
  ROUTE_PATH_PURCHASE_SUPPLIES,
  ROUTE_PATH_PURCHASE_SUPPLIES_DETAILS,
  ROUTE_NAME_PURCHASE_SUPPLIES,
} from './constants';

const route = {
  path: [ROUTE_PATH_PURCHASE_SUPPLIES, ROUTE_PATH_PURCHASE_SUPPLIES_DETAILS],
  name: ROUTE_NAME_PURCHASE_SUPPLIES,
  menu: {
    path: ROUTE_PATH_PURCHASE_SUPPLIES,
    iconClass: 'purchase',
    userLevels: ['company'],
    companyFeatures: [CompanyFeature.AmazonPurchase],
    hasBadge: true,
  },
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "supplies" */
        '.'
      ),
  ),
};

export default route;
