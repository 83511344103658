import { StringLengths } from '@neo1/client';
import { CompanyNumberType } from '@neo1/client/lib/entities/company/constants';
import SelectBox from 'components/elements/form/fields/SelectBox';
import TextInput, {
  TextInputProps,
} from 'components/elements/form/fields/TextInput';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './Input.module.css';

export type RegistrationNumberOption = {
  value: CompanyNumberType;
  countries: string[];
  label: string;
};

const isTypeOptionAvailableForCountry =
  (country: string) => (option: RegistrationNumberOption) =>
    option.countries.includes(country);

const EditRegistrationNumberInput = ({ ...props }: TextInputProps) => {
  const intl = useIntl();

  const [{ name, value, onBlur }, , { setValue: setNumberTypeValue }] =
    useField('registrationNumberType');
  const [{ value: country }] = useField('country');

  const options: RegistrationNumberOption[] = [
    {
      value: CompanyNumberType.Ein,
      countries: ['US'],
      label: intl.formatMessage(messages.ein),
    },
    {
      value: CompanyNumberType.Duns,
      countries: ['US'],
      label: intl.formatMessage(messages.duns),
    },
    {
      value: CompanyNumberType.RegistrationNumber,
      countries: ['GB'],
      label: intl.formatMessage(messages.companyHouseNumber),
    },
  ].filter(isTypeOptionAvailableForCountry(country));

  const onNumberTypeChange = (newType: CompanyNumberType) =>
    setNumberTypeValue(newType);

  return (
    <div className={styles.container}>
      <SelectBox
        id="registrationNumberType"
        className={styles.select}
        name={name}
        value={value}
        onChange={onNumberTypeChange}
        onBlur={onBlur}
        options={options}
        disabled={options.length <= 1 || props.disabled}
      />
      <TextInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        autoComplete="new-password"
        className={styles.input}
        maxLength={
          country === 'GB'
            ? StringLengths.UKCompanyNumber
            : StringLengths.USCompanyNumber
        }
      />
    </div>
  );
};

export default EditRegistrationNumberInput;
