import { UUID_REGEX_AS_STRING } from '@neo1/client/';

export const ROUTE_PATH_AUTHORIZE = `/authorize`;

export const ROUTE_PATH_AUTHORIZE_BUDGET = `${ROUTE_PATH_AUTHORIZE}/budgets`;

export const ROUTE_PATH_AUTHORIZE_PURCHASE = `${ROUTE_PATH_AUTHORIZE}/supplies`;

export const ROUTE_PATH_AUTHORIZE_SPEND = `${ROUTE_PATH_AUTHORIZE}/spend/:txId(${UUID_REGEX_AS_STRING})?`;

export const ROUTE_NAME_AUTHORIZE = 'authorize';

export const ROUTE_NAME_AUTHORIZE_BUDGET = 'authorizeBudget';

export const ROUTE_NAME_AUTHORIZE_PURCHASE = 'authorizePurchase';

export const ROUTE_NAME_AUTHORIZE_SPEND = 'authorizeSpend';

export const AUTHORIZE_PENDING_BANNER_USER = {
  label:
    'Authorize spend will be enabled as soon as your business setup is completed',
  description:
    'Approving and rejecting any request (budgets, purchases, card transactions and out of pocket expensee) requires your business to be fully active. As soon as your business admin finalizes set up all features will be unlocked.',
};
export const AUTHORIZE_PENDING_BANNER_ADMIN = {
  description:
    'Approving and rejecting any requests (budgets, purchases, card transactions and out of pocket expensee) requires the business to be fully active',
};
