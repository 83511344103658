import async from 'utils/wrapAsync';
import { hasSpendManagement, AppMenuRoute } from 'config/routes/utils';
import { ROUTE_PATH_INTEGRATIONS, ROUTE_NAME_INTEGRATIONS } from './constants';

const route: AppMenuRoute = {
  title: 'Integrations',
  show: hasSpendManagement,
  exact: true,
  menu: {
    iconClass: 'integration',
  },
  path: ROUTE_PATH_INTEGRATIONS,
  name: ROUTE_NAME_INTEGRATIONS,
  component: async(
    () =>
      import(
        /* webpackChunkName: "integration" */
        '.'
      ),
  ),
};

export default route;
