import {
  UserPersistedData,
  UserPersitedDataKey,
} from '@neo1/client/lib/entities/persistedUserData/types';

export enum Actions {
  SetData = 'one/app/persisted/SET_DATA',
  DeleteData = 'one/app/persisted/DELETE_DATA',
  SetMulti = 'one/app/persisted/SET_MULTI',
}
export function setData(
  userId: string,
  key: UserPersitedDataKey,
  val: UserPersistedData[typeof key],
) {
  return {
    type: Actions.SetData,
    userId,
    key,
    val,
  };
}

export function deleteData(
  userId: string,
  key: UserPersitedDataKey,
  val: UserPersistedData[typeof key],
) {
  return {
    type: Actions.DeleteData,
    userId,
    key,
    val,
  };
}

export type PersistanceActions = {
  type: Actions;
  userId: string;
  key?: UserPersitedDataKey;
  val: Partial<UserPersistedData>;
};
export function setMulti(userId: string, val: Partial<UserPersistedData>) {
  return {
    type: Actions.SetMulti,
    userId,
    val,
  };
}
