import { CompanyExtractService } from '@neo1/client/lib/entities/company/constants';
import { AppCoreState } from 'redux/types';

export const selectIsServiceConfigured =
  (service: CompanyExtractService) => (state: AppCoreState) =>
    service ? state.accountingIntegration[service].isConfigured : null;

export const selectVendors =
  (service: CompanyExtractService) => (state: AppCoreState) =>
    service ? state.accountingIntegration[service].vendors : [];
export const selectUsersVendors =
  (service: CompanyExtractService) => (state: AppCoreState) =>
    service
      ? state.accountingIntegration[service].cashTxsConfig.usersVendors
      : [];

export const selectAccountingConfig =
  (service: CompanyExtractService) => (state: AppCoreState) =>
    service ? state.accountingIntegration[service].config : null;
