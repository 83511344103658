import { Addr } from '@neo1/core/utils/addressValidation';
import AddressBlock from '../AddressBlock';
import styles from './UnconfirmedAddress.module.css';

type Props = {
  isUSAddress: boolean;
  address: Addr;
  unconfirmedAddressFields: string[];
};
const UnconfirmedAddress = ({
  isUSAddress,
  address,
  unconfirmedAddressFields,
}: Props) => {
  if (!address) {
    return null;
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.title} data-testid="unconfirmedAddressTitle">
        {isUSAddress
          ? 'This information seems incorrect, please click back to modify your address'
          : 'Are you sure this information is correct?'}
      </h4>
      <AddressBlock
        address={address}
        unconfirmedFields={unconfirmedAddressFields}
        testId="unconfirmedAddress"
      />
    </div>
  );
};
export default UnconfirmedAddress;
