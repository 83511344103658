import {
  CompanyUserRole,
  UserLevel,
} from '@neo1/client/lib/entities/user/constants';
import {
  COMPANY_ENTITY_NAME,
  CompanyExtractService,
  CompanyFeature,
  CompanyStatus,
} from '@neo1/client/lib/entities/company/constants';
import { AppCoreState } from 'redux/types';
import ScopeType from './scopeType';

export const FAKE_USER_ID = '0000';

const fakeState: Partial<AppCoreState> = {
  auth: {
    authToken: '',
    isLoggedInAs: false,
    currentUserId: FAKE_USER_ID,
    isProcessing: false,
  },
  dashboard: {
    scope: ScopeType.User,
  },
  counter: {
    user: {
      tx: {
        inApprove: { count: 15, amount: 2000, currency: 'USD' },
        inExtract: { count: 30, amount: 4000, currency: 'USD' },
        inProgress: { count: 10, amount: 1000, currency: 'USD' },
        inReview: { count: 20, amount: 3000, currency: 'USD' },
        toApprove: { count: 0, amount: 0, currency: 'USD' },
        toReview: { count: 0, amount: 0, currency: 'USD' },
      },
      budget: {
        inApprove: { count: 32, amount: 2000, currency: 'USD' },
        inProgress: { count: 0, amount: 0, currency: 'USD' },
        toApprove: { count: 0, amount: 0, currency: 'USD' },
        approved: { count: 36, amount: 3000, currency: 'USD' },
      },
      purchase: {
        inApprove: { count: 32, amount: 2000, currency: 'USD' },
        inProgress: { count: 0, amount: 0, currency: 'USD' },
        toApprove: { count: 0, amount: 0, currency: 'USD' },
        approved: { count: 36, amount: 3000, currency: 'USD' },
      },
      trips: {
        current: { count: 15 },
        future: { count: 20 },
      },
    },
    supervisor: null,
    company: null,
    organization: null,
  },
  reporting: {
    predefined: {
      lastUsedParams: {
        scope: {
          type: 'user',
          user_ids: ['b17cf4dd-886f-4e50-839b-12a0e9a71fad'],
          company_ids: [],
        },
        dateRange: {
          start_date: '2021-11-25',
          end_date: '2022-05-25',
        },
        fields: [],
        currency: 'EUR',
        topN: 6,
      },
      reports: {
        spend_summary: {
          params: {
            scope: {
              type: 'user',
              user_ids: ['b17cf4dd-886f-4e50-839b-12a0e9a71fad'],
              company_ids: [],
            },
            dateRange: {
              start_date: '2021-11-25',
              end_date: '2022-05-25',
            },
            fields: [],
            currency: 'EUR',
            topN: 6,
          },
          data: {
            spend_summary: {
              object_class: 'transaction',
              group_by: ['transaction_month'],
              report: {
                fields: [
                  {
                    value_format: 'raw',
                    name: 'transaction_month',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'year_month',
                  },
                  {
                    value_format: 'raw',
                    name: 'total_billing_amount',
                    header_format: 'capitalize',
                    category: null,
                    class: 'amount',
                  },
                  {
                    value_format: 'raw',
                    name: 'transactions_count',
                    header_format: 'capitalize',
                    category: null,
                    class: 'integer',
                  },
                  {
                    value_format: 'raw',
                    name: 'average_billing_amount',
                    header_format: 'capitalize',
                    category: null,
                    class: 'amount',
                  },
                ],
                lines: [
                  [
                    '2022-01',
                    {
                      amount: 36.02,
                      currency: 'EUR',
                    },
                    4,
                    {
                      amount: 9.01,
                      currency: 'EUR',
                    },
                  ],
                  [
                    '2022-04',
                    {
                      amount: 17.69,
                      currency: 'EUR',
                    },
                    2,
                    {
                      amount: 8.85,
                      currency: 'EUR',
                    },
                  ],
                  [
                    '2022-03',
                    {
                      amount: 35.69,
                      currency: 'EUR',
                    },
                    3,
                    {
                      amount: 11.9,
                      currency: 'EUR',
                    },
                  ],
                  [
                    '2021-12',
                    {
                      amount: 17.18,
                      currency: 'EUR',
                    },
                    2,
                    {
                      amount: 8.59,
                      currency: 'EUR',
                    },
                  ],
                  [
                    '2022-02',
                    {
                      amount: 833.28,
                      currency: 'EUR',
                    },
                    5,
                    {
                      amount: 166.66,
                      currency: 'EUR',
                    },
                  ],
                ],
              },
              details: {
                fields: [
                  {
                    value_format: 'raw',
                    name: 'transaction_month',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'year_month',
                  },
                  {
                    value_format: 'raw',
                    name: 'transaction_date',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'date',
                  },
                  {
                    value_format: 'raw',
                    name: 'posting_date',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'date',
                  },
                  {
                    value_format: 'raw',
                    name: 'billing_period',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'billing_period',
                  },
                  {
                    value_format: 'raw',
                    name: 'emboss_text_line_one',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'string',
                  },
                  {
                    value_format: 'raw',
                    name: 'emboss_text_line_two',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'string',
                  },
                  {
                    value_format: 'raw',
                    name: 'billing_account_display_name',
                    header_format: 'capitalize',
                    category: 'billing',
                    class: 'string',
                  },
                  {
                    value_format: 'raw',
                    name: 'account_number',
                    header_format: 'capitalize',
                    category: 'billing',
                    class: 'masked',
                  },
                  {
                    value_format: 'raw',
                    name: 'payment_instrument_number',
                    category: 'general',
                    class: 'masked',
                  },
                  {
                    value_format: 'raw',
                    name: 'expense_type',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'enumeration',
                  },
                  {
                    value_format: 'raw',
                    name: 'merchant_name',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'string',
                  },
                  {
                    value_format: 'raw',
                    name: 'merchant_country',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'country',
                  },
                  {
                    value_format: 'raw',
                    name: 'original_amount',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'amount',
                  },
                  {
                    value_format: 'raw',
                    name: 'billing_amount',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'amount',
                  },
                  {
                    value_format: 'raw',
                    name: 'exchange_rate',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'decimal',
                  },
                  {
                    value_format: 'raw',
                    name: 'company_paid',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'boolean',
                  },
                  {
                    value_format: 'raw',
                    name: 'user',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'uuid',
                  },
                  {
                    value_format: 'raw',
                    name: 'purpose',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'string',
                  },
                  {
                    value_format: 'raw',
                    name: 'itemization_id',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'integer',
                  },
                  {
                    value_format: 'raw',
                    name: 'percentage',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'percentage',
                  },
                  {
                    value_format: 'raw',
                    name: 'distribution_line_id',
                    header_format: 'capitalize',
                    category: 'general',
                    class: 'integer',
                  },
                  {
                    value_format: 'raw',
                    name: 'transaction_id',
                    header_format: 'capitalize',
                    category: null,
                    class: 'uuid',
                  },
                ],
                lines: [
                  [
                    '2021-12',
                    '2021-12-19',
                    '2021-12-19',
                    {
                      start_date: '2021-12-18',
                      end_date: '2022-01-17',
                      key: '2021-12-18:2022-01-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'marketing_advertising',
                      label: 'M&E Pre-Paid',
                    },
                    'Pluristem Therapeutics',
                    'FRA',
                    {
                      amount: 7.89,
                      currency: 'EUR',
                    },
                    {
                      amount: 9.31,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'cec31817-2720-4906-a395-a39c405b7155',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2021-12',
                    '2021-12-19',
                    '2021-12-19',
                    {
                      start_date: '2021-12-18',
                      end_date: '2022-01-17',
                      key: '2021-12-18:2022-01-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'marketing_advertising',
                      label: 'M&E Pre-Paid',
                    },
                    'Pluristem Therapeutics',
                    'FRA',
                    {
                      amount: 7.89,
                      currency: 'EUR',
                    },
                    {
                      amount: 9.31,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '00f2da7a-7460-43b7-a18c-c2c952245cc5',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-01',
                    '2022-01-05',
                    '2022-01-05',
                    {
                      start_date: '2022-01-18',
                      end_date: '2022-02-17',
                      key: '2022-01-18:2022-02-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'meals_employees',
                      label: 'Travel Counselor Meals',
                    },
                    'The Kinsale',
                    'FRA',
                    {
                      amount: 6.52,
                      currency: 'EUR',
                    },
                    {
                      amount: 7.69,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'a6802e8a-0ede-47e8-8a3b-32e5bd1dc513',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-01',
                    '2022-01-05',
                    '2022-01-05',
                    {
                      start_date: '2022-01-18',
                      end_date: '2022-02-17',
                      key: '2022-01-18:2022-02-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'meals_employees',
                      label: 'Travel Counselor Meals',
                    },
                    'The Kinsale',
                    'FRA',
                    {
                      amount: 6.52,
                      currency: 'EUR',
                    },
                    {
                      amount: 7.69,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '5b93fa11-905c-4931-a9df-5c09a873fb45',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-01',
                    '2022-01-11',
                    '2022-01-11',
                    {
                      start_date: '2022-01-18',
                      end_date: '2022-02-17',
                      key: '2022-01-18:2022-02-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'other_expenses',
                      label: 'Travel Counselor Transport',
                    },
                    'Selfridges',
                    'USA',
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    1,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'b297085b-0e4d-4e98-9131-3a6e63f37024',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-01',
                    '2022-01-25',
                    '2022-01-25',
                    {
                      start_date: '2022-01-18',
                      end_date: '2022-02-17',
                      key: '2022-01-18:2022-02-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'air_transportation_fees',
                      label: 'Client Air Netfare (M&E Air)',
                    },
                    'Japan Airlines',
                    'GBR',
                    {
                      amount: 11.93,
                      currency: 'GBP',
                    },
                    {
                      amount: 15.51,
                      currency: 'USD',
                    },
                    1.3,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '39158bd9-f54b-4497-ab3c-1d0a5399f3e6',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-02',
                    '2022-02-09',
                    '2022-02-09',
                    {
                      start_date: '2022-02-18',
                      end_date: '2022-03-17',
                      key: '2022-02-18:2022-03-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'other_expenses',
                      label: 'Travel Counselor Transport',
                    },
                    'Selfridges',
                    'USA',
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    1,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'd3844451-f9e7-43b6-88ca-af248faf5320',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-02',
                    '2022-02-09',
                    '2022-02-09',
                    {
                      start_date: '2022-02-18',
                      end_date: '2022-03-17',
                      key: '2022-02-18:2022-03-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'other_expenses',
                      label: 'Travel Counselor Transport',
                    },
                    'Selfridges',
                    'USA',
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    {
                      amount: 8.18,
                      currency: 'USD',
                    },
                    1,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '4218d711-e8ce-403d-80ff-57fa9de7025a',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-02',
                    '2022-02-13',
                    '2022-02-13',
                    {
                      start_date: '2022-02-18',
                      end_date: '2022-03-17',
                      key: '2022-02-18:2022-03-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'other_expenses',
                      label: 'Travel Counselor Transport',
                    },
                    'Print Clothing',
                    'FRA',
                    {
                      amount: 5.83,
                      currency: 'EUR',
                    },
                    {
                      amount: 6.88,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'e6401997-f874-4512-bc57-c03e273b1ab5',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-02',
                    '2022-02-13',
                    '2022-02-13',
                    {
                      start_date: '2022-02-18',
                      end_date: '2022-03-17',
                      key: '2022-02-18:2022-03-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'other_expenses',
                      label: 'Travel Counselor Transport',
                    },
                    'Print Clothing',
                    'FRA',
                    {
                      amount: 5.83,
                      currency: 'EUR',
                    },
                    {
                      amount: 6.88,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '59c01055-cf29-4d81-bfc3-01ac7bb1bc45',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-02',
                    '2022-02-24',
                    '2022-02-24',
                    {
                      start_date: '2022-02-18',
                      end_date: '2022-03-17',
                      key: '2022-02-18:2022-03-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'office_consumables',
                      label: 'Travel Counselor Air',
                    },
                    'The Food Store',
                    'USA',
                    {
                      amount: 873.52,
                      currency: 'USD',
                    },
                    {
                      amount: 873.52,
                      currency: 'USD',
                    },
                    1,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '40ee89aa-09d3-47c4-86ac-ffefcd842e43',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-03',
                    '2022-03-02',
                    '2022-03-02',
                    {
                      start_date: '2022-03-18',
                      end_date: '2022-04-17',
                      key: '2022-03-18:2022-04-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'air_transportation_fees',
                      label: 'Client Air Netfare (M&E Air)',
                    },
                    'Japan Airlines',
                    'GBR',
                    {
                      amount: 11.93,
                      currency: 'GBP',
                    },
                    {
                      amount: 15.51,
                      currency: 'USD',
                    },
                    1.3,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '9d77c8f6-bd13-4ad8-a3bf-5ca5f609e5bb',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-03',
                    '2022-03-02',
                    '2022-03-02',
                    {
                      start_date: '2022-03-18',
                      end_date: '2022-04-17',
                      key: '2022-03-18:2022-04-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'air_transportation_fees',
                      label: 'Client Air Netfare (M&E Air)',
                    },
                    'Japan Airlines',
                    'GBR',
                    {
                      amount: 11.93,
                      currency: 'GBP',
                    },
                    {
                      amount: 15.51,
                      currency: 'USD',
                    },
                    1.3,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'f922ca05-00df-4bd6-91cd-5fe22243683b',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-03',
                    '2022-03-16',
                    '2022-03-16',
                    {
                      start_date: '2022-03-18',
                      end_date: '2022-04-17',
                      key: '2022-03-18:2022-04-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'meals_employees',
                      label: 'Travel Counselor Meals',
                    },
                    'The Kinsale',
                    'FRA',
                    {
                      amount: 6.52,
                      currency: 'EUR',
                    },
                    {
                      amount: 7.69,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '217c7bae-37fa-47cb-8f13-5d2571e65bc2',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-04',
                    '2022-04-19',
                    '2022-04-19',
                    {
                      start_date: '2022-04-18',
                      end_date: '2022-05-17',
                      key: '2022-04-18:2022-05-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'air_transportation_fees',
                      label: 'Client Air Netfare (M&E Air)',
                    },
                    'Easy Jet',
                    'GBR',
                    {
                      amount: 7.59,
                      currency: 'GBP',
                    },
                    {
                      amount: 9.87,
                      currency: 'USD',
                    },
                    1.3,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: '7220154b-eed1-4039-9796-4b36e3104bd8',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                  [
                    '2022-04',
                    '2022-04-25',
                    '2022-04-25',
                    {
                      start_date: '2022-04-18',
                      end_date: '2022-05-17',
                      key: '2022-04-18:2022-05-17',
                    },
                    'Charles Wilson',
                    'ADKarma',
                    'AMEX',
                    'BOD0016064US028',
                    '3743*******5844',
                    {
                      value: 'marketing_advertising',
                      label: 'M&E Pre-Paid',
                    },
                    'Pluristem Therapeutics',
                    'FRA',
                    {
                      amount: 7.89,
                      currency: 'EUR',
                    },
                    {
                      amount: 9.31,
                      currency: 'USD',
                    },
                    1.18,
                    true,
                    {
                      uuid: 'b17cf4dd-886f-4e50-839b-12a0e9a71fad',
                      object_class: 'user',
                      label: 'C. Wilson',
                    },
                    null,
                    1,
                    100,
                    0,
                    {
                      uuid: 'f80f0668-e55c-43a2-aa86-97ba79d2f54e',
                      object_class: 'transaction',
                      label: null,
                    },
                  ],
                ],
              },
              currency: 'EUR',
            },
          },
          payload: [
            {
              type: 'table',
              id: 'spend_summary',
              options: {},
            },
          ],
          loading: false,
          loaded: true,
          filters: {},
          options: {},
          name: 'spend_summary',
          lastFetched: 1653493412900,
        },
      },
    },
  },
  entities: {
    Company: {
      '4e5608aa-40cf-49b1-8930-4a4855f34066': {
        entityName: COMPANY_ENTITY_NAME,
        accountingLink: CompanyExtractService.Standard,
        defaultCurrency: 'USD',
        purchaseServices: [],
        id: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        bankingIds: [],
        company_id: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        creation: new Date('2022-05-08T22:23:52.187Z'),
        countryCode: 'US',
        domain_id: 'b1a2cfdb-e44f-45be-8365-ca7d72533ba7',
        domainId: 'b1a2cfdb-e44f-45be-8365-ca7d72533ba7',
        features: [CompanyFeature.Spend],
        lastUpdate: new Date('2022-05-25T13:01:06.906Z'),
        accountingName: 'Test',
        accountingCurrency: 'USD',
        links: {
          parent: null,
          children: [],
        },
        name: 'AdKarma',
        normalizedName: 'adkarma',
        contactEmail: null,
        siblings: [],
        status: CompanyStatus.Active,
      },
    },
    User: {
      [FAKE_USER_ID]: {
        id: FAKE_USER_ID,
        level: UserLevel.COMPANY,
        entityName: 'user',
        creation: new Date('2022-05-08T22:23:52.540Z'),
        displayName: 'Charles Wilson',
        email: 'charles.wilson@adkarma.com',
        lastLogin: new Date('2022-05-25T13:04:12.522Z'),
        lastUpdate: new Date('2022-05-08T22:23:52.943Z'),
        login: 'charles.wilson@adkarma.com',
        status: 'active',
        user_id: FAKE_USER_ID,
        isFetching: false,
        fetchError: null,
        rolesList: 'admin',
        company_id: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        companyId: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        preferredCurrency: 'EUR',
        first_name: 'Charles',
        firstName: 'Charles',
        last_name: 'Wilson',
        lastName: 'Wilson',
        latestAcceptedEula: {
          url: 'http://localhost:3000/eulas/US-20220113.pdf',
          version: 'US-20220113',
          acceptedAt: '2022-05-25T13:04:15.791321Z',
          isLatest: true,
        },
        locale: navigator.language,
        roles: [CompanyUserRole.Admin],
        title: 'mr',
        supervisor_id: '923f346c-4d5f-4968-8cfc-bcc71ef23527',
        supervisorId: '923f346c-4d5f-4968-8cfc-bcc71ef23527',
        phone: null,
        birthDate: null,
      },
      '923f346c-4d5f-4968-8cfc-bcc71ef23527': {
        id: '923f346c-4d5f-4968-8cfc-bcc71ef23527',
        entityName: 'company',
        level: UserLevel.COMPANY,
        creation: new Date('2022-05-08T22:23:52.538Z'),
        displayName: 'Simon Putter',
        email: 'simon.putter@adkarma.com',
        lastLogin: null,
        lastUpdate: new Date('2022-05-08T22:23:52.538Z'),
        login: 'simon.putter@adkarma.com',
        status: 'active',
        user_id: '923f346c-4d5f-4968-8cfc-bcc71ef23527',
        isFetching: false,
        fetchError: null,
        rolesList: 'admin',
        company_id: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        companyId: '4e5608aa-40cf-49b1-8930-4a4855f34066',
        preferredCurrency: 'USD',
        first_name: 'Simon',
        firstName: 'Simon',
        last_name: 'Putter',
        lastName: 'Putter',
        latestAcceptedEula: null,
        locale: navigator.language,
        roles: [CompanyUserRole.Admin],
        title: 'mr',
        supervisor_id: null,
        supervisorId: null,
        phone: null,
        birthDate: null,
      },
    },
  },
};

export default fakeState;
