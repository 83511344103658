import keys from 'lodash/keys';
import eq from 'lodash/fp/eq';
import envConfig from 'config';

export enum CompanyKind {
  UnlimitedCompany = 'unlimited_company',
  LC = 'limited_company',
  LLP = 'limited_liability_partnership',
  ClubOrAssociation = 'club_or_association',
  Other = 'other',
  SoleTrader = 'sole_trader',
  Partnership = 'partnership',
  Society = 'society',
}

export interface CompanyHouseRegistrationDetails {
  addressLine1: string;
  addressLine2: string;
  region: string;
  zipCode: string;
  city: string;
  yearOfCreation?: string;
  industry?: string;
  kind: CompanyKind;
  name: string;
}

export const MAP_COMPANY_KIND_COMPANY_HOUSE_TYPE: {
  [i in CompanyKind]?: Array<string>;
} = {
  unlimited_company: ['private-unlimited', 'private-unlimited-nsc'],
  limited_company: [
    'ltd',
    'private-limited-guarant-nsc-limited-exemption',
    'private-limited-guarant-nsc',
    'private-limited-shares-section-30-exemption',
    'plc',
  ],
  limited_liability_partnership: [
    'limited-partnership',
    'scottish-partnership',
    'llp',
  ],
  club_or_association: [
    'charitable-incorporated-organisation',
    'scottish-charitable-incorporated-organisation',
  ],
  other: [
    'protected-cell-company',
    'old-public-company',
    'converted-or-closed',
    'assurance-company',
    'oversea-company',
    'eeig',
    'icvc-securities',
    'icvc-warrant',
    'icvc-umbrella',
    'industrial-and-provident-society',
    'northern-ireland',
    'northern-ireland-other',
    'royal-charter',
    'investment-company-with-variable-capital',
    'unregistered-company',
    'other',
    'european-public-limited-liability-company-se',
    'further-education-or-sixth-form-college-corporation',
  ],
  sole_trader: ['sole_trader'],
  partnership: ['partnership'],
  society: ['society'],
};

/**
 * Maps a registration type value to a company/business kind
 */
function mapCompanyKind(
  companyRegistrationType: string,
): CompanyKind | undefined {
  const kinds = keys(MAP_COMPANY_KIND_COMPANY_HOUSE_TYPE) as Array<CompanyKind>;
  return kinds.find((kind: CompanyKind) =>
    MAP_COMPANY_KIND_COMPANY_HOUSE_TYPE[kind].some(eq(companyRegistrationType)),
  );
}

/**
 * Will use company house api to retrieve registration details from given registration number
 */
export async function fetchCompanyRegistrationDetails(
  registrationNumber: string,
): Promise<CompanyHouseRegistrationDetails> {
  const response = await fetch(
    `https://api.companieshouse.gov.uk/company/${registrationNumber}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Basic ${btoa(envConfig.companyHouseKey)}`,
      }),
    },
  );

  if (response.status !== 200) {
    throw new Error('Could not find business registration details');
  }

  const {
    registered_office_address: {
      address_line_1: addressLine1,
      address_line_2: addressLine2,
      postal_code: zipCode,
      region,
      locality: city,
    },
    sic_codes: sicCodes,
    type,
    company_name: companyName,
    date_of_creation: dateOfCreation,
  } = await response.json();

  return {
    addressLine1,
    addressLine2,
    region,
    zipCode,
    city,
    industry: Array.isArray(sicCodes) ? sicCodes[0] : null,
    yearOfCreation: dateOfCreation.substr(0, 4),
    kind: mapCompanyKind(type),
    name: companyName,
  };
}
