import { State, Action, Actions } from './types';

const reducer = (state: State = null, action: Action = null) => {
  switch (action?.type) {
    case Actions.SET:
      return action.payload;

    case Actions.CLEAR:
      return null;

    default:
      return state;
  }
};

export default reducer;
