import { User } from '@neo1/client/lib/entities/user/types';
import { isSuperUser } from '@neo1/client/lib/entities/user/utils';
import { AppMenuRoute } from 'config/routes/utils';
import companyUserProfileRoute, {
  adminProfileRoute,
} from 'modules/Profile/route';

const getProfileRoutes = (user: User): AppMenuRoute[] => {
  if (isSuperUser(user)) {
    return [
      {
        ...adminProfileRoute,
        menu: {
          iconClass: 'user',
        },
      },
    ];
  }

  return companyUserProfileRoute.children;
};

export default getProfileRoutes;
