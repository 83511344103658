import {
  AccountingConfig,
  CardTxConfig,
  CashTxConfig,
  Company,
  UserVendor,
  Vendor,
} from './types';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { reify } from '../normalize';
import { normalizeAddress } from '../address/utils';
import {
  AccountingConfigStatus,
  COMPANY_ENTITY_NAME,
  COMPANY_EXTERNAL_ACCOUNTING_LINKS,
  CashAccountMappingPreferences,
  CompanyFeature,
  CompanyStatus,
  CreditCardMappingPreferences,
} from './constants';

export const toCashTxConfig = (config: CashTxConfig) => ({
  mapping_preferences: config.mappingPreferences,
  vendor_id: config.vendorId,
  users_mapping: config.usersMapping?.map((x) => ({
    user_id: x.userId,
    mapping: {
      mapping_type: x.mapping.mappingType,
      vendor_id: x.mapping.vendorId,
    },
  })),
});
export const toCardTxConfig = (config: CardTxConfig) => ({
  mapping_preferences: config.mappingPreferences,
  vendor_id: config.vendorId,
});

export function hasAddress(company: Company) {
  return !isNil(company.address);
}

export function getCompanyAddress(company: Company) {
  return company.address;
}

export function isCompanyRestricted(company: Company) {
  return !company || company.status !== CompanyStatus.Active;
}

export function getChildrenIds(company: Company) {
  return get(company, 'links.children', []);
}

export function hasChildren(company: Company) {
  const childrenIds = getChildrenIds(company);
  return childrenIds.length > 0;
}

export function hasParent(company: Company) {
  const parentCompanyId = get(company, 'links.parent');
  return !isNil(parentCompanyId);
}

export function isChildOf(a: Company) {
  return function (b: Company) {
    const bParentId = get(b, 'links.parent');
    return bParentId === a.company_id;
  };
}

export function isChildOrParent(company: Company) {
  return hasChildren(company) || hasParent(company);
}

export function getCompanyName(companies: Company[], companyId: string) {
  const companyMatch = companies.find((company) => company.id === companyId);

  return companyMatch && companyMatch.name;
}

export function isOrganizationRoot(company: Company): boolean {
  return !hasParent(company) && hasChildren(company);
}

export function hasPurchaseService(company: Company, service: string) {
  return company.purchaseServices.includes(service);
}

export function hasSpendManagementFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.Spend);
}

export function hasNeoTravelFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.Travel);
}

export function hasAmexVirtualAccountsFeature(company: Company) {
  return (
    company && company.features.includes(CompanyFeature.AmexVirtualAccounts)
  );
}

export function hasAmazonPurchaseFeature(company: Company) {
  return company && company.features.includes(CompanyFeature.AmazonPurchase);
}

export function hasFeature(company: Company, feature: CompanyFeature) {
  return company && company.features.includes(feature);
}
export function normalizeVendor(inputData: any = {}): Vendor {
  const data = reify(inputData);
  return {
    id: data.getString('id'),
    displayName: data.getString('display_name'),
  };
}
export function normalizeUserVendor(inputData: any = {}): UserVendor {
  const data = reify(inputData);
  return {
    userId: data.getUuid('user_id'),
    vendorId: data.getString('vendor_id'),
  };
}

export function normalizeAccountiongConfig(
  inputData: any = {},
): AccountingConfig {
  const data = reify(inputData);
  return {
    configStatus: data.mapEnum(
      AccountingConfigStatus,
      'accountingConfigStatus',
    ),
    cardTxMappingPreferences: data.mapEnum(
      CreditCardMappingPreferences,
      'cardTxMappingPreferences',
    ),
    singleCardTxVendorId: data.getString('singleCardTxVendorId'),
    cashTxMappingPreferences: data.mapEnum(
      CashAccountMappingPreferences,
      'cashTxMappingPreferences',
    ),
    singleCashTxVendorId: data.getString('singleCashTxVendorId'),
  };
}
export function normalizeAccountClassificationCount(inputData: any = {}): {
  assets: number;
  expenses: number;
} {
  return {
    // TODO in case we rework other accounting systems update this
    assets: inputData.asset,
    expenses: inputData.expense,
  };
}
export function normalizeCompany(input: object): Company {
  const data = reify(input);

  const companyAddress = data.map('address', normalizeAddress);

  // We set business name as address name by default
  if (companyAddress && !companyAddress.name) {
    Object.assign(companyAddress, { name: data.getString('name') });
  }

  return {
    accountingLink: data.get('accounting_link'),
    entityName: COMPANY_ENTITY_NAME,
    accountingName: data.getString('accounting_company_name'),
    accountingCurrency: data.getString('accounting_company_currency'),
    defaultCurrency: data.getString('default_currency'),
    purchaseServices: data.getArray('configured_purchase_services'),
    address: companyAddress,
    id: data.getString('company_id'),
    bankingIds: data.getArray('banking_ids'),
    company_id: data.getUuid('company_id'),
    creation: data.getDate('creation'),
    countryCode: data.getString('country_code'),
    domain_id: data.getUuid('domain_id'),
    domainId: data.getUuid('domain_id'),
    features: data.getArray('features'),
    lastUpdate: data.getDate('lastUpdate'),
    links: data.get('links', { parent: null, children: [] }),
    name: data.getString('name'),
    normalizedName: data.getString('normalized_name'),
    contactEmail: data.getStringOrNull('contact_email'),
    siblings: [],
    status: data.mapEnum(
      CompanyStatus,
      'status',
      CompanyStatus.PendingActivation,
    ),
    phoneNumber: data.get('phone_number'),
    website: data.getString('website'),
    fiscalPeriodStart: data.getString('fiscal_period_start'),
    fiscalPeriodEnd: data.getString('fiscal_period_end'),
    defaultDashboardLayout: data.getString('default_dashboard_layout', ''),
    travelContactPhone1: data.getString('voice_phone_number1', ''),
    travelContactPhone2: data.getString('voice_phone_number2', ''),
    whatsAppPhone: data.getString('whatsapp_phone_number', ''),
    travelContactEmail: data.getString('travel_contact_email', ''),
    referralCode: data.getString('referral_code', ''),
  };
}

export function normalizeCompanyNameVerification(input: object): {
  isExisting: boolean;
} {
  const data = reify(input);
  return {
    isExisting: data.getBoolean('is_existing'),
  };
}

export function isConnectedToExternalAccountingSystem(company: Company) {
  return COMPANY_EXTERNAL_ACCOUNTING_LINKS.includes(company?.accountingLink);
}

export const getAvalailableStatuses = (
  status: CompanyStatus,
): CompanyStatus[] => {
  const availableStatuses = [status];
  if (
    status === CompanyStatus.PendingActivation ||
    status === CompanyStatus.Active
  ) {
    return availableStatuses.concat(CompanyStatus.Locked);
  }
  if (
    status === CompanyStatus.ActivationInProgress ||
    status === CompanyStatus.DueDiligenceFailed
  ) {
    return availableStatuses.concat([
      CompanyStatus.Locked,
      CompanyStatus.Active,
    ]);
  }
  return availableStatuses;
};
