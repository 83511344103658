import uniq from 'lodash/uniq';
import { SupplyActions, Action, SupplyState } from './types';

export const initialState: SupplyState = {
  ids: [],
};

export default function suppliesReducer(
  state: SupplyState = initialState,
  action?: SupplyActions,
): SupplyState {
  switch (action.type) {
    case Action.SET_SUPPLY:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    case Action.SET_SUPPLIES:
      return {
        ...state,
        ids: action.ids,
      };

    case Action.DELETE_SUPPLY: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };
    }

    default:
      return state;
  }
}
