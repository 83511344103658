import { Action, GeneralConfigAction } from './actions';
import { GeneralConfigState } from './types';

const initialState: GeneralConfigState = {
  txAuditRate: null,
  mileageUnit: null,
};

export default (
  state: GeneralConfigState = initialState,
  action: GeneralConfigAction = null,
): GeneralConfigState => {
  switch (action?.type) {
    case Action.SET_GENERAL_CONFIG:
      return action.config;

    default:
      return state;
  }
};
