import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ActivateCompany__Neo1Features__title',
    defaultMessage: 'When would you like to start using these Neo1 features?',
  },
});

export default messages;
