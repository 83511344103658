import { useIntl } from 'react-intl';
import classNames from 'classnames';
import FormField from 'components/elements/form/Field';
import SelectBox from 'components/elements/form/fields/SelectBox';
import LoaderImage from 'components/elements/Loader/Image';
import { useWindowContext, WinSize } from 'contexts/window';
import Button, { ButtonTheme } from 'components/elements/Button';
import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import { CompanyFeatureUsage } from '@neo1/client/lib/entities/persistedUserData/constants';
import { useFormikContext } from 'formik';
import styles from '../../ActivateCompany.module.css';
import messages from './messages';
import subModuleMessages from '../../messages';
import ownStyles from './Neo1Features.module.css';
import FeatureUsage from './FeatureUsage';
import { FEATURE_USAGE_FIELD_OPTIONS } from './constants';

const EXPENSE_FEATURE_USAGE_FIELD_NAME = 'expenseFeatureUsage';
const PURCHASE_FEATURE_USAGE_FIELD_NAME = 'purchasingFeatureUsage';
const TRAVEL_FEATURE_USAGE_FIELD_NAME = 'travelFeatureUsage';
const TRAVEL_LABEL = 'Travel';
const EXPENSE_LABEL = 'Payment & Expenses';
const PURCHASE_LABEL = 'Employee purchasing';

type FeatureUsageField =
  | typeof EXPENSE_FEATURE_USAGE_FIELD_NAME
  | typeof PURCHASE_FEATURE_USAGE_FIELD_NAME
  | typeof TRAVEL_FEATURE_USAGE_FIELD_NAME;

function Neo1Features() {
  const {
    isSubmitting,
    setFieldValue,
    values,
    errors,
    validateForm,
    touched,
    setFieldTouched,
  } = useFormikContext<CompanyActivationData>();

  const setCompanyFeatureUsage =
    (fieldName: FeatureUsageField) => (value: CompanyFeatureUsage) => {
      setFieldValue(fieldName, value, false);
      setFieldTouched(fieldName, true, false);
      // This forces the validation of the form to run at a later time, so it uses the new value for the field.
      // This is because formik does not garantee that the validation will run using the last values when using `setValue`,
      // see the coment on the behavior from the creator of formik: https://github.com/formium/formik/issues/2083#issuecomment-571259235
      setTimeout(() => {
        validateForm();
      });
    };

  const setTravelFeatureUsage = setCompanyFeatureUsage(
    TRAVEL_FEATURE_USAGE_FIELD_NAME,
  );
  const setExpenseFeatureUsage = setCompanyFeatureUsage(
    EXPENSE_FEATURE_USAGE_FIELD_NAME,
  );
  const setPurchasingFeatureUsage = setCompanyFeatureUsage(
    PURCHASE_FEATURE_USAGE_FIELD_NAME,
  );
  const getFieldValue = (fieldName: FeatureUsageField) => values[fieldName];

  const isSmallScreen = [WinSize.sm, WinSize.xs].includes(
    useWindowContext().winSize,
  );

  const intl = useIntl();

  if (isSubmitting) {
    return (
      <div className={classNames(styles.loader, ownStyles.container)}>
        <LoaderImage width={80} height={80} />
      </div>
    );
  }

  const fieldError = (filedName: FeatureUsageField) =>
    touched[filedName] && errors[filedName];

  const renderRadioButtons = () => (
    <div className={ownStyles.radioGroupsContainer}>
      <FeatureUsage
        showLabelsOnTop
        name={TRAVEL_FEATURE_USAGE_FIELD_NAME}
        key={TRAVEL_FEATURE_USAGE_FIELD_NAME}
        label={TRAVEL_LABEL}
        iconName="luggage"
        error={fieldError(TRAVEL_FEATURE_USAGE_FIELD_NAME)}
        value={getFieldValue(TRAVEL_FEATURE_USAGE_FIELD_NAME)}
        onChange={setTravelFeatureUsage}
      />

      <FeatureUsage
        name={EXPENSE_FEATURE_USAGE_FIELD_NAME}
        key={EXPENSE_FEATURE_USAGE_FIELD_NAME}
        label={EXPENSE_LABEL}
        iconName="paymentMethods"
        error={fieldError(EXPENSE_FEATURE_USAGE_FIELD_NAME)}
        value={getFieldValue(EXPENSE_FEATURE_USAGE_FIELD_NAME)}
        onChange={setExpenseFeatureUsage}
      />

      <FeatureUsage
        name={PURCHASE_FEATURE_USAGE_FIELD_NAME}
        key={PURCHASE_FEATURE_USAGE_FIELD_NAME}
        label={PURCHASE_LABEL}
        iconName="purchase"
        error={fieldError(PURCHASE_FEATURE_USAGE_FIELD_NAME)}
        value={getFieldValue(PURCHASE_FEATURE_USAGE_FIELD_NAME)}
        onChange={setPurchasingFeatureUsage}
      />
    </div>
  );

  const renderSelectBoxes = () => (
    <div>
      <FormField
        title={TRAVEL_LABEL}
        name={TRAVEL_FEATURE_USAGE_FIELD_NAME}
        required
      >
        <SelectBox
          options={FEATURE_USAGE_FIELD_OPTIONS}
          value={getFieldValue(TRAVEL_FEATURE_USAGE_FIELD_NAME)}
        />
      </FormField>

      <FormField
        title={EXPENSE_LABEL}
        name={EXPENSE_FEATURE_USAGE_FIELD_NAME}
        required
      >
        <SelectBox
          options={FEATURE_USAGE_FIELD_OPTIONS}
          value={getFieldValue(EXPENSE_FEATURE_USAGE_FIELD_NAME)}
        />
      </FormField>

      <FormField
        title={PURCHASE_LABEL}
        name={PURCHASE_FEATURE_USAGE_FIELD_NAME}
        required
      >
        <SelectBox
          options={FEATURE_USAGE_FIELD_OPTIONS}
          value={getFieldValue(PURCHASE_FEATURE_USAGE_FIELD_NAME)}
        />
      </FormField>
    </div>
  );

  const renderContent = () => {
    if (isSmallScreen) {
      return renderSelectBoxes();
    }
    return renderRadioButtons();
  };

  return (
    <div className={styles.formMain}>
      <div className={ownStyles.container}>
        <h4 className={styles.title}>{intl.formatMessage(messages.title)}</h4>
      </div>
      {renderContent()}
    </div>
  );
}
export const Neo1FeaturesActions = ({
  className,
  onGoBack,
}: {
  className: string;
  onGoBack: () => void;
}) => {
  const { isSubmitting } = useFormikContext<CompanyActivationData>();
  const intl = useIntl();
  return (
    <footer className={className}>
      <Button
        label="Back"
        theme={ButtonTheme.Ghost}
        iconName="left"
        id="backButton"
        onClick={onGoBack}
        disabled={isSubmitting}
      />
      <Button
        label={intl.formatMessage(subModuleMessages.footer.btnSendRequest)}
        type="submit"
        id="btnContinue"
        loading={isSubmitting}
      />
    </footer>
  );
};
export default Neo1Features;
