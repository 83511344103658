import IntegrationsRedirect from '.';
import { ROUTE_NAME_REDIRECT, ROUTE_PATH_REDIRECT } from './constants';

const redirectRoute = {
  exact: true,
  path: ROUTE_PATH_REDIRECT,
  name: ROUTE_NAME_REDIRECT,
  component: IntegrationsRedirect,
};

export default redirectRoute;
