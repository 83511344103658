import { SpendPolicy } from '@neo1/client/lib/entities/spendPolicy/types';

export enum SpendPolicyActionType {
  SET_POLICIES = 'one/settings/spendPolicies/SET_POLICIES',
  SET_POLICY = 'one/settings/spendPolicies/SET_POLICY',
  DEL_POLICY = 'one/settings/spendPolicies/DEL_POLICY',
}

export const setPolicies = (policiesIds: SpendPolicy['nature'][]): Action => ({
  type: SpendPolicyActionType.SET_POLICIES,
  policiesIds,
});
export const delPolicy = (policyId: SpendPolicy['nature']): Action => ({
  type: SpendPolicyActionType.DEL_POLICY,
  policyId,
});
export const setPolicy = (policyId: SpendPolicy['nature']): Action => ({
  type: SpendPolicyActionType.SET_POLICY,
  policyId,
});

export type Action =
  | {
      type: SpendPolicyActionType.SET_POLICIES;
      policiesIds: SpendPolicy['nature'][];
    }
  | {
      type: SpendPolicyActionType.DEL_POLICY;
      policyId: SpendPolicy['nature'];
    }
  | {
      type: SpendPolicyActionType.SET_POLICY;
      policyId: SpendPolicy['nature'];
    };
