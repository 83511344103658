import { CSSProperties } from 'react';
import classNames from 'classnames';

import styles from './LoadPlaceholder.module.css';

type Props = {
  className?: string;
  style?: CSSProperties;
};

function LoadPlaceholder({ className, style }: Props) {
  return (
    <span style={style} className={classNames(styles.container, className)} />
  );
}

LoadPlaceholder.defaultProps = {
  className: undefined,
  style: undefined,
};

export default LoadPlaceholder;
