import { getConfigValue } from 'config';
import { useEffect } from 'react';

type ScriptAttribute = {
  src?: string;
  async?: boolean;
  nonce: string;
  dataDomain?: string;
  innerHTML?: string;
  type: string;
  id?: string;
};

const OneTrust = () => {
  const oneTrustId = getConfigValue('oneTrustId');

  const createExternalScript = ({
    src,
    async,
    nonce,
    dataDomain,
    type,
  }: ScriptAttribute) => {
    const script = document.createElement('script');
    script.type = type;
    script.src = src;
    script.async = async;
    script.setAttribute('nonce', `${nonce}`);
    if (dataDomain) script.setAttribute('data-domain-script', `${dataDomain}`);

    document.head.append(script);
    return script;
  };
  const createInlineScript = ({
    innerHTML,
    id,
    nonce,
    type,
  }: ScriptAttribute) => {
    const script = document.createElement('script');
    script.innerHTML = innerHTML;
    script.type = type;
    script.id = id;
    script.setAttribute('nonce', `${nonce}`);

    document.head.append(script);
    return script;
  };

  useEffect(() => {
    const firstExternalScript = createExternalScript({
      src: `https://cdn.cookielaw.org/consent/${oneTrustId}/OtAutoBlock.js`,
      async: true,
      type: 'text/javascript',
      nonce: window.currentCspNonce,
    });
    const secondExternalScript = createExternalScript({
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      async: true,
      nonce: window.currentCspNonce,
      type: 'text/javascript',
      dataDomain: oneTrustId,
    });
    const inlineScript = createInlineScript({
      innerHTML: `
                function OptanonWrapper() {};
              `,
      nonce: window.currentCspNonce,
      id: 'oneTrust',
      type: 'text/javascript',
    });
    return () => {
      firstExternalScript.remove();
      secondExternalScript.remove();
      inlineScript.remove();
    };
  }, []);
  return null;
};
export default OneTrust;
