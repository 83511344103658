export enum InternationalizationAction {
  SetLocale = 'one/app/i18n/SET_LOCALE',
  LoadMessagesOk = 'one/app/i18n/LOAD_MESSAGES_OK',
  LoadMessagesErr = 'one/app/i18n/LOAD_MESSAGES_ERR',
  LoadMessages = 'one/app/i18n/LOAD_MESSAGES',
}

export const setLocale = (locale: string) => ({
  type: InternationalizationAction.SetLocale,
  locale,
});

export const loadMessages = () => ({
  type: InternationalizationAction.LoadMessages,
});

export const loadMessagesErr = (error: Error) => ({
  type: InternationalizationAction.LoadMessagesErr,
  error,
});

export const loadMessagesSuccess = (messages: Record<string, string>) => ({
  type: InternationalizationAction.LoadMessagesOk,
  messages,
});

export type I18nActions =
  | {
      type: InternationalizationAction.SetLocale;
      locale: string;
    }
  | {
      type: InternationalizationAction.LoadMessagesErr;
      error: Error;
    }
  | {
      type: InternationalizationAction.LoadMessages;
    }
  | {
      type: InternationalizationAction.LoadMessagesOk;
      messages: Record<string, string>;
    };
