import ScopeType from 'redux/scopeType';
import { State, Action, Actions } from './types';

const initialState: State = Object.values(ScopeType).reduce(
  (acc, type) => ({
    ...acc,
    [type]: null,
  }),
  {} as State,
);

const reducer = (state: State = initialState, action?: Action): State => {
  switch (action?.type) {
    case Actions.SET_COUNTERS:
      return {
        ...state,
        ...action.state,
      };

    default:
      return state;
  }
};

export default reducer;
