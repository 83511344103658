import { ReactNode, useState } from 'react';
import { FormikValues, FormikHelpers } from 'formik';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import trim from 'lodash/trim';
import {
  Addr,
  toAddr,
  unconfirmedcomponentsToAddrKeys,
  validateAddressApi,
} from '@neo1/core/utils/addressValidation';
import { getConfigValue } from 'config';
import { useAddressUtils } from 'hooks/address';
import Button, { ButtonSize, ButtonTheme } from 'components/elements/Button';
import UnconfirmedAddress from './UnconfirmedAddress';
import ReplacedAddressFields, { VALUE } from './ReplacedAddressFields';
import { getSuspeciousKeys, US_COUNTRY_CODE } from './utils';

type Args = {
  onSubmit: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => Promise<void>;
  goToNextStep?: () => void;
  getAddrValues: (values: FormikValues) => Addr;
  getFormValues: (values: FormikValues, addr: Addr) => FormikValues;
  form: ReactNode;
};

type Props = {
  isSubmitDisabled: boolean;
  isAddressModified: boolean;
  isAddressUnconfirmed: boolean;
  isShowingValidationResult: boolean;
  onSubmitFromAddressComponent: (
    values: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => Promise<void>;
  addressView: ReactNode;
  onBack: () => void;
  backButton: ReactNode;
};

const getCleanAddr = (addr: Addr) =>
  addr ? mapValues(addr, (v) => (isNil(v) ? '' : trim(v))) : null;

const useAddressViews = ({
  onSubmit,
  goToNextStep,
  form,
  getAddrValues,
  getFormValues,
}: Args): Props => {
  const { isValidAddress } = useAddressUtils();

  const [originalAddress, setOriginalAddress] = useState<Addr>(null);

  const [modifiedAddress, setModifiedAddress] = useState<Addr>(null);

  const [unconfirmedAddressFields, setUnconfirmedAddressFields] = useState<
    string[] | null
  >(null);

  const [isModifiedAddressSelected, setIsModifiedAddressSelected] =
    useState<boolean>(false);

  const isUSAddress = originalAddress?.countryCode === US_COUNTRY_CODE;

  const isAddressUnconfirmed = Boolean(unconfirmedAddressFields);

  const isAddressModified = Boolean(modifiedAddress);

  const cleanAddressValidationState = () => {
    setOriginalAddress(null);
    setModifiedAddress(null);
    setUnconfirmedAddressFields(null);
    setIsModifiedAddressSelected(false);
  };

  const onSubmitFromAddressComponent = async (
    formValues: FormikValues,
    formikHelpers: FormikHelpers<FormikValues>,
  ) => {
    const origAddress: Addr = getCleanAddr(getAddrValues(formValues));
    setOriginalAddress(origAddress);

    const saveAndGoToNextStep = async () => {
      let newValues = formValues;
      if (modifiedAddress && isModifiedAddressSelected) {
        newValues = getFormValues(newValues, modifiedAddress);
      } else {
        newValues = getFormValues(newValues, origAddress);
      }
      await onSubmit(newValues, formikHelpers);
      if (goToNextStep) goToNextStep();
      cleanAddressValidationState();
    };

    try {
      if (isAddressModified || isAddressUnconfirmed || !origAddress) {
        // cleanup state once the validation is skiped.
        await saveAndGoToNextStep();
        return;
      }

      const result = await validateAddressApi(
        getConfigValue('googleApiBrowserKey'),
        origAddress,
      );

      const newAddress = getCleanAddr(toAddr(result.address));

      const unconfirmedKeys = union(
        result.address.unconfirmedComponentTypes,
        result.address.missingComponentTypes,
      );
      const suspeciousKeys = getSuspeciousKeys(result.address);

      const isGoogleUnconfirmed = Boolean(unconfirmedKeys.length);

      const isGoogleSuspecious = Boolean(suspeciousKeys.length);

      const hasUspsData = Boolean(result.uspsData);

      const isUspsUnconfirmed = hasUspsData
        ? !result.uspsData.dpvConfirmation ||
          result.uspsData.dpvConfirmation === 'N'
        : false;

      const isUnconfirmed =
        !isValidAddress(newAddress) ||
        (hasUspsData
          ? isUspsUnconfirmed || isGoogleSuspecious
          : isGoogleUnconfirmed);

      if (isUnconfirmed) {
        setUnconfirmedAddressFields(
          unconfirmedcomponentsToAddrKeys(unconfirmedKeys),
        );
      }

      const isModified = !isEqual(origAddress, newAddress);

      if (isModified) {
        setModifiedAddress(newAddress);
      }

      if (isModified && !isUnconfirmed) {
        setIsModifiedAddressSelected(true);
      }

      // if google validation is OK (no unconfirmed field on address, no proposed changes), proceed as normally
      if (!isModified && !isUnconfirmed) {
        await saveAndGoToNextStep();
      }
    } catch (e) {
      // if google validation result in an unexpected error  allow the user to continue.
      await saveAndGoToNextStep();
    }
  };

  const getAddressView = (): ReactNode => {
    if (isAddressUnconfirmed) {
      return (
        <UnconfirmedAddress
          isUSAddress={isUSAddress}
          unconfirmedAddressFields={unconfirmedAddressFields}
          address={originalAddress}
        />
      );
    }

    if (isAddressModified) {
      return (
        <ReplacedAddressFields
          isUSAddress={isUSAddress}
          replacedAddress={modifiedAddress}
          address={originalAddress}
          isReplacedAddressSelected={isModifiedAddressSelected}
          onChange={(value) =>
            setIsModifiedAddressSelected(value === VALUE.REPLACED_ADDRESS)
          }
        />
      );
    }

    return form;
  };

  const isShowingValidationResult = isAddressUnconfirmed || isAddressModified;

  const onBack = () => {
    cleanAddressValidationState();
  };

  const backButton = isShowingValidationResult ? (
    <Button
      iconName="left"
      label="Back"
      onClick={onBack}
      theme={ButtonTheme.Ghost}
      size={ButtonSize.Large}
      testId="backToFromBtn"
    />
  ) : undefined;

  return {
    isSubmitDisabled: isAddressUnconfirmed && isUSAddress,
    isAddressUnconfirmed,
    isAddressModified,
    isShowingValidationResult,
    onSubmitFromAddressComponent,
    addressView: getAddressView(),
    onBack,
    backButton,
  };
};

export default useAddressViews;
