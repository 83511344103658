import uniq from 'lodash/uniq';
import { Actions, ActionsType } from './actions';
import { State } from './types';

const initialState: State = {
  accountsSummary: [],
  instrumentIds: [],
};

export default function reducer(
  state = initialState,
  action: ActionsType = null,
): State {
  switch (action?.type) {
    case Actions.SET_ACCOUNTS_SUMMARY:
      return {
        ...state,
        accountsSummary: action.accountsSummary,
      };

    case Actions.SET_ACCOUNT_INSTRUMENT:
      return {
        ...state,
        instrumentIds: uniq([...state.instrumentIds, action.id]),
      };

    case Actions.SET_ACCOUNT_INSTRUMENTS:
      return {
        ...state,
        instrumentIds: action.ids,
      };

    default:
      return state;
  }
}
