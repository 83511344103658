export enum Actions {
  SET_EXPENSE_TYPES = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPES',
  SET_EXPENSE_TYPE = 'one/settings/ExpenseTypes/SET_EXPENSE_TYPE',
}
type SetExpenseTypes = {
  type: Actions.SET_EXPENSE_TYPES;
  ids: string[];
};

type SetExpenseType = {
  type: Actions.SET_EXPENSE_TYPE;
  id: string;
};

export function setAll(ids: string[]) {
  return { type: Actions.SET_EXPENSE_TYPES, ids };
}

export function set(id: string) {
  return { type: Actions.SET_EXPENSE_TYPE, id };
}

export type ExpenseActions = SetExpenseTypes | SetExpenseType;
