export enum ButtonTheme {
  Filled = 'filled',
  Outlined = 'outlined',
  Ghost = 'ghost',
  Discrete = 'discrete',
}

export enum ButtonSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export enum ButtonKind {
  Normal = 'normal',
  Success = 'success',
  Warning = 'warning',
  Alert = 'alert',
  Neutral = 'neutral',
}
