import { ReactComponent as AmexLogo } from '../../../../public/images/logos/neo1.svg';
import styles from './Loader.module.css';

const Loader = () => (
  <div className={styles.container}>
    <AmexLogo className={styles.logo} />
    <div className={styles.spinner} />
  </div>
);

export default Loader;
