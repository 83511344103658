import {
  AccountingSegment,
  AccountingSegmentValue,
} from '@neo1/client/lib/entities/segment/types';

export enum Actions {
  SET_SEGMENT_VALUES = 'one/settings/segments/SET_SEGMENT_VALUES',
  SET_SEGMENT_VALUE = 'one/settings/segments/SET_SEGMENT_VALUE',
  DELETE_SEGMENT_VALUE = 'one/settings/segments/DELETE_SEGMENT_VALUE',
  DELETE_SEGMENT_VALUES = 'one/settings/segments/DELETE_SEGMENT_VALUES',
}

export type SegmentValuesState = AccountingSegmentValue[];

export type SetAction = {
  type: Actions.SET_SEGMENT_VALUE;
  segmentValue: AccountingSegmentValue;
};
export type SetMultiAction = {
  type: Actions.SET_SEGMENT_VALUES;
  segmentValues: AccountingSegmentValue[];
};
export type DeleteSegmentValueAction = {
  type: Actions.DELETE_SEGMENT_VALUE;
  segmentId: AccountingSegmentValue['segmentId'];
  code: AccountingSegmentValue['code'];
};
export type DeleteSegmentValuesAction = {
  type: Actions.DELETE_SEGMENT_VALUES;
  segmentId: AccountingSegmentValue['segmentId'];
};

export type SegmentActions =
  | SetAction
  | SetMultiAction
  | DeleteSegmentValueAction
  | DeleteSegmentValuesAction;
export type SegmentValuesBySegmentId = Record<
  AccountingSegment['id'],
  AccountingSegmentValue[]
>;
