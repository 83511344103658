import uniq from 'lodash/uniq';
import { Action, Actions } from './actions';
import { GuestState } from './types';

export const initialState: GuestState = {
  ids: [],
};

export default (
  state: GuestState = initialState,
  action: Action = null,
): GuestState => {
  switch (action?.type) {
    case Actions.SET_GUESTS:
      return {
        ids: action.guestsIds,
      };
    case Actions.SET_GUEST:
      return {
        ids: uniq([...state.ids, action.guestId]),
      };
    case Actions.DELETE_GUEST:
      return {
        ids: state.ids.filter((id) => id !== action.guestId),
      };
    default:
      return state;
  }
};
