import { Currency } from '@neo1/client/lib/entities/referentialData/types';
import { FundingApproversData } from '@neo1/client/lib/entities/fundingApprover/types';
import {
  CompanyGroupUser,
  CompanyUser,
  User,
} from '@neo1/client/lib/entities/user/types';
import {
  COMPANY_GROUP_USER_ENTITY_NAME,
  USER_ENTITY_NAME,
  UserStatus,
} from '@neo1/client/lib/entities/user/constants';
import { isCompanyUser } from '@neo1/client/lib/entities/user/utils';
import { AppCoreState } from 'redux/types';
import { selectEntitiesByIds, selectEntityById } from 'redux/entities/selector';
import { COMPANY_ENTITY_NAME } from '@neo1/client/lib/entities/company/constants';
import { Company } from '@neo1/client/lib/entities/company/types';
import { UUID } from '@neo1/client';

export function selectIsLoggedInAs(state: AppCoreState): boolean {
  return state.auth.isLoggedInAs;
}

export function selectIsLoggedIn(state: AppCoreState): boolean {
  return Boolean(state.auth.currentUserId);
}
export function selectCurrentUserId(state: AppCoreState): UUID {
  return state.auth.currentUserId;
}

export function selectIsProcessing(state: AppCoreState): boolean {
  return state.auth.isProcessing;
}

export function selectIsLoggedAsCompany(state: AppCoreState): boolean {
  return Boolean(state.auth.loggedCompanyId);
}

export function selectReturnPath(state: AppCoreState): string {
  return state.auth.returnPath;
}

export function selectCurrentUser<T extends User>(state: AppCoreState) {
  const id = state.auth.currentUserId;
  return selectEntityById<T>(USER_ENTITY_NAME, id)(state);
}

export function selectDelegatedUsers(state: AppCoreState) {
  const ids = state.auth.delegatedUsersIds;
  if (!ids) return [];
  return selectEntitiesByIds<CompanyGroupUser>(
    COMPANY_GROUP_USER_ENTITY_NAME,
    ids,
  )(state);
}

export function selectEffectiveUser<T extends User>(state: AppCoreState) {
  const id = state.auth.effectiveUserId;
  return selectEntityById<T>(USER_ENTITY_NAME, id)(state);
}

/**
 * Get acting user from state (effective user OR current user)
 */
export function selectActingUser<T extends User>(state: AppCoreState) {
  return selectIsLoggedInAs(state)
    ? selectEffectiveUser<T>(state)
    : selectCurrentUser<T>(state);
}

export function selectIsDomainAdminLoggedAs(state: AppCoreState) {
  const { currentUserId, effectiveUserId } = state.auth;
  return currentUserId === effectiveUserId;
}

export function selectCurrentCompany(state: AppCoreState): Company | undefined {
  const currentUser = selectCurrentUser(state);
  if (!currentUser || !isCompanyUser(currentUser)) return null;

  return selectEntityById<Company>(
    COMPANY_ENTITY_NAME,
    currentUser.companyId,
  )(state);
}

export function selectActingCompany(state: AppCoreState): Company | undefined {
  const user = selectActingUser(state);
  if (!user || !isCompanyUser(user)) return null;
  return selectEntityById<Company>(COMPANY_ENTITY_NAME, user.companyId)(state);
}

export function selectActingCompanyGroup(state: AppCoreState): Company[] {
  const company = selectActingCompany(state);
  if (!company) return null;

  const children = selectEntitiesByIds<Company>(
    COMPANY_ENTITY_NAME,
    company.links.children,
  )(state);

  return [company, ...children];
}

export function selectActingUserPreferredCurrency(
  state: AppCoreState,
): Currency['code'] {
  const user = selectActingUser(state);
  const company = selectActingCompany(state);
  return (
    (isCompanyUser(user) && user?.preferredCurrency) ||
    company.defaultCurrency ||
    'USD'
  );
}

export function selectActingUserLocale(state: AppCoreState) {
  const user = selectActingUser(state);
  return (isCompanyUser(user) && user?.locale) || 'en-GB';
}

export function selectCompanyGroupUsers(
  state: AppCoreState,
): CompanyGroupUser[] {
  const { company } = state.auth;
  if (!company) return [];

  return selectEntitiesByIds<CompanyGroupUser>(
    COMPANY_GROUP_USER_ENTITY_NAME,
    company.groupUsersIds,
  )(state);
}

export const selectCompanyGroupUser =
  (userId: UUID) =>
  (state: AppCoreState): CompanyGroupUser => {
    const groupUsers = selectCompanyGroupUsers(state);
    return groupUsers.find(({ id }) => id === userId);
  };

export function selectCompanyUsers(state: AppCoreState) {
  const companyGroupUsers = selectCompanyGroupUsers(state);
  return companyGroupUsers.filter(
    ({ companyId }) => companyId === state.auth.company.id,
  );
}

export function selectActiveCompanyUsers(state: AppCoreState) {
  const companyGroupUsers = selectCompanyGroupUsers(state);
  return companyGroupUsers.filter(
    ({ companyId, status }) =>
      state.auth.company &&
      companyId === state.auth.company.id &&
      status === UserStatus.ACTIVE,
  );
}

export function selectCompanyGroupSupervisors(state: AppCoreState) {
  const companyGroupUsers = selectCompanyGroupUsers(state);
  const ids = new Set<string>();
  companyGroupUsers.forEach(
    ({ supervisorId }) => supervisorId && ids.add(supervisorId),
  );
  return companyGroupUsers.filter(({ id }) => ids.has(id));
}

export function selectActingUserSupervisor(state: AppCoreState) {
  const actingUser = selectActingUser<CompanyUser>(state);
  if (!actingUser.supervisorId) return null;

  const companyGroupUsers = selectCompanyGroupUsers(state);
  return companyGroupUsers.find(({ id }) => id === actingUser.supervisorId);
}

export function selectActingUserSupervisees(state: AppCoreState) {
  const actingUser = selectActingUser<CompanyUser>(state);
  if (!actingUser) return [];

  const companyGroupUsers = selectCompanyGroupUsers(state);
  return companyGroupUsers.filter(
    ({ supervisorId }) => supervisorId === actingUser.id,
  );
}

export const selectFundingApproversIds = (state: AppCoreState) =>
  state.auth.company?.fundingApproversData.fundingApproversIds || [];

export const selectOwnedAccountIds = (
  state: AppCoreState,
): FundingApproversData['ownedAccountsIds'] =>
  state.auth.company?.fundingApproversData.ownedAccountsIds || {};
