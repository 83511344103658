import { Addr } from '@neo1/core/utils/addressValidation';
import RadioGroup from 'components/elements/form/fields/RadioGroup';
import AddressBlock from '../AddressBlock';
import styles from './ReplacedAddressFields.module.css';

export enum VALUE {
  REPLACED_ADDRESS = 'replaced',
  ORIGINAL_ADDRESS = 'original',
}

type Props = {
  address: Addr;
  replacedAddress: Addr;
  isReplacedAddressSelected: boolean;
  isUSAddress: boolean;
  onChange: (value: VALUE) => void;
};

const ReplacedAddressFields = ({
  address,
  replacedAddress,
  isReplacedAddressSelected,
  isUSAddress,
  onChange,
}: Props) => {
  if (!address || !replacedAddress) {
    return null;
  }
  return (
    <div className={styles.container}>
      <h4 className={styles.title} data-testid="replacedAddressTitle">
        We detected some discrepancies in your address
        {isUSAddress &&
          ', please click confirm to save the corrected version otherwise click back to modify your address'}
      </h4>
      <RadioGroup
        horizontal
        onChange={onChange}
        value={
          isReplacedAddressSelected
            ? VALUE.REPLACED_ADDRESS
            : VALUE.ORIGINAL_ADDRESS
        }
        options={[
          {
            value: VALUE.ORIGINAL_ADDRESS,
            label: (
              <AddressBlock
                title="Original version:"
                address={address}
                testId="addressValues"
              />
            ),
          },
          {
            value: VALUE.REPLACED_ADDRESS,
            label: (
              <AddressBlock
                title="Corrected version"
                address={replacedAddress}
                replacedFeilds={Object.keys(replacedAddress).reduce(
                  (res, k) => {
                    if (replacedAddress[k] !== address[k]) {
                      return [...res, k];
                    }
                    return res;
                  },
                  [],
                )}
                testId="correctedAddressValues"
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default ReplacedAddressFields;
