import { Fragment, ReactNode } from 'react';
import classNames from 'classnames';
import FieldWrapper, {
  FieldWrapperProps,
} from 'components/elements/form/FieldWrapper';
import RadioBox from 'components/elements/RadioBox';
import styles from './RadioGroup.module.css';

export type RadioGroupOption = {
  label: ReactNode;
  value: string | number | boolean;
  testId?: string;
  extraContent?: ReactNode;
};

interface Props extends Omit<FieldWrapperProps, 'noBorder' | 'children'> {
  name?: string;
  onChange?: (value: any) => void;
  value?: any;
  disabled?: boolean;
  className?: string;
  horizontal?: boolean;
  options?: RadioGroupOption[];
}

const RadioGroup = ({
  onChange,
  disabled,
  name,
  value,
  className,
  horizontal,
  options,
  readOnly,
  ...fieldWrapperProps
}: Props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <FieldWrapper {...fieldWrapperProps} noBorder>
    <div
      className={classNames(
        styles.container,
        className,
        horizontal && styles.horizontal,
      )}
    >
      {options &&
        options.map(({ value: v, label, testId, extraContent }) => (
          <Fragment key={v.toString()}>
            <RadioBox
              name={name}
              isOn={value === v}
              isInvalid={fieldWrapperProps.isInvalid}
              label={label}
              value={v}
              onToggle={onChange}
              disabled={disabled}
              readOnly={readOnly}
              id={`${name}_${v}`}
              testId={testId}
            />
            {extraContent}
          </Fragment>
        ))}
    </div>
  </FieldWrapper>
);

RadioGroup.defaultProps = {
  name: undefined,
  onChange: undefined,
  value: undefined,
  disabled: false,
  className: undefined,
  horizontal: false,
  options: undefined,
};

export default RadioGroup;
