import { getClient } from '@neo1/client/lib/rpc/client';

export type EulaConfig = {
  version: string;
  url: string;
};

export const getCountryEula = async (
  countryCode: string,
): Promise<EulaConfig> =>
  await getClient().sendCommand({
    method: 'get_country_eula_link',
    params: {
      country_code: countryCode,
    },
  });

export const getCompanyEula = async (companyId: string): Promise<EulaConfig> =>
  await getClient().sendCommand({
    method: 'get_company_eula_link',
    params: {
      company_id: companyId,
    },
  });
