import {
  ComponentType,
  createContext,
  FormEventHandler,
  HTMLAttributes,
  useContext,
} from 'react';

import FormFooter from './Footer';

interface Props extends HTMLAttributes<HTMLFormElement> {
  noAutoFocus?: boolean;
  onSubmit?: FormEventHandler;
  testid?: string;
}

export type FormContextValue = {
  focusController: FocusCtrl;
};

export type InjectedFormContextProps = {
  formContext: FormContextValue;
};

class FocusCtrl {
  called: boolean;

  constructor(called: boolean = false) {
    this.called = called;
  }

  requestFocus = () => {
    if (!this.called) {
      this.called = true;
      return true;
    }
    return false;
  };
}

const DEFAULT_FORM_CTX: FormContextValue = { focusController: new FocusCtrl() };

export const FormContext = createContext(DEFAULT_FORM_CTX);

/**
 * HOC to provide FormContext value as given Component prop
 * @param {Component} WrappedFormComponent
 */
export function withFormContext<
  T extends InjectedFormContextProps,
  W extends Omit<T, keyof InjectedFormContextProps>,
>(
  WrappedFormComponent: ComponentType<W & InjectedFormContextProps>,
): ComponentType<any> {
  return function FormContextWrapper(props: any) {
    const formContext = useContext(FormContext);
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <WrappedFormComponent {...props} formContext={formContext} />;
  };
}

function Form({
  children,
  noAutoFocus,
  onSubmit,
  className,
  id,
  testid,
}: Props) {
  return (
    <FormContext.Provider
      value={{ focusController: new FocusCtrl(noAutoFocus) }}
    >
      <form
        onSubmit={onSubmit}
        className={className}
        id={id}
        data-testid={testid}
      >
        {children}
      </form>
    </FormContext.Provider>
  );
}

Form.defaultProps = {
  noAutoFocus: false,
  onSubmit: undefined,
  testid: undefined,
};

Form.Footer = FormFooter;

export default Form;
