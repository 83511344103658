import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { Link as LinkReactRouter, LinkProps } from 'react-router-dom';
import styles from './Link.module.css';

export enum LinkTheme {
  Primary = 'primary',
  Grey = 'grey',
  Dark = 'dark',
}

export enum LinkFontSize {
  S = 'S',
  M = 'M',
  L = 'L',
  Inherit = 'I',
}

interface Props extends Omit<LinkProps, 'children' | 'className'> {
  theme?: LinkTheme;
  fontSize?: LinkFontSize;
  standAlone?: boolean;
  bold?: boolean;
  children: string;
  disabled?: boolean;
  testId?: string;
}

const Link = ({
  children,
  to,
  bold,
  fontSize,
  standAlone,
  theme,
  disabled,
  testId,
  ...props
}: Props) => {
  const ariaLabel = props['aria-label'] ? props['aria-label'] : children;
  return (
    <LinkReactRouter
      data-testid={testId}
      to={to}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={classNames(styles.link, {
        textS: fontSize === LinkFontSize.S,
        textM: fontSize === LinkFontSize.M,
        textL: fontSize === LinkFontSize.L,
        [styles.inherit]: fontSize === LinkFontSize.Inherit,
        [styles.standAlone]: standAlone,
        [styles.bold]: bold,
        [styles.primaryTheme]: theme === LinkTheme.Primary,
        [styles.greyTheme]: theme === LinkTheme.Grey,
        [styles.darkTheme]: theme === LinkTheme.Dark,
        [styles.disabled]: disabled === true,
      })}
      onClick={(event) => {
        if (disabled) {
          event.preventDefault();
          return;
        }
        if (isFunction(props.onClick)) {
          props.onClick(event);
        }
      }}
      aria-label={ariaLabel}
      aria-disabled={disabled ? 'true' : 'false'}
    >
      {children}
    </LinkReactRouter>
  );
};

Link.defaultProps = {
  testId: undefined,
  bold: false,
  disabled: false,
  fontSize: LinkFontSize.M,
  standAlone: false,
  theme: LinkTheme.Primary,
};

export default Link;
