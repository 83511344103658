import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'ActivateCompany__getStarted__title',
    defaultMessage:
      'For us to do a great job we need to know more about your business. To complete your setup you will need:',
  },
  companyName: {
    id: 'ActivateCompany__getStarted__companyName',
    defaultMessage: 'Registered business name',
  },
  companyType: {
    id: 'ActivateCompany__getStarted__companyType',
    defaultMessage: 'Business type',
  },
  incorporationDate: {
    id: 'ActivateCompany__getStarted__incorporationDate',
    defaultMessage: 'Incorporation date',
  },
  industry: {
    id: 'ActivateCompany__getStarted__industry',
    defaultMessage: 'Industry',
  },
  companySize: {
    id: 'ActivateCompany__getStarted__companySize',
    defaultMessage: 'Business size',
  },
  vat: {
    id: 'ActivateCompany__getStarted__vat',
    defaultMessage: 'VAT number (if applicable)',
  },
  dunsNumber: {
    id: 'ActivateCompany__getStarted__dunsNumber',
    defaultMessage: 'DUNS number',
  },
  shareDetails: {
    id: 'ActivateCompany__getStarted__shareDetails',
    defaultMessage:
      'Details of the individuals who own a share of 25% share in your business. We only need to know about people, not other corporate entities that may have a stake in your business',
  },
});

export default messages;
