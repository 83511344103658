import get from 'lodash/get';

import { newTimestamp } from '@neo1/core/utils/dates';

import {
  Actions as reportingActions,
  PredefinedReportingActions,
} from './actions';

const initialState = {
  lastUsedParams: null,
  reports: {},
};

export const initialReportState = {
  params: {
    scope: null,
    dateRange: null,
    fields: [],
    orderBy: null,
  },
  data: {},
  payload: [],
  loading: false,
  loaded: false,
  filters: {},
  options: {},
};

export default function reducer(
  state = initialState,
  action: PredefinedReportingActions = null,
) {
  switch (action?.type) {
    case reportingActions.LOAD_REPORT:
      return {
        ...state,
        lastUsedParams: action.params,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            params: action.params,
            name: action.reportName,
            loading: true,
            payload: action.payload || [],
          },
        },
      };

    case reportingActions.LOAD_REPORT_OK:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            loading: false,
            loaded: true,
            lastFetched: newTimestamp(),
            filters: {},
            data: action.reportsByChartName,
          },
        },
      };

    case reportingActions.LOAD_REPORT_ERR:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            data: {},
            loaded: false,
            loading: false,
            error: action.error,
          },
        },
      };

    case reportingActions.SET_REPORT_FILTERS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            filters: action.filters,
          },
        },
      };

    case reportingActions.SET_REPORT_PARAMS:
      return {
        ...state,
        lastUsedParams: action.params,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            params: {
              ...get(state, `reports.${action.reportName}.params`, {}),
              ...action.params,
            },
          },
        },
      };

    case reportingActions.SET_REPORT_OPTS:
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportName]: {
            ...initialReportState,
            ...state.reports[action.reportName],
            options: {
              ...get(state, `reports.${action.reportName}.options`, {}),
              [action.key]: action.value,
            },
          },
        },
      };
    case reportingActions.RESET:
      return initialState;

    default:
      return state;
  }
}
