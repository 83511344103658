import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'Quickbooks__getStarted__title',
    defaultMessage:
      'By connecting to your Quickbooks account, you authorize Neo1 to download and share data from Neo1 to Quickbooks',
  },
  description1: {
    id: 'Quickbooks__getStarted__desc1',
    defaultMessage: `When connecting to Quickbooks, Neo1 will retrieve your:`,
  },
  description2: {
    id: 'Quickbooks__getStarted__desc2',
    defaultMessage: `Expense accounts and the corresponding accounting codes`,
  },
  description3: {
    id: 'Quickbooks__getStarted__desc3',
    defaultMessage: `We will use them to automatically classify your transactions. `,
  },
  description4: {
    id: 'Quickbooks__getStarted__desc4',
    defaultMessage: `Furthermore, whenever you decide so, Neo1 will directly send to the corresponding expense accounts all transactions that have been made. `,
  },
});
export default messages;
