import { UpdateAction, Actions } from './actions';

export const initialState = {
  isUpdateLaunched: false,
};
export type State = {
  isUpdateLaunched: boolean;
};

export default function reducer(
  state = initialState,
  action: UpdateAction = null,
) {
  switch (action?.type) {
    case Actions.LAUNCH_UPDATE:
      return {
        isUpdateLaunched: true,
      };
    default:
      return state;
  }
}
