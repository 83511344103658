import { EntitiesState } from 'redux/entities/types';
import { denormalizeById } from '@neo1/core/utils/collections';
import { Action, ActionTypes } from 'redux/entities/actions';

const initialState: EntitiesState = {};

export default (
  state: EntitiesState = initialState,
  action: Action<any> = { type: ActionTypes.Nop },
): EntitiesState => {
  switch (action.type) {
    case ActionTypes.Set:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          [action.entity.id]: action.entity,
        },
      };

    case ActionTypes.SetMulti:
      return {
        ...state,
        [action.entityName]: {
          ...state[action.entityName],
          ...denormalizeById(action.entities),
        },
      };
    case ActionTypes.HardSet:
      return {
        ...state,
        [action.entityName]: {
          ...denormalizeById(action.entities),
        },
      };
    case ActionTypes.Delete: {
      const byId = Object.entries(state[action.entityName]).reduce(
        (_byId, [entityId, entity]) => {
          if (entityId === action.entityId) {
            return _byId;
          }

          return {
            ..._byId,
            [entityId]: entity,
          };
        },
        {},
      );

      return {
        ...state,
        [action.entityName]: byId,
      };
    }

    default:
      return state;
  }
};
