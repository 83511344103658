export enum Action {
  SET_TRAVEL_DOCUMENT = 'one/travelDocuments/SET_TRAVEL_DOCUMENT',
  SET_TRAVEL_DOCUMENTS = 'one/travelDocuments/GET_TRAVEL_DOCUMENTS',
  DELETE_TRAVEL_DOCUMENT = 'one/travelDocuments/DELETE_TRAVEL_DOCUMENT',
}

export interface SetTravelDocumentAction {
  type: typeof Action.SET_TRAVEL_DOCUMENT;
  id: string;
}

export type GetTravelDocumentsAction = {
  type: typeof Action.SET_TRAVEL_DOCUMENTS;
  ids: string[];
};

export interface DeleteTravelDocumentAction {
  type: typeof Action.DELETE_TRAVEL_DOCUMENT;
  id: string;
}

export type TravelDocumentActions =
  | SetTravelDocumentAction
  | GetTravelDocumentsAction
  | DeleteTravelDocumentAction;

export type TravelDocsState = {
  ids: string[];
};
