import { createContext, useState, useEffect, ComponentType } from 'react';
import get from 'lodash/get';
import { useJsApiLoader, LoadScriptProps } from '@react-google-maps/api';
import { getConfigValue } from 'config';

export const GoogleMapsContext = createContext({
  map: null,
  isLoaded: false,
});

const googleMapsConfig: LoadScriptProps = {
  id: 'google-maps-script-loader',
  googleMapsApiKey: getConfigValue('googleApiBrowserKey'),
  libraries: ['geometry', 'drawing', 'places'],
  language: 'en',
};

const createMapRef = () => {
  const maps = get(window, 'google.maps');
  if (!maps) {
    return null;
  }
  const mapNode = document.createElement('div');
  const center = new maps.LatLng(0, 0);
  return new maps.Map(mapNode, { center });
};

function withGoogleMaps<T>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    const [map, setMap] = useState<google.maps.Map>(null);

    const { isLoaded } = useJsApiLoader(googleMapsConfig);

    useEffect(() => {
      if (!isLoaded) return;
      setMap(createMapRef());
    }, [isLoaded]);

    return (
      <GoogleMapsContext.Provider
        value={{
          isLoaded,
          map,
        }}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {isLoaded ? <WrappedComponent {...props} /> : null}
      </GoogleMapsContext.Provider>
    );
  };
}

export default withGoogleMaps;
