import { ofType } from 'redux-observable';
import { AnyAction } from 'redux';
import { AuthActions } from 'modules/Authentification/redux/actions';
import { resetState } from 'modules/Reports/Predefined/redux/actions';
import { map } from 'rxjs/operators';

const reports = (action$) =>
  action$.pipe(
    ofType<AnyAction, AuthActions>(
      AuthActions.LOGOUT,
      AuthActions.LOGIN_AS,
      AuthActions.LOGOUT_AS,
    ),
    map(() => resetState()),
  );
export default reports;
