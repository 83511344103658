export type PasswordPolicy = {
  name: string;
  description: string;
};

export const POLICIES: PasswordPolicy[] = [
  { name: 'size', description: 'between 12 and 100 characters' },
  { name: 'upper', description: 'at least one upper case character' },
  { name: 'lower', description: 'at least one lower case character' },
  { name: 'number', description: 'at least one number' },
  { name: 'special', description: 'at least one special character' },
];

type PolicyValidation = (password: string) => boolean;
export const ALLOWED_SPECIAL_CHARACTERS = `!@#$%^&*()_+-=[]{};':"|,.<>/?`;
const specialChars = ALLOWED_SPECIAL_CHARACTERS.split('');

export const PasswordPolicies: Record<string, PolicyValidation> = {
  size: (password) => password.length >= 12 && password.length <= 100,
  upper: (password) => /[A-Z]/.test(password),
  lower: (password) => /[a-z]/.test(password),
  number: (password) => /[0-9]/.test(password),

  special: (password) =>
    password.split('').some((char) => specialChars.includes(char)),
};
