import dashboardRoute from 'modules/Dashboard/route';
import budgetRoute from 'modules/Budgets/route';
import supplyRoute from 'modules/Supplies/route';
import spendRoute from 'modules/Spend/route';
import authorizeRoute from 'modules/Authorize/route';
import reconciliationRoute from 'modules/Reconciliation/route';
import companyUserProfileRoute from 'modules/Profile/route';
import reportsRoute from 'modules/Reports/route';
import {
  settingsGeneralRoute,
  settingsExpenseRoute,
} from 'modules/Settings/route';
import redirectRoute from 'modules/Redirect/route';
import receiptsRoute from 'modules/Receipts/route';
import travelRoute from 'modules/Travel/route';
import downloadRoute from 'modules/Download/route';

const companyUserRoutes = [
  dashboardRoute,
  budgetRoute,
  supplyRoute,
  travelRoute,
  spendRoute,
  authorizeRoute,
  reconciliationRoute,
  receiptsRoute,
  companyUserProfileRoute,
  reportsRoute,
  settingsGeneralRoute,
  settingsExpenseRoute,
  redirectRoute,
  downloadRoute,
];

export default companyUserRoutes;
