import uniq from 'lodash/uniq';
import { BudgetActions, Action, BudgetState } from './types';

export const initialState: BudgetState = {
  ids: [],
};

export default function budgetsReducer(
  state: BudgetState = initialState,
  action?: BudgetActions,
): BudgetState {
  switch (action.type) {
    case Action.SET_BUDGET:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    case Action.SET_BUDGETS:
      return {
        ...state,
        ids: action.ids,
      };

    case Action.DELETE_BUDGET: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };
    }

    default:
      return state;
  }
}
