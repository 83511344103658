import classNames from 'classnames';

import styles from './Footer.module.css';

type Props = {
  className?: string;
  children: any;
};

function FormFooter({ className, children }: Props) {
  return (
    <div className={classNames(styles.container, className)}>{children}</div>
  );
}

FormFooter.defaultProps = {
  className: undefined,
};

export default FormFooter;
