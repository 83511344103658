import async from 'utils/wrapAsync';
import { AppMenuRoute } from 'config/routes/utils';
import { ROUTE_PATH_DELEGATIONS, ROUTE_NAME_DELEGATIONS } from './constants';

const route: AppMenuRoute = {
  title: 'Delegations',
  menu: {
    iconClass: 'key',
  },
  path: ROUTE_PATH_DELEGATIONS,
  name: ROUTE_NAME_DELEGATIONS,
  component: async(
    () =>
      import(
        /* webpackChunkName: "delegations" */
        '.'
      ),
  ),
};

export default route;
