import merge from 'lodash/merge';

import { AppConfig } from './types';
import dev from './env/dev';
import dist from './env/dist';

declare let ENV: string;

const config: AppConfig = ENV === 'dist' ? dist : dev;

export function override(overridingConfig: Record<string, any>) {
  if (!overridingConfig || typeof overridingConfig !== 'object') {
    throw new Error('Invalid overriding config');
  }

  merge(config, overridingConfig);
}

export function getConfigValue<K extends keyof AppConfig>(key: K) {
  return config[key];
}

export default config;
