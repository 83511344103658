import { defineMessages } from 'react-intl';

const messages = defineMessages({
  about: {
    id: 'Activate__tips__about',
    defaultMessage:
      ': Provide your company identification number first, and we will try to complete as much of this form as possible',
  },
  aboutTitle: {
    id: 'Activate__tips__about__title',
    defaultMessage: 'Quick tip',
  },
  ownersTitle: {
    id: 'Activate__tips__owners__title',
    defaultMessage: 'Notice',
  },
  owners: {
    id: 'Activate__tips__owners',
    defaultMessage:
      ': We only need to know about people, not other corporate entities that may have a stake in your business.',
  },
});

export default messages;
