import { defineMessages } from 'react-intl';

const messages = defineMessages({
  showLabel: {
    id: 'PasswordInput__showLabel',
    defaultMessage: 'Show password',
  },
  hideLabel: {
    id: 'PasswordInput__hideLabel',
    defaultMessage: 'Hide password',
  },
});

export default messages;
