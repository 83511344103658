const displayEmail = (email: string, limit = 30) => {
  if (email.length < limit) return email;

  const [name, domain] = email.split('@');

  const ellipsisLength = 3;
  const lengthLimit = Math.max(ellipsisLength, limit - domain.length - 1);
  const lengthBeforeEllipsis =
    Math.ceil((lengthLimit - ellipsisLength) / 2) || 1;
  const lengthAfterEllipsis =
    Math.floor((lengthLimit - ellipsisLength) / 2) || 1;

  const nameBefore = name.slice(0, lengthBeforeEllipsis);
  const ellipsis = '.'.repeat(ellipsisLength);
  const nameAfter = name.slice(-lengthAfterEllipsis);

  return `${nameBefore}${ellipsis}${nameAfter}@${domain}`;
};

export default displayEmail;
