import { components, OptionProps } from 'react-select';

const CountryOption = ({ children, ...props }: OptionProps<any>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Option {...props}>
    <span>{children}</span>
  </components.Option>
);

export default CountryOption;
