import { reify } from '@neo1/client';
import { Country, CountryState, Currency } from './types';

/**
 * Normalizes given input to a country entity
 * @param input
 */
export const normalizeCountry = (input: any): Country => {
  const data = reify(input);

  return {
    name: data.getString('name'),
    currency: data.getString('currency'),
    active: data.getBoolean('available_for_signup'),
    referenced: data.getBoolean('available_for_companies'),
    locale: data.getString('locale'),
    code: data.getString('code'),
    states: data.getArray<CountryState>('states', undefined, []),
  };
};

/**
 * Normalizes given input to a currency entity
 * @param input
 */
export const normalizeCurrency = (input: any): Currency => {
  const data = reify(input);

  return {
    name: data.getString('name'),
    code: data.getString('code'),
    numericCode: data.getNumber('numeric_code'),
    fractionDigits: data.getNumber('fraction_digit'),
  };
};

export function hasStates(country: Country) {
  return country?.states?.length > 0;
}

export function matchesCountryStateCode(code: string) {
  return (state: CountryState): boolean => state.code === code;
}
