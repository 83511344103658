import { HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import Icon, { IconName } from 'components/elements/Icon';
import styles from './IconText.module.css';

const directionStyles: Record<string, string> = {
  top: styles.orientationTop,
  bottom: styles.orientationBottom,
  left: styles.orientationLeft,
  right: styles.orientationRight,
};

export interface IconTextProps extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  icon: IconName;
  text: ReactNode;
  iconStyles?: string;
  textStyles?: string;
  textDirection?: string;
}

const IconText = ({
  className,
  icon,
  text,
  textStyles,
  iconStyles,
  textDirection,
  onClick,
}: IconTextProps) => (
  <div
    role="presentation"
    className={classNames(
      styles.container,
      className,
      textDirection && directionStyles[textDirection],
    )}
    onClick={onClick}
  >
    <Icon
      name={icon}
      className={classNames(styles.icon, iconStyles, 'iconText__icon')}
    />
    <span className={classNames(styles.text, textStyles, 'iconText__text')}>
      {text}
    </span>
  </div>
);

IconText.defaultProps = {
  textDirection: 'left',
  className: undefined,
  iconStyles: undefined,
  textStyles: undefined,
};

export default IconText;
