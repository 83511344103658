import { useTransition, animated } from '@react-spring/web';
import Portal from 'components/composed/Portal';
import Loader from '..';
import styles from './LoaderOverlay.module.css';

type Props = {
  isOpen?: boolean;
};

const LoaderOverlay = ({ isOpen }: Props) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 250 },
  });

  return transition((style, item) =>
    item ? (
      <Portal>
        <animated.div className={styles.container} style={style}>
          <div className={styles.overlay}>
            <Loader />
          </div>
        </animated.div>
      </Portal>
    ) : null,
  );
};

LoaderOverlay.defaultProps = {
  isOpen: false,
};

export default LoaderOverlay;
