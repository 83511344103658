type OnProgressCallback = (progress: number) => void;
export type OnProgressRunner = (cb: OnProgressCallback) => Promise<any>;

export const UNIQ_NOTIFICATION = '0000-0000-0000';
export type ToastersState = Record<string, Toaster>;
export type ToasterType = 'error' | 'success' | 'warning' | 'progress';

export type ToasterProps = {
  type: ToasterType;
  message: string;
  actionButton?: {
    label: string;
    onClick: () => void;
    iconName?: string;
  };
  visible?: boolean;
  onDismiss?: Function;
  timeout?: number;
  showCancel?: boolean;
  pushOnce?: boolean;
};

export interface Toaster extends ToasterProps {
  type: ToasterType;
  message: string;
  visible: boolean;
  onDismiss?: Function;
  showCancel?: boolean;
  timeout?: number;
  progress: number;
  uuid: string;
  created: Date;
}
