import uniq from 'lodash/uniq';
import { Action, LoyaltyCardActions, LoyaltyCardsState } from './types';

const INITIAL_STATE: LoyaltyCardsState = {
  ids: [],
  types: {
    ids: [],
  },
};

export default function loyaltyCardsReducer(
  state: LoyaltyCardsState = INITIAL_STATE,
  action?: LoyaltyCardActions,
): LoyaltyCardsState {
  switch (action.type) {
    case Action.SET_LOYALTY_CARD:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };
    case Action.DELETE_LOYALTY_CARD:
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };
    case Action.SET_LOYALTY_CARDS:
      return {
        ...state,
        ids: action.ids,
      };
    case Action.SET_LOYALTY_CARD_TYPES:
      return {
        ...state,
        types: {
          ids: action.ids,
        },
      };
    default:
      return state;
  }
}
