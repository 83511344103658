import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';
import isString from 'lodash/isString';
import { ReactNode, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';

import styles from './Tabs.module.css';

type TabsType = 'default' | 'route';

export enum TabTheme {
  Navigation = 'navigation',
  Panel = 'panel',
}

export type TabsElement = {
  showElement?: boolean;
  exact?: boolean;
  title: ReactNode;
  path?: string;
  onClick?: () => void;
  content: ReactNode;
  key?: string;
  ariaLabel?: string;
  isDark?: boolean;
};

type Props = {
  theme?: TabTheme;
  menuClassName?: string;
  contentClassName?: string;
  initialTab?: number;
  type?: TabsType;
  containerClassName?: string;
  elements: TabsElement[];
  linkType?: string;
  defaultRoute?: string;
  isDark?: boolean;
};

function Tabs({
  theme,
  elements,
  menuClassName,
  contentClassName,
  initialTab,
  type,
  containerClassName,
  linkType,
  defaultRoute,
  isDark,
}: Props) {
  const [activeTab, setActiveTab] = useState(initialTab);

  const content =
    type === 'route' ? (
      <Switch>
        {elements.map((el) => (
          <Route
            key={el.key || activeTab}
            path={el.path}
            exact={el.exact}
            render={() => el.content}
          />
        ))}
        <Redirect to={defaultRoute || elements[0].path} />
      </Switch>
    ) : (
      elements[activeTab]?.content
    );

  useEffect(() => {
    if (!elements[activeTab]) {
      setActiveTab(initialTab);
    }
  }, [activeTab, elements, initialTab]);

  const renderLink = () =>
    elements.map((el, index): ReactNode => {
      const buttonTitle = `Go to ${el.ariaLabel || el.title}`;

      const handleTabClick = () => {
        setActiveTab(index);
        if (typeof el.onClick === 'function') {
          el.onClick();
        }
      };
      if (isBoolean(el.showElement) && !el.showElement) return null;
      return linkType === 'navLink' ? (
        <NavLink
          key={el.path}
          role="listitem button"
          data-testid="listitem button"
          aria-label={`Go to ${el.ariaLabel || el.title}`}
          to={el.path}
          exact={el.exact}
          className={classNames(styles.tabMenuItem, {
            [styles.isDark]: isDark,
          })}
          activeClassName={styles.tabSelected}
        >
          {el.title}
        </NavLink>
      ) : (
        <button
          type="button"
          key={isString(el.title) ? el.title : `tab-${index}`}
          title={buttonTitle}
          aria-label={buttonTitle}
          className={classNames(styles.tabMenuItem, {
            [styles.tabSelected]: index === activeTab,
            [styles.isDark]: isDark,
          })}
          onClick={handleTabClick}
          data-testid="tab"
        >
          {el.title}
        </button>
      );
    });
  return (
    <nav
      className={classNames(
        styles.container,
        containerClassName,
        styles[theme],
      )}
      data-testid="tabs"
      role="navigation"
      aria-label="tab"
    >
      <div className={classNames(styles.tabMenu, menuClassName)}>
        {renderLink()}
      </div>

      <div
        className={classNames(styles.tabContent, contentClassName)}
        data-testid="tabContent"
      >
        {content}
      </div>
    </nav>
  );
}

const defaultProps: Partial<Props> = {
  theme: TabTheme.Navigation,
  initialTab: 0,
  menuClassName: undefined,
  contentClassName: undefined,
  type: 'default',
  containerClassName: undefined,
  linkType: undefined,
  defaultRoute: undefined,
  isDark: false,
};

Tabs.defaultProps = defaultProps;

export default Tabs;
