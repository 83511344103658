import { UUID } from '@neo1/client';
import {
  TxBulkSetApprovalParams,
  TxBulkUpdateParams,
} from '@neo1/core/modules/expenses/txs/helpers/bulk';

export enum Action {
  SET_TX = 'one/spend/SET_TX',
  SET_TXS = 'one/spend/SET_TXS',
  SET_TXS_OVERRIDE = 'one/spend/SET_TXS_OVERRIDE',
  DELETE_TX = 'one/spend/DELETE_TX',
}

export type SetTxAction = {
  type: typeof Action.SET_TX;
  id: UUID;
};

export type SetTxsAction = {
  type: typeof Action.SET_TXS;
  ids: UUID[];
};

export type SetTxsOverride = {
  type: typeof Action.SET_TXS_OVERRIDE;
  ids: UUID[];
};

export type DeleteTxAction = {
  type: typeof Action.DELETE_TX;
  id: UUID;
};

export type TxAction =
  | SetTxAction
  | SetTxsAction
  | DeleteTxAction
  | SetTxsOverride;
export type TxBulkParams = TxBulkUpdateParams | TxBulkSetApprovalParams;

export type TxState = {
  ids: UUID[];
};
export type TxBulkAction =
  | 'edit'
  | 'assignTag'
  | 'submit'
  | 'approve'
  | 'reject'
  | 'review';
