import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import type { IconName } from './types';
import 'styles/fonts/webfonts.css';

interface Props extends HTMLAttributes<HTMLElement> {
  name: IconName;
  className?: string;
}

const Icon = ({ name, className, ...props }: Props) => (
  <i
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    role="presentation"
    aria-hidden="true"
    className={classNames(`icon-${name}`, className)}
    data-testid={`${name}Icon`}
  />
);

Icon.defaultProps = {
  className: null,
};

export type { IconName } from './types';
export default Icon;
