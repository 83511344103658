export function hexToRgb(hex: string) {
  const catchShortHexPartsRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const catchHexPartsRegex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const normalizedHex = hex
    ? hex.replace(catchShortHexPartsRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      })
    : hex;

  const hexParts = catchHexPartsRegex.exec(normalizedHex);
  return hexParts
    ? {
        r: parseInt(hexParts[1], 16),
        g: parseInt(hexParts[2], 16),
        b: parseInt(hexParts[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

export function hexToRgbaString(hex: string, alpha: number = 1) {
  const { r, g, b } = hexToRgb(hex);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export function isDark(color: string) {
  const rgb = hexToRgb(color);
  return 1 - (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000 / 255 >= 0.5;
}

export function lightenDarkenColor(col: string, amt: number) {
  let usePound = false;

  if (col[0] == '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
}
