export type LoyaltyCardsState = {
  ids: string[];
  types: {
    ids: string[];
  };
};

export enum Action {
  SET_LOYALTY_CARD = 'one/loyaltyCard/SET_LOYALTY_CARD',
  DELETE_LOYALTY_CARD = 'one/loyaltyCard/DELETE_LOYALTY_CARD',
  SET_LOYALTY_CARDS = 'one/loyaltyCard/SET_LOYALTY_CARDS',
  SET_LOYALTY_CARD_TYPES = 'one/loyaltyCard/SET_LOYALTY_CARD_TYPES',
}

export type SetLoyaltyCard = {
  type: Action.SET_LOYALTY_CARD;
  id: string;
};

export type SetLoyaltyCards = {
  type: Action.SET_LOYALTY_CARDS;
  ids: string[];
};
export type SetLoyaltyCardTypes = {
  type: Action.SET_LOYALTY_CARD_TYPES;
  ids: string[];
};

export type DeleteLoyaltyCard = {
  type: Action.DELETE_LOYALTY_CARD;
  id: string;
};

export type LoyaltyCardActions =
  | SetLoyaltyCard
  | SetLoyaltyCards
  | DeleteLoyaltyCard
  | SetLoyaltyCardTypes;
