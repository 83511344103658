import { Toaster } from 'modules/App/redux/notifications/toaster/types';

export enum Actions {
  PUSH = 'one/app/notifications/toaster/PUSH',
  HIDE = 'one/app/notifications/toaster/HIDE',
  SET_PROGRESS = 'one/app/notifications/toaster/SET_PROGRESS',
}

export type NotificationAction =
  | { type: typeof Actions.PUSH; notification: Toaster }
  | { type: typeof Actions.HIDE; uuid: string }
  | { type: typeof Actions.SET_PROGRESS; uuid: string; progress: number };

export const push = (notification: Toaster): NotificationAction => ({
  type: Actions.PUSH,
  notification,
});

export const hide = ({ uuid }: Toaster) => ({ type: Actions.HIDE, uuid });

export const setProgress = (uuid: string, progress: number) => ({
  type: Actions.SET_PROGRESS,
  uuid,
  progress,
});
