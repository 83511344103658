import {
  createContext,
  useContext,
  useReducer,
  useEffect,
  ReactNode,
} from 'react';

export const SelectionContext = createContext(null);

export enum ActionType {
  UPDATE_COUNT = 'UPDATE_COUNT',
  SELECT = 'SELECT',
  UNSELECT = 'UNSELECT',
  SELECT_MULTI_HARD = 'SELECT_MULTI_HARD',
  SELECT_ALL = 'SELECT_ALL',
  UNSELECT_ALL = 'UNSELECT_ALL',
}

export type State = {
  count: number;
  isAllSelected: boolean;
  selectedIds: string[];
  excludedIds: string[];
};

type Action =
  | { type: ActionType.UPDATE_COUNT; count: number }
  | { type: ActionType.SELECT; id: string }
  | { type: ActionType.UNSELECT; id: string }
  | { type: ActionType.SELECT_MULTI_HARD; ids: string[] }
  | { type: ActionType.SELECT_ALL }
  | { type: ActionType.UNSELECT_ALL };

const getIsAllSelected = (count: number, selectedIds: string[]) =>
  Boolean(count && selectedIds.length && selectedIds.length === count);

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_COUNT: {
      return { ...state, count: action.count };
    }

    case ActionType.SELECT: {
      if (state.isAllSelected) {
        const excludedIds = [...state.excludedIds];
        excludedIds.splice(excludedIds.indexOf(action.id), 1);
        return {
          ...state,
          excludedIds,
        };
      }

      let selectedIds = [...state.selectedIds, action.id];
      let isAllSelected = false;
      if (selectedIds.length === state.count) {
        isAllSelected = true;
        selectedIds = [];
      }
      return {
        ...state,
        isAllSelected,
        selectedIds,
      };
    }

    case ActionType.UNSELECT: {
      if (!state.isAllSelected) {
        const selectedIds = [...state.selectedIds];
        selectedIds.splice(selectedIds.indexOf(action.id), 1);
        return {
          ...state,
          selectedIds,
        };
      }

      let excludedIds = [...state.excludedIds, action.id];
      let isAllSelected = true;
      if (excludedIds.length === state.count) {
        isAllSelected = false;
        excludedIds = [];
      }
      return {
        ...state,
        isAllSelected,
        excludedIds,
      };
    }

    case ActionType.SELECT_MULTI_HARD: {
      const isAllSelected = getIsAllSelected(state.count, action.ids);
      return {
        ...state,
        isAllSelected,
        selectedIds: isAllSelected ? [] : action.ids,
        excludedIds: [],
      };
    }

    case ActionType.SELECT_ALL: {
      return {
        ...state,
        isAllSelected: true,
        selectedIds: [],
        excludedIds: [],
      };
    }

    case ActionType.UNSELECT_ALL: {
      return {
        ...state,
        isAllSelected: false,
        selectedIds: [],
        excludedIds: [],
      };
    }

    default:
      return state;
  }
};

type Props = {
  children: ReactNode;
  count: number;
  defaultSelectedIds?: State['selectedIds'];
  onSelectionChange?: (selectionState: State) => void;
  disabled?: boolean;
};

type SelectionContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
  disabled: boolean;
};

export const SelectionProvider = ({
  children,
  count,
  defaultSelectedIds,
  onSelectionChange,
  disabled,
}: Props) => {
  const [state, dispatch] = useReducer(reducer, {
    count,
    isAllSelected: getIsAllSelected(count, defaultSelectedIds),
    selectedIds: defaultSelectedIds,
    excludedIds: [],
  });

  const value: SelectionContextType = { state, dispatch, disabled };

  useEffect(() => {
    dispatch({ type: ActionType.UPDATE_COUNT, count });
  }, [count]);

  useEffect(() => {
    dispatch({ type: ActionType.SELECT_MULTI_HARD, ids: defaultSelectedIds });
  }, [defaultSelectedIds.join('&&')]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(state);
    }
  }, [JSON.stringify(state)]);

  return (
    <SelectionContext.Provider value={value}>
      {children}
    </SelectionContext.Provider>
  );
};

SelectionProvider.defaultProps = {
  defaultSelectedIds: [],
  onSelectionChange: undefined,
  disabled: false,
};

export const useSelection = () => {
  const context = useContext<SelectionContextType>(SelectionContext);
  if (context === undefined) {
    throw new Error('useSelection must be used within a SelectionProvider');
  }

  return context;
};
