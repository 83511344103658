import wrapAsync from 'utils/wrapAsync';
import { ROUTE_PATH_FILE, ROUTE_NAME_FILE } from './constants';

const route = {
  path: ROUTE_PATH_FILE,
  name: ROUTE_NAME_FILE,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "download" */
        '.'
      ),
  ),
};

export default route;
