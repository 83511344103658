import values from 'lodash/values';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import {
  ToastersState,
  Toaster,
} from 'modules/App/redux/notifications/toaster/types';
import { AppCoreState } from 'redux/types';
import {
  hide,
  Actions,
} from '../../../../modules/App/redux/notifications/toaster/actions';
import Message from './Message';

import styles from './Notifications.module.css';

type Props = {
  onHideNotification?: ({ uuid }: Toaster) => {
    type: typeof Actions.HIDE;
    uuid: string;
  };
  toaster?: ToastersState;
};

function NotificationBox({ toaster, onHideNotification }: Props) {
  if (isEmpty(toaster)) {
    return null;
  }

  return (
    <div className={styles.container}>
      {values(toaster).map((notification) => (
        <Message
          key={notification.uuid}
          notification={notification}
          onHideNotification={onHideNotification}
        />
      ))}
    </div>
  );
}

function mapState({
  app: {
    notifications: { toaster },
  },
}: AppCoreState) {
  return {
    toaster,
  };
}

NotificationBox.defaultProps = {
  onHideNotification: undefined,
  toaster: {},
};

export default connect(mapState, { onHideNotification: hide })(NotificationBox);
