import get from 'lodash/get';
import { getClient } from '@neo1/client/lib/rpc/client';
import { UserPersitedDataKey, UserPersistedData } from './types';
import { isUserDataValid } from './utils';

export function setUserData(userId: string, key: string, data: any) {
  return getClient().sendCommand({
    method: 'set_user_data',
    params: {
      user_id: userId,
      key,
      data: JSON.stringify(data),
    },
  });
}

export function deleteUserData(userId: string, key: string, data: any) {
  return getClient().sendCommand({
    method: 'delete_user_data',
    params: {
      user_id: userId,
      key,
      data: JSON.stringify(data),
    },
  });
}

export async function getUserData(userId: string): Promise<UserPersistedData> {
  const userKeys: UserPersitedDataKey[] = [
    'companyActivation',
    'reportTemplates',
    'amazonShippingAddress',
    'dashboardLayouts',
  ];

  const results = await getClient().batchCommands(
    userKeys.map((k) => ({
      method: 'get_user_data',
      params: {
        user_id: userId,
        key: k,
      },
    })),
  );

  return results.reduce((userData, r, index) => {
    const key = userKeys[index];
    const data = get(r, 'result.data');

    if (data === null || !isUserDataValid(key, data)) {
      return userData;
    }

    return {
      ...userData,
      [key]: data,
    };
  }, {});
}
