export enum AmexEnrollmentStatus {
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  WAITING_FOR_CONFIGURATION = 'waiting_for_configuration',
  ENROLLED = 'enrolled',
}

export enum AmexEnrollmentError {
  MISSING_PHONE_NUMBER = 'Missing phone number',
  MISSING_PHONE_NUMBER_COUNTRY_CODE = 'Missing phone number country code',
  INVALID_PHONE_NUMBER_COUNTRY_CODE = 'Invalid phone number country code',
  MISSING_EMAIL = 'Missing email',
  MISSING_ADDRESS = 'Missing address',
}

export enum UserScreeningErrorNeo1 {
  MISSING_PHONE_NUMBER = 'Missing phone number',
  MISSING_PHONE_NUMBER_COUNTRY_CODE = 'Missing phone number country code',
  INVALID_PHONE_NUMBER_COUNTRY_CODE = 'Invalid phone number country code',
  MISSING_EMAIL = 'Missing email',
  MISSING_ADDRESS = 'Missing address',
  MISSING_DATE_OF_BIRTH = 'Missing date of birth',
}

export enum UserScreeningStatus {
  Processed = 'processed',
  Failed = 'failed',
  PendingUserInformation = 'pending_user_information',
  InProgress = 'in_progress',
  UnableToProcess = 'unable_to_process',
  CardCreationInProgress = 'card_creation_in_progress',
}

export const MISSING_PHONE_NUMBER_ERROR_CODES = [2004, 2003, 2019];
export const MISSING_DATE_OF_BIRTH_ERROR_CODES = [2039, 2038, 2026, 2015];
export const MISSING_ADDRESS_ERROR_CODES = [
  2010, 2011, 2012, 2013, 2014, 2041, 2021, 2077,
];
