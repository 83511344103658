import { defineMessages } from 'react-intl';

const messages = {
  menu: defineMessages({
    spend: {
      id: 'App_Menu_Spend',
      defaultMessage: 'Spend',
    },
    reports: {
      id: 'App_Menu_Reports',
      defaultMessage: 'Reports',
    },
    reconciliation: {
      id: 'App_Menu_Reconciliation',
      defaultMessage: 'Reconcile',
    },
    companySettings: {
      id: 'App_Menu_Settings',
      defaultMessage: 'Settings',
    },
    home: {
      id: 'App_Menu_Home',
      defaultMessage: 'Home',
    },
  }),
};

export default messages;
