import { WinSize } from './types';

export const lgWidth = 1280;

export const mdWidth = 1024;

export const smWidth = 768;

export const xsWidth = 375;

export const BreakPoints: Array<WinSize> = [
  WinSize.xl,
  WinSize.lg,
  WinSize.md,
  WinSize.sm,
  WinSize.xs,
];
