import { getConfigValue } from 'config';

/**
 * Initialize CloudWatch real-user monitoring
 */
const initCloudWatchRum = () => {
  const configValue = getConfigValue('cloudwatchRum');
  if (!configValue || !configValue.enabled) return;

  const {
    appMonitorId,
    appMonitorVersion,
    appMonitorRegion,
    scriptUrl,
    config,
  } = configValue;

  const defaultConfig = {
    sessionSampleRate: 1,
    allowCookies: true,
    enableXRay: true,
  };

  window.AwsRumClient = {
    q: [],
    n: 'cwr',
    i: appMonitorId,
    v: appMonitorVersion,
    r: appMonitorRegion,
    c: { ...defaultConfig, ...config },
  };

  window.cwr = (c, p) => {
    window.AwsRumClient.q.push({ c, p });
  };

  const script = document.createElement('script');
  script.async = true;
  script.src = scriptUrl;
  document.head.insertBefore(
    script,
    document.head.getElementsByTagName('script')[0],
  );
};

export default initCloudWatchRum;
