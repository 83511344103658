import { SegmentActions, SegmentValuesState, Actions } from './types';

const initialState: SegmentValuesState = [];

export default function segmentValuesReducer(
  state = initialState,
  action: SegmentActions = null,
): SegmentValuesState {
  switch (action?.type) {
    case Actions.SET_SEGMENT_VALUES:
      return action.segmentValues;

    case Actions.SET_SEGMENT_VALUE: {
      return [
        ...state.filter(
          ({ segmentId, code }) =>
            segmentId !== action.segmentValue.segmentId ||
            code !== action.segmentValue.code,
        ),
        action.segmentValue,
      ];
    }

    case Actions.DELETE_SEGMENT_VALUE: {
      return state.filter(
        (value) =>
          value.segmentId !== action.segmentId || value.code !== action.code,
      );
    }

    case Actions.DELETE_SEGMENT_VALUES: {
      return state.filter((value) => value.segmentId !== action.segmentId);
    }

    default:
      return state;
  }
}
