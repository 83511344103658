import { useState, CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';
import isBoolean from 'lodash/isBoolean';
import IconText from 'components/elements/IconText';
import { IconName } from 'components/elements/Icon';

import styles from './InfoBox.module.css';
import Button, { ButtonKind, ButtonSize, ButtonTheme } from '../Button';

const classNameByType: { [t in InfoBoxType]: string } = {
  warning: styles.warning,
  error: styles.error,
  info: styles.info,
  success: styles.success,
};

const iconByType: Record<InfoBoxType, IconName> = {
  warning: 'infoWarn',
  error: 'warning',
  info: 'info',
  success: 'checkCircle',
};

export type InfoBoxType = 'error' | 'info' | 'warning' | 'success';

type Props = {
  children: ReactNode;
  className?: string;
  actionsClassName?: string;
  renderActions?: Function;
  type?: InfoBoxType;
  isClosable?: boolean;
  style?: CSSProperties;
  showIcon?: boolean;
  onDismiss?(): void;
  id?: string;
  testId?: string;
};

function InfoBox({
  className,
  actionsClassName,
  renderActions,
  children,
  type,
  isClosable: isClosableProp,
  style,
  showIcon,
  id,
  testId,
  ...props
}: Props) {
  const isClosable = isBoolean(isClosableProp)
    ? isClosableProp
    : type !== 'info';

  const [isOpen, setOpen] = useState(true);

  const onDismiss = () => {
    if (typeof props.onDismiss === 'function') {
      props.onDismiss();
    }
    setOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={style}
      className={classNames(styles.container, classNameByType[type], className)}
      id={id}
      data-testid={testId}
    >
      {showIcon ? (
        <IconText
          icon={iconByType[type]}
          text={children}
          iconStyles={styles.icon}
          textStyles={styles.message}
        />
      ) : (
        <span className={styles.message}>{children}</span>
      )}
      {(renderActions || isClosable) && (
        <footer className={styles.footer}>
          <div className={classNames(styles.actions, actionsClassName)}>
            {renderActions && renderActions()}
          </div>
          {isClosable && (
            <Button
              label="Close"
              iconName="close"
              iconOnly
              theme={ButtonTheme.Discrete}
              size={ButtonSize.Small}
              kind={ButtonKind.Neutral}
              onClick={onDismiss}
            />
          )}
        </footer>
      )}
    </div>
  );
}

InfoBox.defaultProps = {
  className: undefined,
  actionsClassName: undefined,
  renderActions: undefined,
  type: 'info',
  isClosable: false,
  style: undefined,
  showIcon: true,
  onDismiss: undefined,
  id: undefined,
  testId: undefined,
};

export default InfoBox;
