import { defineMessages } from 'react-intl';

const messages = defineMessages({
  userMapping: {
    id: 'Quickbooks__cashTxsConfig__userMapping',
    defaultMessage:
      'Map out of pocket transactions of every user to a specific account',
  },
  singleVendor: {
    id: 'Quickbooks__cashTxsConfig__singleVendor',
    defaultMessage:
      "Map all the users' out of pocket transactions to one common account",
  },
  failedRecordsMessage: {
    id: 'Quickbooks__cashTxsConfig__failedRecordsMessage',
    defaultMessage: `These are the failed mappings, please try submitting with this
          recommended mapping:`,
  },
  vendor: {
    id: 'Quickbooks_cashTxsConfig__vendor',
    defaultMessage: 'To which account the transactions shall be sent',
  },
  loadingError: {
    id: 'loadingError',
    defaultMessage: 'Could not get vendors and stored mapping.',
  },
});
export default messages;
