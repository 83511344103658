import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const phoneUtil = PhoneNumberUtil.getInstance();

export const toE164PhoneNumberOrNull = (phone?: string) => {
  // This library throws errors when the input is too short (among other cases)
  // so we need to consider all possible errors as invalid inputs
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.format(number, PhoneNumberFormat.E164);
  } catch (_) {
    return null;
  }
};

export const isValidPhoneNumber = (phone: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone);
    return phoneUtil.isValidNumber(number);
  } catch (_) {
    return false;
  }
};
