import { useState } from 'react';
// TODO: to be trashed and replaced by [useId](https://react.dev/reference/react/useId#generating-unique-ids-for-accessibility-attributes)
// When we migrate to React 18

/**
 * given a prefix get a simple random value to be used in accessibility attributes that must be unique.
 */
const useRandomHtmlId = (prefix: string) => {
  const [randomValue] = useState(`${prefix}${Math.random()}`);
  return randomValue;
};

export default useRandomHtmlId;
