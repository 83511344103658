let instance = null;

export default class LoaderObserver {
  static get() {
    if (instance) {
      return instance;
    }

    instance = new LoaderObserver();
    return instance;
  }

  private observer: IntersectionObserver;

  private callbacks: Map<HTMLElement, Function>;

  constructor() {
    this.observer = new IntersectionObserver(this.onIntersecting.bind(this), {
      threshold: 0,
    });
    this.callbacks = new Map();
  }

  observe(element: HTMLElement, callback: Function) {
    // Check if the element is already almost in the viewport and should be triggered immediately
    if (element.getBoundingClientRect().top < 0) {
      callback(element);
      return;
    }

    this.callbacks.set(element, callback);
    this.observer.observe(element);
  }

  unobserve(element: HTMLElement) {
    this.callbacks.delete(element);
    this.observer.unobserve(element);
  }

  trigger(element: HTMLElement) {
    const callback = this.callbacks.get(element);
    if (callback && typeof callback === 'function') {
      callback(element);
    }
  }

  onIntersecting(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.trigger(entry.target);
      }
    });
  }
}
