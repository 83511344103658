// TODO Polyfill of intl.locale for Safari older than 14, Firefox older than 75, ios-saf older than 14 - https://caniuse.com/mdn-javascript_builtins_intl_locale
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
// TODO Polyfill of Intl.getCanonicalLocales - https://caniuse.com/mdn-javascript_builtins_intl_getcanonicallocales
import { shouldPolyfill as shouldPolyfillCannonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
// TODO Polyfill of intl.locale for Safari older than 13 - https://caniuse.com/mdn-javascript_builtins_intl_pluralrules
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
// TODO Polyfill of intl.locale for Safari older than 14, ios-saf older than 14 - https://caniuse.com/mdn-javascript_builtins_intl_relativetimeformat
import { shouldPolyfill as shouldPolyfillRelativeTime } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { Locale, PolyfillConfig } from './types';

const polyfillConfigurations: Record<string, PolyfillConfig> = {
  cannonicalLocales: {
    shouldPolyfill: shouldPolyfillCannonicalLocales,
    name: 'intl-getcanonicallocales',
  },
  localeObject: {
    shouldPolyfill: shouldPolyfillLocale,
    name: 'intl-locale',
  },

  relativetimeFormat: {
    shouldPolyfill: shouldPolyfillRelativeTime,
    name: 'intl-relativetimeformat',
    intlProperty: 'RelativeTimeFormat',
  },
  pluralRules: {
    shouldPolyfill: shouldPolyfillPluralRules,
    name: 'intl-pluralrules',
    intlProperty: 'PluralRules',
  },
};

const { relativetimeFormat, pluralRules, localeObject, cannonicalLocales } =
  polyfillConfigurations;

const POLYFILLS_THAT_DONT_CHARGE_DATA = [
  localeObject.name,
  cannonicalLocales.name,
];

async function loadPolyFill(
  locale: Locale,
  { shouldPolyfill, name, intlProperty }: PolyfillConfig,
) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import(`@formatjs/${name}/polyfill`);
  }

  if (POLYFILLS_THAT_DONT_CHARGE_DATA.includes(name)) {
    return;
  }

  if (Intl[intlProperty].polyfilled) {
    switch (locale) {
      default:
        await import(`@formatjs/${name}/locale-data/en`);
        break;
    }
  }
}

export default async function configureI18n(locale: Locale) {
  // The order of function calls is relevant because some polyfills may use the precedent polyfill.
  await loadPolyFill(locale, cannonicalLocales);
  await loadPolyFill(locale, localeObject);
  await loadPolyFill(locale, relativetimeFormat);
  await loadPolyFill(locale, pluralRules);
}
