import { AppMenuRoute } from 'config/routes/utils';
import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import Body from './Body';
import Logo from '../Logo';
import styles from './styles.module.css';

interface Props {
  menu: AppMenuRoute[];
  onNavigation: () => void;
  onClickOnClose?: () => void;
}

const NavigationMenu = ({ menu, onNavigation, onClickOnClose }: Props) => (
  <>
    {onClickOnClose ? (
      <div className={styles.header}>
        <Button
          label="Close"
          iconOnly
          wrapperClassName={styles.closeBtn}
          onClick={onClickOnClose}
          iconName="close"
          theme={ButtonTheme.Discrete}
          kind={ButtonKind.Neutral}
          dark
        />
        <Logo />
      </div>
    ) : null}
    <Body menu={menu} onNavigation={onNavigation} />
  </>
);

NavigationMenu.defaultProps = {
  onClickOnClose: undefined,
};

export default NavigationMenu;
