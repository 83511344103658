import { Sizes } from 'components/elements/Grid';

export const ROUTE_NAME_PROFILE = 'profile';

export const ROUTE_PATH_PROFILE = '/profile';

export const gridSizes: Sizes = {
  xl: 3,
  lg: 4,
  md: 6,
  sm: 12,
  xs: 12,
};
