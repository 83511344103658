import { Base64 } from 'js-base64';
import { PasswordPolicies } from './constants';
import { PasswordError } from './types';
import { RpcClientError } from '@neo1/client';

export const parseJwt = (token: string) =>
  JSON.parse(Base64.decode(token.split('.')[1]));

export const tokenNotExpired = (token: string): boolean => {
  try {
    const { exp } = parseJwt(token);
    return exp > Math.ceil(Date.now() / 1000);
  } catch (error) {
    // in cases like token is not in valid format, treat it as expired
    return false;
  }
};

/**
 * Will check if given password matches the password policy and return the errors
 * @param {*} password the password to validate
 */
export function validatePolicy(password = ''): PasswordError {
  return Object.keys(PasswordPolicies).reduce((errors, policy) => {
    const matchesPolicy = PasswordPolicies[policy];
    if (matchesPolicy(password)) {
      return errors;
    }
    return {
      ...errors,
      [policy]: true,
    };
  }, {});
}

export function isAlreadyUsedPasswordError(error: RpcClientError) {
  return error.data?.policy_violations?.some(
    (e: any) => e?.error_type === 'password_already_used',
  );
}

export interface Values {
  password: string;
  passwordConfirmation: string;
}
