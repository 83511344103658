import { isValidAddress } from '@neo1/client/lib/entities/address/utils';
import { UserPersitedDataKey } from './types';

/**
 *
 * @param key
 * @param data
 */
export const isUserDataValid = (key: UserPersitedDataKey, data: any) => {
  if (key === 'amazonShippingAddress') {
    return isValidAddress(data);
  }
  return true;
};
