import {
  CompanyGroupUser,
  CompanyUser,
  DomainUser,
  SystemUser,
  User,
} from './types';
import { ReifiedDataWrapper, reify } from '../normalize';
import {
  COMPANY_GROUP_USER_ENTITY_NAME,
  CompanyUserRole,
  TravelerGroup,
  USER_ENTITY_NAME,
  UserLevel,
} from './constants';
import partial from 'lodash/partial';
import isNil from 'lodash/isNil';
import { isUUID } from '../common';
import { hasFeature as hasCompanyFeature } from '@neo1/client/lib/entities/company/utils';
import { Company, CompanyData } from '../company/types';

export function normalizeSystemUser(data: ReifiedDataWrapper): SystemUser {
  return {
    id: data.getUuid('user_id'),
    entityName: USER_ENTITY_NAME,
    level: UserLevel.SYSTEM,
    creation: data.getDate('creation'),
    displayName: data.getString('full_name'),
    email: data.getString('email'),
    lastLogin: data.getDate('last_login'),
    lastUpdate: data.getDate('last_update'),
    login: data.getString('login'),
    status: data.getString('status'),
    user_id: data.getUuid('user_id'),
    isFetching: false,
    fetchError: null,
  };
}

export function normalizeDomainUser(data: ReifiedDataWrapper): DomainUser {
  return {
    ...normalizeSystemUser(data),
    level: UserLevel.DOMAIN,
    domainId: data.getUuid('domain_id'),
    additionalDomains: data.getArray<string>('additional_domain_ids'),
  };
}

export function normalizeCompanyUser(data: ReifiedDataWrapper): CompanyUser {
  const rolesList = data.getArray('roles').join(', ');

  const displayName = `${data.getString('first_name', '')} ${data.getString(
    'last_name',
    '',
  )}`.trim();

  return {
    ...normalizeSystemUser(data),
    level: UserLevel.COMPANY,
    displayName,
    rolesList,
    companyId: data.getUuid('company_id'),
    preferredCurrency: data.getString('preferred_currency', 'USD'),
    domainId: data.getUuid('domain_id'),
    externalId: data.get('external_id'),
    firstName: data.getString('first_name'),
    lastName: data.getString('last_name'),
    latestAcceptedEula: data.get('latest_accepted_eula', null) && {
      url: data.getString('latest_accepted_eula.url'),
      version: data.getString('latest_accepted_eula.version'),
      acceptedAt: data.getString('latest_accepted_eula.accepted_at'),
      isLatest: data.getBoolean('latest_accepted_eula.is_latest'),
    },
    locale: data.getString('locale'),
    roles: data.getArray('roles'),
    title: data.getString('title'),
    supervisorId: data.getStringOrNull('supervisor_id'),
    phone: data.getStringOrNull('phone'),
    birthDate: data.getStringOrNull('birth_date'),
    travelerGroup: data.getString('traveler_group') as TravelerGroup,
  };
}

export function normalizeCompanyGroupUser(
  inputData: any = {},
): CompanyGroupUser {
  const data = reify(inputData);
  return {
    level: UserLevel.COMPANY,
    id: data.getUuid('user_id'),
    entityName: COMPANY_GROUP_USER_ENTITY_NAME,
    companyId: data.getUuid('company_id'),
    firstName: data.getString('first_name'),
    lastName: data.getString('last_name'),
    displayName: data.getString('full_name'),
    title: data.getString('title'),
    companyName: data.getString('company_name'),
    email: data.getString('email'),
    supervisorId: data.getStringOrNull('supervisor_id'),
    roles: data.getArray('roles') as CompanyUserRole[],
    status: data.getString('status'),
    travelerGroup: data.getString('traveler_group') as TravelerGroup,
  };
}

export function invalidUserLevel(level: any): never {
  throw new Error(`Invalid user level: ${level}`);
}
/**
 * Creates an hybridated user object
 * @param data
 */
export function normalizeUser(inputData: any = {}) {
  const { level } = inputData;
  const data = reify(inputData);

  if (level === UserLevel.SYSTEM) {
    return normalizeSystemUser(data);
  } else if (level === UserLevel.DOMAIN) {
    return normalizeDomainUser(data);
  } else if (level === UserLevel.COMPANY) {
    return normalizeCompanyUser(data);
  } else {
    return invalidUserLevel(level);
  }
}

export function domainToCompanyUser(
  user: DomainUser,
  companyData: CompanyData,
): CompanyUser {
  return {
    ...user,
    id: user.id,
    level: UserLevel.COMPANY,
    freezed: true,
    rolesList: '',
    companyId: companyData.id,
    preferredCurrency: companyData.defaultCurrency,
    domainId: companyData.domainId,
    externalId: '',
    firstName: 'Admin',
    lastName: 'Admin',
    displayName: 'Admin',
    locale: 'US',
    roles: [CompanyUserRole.Admin, CompanyUserRole.Finance],
    title: 'mr',
    supervisorId: null,
    latestAcceptedEula: null,
    travelerGroup: TravelerGroup.Employees,
  };
}

export function getDisplayName(user: User | CompanyGroupUser) {
  return user.displayName;
}

export function isCompanyUser(
  user: User | CompanyGroupUser,
): user is CompanyUser {
  return user.level === UserLevel.COMPANY && 'latestAcceptedEula' in user;
}

export function isCompanyGroupUser(
  user: User | CompanyGroupUser,
): user is CompanyGroupUser {
  return user.level === UserLevel.COMPANY && 'companyName' in user;
}

export function hasCompanyRole(user: User, role: CompanyUserRole) {
  return isCompanyUser(user) && user.roles.includes(role);
}

export function isDomainUser(user: User): user is DomainUser {
  return user.level === UserLevel.DOMAIN;
}

export function isDomainUserLoginAsCompany(user: User): user is CompanyUser {
  return isCompanyUser(user) && user.freezed;
}

export function rolesContainsAdminRole(roles: CompanyUser['roles']) {
  return Array.isArray(roles) && roles.indexOf(CompanyUserRole.Admin) > -1;
}

export function isAdmin(user: User) {
  return isCompanyUser(user) && rolesContainsAdminRole(user.roles);
}

export function isCompanyAdmin(user: User) {
  return isCompanyUser(user) && isAdmin(user);
}

export function isCompanyFinance(user: User) {
  return isCompanyUser(user) && isFinance(user);
}

export function isFinance(user: CompanyUser) {
  return (
    Array.isArray(user.roles) &&
    user.roles.indexOf(CompanyUserRole.Finance) > -1
  );
}

export function isArranger(user: User) {
  return (
    isCompanyUser(user) &&
    Array.isArray(user.roles) &&
    user.roles.indexOf(CompanyUserRole.Arranger) > -1
  );
}

export function isTravelHost(user: User) {
  return (
    isCompanyUser(user) &&
    Array.isArray(user.roles) &&
    user.roles.indexOf(CompanyUserRole.TravelHost) > -1
  );
}

export function isSystemUser(user: User): user is SystemUser {
  return user.level === UserLevel.SYSTEM;
}

function hasLevel(user: User, level: UserLevel) {
  return user && user.level === level;
}

export function isSuperUser(user: User): user is SystemUser | DomainUser {
  return isDomainUser(user) || isSystemUser(user);
}

export function hasSupervisor(user: User) {
  return isCompanyUser(user) && isUUID(user.supervisorId);
}

export const canCreateDomain = isSystemUser;

export function canCreateCompany(user: User) {
  return isDomainUser(user) || isSystemUser(user);
}

export function actingUserHasRightRoles(
  userRoles: CompanyUserRole[] = [],
  actingUser: CompanyUser,
) {
  return userRoles.some(partial(hasCompanyRole, actingUser));
}

export function actingUserHasRightLevel(
  userLevels: UserLevel[] = [],
  actingUser: User,
) {
  return userLevels.some(partial(hasLevel, actingUser));
}

export function actingCompanyHasRightFeatures(
  companyFeatures: string[],
  actingCompany: Company,
) {
  return (
    !Array.isArray(companyFeatures) ||
    companyFeatures.some(partial(hasCompanyFeature, actingCompany))
  );
}

export function isUserProfileCompleteForNeoTravel(user: CompanyUser) {
  return !isNil(user.birthDate) && !isNil(user.phone);
}

export function isPrivileged(user: User) {
  return (
    isCompanyUser(user) &&
    (user.roles.includes(CompanyUserRole.Admin) ||
      user.roles.includes(CompanyUserRole.Finance))
  );
}
