import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { getConfigValue } from 'config';
import { useWindowContext, mdWidth } from 'contexts/window';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import { getIsLolaDomain } from 'utils/lola';
import { getIsSelfRegistrationDomain } from 'utils/selfRegistration';

import './styles.module.css';

const SCRIPT_CLASSNAME = 'freshworks_scripts';

const mountWidget = (widgetId: string) => {
  window.fwSettings = {
    widget_id: widgetId,
  };
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.defer = true;
  script.id = widgetId;
  script.className = SCRIPT_CLASSNAME;
  script.src = `https://widget.freshworks.com/widgets/${widgetId}.js`;
  document.querySelector('body').appendChild(script);
};

const cleanWidget = () => {
  window.FreshworksWidget('destroy');
  window.FreshworksWidget = null;
  const scripts = document.getElementsByClassName(SCRIPT_CLASSNAME);
  const freshworksContainer = document.getElementById('freshworks-container');
  const freshworksFrame = document.getElementById('freshworks-frame');
  const nodes = [...scripts, freshworksContainer, freshworksFrame];
  for (let i = 0; i < nodes.length; i += 1) {
    nodes[i].parentNode.removeChild(nodes[i]);
  }
};

const isWidgetMounted = () => typeof window.FreshworksWidget === 'function';

const FreshdeskWidget = () => {
  const {
    location: { pathname },
  } = useHistory();
  const company = useSelector(selectActingCompany);
  const { winWidth } = useWindowContext();

  const showLolaWidget = getIsLolaDomain(company?.domainId);
  const showSelfRegistrationWidget = getIsSelfRegistrationDomain(
    company?.domainId,
  );
  const isDesktop = winWidth >= mdWidth;

  let widgetId = null;
  if (showLolaWidget) {
    widgetId = getConfigValue('lolaFreshworksWidgetId');
  } else if (showSelfRegistrationWidget) {
    widgetId = getConfigValue('selfRegistrationFreshworksWidgetId');
  }

  useEffect(() => {
    if (widgetId && isDesktop) {
      mountWidget(widgetId);
    }

    return () => {
      if (isWidgetMounted()) {
        cleanWidget();
      }
    };
  }, [widgetId, isDesktop]);

  useEffect(() => {
    // close the widget when user change location
    if (isWidgetMounted()) {
      window.FreshworksWidget('close');
    }
  }, [pathname]);

  return null;
};

export default FreshdeskWidget;
