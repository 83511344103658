import { ReactNode } from 'react';

export enum WinSize {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

export enum DeviceSize {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export type Props = {
  children: ReactNode;
};

export type State = {
  winWidth: number;
  winHeight: number;
  winSize: WinSize;
  deviceSize: DeviceSize;
};
