import { UUID } from '@neo1/client';

export enum Action {
  SET_TX = 'one/authorize/spend/SET_TX',
  SET_TXS = 'one/authorize/spend/SET_TXS',
  SET_TXS_OVERRIDE = 'one/authorize/spend/SET_TXS_OVERRIDE',
}

export type SetTxAction = {
  type: typeof Action.SET_TX;
  id: UUID;
};

export type SetTxsAction = {
  type: typeof Action.SET_TXS;
  ids: UUID[];
};

export type SetTxsOverride = {
  type: typeof Action.SET_TXS_OVERRIDE;
  ids: UUID[];
};

export type TxAction = SetTxAction | SetTxsAction | SetTxsOverride;

export type TxState = {
  ids: UUID[];
};
