import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Banner from 'components/composed/Banner';
import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import { useWindowContext, WinSize } from 'contexts/window';
import Link, { LinkFontSize, LinkTheme } from 'components/elements/Link';

import messages from './messages';
import styles from './BrowserNotSuppportedBanner.module.css';

type Props = {
  className?: string;
};

export const IS_OUTDATED_BROWSERS_BANNER_DISMISSED =
  'IS_OUTDATED_BROWSERS_BANNER_DISMISSED';
export const clearOutdatedBrowserBannerData = () =>
  localStorage.removeItem(IS_OUTDATED_BROWSERS_BANNER_DISMISSED);

const HELP_MAIL = 'help@neo1.com';

declare let SUPPORTED_BROWSERS_USER_AGENT_REGEXP_SOURCE: string;

const testSupportedBrowsersUserAgent = () => {
  const source = `${SUPPORTED_BROWSERS_USER_AGENT_REGEXP_SOURCE}`;
  if (source) {
    return new RegExp(source).test(navigator.userAgent);
  }
  // when `source` is falsy we are not in a real env.
  return true;
};

const BrowserNotSuppportedBanner = ({ className }: Props) => {
  const [isHidden, setIsHidden] = useState(false);
  const { winSize } = useWindowContext();
  const intl = useIntl();
  const isDismissed = localStorage.getItem(
    IS_OUTDATED_BROWSERS_BANNER_DISMISSED,
  );

  if (isDismissed || isHidden || [WinSize.sm, WinSize.xs].includes(winSize)) {
    return null;
  }

  if (testSupportedBrowsersUserAgent()) {
    return null;
  }

  const handleOnClick = () => {
    localStorage.setItem(IS_OUTDATED_BROWSERS_BANNER_DISMISSED, '1');
    setIsHidden(true);
  };

  const description = (
    <div>
      <p className={styles.description}>
        {intl.formatMessage(messages.outadatedBrowsersBannerMessage)}{' '}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          theme={LinkTheme.Dark}
          fontSize={LinkFontSize.Inherit}
          to="#"
          onClick={() => {
            window.open(`mailto:${HELP_MAIL}`);
          }}
        >
          {HELP_MAIL}
        </Link>
      </p>
      <Button
        label="Close"
        iconName="close"
        iconOnly
        wrapperClassName={styles.closeBtnContainer}
        theme={ButtonTheme.Discrete}
        kind={ButtonKind.Neutral}
        onClick={handleOnClick}
        dark
      />
    </div>
  );

  return (
    <Banner
      className={classNames(styles.banner, className)}
      infoWrapperClassName={styles.infoWrapper}
      id="browser_not_supported"
      description={description}
      expanded={false}
    />
  );
};

BrowserNotSuppportedBanner.defaultProps = {
  className: undefined,
};

export default BrowserNotSuppportedBanner;
