import 'focus-visible';
import { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import noop from 'lodash/noop';
import { useDispatch, useSelector } from 'react-redux';
import { StaticContext, generatePath } from 'react-router';
import { Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import { RpcErrorCode } from '@neo1/client';
import { isTravelSSOActive } from '@neo1/core/utils/travelSso';
import { User } from '@neo1/client/lib/entities/user/types';
import {
  isCompanyUser,
  isSuperUser,
} from '@neo1/client/lib/entities/user/utils';
import { hasLoggedInWithSso } from 'utils/storage';
import isUpdateLaunchedSelector from 'modules/App/redux/update/selector';
import { mapRouteConfigToRoutes, AppRoute } from 'config/routes/utils';
import adminRoutes from 'config/routes/admin';
import publicRoutes, { isPublicRoute } from 'config/routes/public';
import AfterLogout from 'modules/Authentification/Logout';
import UpdateHandler from 'modules/App/Update';
import AppLayout from 'components/layout/App';
import AppLoader from 'components/layout/Loader';
import companyUserRoutes from 'config/routes/companyUser';
import {
  selectActingCompany,
  selectActingUser,
  selectIsLoggedIn,
  selectIsProcessing,
} from 'modules/Authentification/redux/selectors';
import Button, { ButtonSize } from 'components/elements/Button';
import { WindowContextProvider } from 'contexts/window';
import { LayoutContextProvider } from 'contexts/layout';
import { InstrumentationProvider } from 'contexts/instrumentation';
import { setLocale } from 'modules/App/redux/i18n/actions';
import Login from 'modules/Authentification/Login';
import DefaultLogin from 'modules/Authentification/Login/DefaultLogin';
import { RESET_PASSWORD_PATH } from 'modules/Authentification/constants';
import LoaderOverlay from 'components/elements/Loader/LoaderOverlay';
import { clearOutdatedBrowserBannerData } from 'components/layout/App/BrowserNotSuppportedBanner';
import { isSupportedLocale } from 'utils/i18n';
import { DEFAULT_LOCALE } from 'config/i18n/constants';
import styles from './App.module.css';
import { selectAppI18nConfig } from './redux/i18n/selectors';

export interface Props extends RouteComponentProps<{}, StaticContext> {}

/**
 * App-level Root component embedding router configuration
 */
function App({
  history,
  history: {
    location: { pathname },
    location,
  },
}: Props) {
  const dispatch = useDispatch();
  const {
    locale: appLocale,
    isLoading: isLoadingLocales,
    messages: i18nMessages,
  } = useSelector(selectAppI18nConfig);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isProcessing = useSelector(selectIsProcessing);
  const isUpdateLaunched = useSelector(isUpdateLaunchedSelector);
  const actingUser = useSelector(selectActingUser);
  const actingCompany = useSelector(selectActingCompany);
  const [wantedLocationBeforeLogin, setWantedLocationBeforeLogin] = useState<
    null | RouteComponentProps['location']
  >(null);

  const onLoginSuccess = (user: User) => {
    clearOutdatedBrowserBannerData();
    if (
      wantedLocationBeforeLogin &&
      !isSuperUser(user) &&
      !wantedLocationBeforeLogin.pathname.includes('reset_password')
    ) {
      setWantedLocationBeforeLogin(null);
      return history.replace(wantedLocationBeforeLogin);
    }
    return history.replace('/');
  };

  const onLoginError = (error: any) => {
    if (
      error.code === RpcErrorCode.PasswordExpired &&
      error.data &&
      error.data.reset_password_token
    ) {
      // Redirect to reset password
      history.push(
        generatePath(RESET_PASSWORD_PATH, {
          token: error.data.reset_password_token,
          userId: error.data.user_id,
        }),
      );
    }
  };

  const isPublicPath =
    isPublicRoute(pathname) || (pathname === '/' && !isLoggedIn);

  useEffect(() => {
    const userLocale =
      actingUser && isCompanyUser(actingUser)
        ? actingUser.locale
        : navigator.language;
    const locale = isSupportedLocale(userLocale) ? userLocale : DEFAULT_LOCALE;
    dispatch(setLocale(locale));
  }, [actingUser, dispatch]);

  useEffect(() => {
    if (pathname !== '/' && !isPublicPath && !isLoggedIn) {
      setWantedLocationBeforeLogin(location);
    }
  }, []);

  if (isTravelSSOActive() && isLoggedIn) {
    return null;
  }

  const getRoutes = (): AppRoute[] => {
    if (!actingUser) {
      return [];
    }
    if (isSuperUser(actingUser)) {
      return adminRoutes as AppRoute[]; // TODO: fix in DEV-6846
    }
    if (isCompanyUser(actingUser) && actingCompany) {
      return companyUserRoutes as AppRoute[]; // TODO: fix in DEV-6846
    }
    return [];
  };

  return (
    <IntlProvider locale={appLocale} messages={i18nMessages} onError={noop}>
      <WindowContextProvider>
        <LayoutContextProvider>
          <LoaderOverlay isOpen={isLoadingLocales} />
          <Router history={history}>
            <InstrumentationProvider>
              {isUpdateLaunched && <UpdateHandler />}
              <Switch>
                {mapRouteConfigToRoutes(publicRoutes)}
                <Route
                  render={() => {
                    if (isProcessing) {
                      return <AppLoader />;
                    }

                    const routes = getRoutes();
                    if (routes.length) {
                      return (
                        <AppLayout
                          // each time the acting user change, force to re-mount the app
                          key={actingUser.id}
                          currentPath={location.pathname}
                          routes={routes}
                        />
                      );
                    }

                    if (hasLoggedInWithSso()) {
                      <AfterLogout />;
                    }

                    return (
                      <Login>
                        <DefaultLogin
                          onLoginSuccess={onLoginSuccess}
                          onLoginError={onLoginError}
                        />
                      </Login>
                    );
                  }}
                />
              </Switch>
            </InstrumentationProvider>
          </Router>
          {isPublicPath && (
            <div className={styles.cookieContainer}>
              <Button
                label="Cookie preferences"
                iconName="settings"
                iconOnly
                onClick={() => window.Optanon?.ToggleInfoDisplay()}
                size={ButtonSize.Large}
                floating
              />
            </div>
          )}
        </LayoutContextProvider>
      </WindowContextProvider>
    </IntlProvider>
  );
}

App.defaultProps = {
  i18nMessages: {},
};

export default App;
