const messages = {
  forgotMyPassword: {
    id: 'forgotMyPassword',
    defaultMessage: 'Reset/change password',
  },
  registerNewCompany: {
    id: 'registerNewCompany',
    defaultMessage: 'Sign up',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  userName: {
    id: 'userName',
    defaultMessage: 'Email',
  },
  requiredFieldError: {
    id: 'requiredFieldError',
    defaultMessage: 'This field can not be empty',
  },
};

export default messages;
