import { defineMessages } from 'react-intl';

const messages = defineMessages({
  outadatedBrowsersBannerMessage: {
    id: 'outadatedBrowsersBannerMessage',
    defaultMessage:
      'Your current browser is no longer supported. To ensure the correct representation and functionality of Neo1 we recommend using the latest version of Microsoft Edge, Google Chrome, Firefox and Safari. If you still need help, please contact us at',
  },
});

export default messages;
