import uniq from 'lodash/uniq';
import { Action, TravelDocumentActions, TravelDocsState } from './types';

const INITIAL_STATE: TravelDocsState = {
  ids: [],
};

export default function travelDocumentsReducer(
  state: TravelDocsState = INITIAL_STATE,
  action?: TravelDocumentActions,
): TravelDocsState {
  switch (action.type) {
    case Action.SET_TRAVEL_DOCUMENT:
      return {
        ids: uniq([...state.ids, action.id]),
      };
    case Action.DELETE_TRAVEL_DOCUMENT:
      return {
        ids: state.ids.filter((id) => id !== action.id),
      };
    case Action.SET_TRAVEL_DOCUMENTS:
      return {
        ids: action.ids,
      };

    default:
      return state;
  }
}
