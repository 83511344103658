import { CompanyExtractService } from '@neo1/client/lib/entities/company/constants';
import {
  Vendor,
  UserVendor,
  AccountingConfig,
} from '@neo1/client/lib/entities/company/types';

export enum Action {
  SET = 'one/settings/myCompany/SET',
  SET_VENDORS = 'one/settings/myCompany/SET_VENDORS',
  SET_USERS_VENDORS = 'one/settings/myCompany/SET_USERS_VENDORS',
  SET_CONFIG = 'one/settings/myCompany/SET_CONFIG',
}

export type SetIntegrationSettingsAction = {
  type: Action.SET;
  service: CompanyExtractService;
  isConfigured: boolean;
};

export type SetVendors = {
  type: Action.SET_VENDORS;
  service: CompanyExtractService;
  vendors: Vendor[];
};

export type SetUsersVendors = {
  type: Action.SET_USERS_VENDORS;
  service: CompanyExtractService;
  usersVendors: UserVendor[];
};

export type SetConfig = {
  type: Action.SET_CONFIG;
  service: CompanyExtractService;
  config: AccountingConfig;
};

export type IntegrationActions =
  | SetIntegrationSettingsAction
  | SetVendors
  | SetUsersVendors
  | SetConfig;

export type State = {
  [key in CompanyExtractService]: {
    isConfigured: boolean;
    vendors: Vendor[];
    config: AccountingConfig;
    cashTxsConfig: {
      usersVendors: UserVendor[];
    };
  };
};
