import async from 'utils/wrapAsync';
import { AppMenuRoute, AppRoute } from 'config/routes/utils';
import { ROUTE_PATH_PROFILE, ROUTE_NAME_PROFILE } from '../constants';
import { ROUTE_PATH_USER_INFO, ROUTE_NAME_USER_INFO } from './constants';

const personalInfo = import(
  /* webpackChunkName: "personalInfo" */
  '.'
);

export const adminProfileRoute: AppRoute = {
  title: 'Personal information',
  exact: true,
  path: ROUTE_PATH_PROFILE,
  name: ROUTE_NAME_PROFILE,
  component: async(() => personalInfo),
};

const route: AppMenuRoute = {
  title: 'Personal information',
  exact: true,
  menu: {
    iconClass: 'user',
  },
  path: ROUTE_PATH_USER_INFO,
  name: ROUTE_NAME_USER_INFO,
  component: async(() => personalInfo),
};

export default route;
