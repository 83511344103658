import { defineMessages } from 'react-intl';

const messages = defineMessages({
  merchantMapping: {
    id: 'Quickbooks__cardTxsConfig__merchantMapping',
    defaultMessage:
      'Map Card transactions of every user to a specific merchant',
  },
  singleVendor: {
    id: 'Quickbooks__cardTxsConfig__singleVendor',
    defaultMessage: "Map all the users' Card transactions to one common vendor",
  },
  vendor: {
    id: 'Quickbooks_cardTxsConfig__vendor',
    defaultMessage: 'To which account the transactions shall be sent',
  },
  loadingError: {
    id: 'loadingError',
    defaultMessage: 'Could not get vendors.',
  },
});
export default messages;
