import { GeneralConfigState } from './types';

export enum Action {
  SET_GENERAL_CONFIG = 'one/company/general/config/SET',
}
type SetGeneralConfig = {
  type: Action.SET_GENERAL_CONFIG;
  config: GeneralConfigState;
};

export function setGeneralConfig(config: GeneralConfigState) {
  return { type: Action.SET_GENERAL_CONFIG, config };
}

export type GeneralConfigAction = SetGeneralConfig;
