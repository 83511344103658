import { CompanyUser } from '@neo1/client/lib/entities/user/types';
import { AccountingSegmentDefaultCoding } from '@neo1/client/lib/entities/segment/utils';
import { CollectionSortFilters } from '@neo1/core/utils/collections';
import { DefaultCodingByUserId, State } from './types';

export enum ActionTypeName {
  SET_COMPANY_USER_IDS = 'one/settings/users/SET_COMPANY_USER_IDS',
  ADD_COMPANY_USER = 'one/settings/users/ADD_COMPANY_USER',
  DELETE_COMPANY_USER = 'one/settings/users/DELETE_COMPANY_USER',
  SET_DEFAULT_CODING_BY_USER_ID = 'one/settings/users/SET_DEFAULT_CODING_BY_USER_ID',
  UPDATE_USER_DEFAULT_CODING = 'one/settings/users/UPDATE_USER_DEFAULT_CODING',
  SET_SEARCH_FILTER = 'one/settings/users/SET_SEARCH_FILTER',
  SET_SORT_OPTIONS = 'one/settings/users/SET_SORT_OPTIONS',
}

type SetCompanyUserIds = {
  type: ActionTypeName.SET_COMPANY_USER_IDS;
  companyUserIds: State['companyUserIds'];
};

type AddCompanyUser = {
  type: ActionTypeName.ADD_COMPANY_USER;
  companyUserId: CompanyUser['id'];
};

type DeleteCompanyUser = {
  type: ActionTypeName.DELETE_COMPANY_USER;
  companyUserId: CompanyUser['id'];
};

type SetDefaultCodingByUserId = {
  type: ActionTypeName.SET_DEFAULT_CODING_BY_USER_ID;
  defaultCodingByUserId: State['defaultCodingByUserId'];
};

type UpdateUserDefaultCoding = {
  type: ActionTypeName.UPDATE_USER_DEFAULT_CODING;
  payload: {
    userId: CompanyUser['id'];
    defaultCoding: AccountingSegmentDefaultCoding;
  };
};

type SetSearch = {
  type: ActionTypeName.SET_SEARCH_FILTER;
  search;
};

type SetSortOptions = {
  type: ActionTypeName.SET_SORT_OPTIONS;
  options: any;
};

export function setCompanyUserIds(
  companyUserIds: CompanyUser['id'][],
): SetCompanyUserIds {
  return {
    type: ActionTypeName.SET_COMPANY_USER_IDS,
    companyUserIds,
  };
}

export function addCompanyUser(
  companyUserId: CompanyUser['id'],
): AddCompanyUser {
  return {
    type: ActionTypeName.ADD_COMPANY_USER,
    companyUserId,
  };
}

export function deleteCompanyUser(
  companyUserId: CompanyUser['id'],
): DeleteCompanyUser {
  return {
    type: ActionTypeName.DELETE_COMPANY_USER,
    companyUserId,
  };
}

export function setDefaultCodingByUserId(
  defaultCodingByUserId: DefaultCodingByUserId,
): SetDefaultCodingByUserId {
  return {
    type: ActionTypeName.SET_DEFAULT_CODING_BY_USER_ID,
    defaultCodingByUserId,
  };
}

export function updateUserDefaultCoding(
  userId: CompanyUser['id'],
  defaultCoding: AccountingSegmentDefaultCoding,
): UpdateUserDefaultCoding {
  return {
    type: ActionTypeName.UPDATE_USER_DEFAULT_CODING,
    payload: { userId, defaultCoding },
  };
}

export function setSearchFilter(search): SetSearch {
  return {
    type: ActionTypeName.SET_SEARCH_FILTER,
    search,
  };
}

export function setColumnSortOptions(
  options: CollectionSortFilters,
): SetSortOptions {
  return {
    type: ActionTypeName.SET_SORT_OPTIONS,
    options,
  };
}

export type Actions =
  | SetCompanyUserIds
  | AddCompanyUser
  | DeleteCompanyUser
  | SetDefaultCodingByUserId
  | UpdateUserDefaultCoding
  | SetSearch
  | SetSortOptions;
