import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  EulaConfig,
  getCountryEula,
  getCompanyEula,
} from '@neo1/client/lib/entities/eula/api';

import { getEulaCountryCode } from 'utils/eula';
import { notifyError } from 'modules/App/redux/notifications/toaster/thunks';

type Props = {
  companyId?: string;
  countryCode?: string;
};
const useEulaConfig = ({ companyId, countryCode = 'GB' }: Props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [eulaConfig, setEulaConfig] = useState<EulaConfig>(null);
  const [eulaError, setEulaError] = useState(null);
  useEffect(() => {
    const load = async (): Promise<void> => {
      let eulaResponse: EulaConfig;
      try {
        if (companyId) {
          eulaResponse = await getCompanyEula(companyId);
          return setEulaConfig(eulaResponse);
        }
        if (countryCode) {
          const eulaCountryCode = getEulaCountryCode(countryCode);
          eulaResponse = await getCountryEula(eulaCountryCode);
          return setEulaConfig(eulaResponse);
        }
      } catch (e) {
        setEulaError(e);
        dispatch(notifyError(e));
      } finally {
        setIsLoading(false);
      }

      return setEulaConfig(eulaResponse);
    };
    load();
  }, [companyId, countryCode, dispatch]);

  return { eulaConfig, isLoading, eulaError };
};

export default useEulaConfig;

// exported for tests
export { useEulaConfig };
