import uniq from 'lodash/uniq';
import { SpendPolicyState } from './types';
import { SpendPolicyActionType, Action } from './actions';

export const initialState: SpendPolicyState = {
  policiesIds: [],
};

export default (
  state: SpendPolicyState = initialState,
  action: Action = null,
): SpendPolicyState => {
  switch (action?.type) {
    case SpendPolicyActionType.SET_POLICIES:
      return {
        policiesIds: action.policiesIds,
      };
    case SpendPolicyActionType.SET_POLICY:
      return {
        policiesIds: uniq([...state.policiesIds, action.policyId]),
      };
    case SpendPolicyActionType.DEL_POLICY:
      return {
        policiesIds: state.policiesIds.filter((id) => id !== action.policyId),
      };
    default:
      return state;
  }
};
