import { useEffect } from 'react';
import { generatePath, useHistory } from 'react-router';
import ScopeType from 'redux/scopeType';
import { selectCountersByScope } from 'redux/counter/selector';
import { useSelector } from 'react-redux';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import {
  hasAmazonPurchaseFeature,
  hasSpendManagementFeature,
} from '@neo1/client/lib/entities/company/utils';
import {
  ROUTE_PATH_AUTHORIZE_BUDGET,
  ROUTE_PATH_AUTHORIZE_PURCHASE,
  ROUTE_PATH_AUTHORIZE_SPEND,
} from '../constants';

const AuthorizeIndex = () => {
  const history = useHistory();
  const counts = useSelector(selectCountersByScope(ScopeType.Supervisor));
  const company = useSelector(selectActingCompany);
  useEffect(() => {
    if (!counts) return;

    if (hasSpendManagementFeature(company) && counts.budget.toApprove.count) {
      history.replace(generatePath(ROUTE_PATH_AUTHORIZE_BUDGET));
      return;
    }

    if (hasAmazonPurchaseFeature(company) && counts.purchase.toApprove.count) {
      history.replace(generatePath(ROUTE_PATH_AUTHORIZE_PURCHASE));
      return;
    }

    if (hasSpendManagementFeature(company) && counts.tx.toApprove.count) {
      history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=approve`);
      return;
    }

    if (hasSpendManagementFeature(company) && counts.tx.toReview.count) {
      history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=review`);
      return;
    }

    // This is the default route if all counters are at 0
    if (hasSpendManagementFeature(company)) {
      history.replace(`${generatePath(ROUTE_PATH_AUTHORIZE_SPEND)}?v=approve`);
    } else if (hasAmazonPurchaseFeature(company)) {
      history.replace(generatePath(ROUTE_PATH_AUTHORIZE_PURCHASE));
    }
  }, [counts]);

  return null;
};

export default AuthorizeIndex;
