import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { User } from '@neo1/client/lib/entities/user/types';
import { useLayoutContext } from 'contexts/layout';
import { DeviceSize, useWindowContext } from 'contexts/window';
import useLogoutAs from 'modules/Authentification/useLogoutAs';
import { selectIsLoggedInAs } from 'modules/Authentification/redux/selectors';
import Button, { ButtonKind, ButtonTheme } from 'components/elements/Button';
import displayEmail from 'utils/displayEmail';

import styles from './styles.module.css';

type Props = {
  user: User;
  startLoggingAs: () => void;
};

const Header = ({ user, startLoggingAs }: Props) => {
  const isLoggedInAs = useSelector(selectIsLoggedInAs);
  const {
    actions: { closeProfileMenu },
  } = useLayoutContext();
  const { deviceSize } = useWindowContext();
  const { execute: logoutAs } = useLogoutAs();

  return (
    <header className={styles.container}>
      <div className={classNames(styles.logAsLabel, 'textS')}>
        Logged in as:
      </div>
      <div className={styles.name}>{user.displayName}</div>
      <div className={classNames(styles.email, 'textS')}>
        {displayEmail(user.email)}
      </div>
      {isLoggedInAs ? (
        <Button
          label="Quit log as"
          theme={ButtonTheme.Ghost}
          className={styles.button}
          dark={deviceSize !== DeviceSize.desktop}
          onClick={() => logoutAs()}
        />
      ) : (
        startLoggingAs && (
          <Button
            label="Log as another user"
            theme={ButtonTheme.Ghost}
            className={styles.button}
            dark={deviceSize !== DeviceSize.desktop}
            onClick={() => startLoggingAs()}
          />
        )
      )}
      {deviceSize !== DeviceSize.desktop && (
        <Button
          label="Close"
          iconOnly
          wrapperClassName={styles.closeBtn}
          onClick={closeProfileMenu}
          iconName="close"
          theme={ButtonTheme.Discrete}
          kind={ButtonKind.Neutral}
          dark
        />
      )}
    </header>
  );
};

export default Header;
