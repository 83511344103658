import superagent from 'superagent';

/**
 * Parses html contents to search for Neo1 version
 * @param {*} html
 */
export function getNeo1VersionFromHtml(html: string) {
  const domParser = new DOMParser();
  const dom = domParser.parseFromString(html, 'text/html');
  const versionMetaTag = dom.querySelector("meta[name='neo-one-ui-version']");
  return versionMetaTag ? versionMetaTag.getAttribute('content') : null;
}

/**
 * Fetches index.html and parses contents to retrieve ui version meta tag contents
 */
export function getLatestVersion(): Promise<string> {
  return new Promise((resolve) => {
    superagent.get('.').end((err, res) => {
      if (err) {
        throw new Error(
          `Error while checking for a new Neo1 UI version: ${err}`,
        );
      }

      resolve(getNeo1VersionFromHtml(res.text));
    });
  });
}
