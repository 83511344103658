import superagent from 'superagent';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import { getClientHeaders, getClient } from '@neo1/client/lib/rpc/client';

export const FileType = {
  JPG: 'jpg',
  GIF: 'gif',
  PNG: 'png',
  PDF: 'pdf',
  UNKNOWN: 'n/a',
};

const EXCEEDS_MAX_SIZE_ERR_MSG =
  'Your proposed upload exceeds the maximum allowed size';

export const getFileType = (file: File) => fileTypeFromMime(file.type);

export const fileTypeFromMime = (fileMimeType: string) => {
  const fileTypeParts = /\/([^/]+)$/.exec(fileMimeType) || [];
  return fileType(fileTypeParts[1]);
};

export const getFileTypeFromFileName = (filename: string) =>
  fileType(filename.split('.').pop().toLowerCase());

const fileType = (type: string) => {
  switch (type) {
    case 'jpg':
    case 'jpeg':
      return FileType.JPG;
    case 'png':
      return FileType.PNG;
    case 'pdf':
      return FileType.PDF;
    case 'gif':
      return FileType.GIF;
    default:
      return FileType.UNKNOWN;
  }
};

/**
 *  Normalizes a file service Url
 */
export function fileServiceUrl(url: string, randParam?: string) {
  const euid = getClient().getConfigKey('euid');
  return `${url}?${euid ? `euid=${euid}` : ''}${
    randParam ? `&${randParam}` : ''
  }`;
}

/**
 * Converts given blob to data url
 */
export const blobToDataUrl = (blob: Blob) =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(this.result);
    };
    reader.readAsDataURL(blob);
  });

/**
 * Converts given blob to pdf data url
 */
export const blobToPdfDataUrl = async (blob: Blob) => {
  try {
    return await blobToDataUrl(blob);
  } catch (err) {
    return '';
  }
};

export const getEncodedContentsFromUri = (dataUri: string) =>
  dataUri.replace(/data:[a-zA-Z0-9\/]+;base64,(.*)$/, '$1');

/**
 * Fetches a blob from file service
 */
export function fetch(url: string, options: any = {}) {
  const { transform = blobToDataUrl, headers = getClientHeaders() } = options;

  const request = superagent
    .get(fileServiceUrl(url, `${Math.random() * 100}`))
    .responseType('blob');

  Object.entries(headers).forEach(([key, value]: [string, any]) => {
    request.set(key, value);
  });

  return new Promise<ReturnType<typeof transform>>((resolve, reject) => {
    request.end((err, res) => {
      if (err) {
        return reject(err);
      }
      return resolve(isFunction(transform) ? transform(res.body) : res.body);
    });
  });
}

/**
 * Fetches JSON file contents
 */
export const fetchJson = (url: string) =>
  new Promise((resolve, reject) =>
    superagent
      .get(url)
      .set('Accept', 'application/json')
      .end((err, res) => (err ? reject(err) : resolve(res.body))),
  );

/**
 * Fetches File API file and resolves to a Blob
 */
export const fetchBlob = (
  url: string,
): Promise<superagent.Response['body']> => {
  const request = superagent.get(url).responseType('blob');
  Object.entries(getClientHeaders()).forEach(([key, value]) => {
    request.set(key, value);
  });
  return new Promise((resolve, reject) =>
    request.end((err, res) => (err ? reject(err) : resolve(res.body))),
  );
};

/**
 * Make post request to s3 to upload the given file blob
 */
export const uploadToS3 = (file, fileName = 'file', params: any = {}) => {
  return new Promise((resolve, reject) =>
    superagent
      .post(params.action)
      .field('x-amz-date', params['x-amz-date'])
      .field('x-amz-credential', params['x-amz-credential'])
      .field('x-amz-security-token', params['x-amz-security-token'])
      .field(
        'x-amz-server-side-encryption',
        params['x-amz-server-side-encryption'],
      )
      .field('x-amz-algorithm', params['x-amz-algorithm'])
      .field('x-amz-signature', params['x-amz-signature'])
      .field('key', params.key)
      .field('acl', params.acl)
      .field('policy', params.policy)
      .field('success_action_status', params.success_action_status)
      .attach('file', file, fileName)
      .end((err, res) => {
        const errorResponseText = err?.response?.text || res?.text || '';
        const isMaxUploadSizeExceeded =
          errorResponseText.includes(EXCEEDS_MAX_SIZE_ERR_MSG) ||
          // Sometimes sending a file the exceeds the max size results in no response at all,
          // and is treated as a crossDomain error.
          (err?.crossDomain && res === undefined);

        if (isMaxUploadSizeExceeded) {
          return reject(
            new Error(
              'Your proposed upload file exceeds the maximum size allowed. Max size accepted: 9 MB.',
            ),
          );
        }

        if (err) {
          return reject(get(err, 'response.body.error', err));
        }

        if (params.success_action_status === `${res.status}`) {
          return resolve(res.body);
        }

        return reject(new Error('Could not upload the file'));
      }),
  );
};

let fileEndpointsConfig = {
  apiFilesBaseUrl: '',
  localesBaseUrl: '/locales',
};

export function getPostCompanyImportFileUrl(companyId: string) {
  return fileServiceUrl(
    `${fileEndpointsConfig.apiFilesBaseUrl}/excel-upload/company/${companyId}`,
  );
}

export function getPostDomainImportFilesUrl(domainId: string) {
  return fileServiceUrl(
    `${fileEndpointsConfig.apiFilesBaseUrl}/excel-upload/domain/${domainId}`,
  );
}

export function getCompanyAccountExtractFileUrl(
  companyId: string,
  accountId: string,
  fileName?: string,
) {
  let endpointUrl = `${fileEndpointsConfig.apiFilesBaseUrl}/extract/${companyId}/${accountId}`;

  if (fileName) {
    endpointUrl = `${endpointUrl}/${fileName}`;
  }

  return fileServiceUrl(endpointUrl);
}

export function getMessagesFileUrl(locale: string) {
  return `${fileEndpointsConfig.localesBaseUrl}/${locale}.json`;
}

export function configureFileEndpoints(newConfig: any) {
  return (fileEndpointsConfig = {
    ...fileEndpointsConfig,
    ...newConfig,
  });
}
