// TODO DEV-7011 to be investigated how to generalize this as a functionality of FieldWrapper
import { useLayoutEffect, MutableRefObject } from 'react';

export type ForceFocusProps = {
  forceFocus?: boolean;
};

const useForceFocus = (
  inputRef: MutableRefObject<HTMLInputElement>,
  forceFocus: boolean,
) => {
  useLayoutEffect(() => {
    if (forceFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [forceFocus]);
};

export default useForceFocus;
