export enum Actions {
  LAUNCH_UPDATE = 'one/app/update/LAUNCH_UPDATE',
}
export const launchUpdate = (): UpdateAction => ({
  type: Actions.LAUNCH_UPDATE,
});

export type UpdateAction = {
  type: Actions.LAUNCH_UPDATE;
};
