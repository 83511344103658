import isNil from 'lodash/isNil';
import isFunction from 'lodash/isFunction';
import { RpcBatchResult, isRpcSuccessResult } from './utils';

export type RpcBatchResults = {
  byId: Record<number, any>;
  get(id: number, defaultValue?: any): any;
};

/**
 * Wraps batch result array
 * @param results
 */
export const wrapResults = (results: Array<any>): RpcBatchResults => ({
  byId: results.reduce(
    (resultsById, { result, id }) => ({
      ...resultsById,
      [id]: result,
    }),
    {},
  ),

  get(id, defaultValue) {
    return this.byId[id] || defaultValue;
  },
});

/**
 * Will Unwrap batch response results and apply optionally given map function
 * @param {*} results
 * @param {*} mapFn
 */
export const unwrapResults = (results: RpcBatchResult = [], mapFn?: Function) =>
  results.reduce((filteredResults, payload) => {
    if (!isRpcSuccessResult(payload)) {
      return filteredResults;
    }
    return [
      ...filteredResults,
      isFunction(mapFn) && payload.result
        ? mapFn(payload.result)
        : payload.result,
    ];
  }, []);

/**
 * Will Unwrap batch response results collections and apply optionally given map function
 * @param {*} batchResults
 * @param {*} mapFn
 */
export const unwrapFlatten = (batchResults: any[], mapFn?: Function) =>
  batchResults.reduce((results, { result }) => {
    let collectedResults = Array.isArray(result) ? result : [];

    if (isFunction(mapFn)) {
      collectedResults = collectedResults.reduce(
        (filteredResults, result, i) => {
          const item = mapFn(result, i);

          if (!isNil(item)) {
            return filteredResults.concat(item);
          }

          return filteredResults;
        },
        [],
      );
    }

    return [...results, ...collectedResults];
  }, []);

/**
 * Will Unwrap batch response results, filter out errors and apply optionally given map function
 * @param {*} results
 * @param {*} mapFn
 */
export const unwrapFilter = (results: any[], mapFn?: Function) =>
  results.reduce((filteredResults, { result }) => {
    if (!isNil(result)) {
      return [...filteredResults, isFunction(mapFn) ? mapFn(result) : result];
    }
    return filteredResults;
  }, []);
