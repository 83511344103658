import { combineReducers, Reducer } from 'redux';
import { GuestState } from '../pages/Guests/redux/types';
import guests from '../pages/Guests/redux/reducer';
import trips from '../pages/Trips/redux/reducer';
import { TripState } from '../pages/Trips/redux/types';

export type TravelState = {
  guests: GuestState;
  trips: TripState;
};

const travelReducer: Reducer<TravelState> = combineReducers({
  guests,
  trips,
});

export default travelReducer;
