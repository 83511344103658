export const DAYJS_LOCALE_DEFAULT = 'en-gb';

export const DISPLAY_DATE_TIME_FORMAT_DEFAULT = 'D MMM YYYY HH:mm';
export const DISPLAY_DATE_FORMAT_DEFAULT = 'D MMM YYYY';
export const DISPLAY_DATE_WITHOUT_YEAR_FORMAT_DEFAULT = 'DD MMM';
export const DISPLAY_MONTH_FORMAT_DEFAULT = 'MMM YYYY';
export const INPUT_DATE_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const INPUT_DATE_FORMAT_LABEL_DEFAULT = 'dd/mm/yyyy';
export const INPUT_YEAR_PLACEHOLDER_DEFAULT = 'yyyy';
export const INPUT_MONTH_PLACEHOLDER_DEFAULT = 'mm';
export const INPUT_DAY_PLACEHOLDER_DEFAULT = 'dd';
export const API_DATE_FORMAT_DEFAULT = 'YYYY-MM-DD';
