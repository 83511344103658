/* eslint-disable import/prefer-default-export */

import { Country } from '@neo1/client/lib/entities/referentialData/types';

const US = 'US';

const GB = 'GB';

export const getEulaCountryCode = (countryCode: Country['code']) =>
  // !!!important!!!!!
  // show US terms conditon only for USA
  // others use the one for GB
  countryCode === US ? US : GB;
