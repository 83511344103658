export const ROUTE_NAME_REPORTS = 'reports';

export const ROUTE_PATH_REPORTS = '/reports';

export const ROUTE_PATH_ARCHIVED_TX = `${ROUTE_PATH_REPORTS}/archived/:txId`;

export const ROUTE_PATH_ARCHIVED_BUDGET = `${ROUTE_PATH_REPORTS}/archivedBudget/:budgetId`;

export const ROUTE_PATH_REPORTS_DASHBOARD = `${ROUTE_PATH_REPORTS}/:scope`;

export const ROUTE_PATH_PREDEFINED_REPORTS = `${ROUTE_PATH_REPORTS_DASHBOARD}/:name`;

export const ROUTE_PATH_REPORT_BUILDER = `${ROUTE_PATH_REPORTS}/builder`;
