import async from 'utils/wrapAsync';
import { AppMenuRoute } from 'config/routes/utils';
import { ROUTE_PATH_ADDRESSES, ROUTE_NAME_ADDRESSES } from './constants';

const route: AppMenuRoute = {
  title: 'Addresses',
  menu: {
    iconClass: 'pin',
  },
  path: ROUTE_PATH_ADDRESSES,
  name: ROUTE_NAME_ADDRESSES,
  component: async(
    () =>
      import(
        /* webpackChunkName: "addresses" */
        '.'
      ),
  ),
};

export default route;
