import classNames from 'classnames';
import Button, { ButtonSize } from 'components/elements/Button';
import { ReactComponent as ErrorMan } from 'styles/illustrations/errorMan.svg';

import styles from './ErrorPage.module.css';

type Props = {
  message: string;
  title?: string;
  titleClassName?: string;
  buttonTitle: string;
  handleClick: () => void;
};

const ErrorPage = ({
  title,
  message,
  titleClassName,
  buttonTitle,
  handleClick,
}: Props) => (
  <section className={styles.container}>
    <div className={styles.card} data-testid="errorCard">
      <div className={styles.innerCardContainer}>
        <ErrorMan className={styles.errorManImage} />
        <header className={styles.content}>
          {title && (
            <h2 className={classNames(styles.title, titleClassName)}>
              {title}
            </h2>
          )}
          <h3 className={classNames(styles.message, 'textL')}>{message}</h3>
          <Button
            label={buttonTitle}
            size={ButtonSize.Large}
            onClick={handleClick}
          />
        </header>
      </div>
    </div>
  </section>
);

ErrorPage.defaultProps = {
  title: undefined,
  titleClassName: undefined,
};

export default ErrorPage;
