import { Company } from '@neo1/client/lib/entities/company/types';
import {
  hasNeoTravelFeature,
  hasSpendManagementFeature,
} from '@neo1/client/lib/entities/company/utils';
import { User } from '@neo1/client/lib/entities/user/types';
import {
  isAdmin,
  isArranger,
  isTravelHost,
} from '@neo1/client/lib/entities/user/utils';

export const hasSpendReceiptPage = (company: Company) =>
  hasSpendManagementFeature(company);

export const hasTravelReceiptPage = (company: Company, user: User) =>
  hasNeoTravelFeature(company) &&
  (isAdmin(user) || isTravelHost(user) || isArranger(user));
