import { ReactNode } from 'react';
import classNames from 'classnames';
import Button, { ButtonProps, ButtonTheme } from 'components/elements/Button';
import Icon, { IconName } from 'components/elements/Icon';

import styles from './Banner.module.css';

type Props = {
  id: string;
  icon?: IconName;
  label?: ReactNode;
  description?: ReactNode;
  actions?: ButtonProps[];
  mainAction?: ButtonProps;
  expanded?: boolean;
  className?: string;
  descriptionClassName?: string;
  infoWrapperClassName?: string;
  actionTheme?: ButtonTheme;
};

const CompanyStatusBanner = ({
  id,
  icon,
  label,
  description,
  actions,
  mainAction,
  expanded,
  className,
  descriptionClassName,
  infoWrapperClassName,
  actionTheme,
}: Props) => {
  const hasActions = Boolean(actions.length);
  const hasMainAction = Boolean(mainAction);

  return (
    <div
      id={id}
      className={classNames(
        styles.container,
        expanded && styles.expanded,
        className,
      )}
    >
      {expanded && <div className={styles.backdrop} />}
      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <Icon name={icon} className={styles.icon} />
        </div>
        <div className={classNames(styles.infoWrapper, infoWrapperClassName)}>
          <div className={styles.labelsWrapper}>
            {label && <div className={styles.label}>{label}</div>}
            {description && (
              <div
                className={classNames(styles.description, descriptionClassName)}
              >
                {description}
              </div>
            )}
          </div>
          {(hasActions || hasMainAction) && (
            <div className={styles.actionsWrapper}>
              {hasActions &&
                expanded &&
                actions.map((action) => (
                  <Button
                    key={action.label}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...action}
                    className={classNames(styles.action, infoWrapperClassName)}
                  />
                ))}
              {hasMainAction && (
                <Button
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...mainAction}
                  className={classNames(styles.action, infoWrapperClassName)}
                  theme={actionTheme}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CompanyStatusBanner.defaultProps = {
  icon: 'warning',
  label: undefined,
  description: undefined,
  actions: [],
  mainAction: undefined,
  expanded: false,
  className: undefined,
  descriptionClassName: undefined,
  infoWrapperClassName: undefined,
  actionTheme: undefined,
};

export default CompanyStatusBanner;
