import wrapAsync from 'utils/wrapAsync';
import SHOWROOM_ROOT_PATH from './constants';

const route = {
  path: SHOWROOM_ROOT_PATH,
  name: 'buttonsShowroom',
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "showroom" */
        '.'
      ),
  ),
  exact: false,
};

export default route;
