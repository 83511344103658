import { isDataUrl } from '@neo1/client/lib/entities';
import {
  blobToDataUrl,
  getEncodedContentsFromUri,
} from '@neo1/core/utils/files';
import { buildURI } from '@neo1/core/utils/csv';

export const DEFAULT_EXPORT_CSV_FILE_NAME = 'export.csv';

const MAX_DATA_URI_LENGTH = 1024 * 1024 * 1.999;

export const stringify = (a: any) => String(a);

export const MimeTypes = {
  EXCEL: 'application/vnd.ms-excel',
};

/**
 * Asserts if navigator user agent matches to is Safari
 */
export const isSafari = () =>
  /(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent);

/**
 * Returns the most probable file name for given path
 */
export const getFileName = (path: string) => path.split(/(\\|\/)/g).pop();

/**
 * Converts given data url to array buffer
 */
export const dataUrlToArrayBuffer = (dataUrl: string) =>
  Promise.resolve(window.atob(getEncodedContentsFromUri(dataUrl)));

/**
 * Converts given data url to a Blob
 */
export const dataUrlToBlob = (dataUrl: string) => {
  const parts = dataUrl.split(/[:;,]/);
  const indexDecoder = dataUrl.indexOf('charset') > 0 ? 3 : 2;
  const decoder =
    window[parts[indexDecoder] === 'base64' ? 'atob' : 'decodeURIComponent'];
  const type = parts[1];
  const binData = decoder(parts.pop());
  const mx = binData.length;
  const blobBytes = new window.Uint8Array(mx);

  for (let i = 0; i < mx; i += 1) {
    blobBytes[i] = binData.charCodeAt(i);
  }

  return new Blob([blobBytes], { type });
};

/**
 * Converts given payload to a Blob fallbacking to a plain string
 */
const blobify = (payload: any, mimeType: string) => {
  const { Uint8Array } = window;
  const BlobBuilder = Blob.call ? Blob.bind(window) : Blob;

  if (
    typeof payload === 'string' &&
    isDataUrl(payload) &&
    payload.length > MAX_DATA_URI_LENGTH
  ) {
    return new BlobBuilder([payload], { type: mimeType });
  }

  if (typeof payload === 'string' && /([\x80-\xff])/.test(payload)) {
    // not a data url, is it a string with non-ascii characters
    const blobBytes = new Uint8Array(payload.length);
    const mx = blobBytes.length;
    for (let i = 0; i < mx; i += 1) {
      blobBytes[i] = payload.charCodeAt(i);
    }
    return new BlobBuilder([blobBytes], { type: mimeType });
  }

  return new BlobBuilder([payload], { type: mimeType });
};

/**
 * A sad solution to generate a file download
 * It's not expected to work on all the browsers
 */
export const fakeClickDownload = (
  uri: string,
  fileName: string,
  mimeType = 'application/octet-stream',
) => {
  const downloadLink = document.createElement('a');

  if ('download' in downloadLink) {
    downloadLink.href = uri;
    downloadLink.download = fileName;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);

    setTimeout(() => {
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  } else if (isSafari() && isDataUrl(uri)) {
    // handle non-a[download] safari as best we can:
    /* eslint-disable-next-line no-useless-escape */
    window.open(`data:${uri.replace(/^data:([\w\/\-\+]+)/, mimeType)}`);
  }
};

/**
 * Triggers download of given csv array as a csv file
 */
export const exportCsvArray = (
  csvArray: Object[],
  fileName = DEFAULT_EXPORT_CSV_FILE_NAME,
) => {
  fakeClickDownload(buildURI(csvArray, null, ','), fileName);
};

/**
 * Converts given payload to a blob and triggers a download
 */
export const download = (
  payload: any,
  fileName = 'download',
  mimeType = 'application/octet-stream',
) => {
  const { URL, btoa, encodeURIComponent } = window;
  const blob = blobify(payload, mimeType);

  if (URL) {
    // Best case scenario
    return fakeClickDownload(URL.createObjectURL(blob), fileName, mimeType);
  }

  // handle non-Blob()+non-URL browsers:
  if (typeof blob === 'string' || blob.constructor === stringify) {
    try {
      return fakeClickDownload(
        `data:${mimeType};base64,${btoa(blob)}`,
        fileName,
        mimeType,
      );
    } catch (error) {
      return fakeClickDownload(
        `data:${mimeType},${encodeURIComponent(blob)}`,
        fileName,
        mimeType,
      );
    }
  }

  // Blob but not URL support, fallback to data URI
  return blobToDataUrl(blob).then((dataUrl: string) =>
    fakeClickDownload(dataUrl, fileName, mimeType),
  );
};
