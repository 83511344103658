import { CompanyUserRole } from '@neo1/client/lib/entities/user/constants';
import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';
import {
  isCompanyAdmin,
  isCompanyFinance,
} from '@neo1/client/lib/entities/user/utils';
import { RouteContext } from 'config/routes/utils';
import wrapAsync from 'utils/wrapAsync';
import {
  ROUTE_NAME_RECONCILIATION,
  ROUTE_PATH_RECONCILIATION,
} from './constants';

const reconciliationRoute = {
  path: ROUTE_PATH_RECONCILIATION,
  name: ROUTE_NAME_RECONCILIATION,
  menu: {
    iconClass: 'reconciliation',
    userLevels: ['company'],
    userRoles: [CompanyUserRole.Admin, CompanyUserRole.Finance],
    companyFeatures: [CompanyFeature.Spend],
  },
  children: [
    {
      title: 'Reconciliation',
      exact: true,
      path: ROUTE_PATH_RECONCILIATION,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "reconciliationReport" */
            './Report'
          ),
      ),
    },
    {
      title: 'Extracts',
      exact: true,
      path: `${ROUTE_PATH_RECONCILIATION}/files`,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "reconciliationFiles" */
            './Files'
          ),
      ),
    },
  ],
  show: ({ user }: RouteContext) =>
    isCompanyAdmin(user) || isCompanyFinance(user),
};

export default reconciliationRoute;
