import { fetchAppReferentialData } from '@neo1/client/lib/entities/referentialData/api';
import { loadReferentialSuccess } from './actions';
/**
 * Loads App Referential
 */
export const loadReferential = () => async (dispatch: Function) => {
  const data = await fetchAppReferentialData();
  dispatch(loadReferentialSuccess(data));
};

export default loadReferential;
