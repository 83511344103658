import uniq from 'lodash/uniq';
import { Actions, PaymentMethodActions } from './actions';
import { PaymentMethodState } from './types';

export const initialState = {
  creditCardsIds: [],
  paymentInstrumentsIds: [],
  accountsIds: [],
};

export default (
  state: PaymentMethodState = initialState,
  action: PaymentMethodActions = null,
): PaymentMethodState => {
  switch (action?.type) {
    case Actions.SET_CREDIT_CARDS:
      return {
        ...state,
        creditCardsIds: action.creditCardsIds,
      };

    case Actions.SET_CREDIT_CARD:
      return {
        ...state,
        creditCardsIds: uniq([...state.creditCardsIds, action.creditCardId]),
      };

    case Actions.DEL_CREDIT_CARD:
      return {
        ...state,
        creditCardsIds: state.creditCardsIds.filter(
          (id) => id !== action.creditCardId,
        ),
      };

    case Actions.SET_PAYMENT_INSTRUMENTS:
      return {
        ...state,
        paymentInstrumentsIds: action.paymentInstrumentsIds,
      };

    case Actions.SET_PAYMENT_INSTRUMENT:
      return {
        ...state,
        paymentInstrumentsIds: uniq([
          ...state.paymentInstrumentsIds,
          action.paymentInstrumentId,
        ]),
      };

    case Actions.SET_ACCOUNTS:
      return {
        ...state,
        accountsIds: action.accountsIds,
      };

    default:
      return state;
  }
};
