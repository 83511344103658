import { newTimestamp } from '@neo1/core/utils/dates';
import { Actions, BuilderActions } from './actions';

export const initialState = {
  isMetadataLoaded: false,
  lastUsedParams: null,
  metadata: {},
  params: {
    scope: null,
    dateRange: null,
    fields: [],
    orderBy: null,
  },
  data: {
    fields: [],
    lines: [],
    totalLines: 0,
  },
  loading: false,
  loaded: false,
  filters: {
    query: [],
  },
  options: {
    isEditingOptions: true,
  },
};

export default function reducer(
  state = initialState,
  action: BuilderActions = null,
) {
  switch (action.type) {
    case Actions.BUILD_REPORT:
      return {
        ...state,
        loading: true,
        params: action.params,
        options: {
          ...state.options,
          isEditingOptions: false,
        },
      };

    case Actions.LOAD_TEMPLATE:
      return {
        ...state,
        loading: true,
        params: action.params,
        filters: action.filters,
        options: {
          ...state.options,
          reportTemplateName: action.name,
        },
      };

    case Actions.BUILD_REPORT_OK:
      return {
        ...state,
        loading: false,
        loaded: true,
        lastFetched: newTimestamp(),
        filters: action.resetFilters ? initialState.filters : state.filters,
        data: action.data,
      };

    case Actions.BUILD_REPORT_ERR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case Actions.LOAD_METADATA_OK:
      return {
        ...state,
        metadata: action.metadata,
        isMetadataLoaded: true,
      };

    case Actions.SET_VIEW_OPTS:
      return {
        ...state,
        options: action.options,
      };

    case Actions.SET_VIEW_OPT:
      return {
        ...state,
        options: {
          ...state.options,
          [action.key]: action.value,
        },
      };

    case Actions.SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };

    default:
      return state;
  }
}
