import { useSelector } from 'react-redux';
import { CompanyGroupUser, User } from '@neo1/client/lib/entities/user/types';
import { UserStatus } from '@neo1/client/lib/entities/user/constants';
import {
  isAdmin,
  isDomainUser,
  isSystemUser,
} from '@neo1/client/lib/entities/user/utils';
import {
  selectCompanyUsers,
  selectCurrentUser,
  selectDelegatedUsers,
  selectEffectiveUser,
} from 'modules/Authentification/redux/selectors';
import { AppCoreState } from 'redux/types';

const useLoggableAsUsers = () => {
  const currentUser = useSelector<AppCoreState>(selectCurrentUser) as User;
  const effectiveUser = useSelector<AppCoreState>(selectEffectiveUser) as User;
  const companyUsers = useSelector(selectCompanyUsers);
  const delegatedUsers = useSelector(selectDelegatedUsers);

  const filterUser = (user: CompanyGroupUser) =>
    user?.id !== currentUser?.id &&
    user?.id !== effectiveUser?.id &&
    user?.status === UserStatus.ACTIVE;

  if (
    isSystemUser(currentUser) ||
    isDomainUser(currentUser) ||
    isAdmin(currentUser)
  ) {
    return companyUsers.filter(filterUser);
  }

  if (delegatedUsers.length > 0) {
    return delegatedUsers.filter(filterUser);
  }

  return [];
};

export default useLoggableAsUsers;
