import { combineReducers } from 'redux';
import address from '../Addresses/redux/reducer';
import delegation from '../Delegations/redux/reducer';
import vehicle from '../Vehicles/redux/reducer';
import paymentMethod from '../Payments/redux/reducer';
import loyaltyCardsReducer from '../LoyaltyCards/redux/reducer';
import travelDocumentsReducer from '../TravelDoc/redux/reducer';

export default combineReducers({
  address,
  delegation,
  vehicle,
  loyaltyProgram: loyaltyCardsReducer,
  travelDocument: travelDocumentsReducer,
  paymentMethod,
});
