import { createContext, useContext, ReactNode, useReducer } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  isProfileMenuOpen: boolean;
  isNavMenuOpen: boolean;
};

const defaultState: State = {
  isProfileMenuOpen: false,
  isNavMenuOpen: false,
};

type Action =
  | { type: 'openProfileMenu' }
  | { type: 'closeProfileMenu' }
  | { type: 'toggleProfileMenu' }
  | { type: 'openNavMenu' }
  | { type: 'closeNavMenu' }
  | { type: 'toggleNavMenu' }
  | { type: 'closeAllMenus' };

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'openProfileMenu':
      return {
        isProfileMenuOpen: true,
        isNavMenuOpen: false,
      };
    case 'closeProfileMenu':
      return {
        ...state,
        isProfileMenuOpen: false,
      };
    case 'toggleProfileMenu':
      return {
        isProfileMenuOpen: !state.isProfileMenuOpen,
        isNavMenuOpen: !state.isProfileMenuOpen ? false : state.isNavMenuOpen,
      };
    case 'openNavMenu':
      return {
        isProfileMenuOpen: false,
        isNavMenuOpen: true,
      };
    case 'closeNavMenu':
      return {
        ...state,
        isNavMenuOpen: false,
      };
    case 'toggleNavMenu':
      return {
        isProfileMenuOpen: !state.isNavMenuOpen
          ? false
          : state.isProfileMenuOpen,
        isNavMenuOpen: !state.isNavMenuOpen,
      };
    case 'closeAllMenus':
      return {
        isProfileMenuOpen: false,
        isNavMenuOpen: false,
      };
    default: {
      throw Error('Unknown action.');
    }
  }
};

export const LayoutContext = createContext<any>(defaultState);

export const LayoutContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const actions = {
    openProfileMenu: () => {
      dispatch({ type: 'openProfileMenu' });
    },
    closeProfileMenu: () => {
      dispatch({ type: 'closeProfileMenu' });
    },
    toggleProfileMenu: () => {
      dispatch({ type: 'toggleProfileMenu' });
    },
    openNavMenu: () => {
      dispatch({ type: 'openNavMenu' });
    },
    closeNavMenu: () => {
      dispatch({ type: 'closeNavMenu' });
    },
    toggleNavMenu: () => {
      dispatch({ type: 'toggleNavMenu' });
    },
    closeAllMenus: () => {
      dispatch({ type: 'closeAllMenus' });
    },
  };

  const value = { state, actions };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayoutContext = () => useContext(LayoutContext);
