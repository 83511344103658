import { Dispatch } from 'redux';
import { sleep } from '@neo1/core/utils/misc';
import { ButtonKind } from 'components/elements/Button';
import { OnProgressRunner, ToasterProps, ToasterType } from './types';
import newNotification from './helper';
import { hide, push, setProgress } from './actions';

const NOTIFICATION_HIDE_TIMEOUT = 4000;

export const notify = (payload: ToasterProps) => push(newNotification(payload));

export const notifySuccess = (
  message: string,
  actionButton?: ToasterProps['actionButton'],
) =>
  notify({
    message,
    actionButton,
    type: 'success',
    timeout: NOTIFICATION_HIDE_TIMEOUT,
  });

export const notifyWarning = (
  message: string,
  actionButton?: ToasterProps['actionButton'],
  pushOnce: boolean = true,
) => notify({ message, actionButton, type: 'warning', pushOnce });

export const notifyError = (
  error: any,
  withMessage?: string,
  actionButton?: ToasterProps['actionButton'],
) => {
  let message = withMessage;
  if (error instanceof Error || typeof error === 'object') {
    message = message || error.message;
  } else if (typeof error === 'string') {
    message = message || error;
  }

  return notify({ message, type: 'error', actionButton });
};

/**
 * Displays a notification with a confirmation button
 * @param {*} message
 * @param {*} actionTitle
 * @param {*} onConfirm
 */
export const notifyConfirm = (
  message: string,
  actionTitle: string,
  onConfirm: () => void,
  type: ToasterType = ButtonKind.Warning,
  onDismiss?: () => void,
) =>
  notify({
    message,
    type,
    onDismiss,
    actionButton: {
      label: actionTitle,
      onClick: onConfirm,
    },
  });

export const notifyProgress =
  (run: OnProgressRunner, message: string) =>
  async (dispatch: Dispatch<any>) => {
    const notification = newNotification({
      type: 'progress',
      message,
    });

    let result: any;

    dispatch(push(notification));

    const onProgress = (progressionPercentile: number) => {
      dispatch(setProgress(notification.uuid, progressionPercentile));
    };

    try {
      result = await run(onProgress);
    } catch (err) {
      dispatch(hide(notification));
      throw err;
    }

    // Go to state 100% because there is nothing left to do anyway and no error occured
    dispatch(setProgress(notification.uuid, 100));

    // For animation purposes we let the notification be at least 1s
    await sleep(1000);

    dispatch(hide(notification));

    return result;
  };
