import { ReactNode } from 'react';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import Spinner from 'components/elements/Loader/Spinner';
import Button, { ButtonTheme } from 'components/elements/Button';
import useConfirmDialog from 'components/composed/MultiStepModalForm/useConfirmDialog';
import styles from './StepContent.module.css';

type Props = {
  title?: string;
  titleCentered?: boolean;
  description?: ReactNode;
  children?: ReactNode;
  submitTitle?: string;
  closeTitle?: string;
  onClose?: () => void;
  onSkip?: () => void;
  onGoBack?: () => void;
  isLoading?: boolean;
  loadingMsg?: string;
};

const StepContent = ({
  title,
  titleCentered,
  description,
  submitTitle,
  closeTitle,
  onClose,
  onSkip,
  onGoBack,
  children,
  isLoading,
  loadingMsg,
}: Props) => {
  const { isSubmitting, dirty, values } = useFormikContext();

  const { confirmDialog: confirmCloseDialog, handleOnClose } = useConfirmDialog(
    onClose,
    true,
  );
  const { confirmDialog: confirmGoBackDialog, handleOnClose: handleOnGoBack } =
    useConfirmDialog(onGoBack, true);

  const { confirmDialog: confirmSkipDialog, handleOnClose: handleOnSkip } =
    useConfirmDialog(onSkip, true);

  const hasActions = Boolean(submitTitle || onClose || onGoBack || onSkip);

  return (
    <div className={styles.container}>
      {title && (
        <div
          className={classNames(
            styles.title,
            titleCentered && styles.titleCentered,
            'header3',
          )}
        >
          {title}
        </div>
      )}
      {description && <div className="textM">{description}</div>}
      <div className={styles.main}>
        {isLoading ? (
          <div className={styles.spinnerWrapper}>
            <Spinner />
            <div>{loadingMsg}</div>
          </div>
        ) : (
          children
        )}
      </div>
      {!isLoading && hasActions && (
        <div className={styles.actionsWrapper}>
          {onGoBack && (
            <Button
              label="Back"
              theme={ButtonTheme.Ghost}
              disabled={isSubmitting}
              onClick={() => handleOnGoBack(values, dirty)}
              testId="goBackBtn"
              iconName="left"
              iconPosition="leading"
            />
          )}
          <div className={styles.mainActions}>
            {onClose && (
              <Button
                label={closeTitle}
                theme={submitTitle ? ButtonTheme.Ghost : ButtonTheme.Filled}
                disabled={isSubmitting}
                onClick={() => handleOnClose(values, dirty)}
                testId="closeBtn"
              />
            )}
            {onSkip && (
              <Button
                label="Skip"
                theme={ButtonTheme.Ghost}
                disabled={isSubmitting}
                onClick={() => handleOnSkip(values, dirty)}
                testId="skipBtn"
              />
            )}
            {submitTitle && (
              <Button
                type="submit"
                label={submitTitle}
                disabled={isSubmitting}
                loading={isSubmitting}
              />
            )}
          </div>
        </div>
      )}
      {confirmCloseDialog}
      {confirmSkipDialog}
      {confirmGoBackDialog}
    </div>
  );
};

StepContent.defaultProps = {
  title: undefined,
  titleCentered: false,
  description: undefined,
  submitTitle: undefined,
  closeTitle: 'Cancel',
  onClose: undefined,
  onSkip: undefined,
  onGoBack: undefined,
  isLoading: false,
  loadingMsg: undefined,
  children: null,
};

export default StepContent;
