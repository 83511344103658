import { useCallback, useState } from 'react';

enum Status {
  Pending = 'pending',
  Loading = 'loading',
  Ready = 'ready',
  Error = 'error',
}

type AsyncStateData<G extends Array<any>, T> = {
  execute: (...args: G) => Promise<void>;
  data: T;
  error: any;
  status: Status;
  isPending: boolean;
  isLoading: boolean;
  isReady: boolean;
  isError: boolean;
};

const useAsyncState = <G extends Array<any>, T>(
  getData: (...args: G) => Promise<T>,
): AsyncStateData<G, T> => {
  const [data, setData] = useState<T>(null);
  const [status, setStatus] = useState<Status>(Status.Pending);
  const [error, setError] = useState(null);

  const execute = useCallback(
    async (...args: G) => {
      setError(null);
      setStatus(Status.Loading);
      try {
        const result = await getData(...args);
        setData(result);
        setStatus(Status.Ready);
      } catch (e) {
        setError(e);
        setData(null);
        setStatus(Status.Error);
      }
    },
    [getData],
  );

  return {
    status,
    data,
    error,
    execute,
    isPending: status === Status.Pending,
    isLoading: status === Status.Loading,
    isReady: status === Status.Ready,
    isError: status === Status.Error,
  };
};

export default useAsyncState;
