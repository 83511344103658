import 'core-js/stable';
import 'core-js/features/string/replace-all';
import { createBrowserHistory as createHistory, History } from 'history';
import configureStore from 'redux/configure';
import bootstrap from 'utils/bootstrap';
import 'styles/app.module.css';

declare global {
  interface Window {
    debugMode: boolean;
    Intl: any;
    AwsRumClient: any; // AWS real-user monitoring client
    cwr: any;
    FreshworksWidget: Function;
    fwSettings: {
      widget_id: string;
    };
    currentCspNonce: string;
    Optanon: any;
  }
}

// Create redux store
const history: History = createHistory();

const store = configureStore();

// Target element where app is supposed to be rendered
const container = document.getElementById('root');

if (container) {
  // Initialize and start the application
  bootstrap({ store, container, history });
}
