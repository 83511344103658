import { useState, HTMLAttributes, KeyboardEvent, FocusEvent } from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import DropboxInput from 'components/composed/DropboxInput';
import FieldWrapper, {
  FieldWrapperProps,
} from 'components/elements/form/FieldWrapper';
import RegistrationDetails from './RegistrationDetails';

import {
  CompanyHouseRegistrationDetails,
  fetchCompanyRegistrationDetails,
} from './utils';

import { isValidRegistrationNumber } from '../../../utils';
import styles from './EditRegistrationNumber.module.css';

import EditRegistrationNumberInput from './Input';

interface Props
  extends Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>,
    Omit<FieldWrapperProps, 'children'> {
  onSelectCompany(details: CompanyHouseRegistrationDetails): void;
  withRegistrationDetails?: boolean;
  onChange?: (value: string) => void;
}

function EditRegistrationNumber({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  isInvalid,
  isRequired,
  isSuccess,
  disabled,
  onSelectCompany,
  withRegistrationDetails,
  ...props
}: Props) {
  const [registrationDetails, setRegistrationDetails] =
    useState<CompanyHouseRegistrationDetails | null>(null);

  const fetchResults = debounce(async (input: string) => {
    if (!isEmpty(input) && isValidRegistrationNumber(input, 'GB')) {
      try {
        const details = await fetchCompanyRegistrationDetails(input);
        setRegistrationDetails(details);
      } catch (err) {
        setRegistrationDetails(null);
      }
    } else {
      setRegistrationDetails(null);
    }
  }, 300);

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    fetchResults(input);
  };

  const onFocus = (e: FocusEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value;
    fetchResults(input);
  };

  let input = null;
  if (withRegistrationDetails) {
    input = (
      <DropboxInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
        name={name}
        InputComponent={EditRegistrationNumberInput}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
      >
        <div className={styles.container}>
          <p className={styles.info}>
            <FormattedMessage
              id="ActivateCompany__About__EditRegNumber__info"
              defaultMessage="Providing your company identification number will help us easy your setup"
            />
          </p>
          {registrationDetails && (
            <RegistrationDetails
              details={registrationDetails}
              onSelect={onSelectCompany}
            />
          )}
        </div>
      </DropboxInput>
    );
  }

  if (!withRegistrationDetails) {
    input = (
      <EditRegistrationNumberInput
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        id={id}
        name={name}
        isInvalid={isInvalid}
        errorText={errorText}
      />
    );
  }

  return (
    <FieldWrapper
      id={id}
      name={name}
      title={title}
      info={info}
      successText={successText}
      helperText={helperText}
      keepBottomSpace={keepBottomSpace}
      isInvalid={isInvalid}
      isRequired={isRequired}
      isSuccess={isSuccess}
      disabled={disabled}
      noBorder
    >
      {input}
    </FieldWrapper>
  );
}

EditRegistrationNumber.defaultProps = {
  withRegistrationDetails: false,
  onChange: undefined,
};

export default EditRegistrationNumber;
