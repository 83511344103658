import classNames from 'classnames';
import RadioBox from 'components/elements/RadioBox';
import styles from './RadioGroupTimeline.module.css';

export interface Props<T> {
  onChange: (value: T) => void;
  className?: string;
  value: string;
  values: string[];
  disabled?: boolean;
  name: string;
}

function RadioGroupTimeline<T>({
  className,
  onChange,
  disabled,
  value,
  values,
  name,
}: Props<T>) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.line}>
        {values.map((v) => (
          <RadioBox
            className={styles.radio}
            key={`${name}-${v}`}
            value={v}
            name={name}
            disabled={disabled}
            isOn={value === v}
            onToggle={onChange}
            testId={`${name}-${v}`}
          />
        ))}
      </div>
    </div>
  );
}

RadioGroupTimeline.defaultProps = {
  disabled: false,
  className: undefined,
};

export default RadioGroupTimeline;
