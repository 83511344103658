import classNames from 'classnames';

import styles from './Badge.module.css';

type Props = {
  className?: string;
  count?: number;
  isActive?: boolean;
};

function Badge({ className, isActive, count }: Props) {
  return (
    <div
      data-testid="badge"
      className={classNames(
        styles.container,
        className,
        count > 0 && isActive ? styles.active : styles.inactive,
      )}
    >
      <span className={styles.text}>{count}</span>
    </div>
  );
}

Badge.defaultProps = {
  className: undefined,
  count: 0,
  isActive: true,
};

export default Badge;
