export type Amount = {
  amount: number;
  currency: string;
};

/**
 * Asserts if given object is usable as amount
 * @param {*} obj
 */
export const isAmountLike = (obj: any): obj is Amount => {
  return (
    typeof obj === 'object' &&
    typeof obj?.amount === 'number' &&
    typeof obj?.currency === 'string'
  );
};
