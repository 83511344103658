import { AppCoreState } from 'redux/types';
import { DEFAULT_LOCALE } from 'config/i18n/constants';

export function selectAppLocale(state: AppCoreState) {
  return state.app.i18n.locale || DEFAULT_LOCALE;
}

export const selectAppI18nConfig = (state: AppCoreState) => ({
  locale: selectAppLocale(state),
  isLoading: state.app.i18n.messages.loading,
  messages: state.app.i18n.messages.data || {},
});
