import { defineMessages } from 'react-intl';

const messages = {
  address: defineMessages({
    displayName: {
      id: 'Address__displayName',
      defaultMessage: 'Display name',
    },
    address: {
      id: 'Address__address',
      defaultMessage: 'Address',
    },
    streetLine1: {
      id: 'Address__streetLine1',
      defaultMessage: 'Address line 1',
    },
    streetLine2: {
      id: 'Address__streetLine2',
      defaultMessage: 'Address line 2',
    },
    city: {
      id: 'Address__city',
      defaultMessage: 'City',
    },
    countryCode: {
      id: 'Address__countryCode',
      defaultMessage: 'C\u200Country',
    },
  }),
  owner: defineMessages({
    firstName: {
      id: 'Owner__firstName',
      defaultMessage: 'First name',
    },
    lastName: {
      id: 'Owner__lastName',
      defaultMessage: 'Last name',
    },
    birthDate: {
      id: 'Owner__birthDate',
      defaultMessage: 'Date of birth',
    },
    nationality: {
      id: 'Owner__nationality',
      defaultMessage: 'N\u200Cationality',
    },
  }),
  date: defineMessages({
    formatHelper: {
      id: 'Date__formatHelper',
      defaultMessage: 'Format ({format})',
    },
    rangeFormatHelper: {
      id: 'Date__rangeFormatHelper',
      defaultMessage: 'Format ({format} - {format})',
    },
  }),
};

export default messages;
