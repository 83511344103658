import async from 'utils/wrapAsync';
import { AppMenuRoute, hasNeoTravel } from 'config/routes/utils';
import { ROUTE_PATH_TRAVEL_DOC, ROUTE_NAME_TRAVEL_DOC } from './constants';

const route: AppMenuRoute = {
  title: 'Travel documents',
  show: hasNeoTravel,
  exact: true,
  menu: {
    iconClass: 'travelDoc',
  },
  path: ROUTE_PATH_TRAVEL_DOC,
  name: ROUTE_NAME_TRAVEL_DOC,
  component: async(
    () =>
      import(
        /* webpackChunkName: "travelDocuments" */
        '.'
      ),
  ),
};

export default route;
