import { ReactNode } from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './MultistepTracker.module.css';

export type Step = {
  key: string | number;
  label?: ReactNode;
  pastTrack?: boolean;
  futureTrack?: boolean;
};

type Props = {
  steps: Step[];
  currentStepKey: Step['key'];
};

const MultistepTracker = ({ steps, currentStepKey }: Props) => {
  const intl = useIntl();

  const currentStepIdx = Math.max(
    steps.findIndex((step) => step.key === currentStepKey),
    0,
  );

  const currentStep = steps[currentStepIdx];

  const currentStepNumber = currentStepIdx + 1;
  return (
    <div
      role="progressbar"
      aria-valuenow={currentStepNumber}
      aria-valuetext={`${intl.formatMessage(
        messages.step,
      )} ${currentStepNumber}: ${currentStep.label}`}
      aria-valuemin={1}
      aria-valuemax={steps.length}
      className={styles.container}
      data-testid="multistepTracker"
    >
      {steps.map(({ key, label, pastTrack, futureTrack }, idx) =>
        label ? (
          <div
            key={key}
            className={classNames(
              styles.step,
              idx <= currentStepIdx && styles.active,
              key === currentStepKey && styles.current,
              pastTrack && styles.pastTrack,
              futureTrack && styles.futureTrack,
            )}
          >
            <div className={styles.dotBar}>
              <div className={styles.dot} />
              <div className={styles.bar} />
            </div>
            <div className={styles.label}>{label}</div>
          </div>
        ) : null,
      )}
    </div>
  );
};

export default MultistepTracker;
