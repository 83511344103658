import get from 'lodash/get';
import { SortTypes } from '@neo1/core/utils/collections';
import alphanumSort from 'utils/alphanumSort';
import { SortFunction } from './types';

const defaultSortFunction: SortFunction = (list, sort, getSortValue) => {
  if (!sort?.prop) return list;

  const sortedList = list.sort((a, b) => {
    const aValue = getSortValue
      ? (getSortValue(a) || '').toString()
      : get(a, sort.prop);
    const bValue = getSortValue
      ? (getSortValue(b) || '').toString()
      : get(b, sort.prop);
    return alphanumSort(aValue, bValue);
  });

  if (sort.dir === SortTypes.DESC) {
    sortedList.reverse();
  }

  return sortedList;
};

export default defaultSortFunction;
