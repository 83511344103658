import { defineMessages } from 'react-intl';

const messages = defineMessages({
  registrationNumber: {
    id: 'ActivateCompany__about__registrationNumber',
    defaultMessage: 'Company identification number',
  },
  confirmCompanyName: {
    id: 'ActivateCompany__about__confirmCompanyName',
    defaultMessage: 'Confirm your registered business name',
  },
  type: {
    id: 'ActivateCompany__about__type',
    defaultMessage: 'Business type',
  },
  yearOfRegistration: {
    id: 'ActivateCompany__about__yearOfRegistration',
    defaultMessage: 'Year of registration',
  },
  tradingName: {
    id: 'ActivateCompany__about__tradingName',
    defaultMessage: 'If you use a trading name, what is it?',
  },
  industry: {
    id: 'ActivateCompany__about__industry',
    defaultMessage: 'What best describes your industry?',
  },
  vatNumber: {
    id: 'ActivateCompany__about__vatNumber',
    defaultMessage: 'VAT number',
  },
  numberOfEmployees: {
    id: 'ActivateCompany__about__numberOfEmployees',
    defaultMessage: 'Number of employees',
  },
});

export default messages;
