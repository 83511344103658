export type LatLong = {
  lat: number;
  lng: number;
};

export enum DistanceUnit {
  MILES = 'miles',
  KM = 'kilometers',
}

export const DISTANCE_UNITS: Array<DistanceUnit> = [
  DistanceUnit.MILES,
  DistanceUnit.KM,
];

const VIEWER_COUNTRY_ENDPOINT = '/utils/viewer-country';
const VIEWER_COUNTRY_HEADER = 'x-neo1-result';

const VIEWER_LOCATION_ENDPOINT = '/utils/viewer-lat-lon';
const VIEWER_LOCATION_LONG_HEADER = 'x-neo1-lon';
const VIEWER_LOCATION_LAT_HEADER = 'x-neo1-lat';

/**
 * Converts meters distance to miles
 */
export function metersToMile(metersDistance: number) {
  return metersDistance * 0.000621371192;
}

export const getViewerCountry = async (baseUrl = ''): Promise<string> => {
  try {
    const response = await fetch(baseUrl + VIEWER_COUNTRY_ENDPOINT, {
      method: 'HEAD',
    });
    return response.headers.get(VIEWER_COUNTRY_HEADER);
  } catch (e) {
    console.error(e);
  }
};

export const getViewerLocation = async (baseUrl = ''): Promise<LatLong> => {
  try {
    const response = await fetch(baseUrl + VIEWER_LOCATION_ENDPOINT, {
      method: 'HEAD',
    });
    const lng = parseFloat(response.headers.get(VIEWER_LOCATION_LONG_HEADER));
    const lat = parseFloat(response.headers.get(VIEWER_LOCATION_LAT_HEADER));
    if (isNaN(lng) || isNaN(lat)) {
      throw new Error(`location was unavailable: lat ${lat}, lng:${lng} `);
    }
    return { lat, lng };
  } catch (e) {
    console.error(e);
  }
};
