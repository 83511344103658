import { Dispatch, AnyAction } from 'redux';
import { batch } from 'react-redux';
import { UUID } from '@neo1/client';
import { EntityType } from './types';
import * as actions from './actions';

type SetEntitiesPayload<T> = {
  entityName: EntityType;
  entities: T[];
  action?: AnyAction;
};

type SetEntityPayload<T> = {
  entityName: EntityType;
  entity: T;
  action?: AnyAction;
};

type DelEntityPayload = {
  entityName: EntityType;
  entityId: UUID | number;
  action: AnyAction;
};

export const setEntities =
  <T>({ entityName, entities, action }: SetEntitiesPayload<T>) =>
  (dispatch: Dispatch) => {
    batch(() => {
      dispatch(actions.setMulti(entityName, entities));
      if (action) {
        dispatch(action);
      }
    });
  };

export const setEntity =
  <T>({ entityName, entity, action }: SetEntityPayload<T>) =>
  (dispatch: Dispatch) => {
    batch(() => {
      dispatch(actions.setEntity(entityName, entity));
      if (action) {
        dispatch(action);
      }
    });
  };

export const delEntity =
  ({ entityName, entityId, action }: DelEntityPayload) =>
  (dispatch: Dispatch) => {
    batch(() => {
      dispatch(actions.deleteEntity(entityName, entityId));
      dispatch(action);
    });
  };

export const hardSetEntities =
  <T>({ entityName, entities, action }: SetEntitiesPayload<T>) =>
  (dispatch: Dispatch) => {
    batch(() => {
      dispatch(actions.hardSet(entityName, entities));
      dispatch(action);
    });
  };
