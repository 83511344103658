import { ReactNode } from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import Label from './Label';
import styles from './FieldWrapper.module.css';
import Icon from '../Icon';

export interface FieldWrapperProps {
  id?: string;
  name?: string;
  title?: string;
  info?: ReactNode;
  errorText?: string;
  successText?: string;
  helperText?: string;
  keepBottomSpace?: boolean;
  noBorder?: boolean;
  className?: string;
  isInvalid?: boolean;
  disabled?: boolean;
  isRequired?: boolean;
  isSuccess?: boolean;
  readOnly?: boolean;
  readOnlyValue?: ReactNode;
  rightAlign?: boolean;
  children?: ReactNode;
  testId?: string;
}

const FieldWrapper = ({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  noBorder,
  className,
  isInvalid,
  disabled,
  isRequired,
  isSuccess,
  readOnly,
  readOnlyValue,
  rightAlign,
  children,
  testId,
}: FieldWrapperProps) => {
  const showHelperText = !isInvalid && !isSuccess && helperText;
  const showErrorText = isInvalid && errorText;
  const showSuccessText = isSuccess && successText;
  const showButtomSpace =
    keepBottomSpace && !showHelperText && !showErrorText && !showSuccessText;

  return (
    <div
      className={classNames(
        isInvalid && styles.invalid,
        disabled && styles.disabled,
        isSuccess && styles.success,
        (noBorder || readOnly) && styles.noBorder,
        className,
      )}
      data-testid={testId || 'fieldWrapper'}
    >
      {title && (
        <Label
          htmlFor={id}
          title={title}
          info={info}
          isRequired={isRequired}
          className={styles.label}
        />
      )}

      <div className={styles.inputWrapper}>
        {readOnly ? (
          <div
            id={id}
            className={classNames(
              styles.readOnlyValue,
              rightAlign && styles.rightAlign,
              'textM',
            )}
            aria-label={title}
          >
            {isNil(readOnlyValue) || isEmpty(readOnlyValue)
              ? '-'
              : readOnlyValue}
          </div>
        ) : (
          children
        )}
      </div>
      {showHelperText && (
        <div className={styles.msg} data-testid={`${testId || name}HelperText`}>
          <div className="textS">{helperText}</div>
        </div>
      )}
      {showErrorText && (
        <div
          className={classNames(styles.msg, styles.error)}
          data-testid={`${testId || name}Error`}
        >
          <Icon name="warning" className={styles.msgIcon} />
          <div className="textS">{errorText}</div>
        </div>
      )}
      {showSuccessText && (
        <div
          className={classNames(styles.msg, styles.success)}
          data-testid={`${testId || name}Success`}
        >
          <Icon name="checkCircle" className={styles.msgIcon} />
          <div className="textS">{successText}</div>
        </div>
      )}
      {showButtomSpace && (
        <div className={classNames(styles.msg, styles.placeholder)} />
      )}
    </div>
  );
};

FieldWrapper.defaultProps = {
  id: undefined,
  name: undefined,
  title: undefined,
  info: undefined,
  errorText: undefined,
  successText: undefined,
  helperText: undefined,
  keepBottomSpace: false,
  noBorder: false,
  className: undefined,
  isInvalid: false,
  disabled: false,
  isRequired: true,
  isSuccess: false,
  readOnly: false,
  readOnlyValue: undefined,
  rightAlign: false,
  children: undefined,
  testId: undefined,
};

export default FieldWrapper;
