import { combineReducers } from 'redux';
import budget from '../pages/Budgets/redux/reducer';
import { BudgetState } from '../pages/Budgets/redux/types';
import supply from '../pages/Supplies/redux/reducer';
import { SupplyState } from '../pages/Supplies/redux/types';
import { TxState } from '../pages/Spend/redux/types';
import spend from '../pages/Spend/redux/reducer';

export type AuthorizeState = {
  budget: BudgetState;
  supply: SupplyState;
  spend: TxState;
};

export default combineReducers({
  budget,
  supply,
  spend,
});
