import colors from './colors';
import sizes from './sizes';

const CssVars = () => (
  <style type="text/css" nonce={window.currentCspNonce}>
    {`
      :root {
        ${Object.keys(colors)
          .map((name) => `--${name}: ${colors[name]};`)
          .join('')}
        ${Object.keys(sizes)
          .map((name) => `--${name}: ${sizes[name]}px;`)
          .join('')}
      }
    `}
  </style>
);

export default CssVars;
