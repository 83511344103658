import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './RadioBox.module.css';

type Props = {
  id?: string;
  name: string;
  isInvalid?: boolean;
  isOn?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  label?: ReactNode;
  onToggle?: (value: any) => void;
  className?: string;
  testId?: string;
  value: any;
};

const RadioBox = ({
  id,
  name,
  isInvalid,
  isOn,
  disabled,
  readOnly,
  label,
  onToggle,
  className,
  testId,
  value,
}: Props) => {
  const handleChange = () => {
    if (!onToggle) return;
    onToggle(value);
  };

  return (
    <label htmlFor={id} className={classNames(className, styles.container)}>
      <input
        type="radio"
        id={id}
        name={name}
        checked={isOn}
        value={value}
        disabled={disabled || readOnly}
        onChange={handleChange}
        className={classNames(styles.radio, isInvalid && styles.isInvalid)}
        data-testid={testId}
      />
      {Boolean(label) && (
        <span className={classNames(disabled && styles.disabledLabel)}>
          {label}
        </span>
      )}
    </label>
  );
};

RadioBox.defaultProps = {
  id: undefined,
  isInvalid: false,
  isOn: false,
  disabled: false,
  readOnly: false,
  label: undefined,
  className: undefined,
  onToggle: undefined,
  testId: undefined,
};

export default RadioBox;
