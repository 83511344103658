import { UUID } from '@neo1/client';

export enum Action {
  SET_BUDGET = 'one/SET_BUDGET',
  SET_BUDGETS = 'one/SET_BUDGETS',
  DELETE_BUDGET = 'one/DELETE_BUDGET',
}

export type SetBudget = {
  type: typeof Action.SET_BUDGET;
  id: UUID;
};

export type SetBudgets = {
  type: typeof Action.SET_BUDGETS;
  ids: UUID[];
};

export type DeleteBudget = {
  type: typeof Action.DELETE_BUDGET;
  id: UUID;
};

export type BudgetActions = SetBudget | SetBudgets | DeleteBudget;

export type BudgetState = {
  ids: UUID[];
};
