import ErrorPage from 'components/composed/ErrorPage';

function FullPageError() {
  const redirectToHome = () => {
    window.location.reload();
  };

  return (
    <ErrorPage
      title="Oops, it looks like something went wrong!"
      message="Do not worry, just click on the button below to go back to normal."
      titleClassName="header1"
      buttonTitle="Reload"
      handleClick={redirectToHome}
    />
  );
}

export default FullPageError;
