import { ReactNode } from 'react';
import classNames from 'classnames';
import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import Actions from './Actions';
import useGoback from '../../useGoBack';
import styles from '../../ActivateCompany.module.css';
import stepStyles from '../StepView.module.css';

interface Props {
  children: ReactNode;
  isSubmitDisabled: boolean;
  isShowingValidationResult: boolean;
  onBackAddressValidation: () => void;
  onBack: (values: CompanyActivationData) => void;
}

const Address = ({
  children,
  onBack,
  isSubmitDisabled,
  isShowingValidationResult,
  onBackAddressValidation,
}: Props) => {
  const { handleOnBack: onStepBack } = useGoback(onBack);

  const handleOnBack = isShowingValidationResult
    ? onBackAddressValidation
    : onStepBack;

  return (
    <div className={classNames(styles.form, stepStyles.container)}>
      {children}
      <Actions
        className={stepStyles.btn}
        isSubmitDisabled={isSubmitDisabled}
        onGoBack={handleOnBack}
      />
    </div>
  );
};

export default Address;
