import get from 'lodash/get';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import {
  BillingPeriod,
  isAmountLike,
  isHexaColor,
  isUrl,
  isUUID,
  formatDateTimeToLocal,
} from './common';

export type Normalizer<T = any> = (obj: any) => T;

/**
 * Helper Object to coerce data to expected types
 */
export class ReifiedDataWrapper {
  data: object;

  constructor(data: object) {
    this.data = data;
  }

  get = <T>(key: string, defaultValue?: T): T => {
    return (get(this.data, key) as T) || defaultValue;
  };

  map = <T>(key: string, normalize: Normalizer<T>): T => {
    const value = get(this.data, key);
    return value ? normalize(value) : undefined;
  };

  getString = (key: string, defaultValue?: string): string | undefined => {
    let value = get(this.data, key);
    if (!isString(value) && value !== defaultValue) {
      value = defaultValue;
    }
    return value;
  };

  getUuid = (key: string, defaultValue?: string): string | undefined => {
    const value = this.getString(key, defaultValue);
    return isUUID(value) ? value : defaultValue;
  };

  getUrl = (key: string, defaultValue?: string): string | undefined => {
    const value = this.getString(key, defaultValue);
    return isUrl(value) ? value : defaultValue;
  };

  getHexColor = (key: string, defaultValue?: string): string | undefined => {
    const value = this.getString(key, defaultValue);
    return isHexaColor(value) ? value : defaultValue;
  };

  getStringOrNull = (key: string): string | null => this.getString(key) || null;

  getNullableNumber = (key: string): number | null => {
    let value = get(this.data, key);
    if (!isNumber(value)) {
      value = null;
    }
    return value;
  };

  getNumber = (key: string, defaultValue?: number): number => {
    let value = get(this.data, key);
    if (!isNumber(value) && value !== defaultValue) {
      value = defaultValue;
    }
    return value;
  };

  getInt = (key: string, defaultValue: number = 0): number => {
    return this.getNumber(key, defaultValue);
  };

  getAmount = (key: string) => {
    if (!isAmountLike(this.get(key))) {
      return;
    }

    return {
      amount: this.getNumber(`${key}.amount`),
      currency: this.getString(`${key}.currency`),
    };
  };

  getDate = (key: string): Date => {
    const value: any = get(this.data, key);
    return ['number', 'string'].includes(typeof value) ? new Date(value) : null;
  };

  getLocalDateFromUTC = (key: string): string => {
    const value: string = this.getString(key);
    return formatDateTimeToLocal(value).format('YYYY-MM-DD');
  };

  getLocalTimeFromUTC = (key: string): string => {
    const value: string = this.getString(key);
    return formatDateTimeToLocal(value).format('HH:mm');
  };

  getArray = <T>(
    key: string,
    normalizer?: Normalizer<T>,
    defaultValue: T[] = [],
  ): T[] | Array<ReturnType<typeof normalizer>> => {
    let array = get(this.data, key);
    if (!Array.isArray(array) && !isEqual(array, defaultValue)) {
      array = defaultValue;
    }
    return normalizer ? array.map(normalizer) : array;
  };

  mapEnum(ref: any, key: string, defaultValue?: any) {
    const value = this.getString(key);

    if (ref && typeof ref === 'object') {
      for (let constantName of Object.keys(ref)) {
        if (
          typeof constantName === 'string' &&
          typeof ref[constantName] === 'string' &&
          ref[constantName] === value
        ) {
          return ref[constantName];
        }
      }
    }

    return defaultValue;
  }

  getBoolean(key: string, defaultValue: boolean = false): boolean {
    let value = get(this.data, key);

    if (typeof value !== 'boolean' && value !== defaultValue) {
      value = defaultValue;
    }

    return value;
  }

  getBillingPeriod(key: string): BillingPeriod {
    let value = get(this.data, key);

    if (!value || typeof value !== 'string') {
      throw new Error('Invalid Billing Period Key');
    }

    const [start, end] = value.split(':');

    return {
      start,
      end,
      key: value,
    };
  }
}

/**
 * Wraps given data object into a reified data wrapper
 * @param {*} data
 */
export const reify = (data: object) => {
  return new ReifiedDataWrapper(data || {});
};
