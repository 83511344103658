import { useEffect, useState } from 'react';
import { searchPlaces } from '@neo1/core/utils/maps';
import useAsyncState from 'hooks/asyncState';
import useCountryCenterOrViewerLocation from './useCountryCenterOrViewerLocation';

export type Option = {
  label: string;
  placeId: string;
};

type Props = {
  setAddress: React.Dispatch<React.SetStateAction<string>>;
  searchPlacesError: any;
  placesSuggestions: Option[];
  clearSuggestions: () => void;
};

const useAddressSuggestions = (country: string): Props => {
  const [address, setAddress] = useState<string>('');
  const [prevAddress, setPrevAddress] = useState<string>('');
  const [placesSuggestions, setSuggestions] = useState<Option[]>([]);
  const clearSuggestions = () => {
    // discard pending suggestions.
    setSuggestions([]);
  };
  const location = useCountryCenterOrViewerLocation(country);

  const {
    execute: executeSearchPlaces,
    data: placesPredictions,
    error: searchPlacesError,
  } = useAsyncState(searchPlaces);

  useEffect(() => {
    if (placesPredictions) {
      setSuggestions(
        placesPredictions.map(({ description, place_id }) => ({
          placeId: place_id,
          label: description,
        })),
      );
    }
  }, [placesPredictions]);

  useEffect(() => {
    if (address !== prevAddress) {
      executeSearchPlaces(address, location);
      setPrevAddress(address);
    }
  }, [address, location, prevAddress, setPrevAddress, executeSearchPlaces]);

  return {
    setAddress,
    searchPlacesError,
    placesSuggestions,
    clearSuggestions,
  };
};

export default useAddressSuggestions;
