import { useSelector } from 'react-redux';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { selectCountersByScope } from 'redux/counter/selector';
import ScopeType from 'redux/scopeType';
import { Counters } from 'redux/counter/types';
import TabTitleWithBadge from 'components/layout/Tabs/TabTitleWithBadge';
import {
  ROUTE_NAME_AUTHORIZE_BUDGET,
  ROUTE_NAME_AUTHORIZE_PURCHASE,
  ROUTE_NAME_AUTHORIZE_SPEND,
} from '../constants';

type Props = {
  name: string;
  title: MessageDescriptor;
};

/**
 * Maps a tab name to a count
 * this part each time we change the wording of a tab
 * @param tabName
 * @param counts
 */
const mapTabNameToCount = (tabName: string, counters: Counters) => {
  const { tx, budget, purchase } = counters;
  switch (tabName) {
    case ROUTE_NAME_AUTHORIZE_BUDGET:
      return budget.toApprove.count;

    case ROUTE_NAME_AUTHORIZE_PURCHASE:
      return purchase.toApprove.count;

    case ROUTE_NAME_AUTHORIZE_SPEND:
      return tx.toApprove.count + tx.toReview.count;

    default:
      return 0;
  }
};

const Tabtitle = ({ name, title }: Props) => {
  const counts = useSelector(selectCountersByScope(ScopeType.Supervisor));
  const badgeCount = counts ? mapTabNameToCount(name, counts) : 0;
  return (
    <FormattedMessage id={title.id} defaultMessage={title.defaultMessage}>
      {(label) => (
        <TabTitleWithBadge title={label.toString()} count={badgeCount} />
      )}
    </FormattedMessage>
  );
};

export default Tabtitle;
