import { Action, Actions, SearchViewState } from './types';

export const initialState: SearchViewState = {
  searchText: '',
  usersIds: null,
  companiesIds: null,
  managedDomains: [],
};

export default (
  state: SearchViewState = initialState,
  action: Action = null,
): SearchViewState => {
  switch (action?.type) {
    case Actions.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
      };
    case Actions.SET_COMPANIES:
      return { ...state, companiesIds: action.ids };
    case Actions.SET_MANAGED_DOMAINS:
      return { ...state, managedDomains: action.ids };
    case Actions.SET_USERS:
      return { ...state, usersIds: action.ids };

    case Actions.REINIT:
      return {
        ...initialState,
        managedDomains: state.managedDomains,
      };

    default:
      return state;
  }
};
