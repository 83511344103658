import { pseudoGuid } from '@neo1/core/utils/guid';
import { UNIQ_NOTIFICATION, ToasterProps, Toaster } from './types';

const newNotification = (data: ToasterProps): Toaster => ({
  ...data,
  uuid: data.pushOnce ? UNIQ_NOTIFICATION : pseudoGuid(),
  created: new Date(),
  visible: data.visible || true,
  progress: 0,
});
export default newNotification;
