import { InputHTMLAttributes, ReactNode } from 'react';
import CheckBoxComp from 'components/elements/Checkbox';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';

interface CheckBoxProps
  extends Omit<InputHTMLAttributes<HTMLLabelElement>, 'onChange'>,
    Omit<FieldWrapperProps, 'children' | 'noBorder'> {
  onChange?: (checked: boolean) => void;
  label?: ReactNode;
  checked?: boolean;
  partiallyChecked?: boolean;
  isInvalid?: boolean;
  testId?: string;
}

const CheckBox = ({
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  isInvalid,
  isRequired,
  isSuccess,
  label,
  value,
  checked,
  partiallyChecked,
  disabled,
  readOnly,
  onClick,
  onChange,
  testId,
}: CheckBoxProps) => (
  <FieldWrapper
    id={id}
    name={name}
    title={title}
    info={info}
    errorText={errorText}
    successText={successText}
    helperText={helperText}
    keepBottomSpace={keepBottomSpace}
    isInvalid={isInvalid}
    disabled={disabled}
    isRequired={isRequired}
    isSuccess={isSuccess}
    readOnly={readOnly}
    readOnlyValue="TODO"
    noBorder
  >
    <CheckBoxComp
      id={id}
      value={value}
      checked={!!(value || checked)}
      partiallyChecked={partiallyChecked}
      disabled={disabled}
      isInvalid={isInvalid}
      onClick={onClick}
      onChange={onChange}
      testId={testId}
    >
      {label}
    </CheckBoxComp>
  </FieldWrapper>
);

CheckBox.defaultProps = {
  onChange: undefined,
  label: undefined,
  checked: false,
  partiallyChecked: false,
  isInvalid: false,
  testId: undefined,
};

export default CheckBox;
