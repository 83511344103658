import uniq from 'lodash/uniq';
import { Actions, ExpenseActions } from './actions';
import { ExpenseTypesState } from './types';

const initialState: ExpenseTypesState = {
  ids: [],
};

const expenseTypesReducer = (
  state: ExpenseTypesState = initialState,
  action: ExpenseActions = null,
): ExpenseTypesState => {
  switch (action?.type) {
    case Actions.SET_EXPENSE_TYPES:
      return {
        ...state,
        ids: action.ids,
      };

    case Actions.SET_EXPENSE_TYPE:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    default:
      return state;
  }
};
export default expenseTypesReducer;
