import { ReactNode } from 'react';
import classNames from 'classnames';
import FieldTooltip from 'components/elements/form/FieldTooltip';
import styles from './Label.module.css';

export type Props = {
  title?: ReactNode;
  className?: string;
  htmlFor?: string;
  isRequired?: boolean;
  info?: ReactNode;
};

const FormLabel = ({ title, className, htmlFor, isRequired, info }: Props) => (
  <label
    htmlFor={htmlFor}
    className={classNames(styles.container, className, 'textMSemibold')}
  >
    <span>
      {title}
      {!isRequired && (
        <span className={classNames(styles.optional, 'textM')}>(optional)</span>
      )}
    </span>
    {info && (
      <FieldTooltip
        className={styles.infoTooltip}
        message={info}
        testId={`${htmlFor}Tooltip`}
      />
    )}
  </label>
);

FormLabel.defaultProps = {
  isRequired: true,
  title: null,
  className: undefined,
  htmlFor: undefined,
  info: undefined,
};

export default FormLabel;
