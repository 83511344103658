import { AppCoreState } from 'redux/types';

import { selectEntitiesByIds } from 'redux/entities/selector';
import { Tax } from '@neo1/client/lib/entities/tax/types';
import { TAX_ENTITY_NAME } from '@neo1/client/lib/entities/tax/constants';

export const selectCompanyTaxesIds = (state: AppCoreState) =>
  state.settings.tax.ids;

export const selectTaxes = (state: AppCoreState) =>
  selectEntitiesByIds<Tax>(
    TAX_ENTITY_NAME,
    selectCompanyTaxesIds(state),
  )(state);

export const selectFilters = (state: AppCoreState) =>
  state.settings.tax.filters;

export const selectTaxConfig = (state: AppCoreState) =>
  state.settings.tax.config;
