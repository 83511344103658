const companyIndustries = [
  {
    Code: '1110',
    Description:
      'Growing of cereals (except rice), leguminous crops and oil seeds',
    Industry: 'agriculture',
  },
  { Code: '1120', Description: 'Growing of rice', Industry: 'agriculture' },
  {
    Code: '1130',
    Description: 'Growing of vegetables and melons, roots and tubers',
    Industry: 'agriculture',
  },
  {
    Code: '1140',
    Description: 'Growing of sugar cane',
    Industry: 'agriculture',
  },
  { Code: '1150', Description: 'Growing of tobacco', Industry: 'agriculture' },
  {
    Code: '1160',
    Description: 'Growing of fibre crops',
    Industry: 'agriculture',
  },
  {
    Code: '1190',
    Description: 'Growing of other non-perennial crops',
    Industry: 'agriculture',
  },
  { Code: '1210', Description: 'Growing of grapes', Industry: 'agriculture' },
  {
    Code: '1220',
    Description: 'Growing of tropical and subtropical fruits',
    Industry: 'agriculture',
  },
  {
    Code: '1230',
    Description: 'Growing of citrus fruits',
    Industry: 'agriculture',
  },
  {
    Code: '1240',
    Description: 'Growing of pome fruits and stone fruits',
    Industry: 'agriculture',
  },
  {
    Code: '1250',
    Description: 'Growing of other tree and bush fruits and nuts',
    Industry: 'agriculture',
  },
  {
    Code: '1260',
    Description: 'Growing of oleaginous fruits',
    Industry: 'agriculture',
  },
  {
    Code: '1270',
    Description: 'Growing of beverage crops',
    Industry: 'agriculture',
  },
  {
    Code: '1280',
    Description: 'Growing of spices, aromatic, drug and pharmaceutical crops',
    Industry: 'agriculture',
  },
  {
    Code: '1290',
    Description: 'Growing of other perennial crops',
    Industry: 'agriculture',
  },
  { Code: '1300', Description: 'Plant propagation', Industry: 'agriculture' },
  {
    Code: '1410',
    Description: 'Raising of dairy cattle',
    Industry: 'agriculture',
  },
  {
    Code: '1420',
    Description: 'Raising of other cattle and buffaloes',
    Industry: 'agriculture',
  },
  {
    Code: '1430',
    Description: 'Raising of horses and other equines',
    Industry: 'agriculture',
  },
  {
    Code: '1440',
    Description: 'Raising of camels and camelids',
    Industry: 'agriculture',
  },
  {
    Code: '1450',
    Description: 'Raising of sheep and goats',
    Industry: 'agriculture',
  },
  {
    Code: '1460',
    Description: 'Raising of swine/pigs',
    Industry: 'agriculture',
  },
  { Code: '1470', Description: 'Raising of poultry', Industry: 'agriculture' },
  {
    Code: '1490',
    Description: 'Raising of other animals',
    Industry: 'agriculture',
  },
  { Code: '1500', Description: 'Mixed farming', Industry: 'agriculture' },
  {
    Code: '1610',
    Description: 'Support activities for crop production',
    Industry: 'agriculture',
  },
  {
    Code: '1621',
    Description: 'Farm animal boarding and care',
    Industry: 'agriculture',
  },
  {
    Code: '1629',
    Description:
      'Support activities for animal production (other than farm animal boarding and care) n.e.c.',
    Industry: 'agriculture',
  },
  {
    Code: '1630',
    Description: 'Post-harvest crop activities',
    Industry: 'agriculture',
  },
  {
    Code: '1640',
    Description: 'Seed processing for propagation',
    Industry: 'agriculture',
  },
  {
    Code: '1700',
    Description: 'Hunting, trapping and related service activities',
    Industry: 'agriculture',
  },
  {
    Code: '2100',
    Description: 'Silviculture and other forestry activities',
    Industry: 'agriculture',
  },
  { Code: '2200', Description: 'Logging', Industry: 'agriculture' },
  {
    Code: '2300',
    Description: 'Gathering of wild growing non-wood products',
    Industry: 'agriculture',
  },
  {
    Code: '2400',
    Description: 'Support services to forestry',
    Industry: 'agriculture',
  },
  { Code: '3110', Description: 'Marine fishing', Industry: 'agriculture' },
  { Code: '3120', Description: 'Freshwater fishing', Industry: 'agriculture' },
  { Code: '3210', Description: 'Marine aquaculture', Industry: 'agriculture' },
  {
    Code: '3220',
    Description: 'Freshwater aquaculture',
    Industry: 'agriculture',
  },
  {
    Code: '5101',
    Description: 'Deep coal mines',
    Industry: 'metals_and_mining',
  },
  {
    Code: '5102',
    Description: 'Open cast coal working',
    Industry: 'metals_and_mining',
  },
  {
    Code: '5200',
    Description: 'Mining of lignite',
    Industry: 'metals_and_mining',
  },
  {
    Code: '6100',
    Description: 'Extraction of crude petroleum',
    Industry: 'metals_and_mining',
  },
  {
    Code: '6200',
    Description: 'Extraction of natural gas',
    Industry: 'metals_and_mining',
  },
  {
    Code: '7100',
    Description: 'Mining of iron ores',
    Industry: 'metals_and_mining',
  },
  {
    Code: '7210',
    Description: 'Mining of uranium and thorium ores',
    Industry: 'metals_and_mining',
  },
  {
    Code: '7290',
    Description: 'Mining of other non-ferrous metal ores',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8110',
    Description:
      'Quarrying of ornamental and building stone, limestone, gypsum, chalk and slate',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8120',
    Description:
      'Operation of gravel and sand pits; mining of clays and kaolin',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8910',
    Description: 'Mining of chemical and fertilizer minerals',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8920',
    Description: 'Extraction of peat',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8930',
    Description: 'Extraction of salt',
    Industry: 'metals_and_mining',
  },
  {
    Code: '8990',
    Description: 'Other mining and quarrying n.e.c.',
    Industry: 'metals_and_mining',
  },
  {
    Code: '9100',
    Description: 'Support activities for petroleum and natural gas mining',
    Industry: 'metals_and_mining',
  },
  {
    Code: '9900',
    Description: 'Support activities for other mining and quarrying',
    Industry: 'metals_and_mining',
  },
  {
    Code: '10110',
    Description: 'Processing and preserving of meat',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10120',
    Description: 'Processing and preserving of poultry meat',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10130',
    Description: 'Production of meat and poultry meat products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10200',
    Description: 'Processing and preserving of fish, crustaceans and molluscs',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10310',
    Description: 'Processing and preserving of potatoes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10320',
    Description: 'Manufacture of fruit and vegetable juice',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10390',
    Description: 'Other processing and preserving of fruit and vegetables',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10410',
    Description: 'Manufacture of oils and fats',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10420',
    Description: 'Manufacture of margarine and similar edible fats',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10511',
    Description: 'Liquid milk and cream production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10512',
    Description: 'Butter and cheese production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10519',
    Description: 'Manufacture of other milk products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10520',
    Description: 'Manufacture of ice cream',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10611',
    Description: 'Grain milling',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10612',
    Description: 'Manufacture of breakfast cereals and cereals-based food',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10620',
    Description: 'Manufacture of starches and starch products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10710',
    Description:
      'Manufacture of bread; manufacture of fresh pastry goods and cakes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10720',
    Description:
      'Manufacture of rusks and biscuits; manufacture of preserved pastry goods and cakes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10730',
    Description:
      'Manufacture of macaroni, noodles, couscous and similar farinaceous products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10810',
    Description: 'Manufacture of sugar',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10821',
    Description: 'Manufacture of cocoa and chocolate confectionery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10822',
    Description: 'Manufacture of sugar confectionery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10831',
    Description: 'Tea processing',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10832',
    Description: 'Production of coffee and coffee substitutes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10840',
    Description: 'Manufacture of condiments and seasonings',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10850',
    Description: 'Manufacture of prepared meals and dishes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10860',
    Description:
      'Manufacture of homogenized food preparations and dietetic food',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10890',
    Description: 'Manufacture of other food products n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10910',
    Description: 'Manufacture of prepared feeds for farm animals',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '10920',
    Description: 'Manufacture of prepared pet foods',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11010',
    Description: 'Distilling, rectifying and blending of spirits',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11020',
    Description: 'Manufacture of wine from grape',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11030',
    Description: 'Manufacture of cider and other fruit wines',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11040',
    Description: 'Manufacture of other non-distilled fermented beverages',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11050',
    Description: 'Manufacture of beer',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11060',
    Description: 'Manufacture of malt',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '11070',
    Description:
      'Manufacture of soft drinks; production of mineral waters and other bottled waters',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '12000',
    Description: 'Manufacture of tobacco products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13100',
    Description: 'Preparation and spinning of textile fibres',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13200',
    Description: 'Weaving of textiles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13300',
    Description: 'Finishing of textiles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13910',
    Description: 'Manufacture of knitted and crocheted fabrics',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13921',
    Description: 'Manufacture of soft furnishings',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13922',
    Description: 'manufacture of canvas goods, sacks, etc.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13923',
    Description: 'manufacture of household textiles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13931',
    Description: 'Manufacture of woven or tufted carpets and rugs',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13939',
    Description: 'Manufacture of other carpets and rugs',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13940',
    Description: 'Manufacture of cordage, rope, twine and netting',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13950',
    Description:
      'Manufacture of non-wovens and articles made from non-wovens, except apparel',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13960',
    Description: 'Manufacture of other technical and industrial textiles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '13990',
    Description: 'Manufacture of other textiles n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14110',
    Description: 'Manufacture of leather clothes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14120',
    Description: 'Manufacture of workwear',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14131',
    Description: "Manufacture of other men's outerwear",
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14132',
    Description: "Manufacture of other women's outerwear",
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14141',
    Description: "Manufacture of men's underwear",
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14142',
    Description: "Manufacture of women's underwear",
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14190',
    Description: 'Manufacture of other wearing apparel and accessories n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14200',
    Description: 'Manufacture of articles of fur',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14310',
    Description: 'Manufacture of knitted and crocheted hosiery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '14390',
    Description: 'Manufacture of other knitted and crocheted apparel',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '15110',
    Description: 'Tanning and dressing of leather; dressing and dyeing of fur',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '15120',
    Description:
      'Manufacture of luggage, handbags and the like, saddlery and harness',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '15200',
    Description: 'Manufacture of footwear',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16100',
    Description: 'Sawmilling and planing of wood',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16210',
    Description: 'Manufacture of veneer sheets and wood-based panels',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16220',
    Description: 'Manufacture of assembled parquet floors',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16230',
    Description: "Manufacture of other builders' carpentry and joinery",
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16240',
    Description: 'Manufacture of wooden containers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '16290',
    Description:
      'Manufacture of other products of wood; manufacture of articles of cork, straw and plaiting materials',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17110',
    Description: 'Manufacture of pulp',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17120',
    Description: 'Manufacture of paper and paperboard',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17211',
    Description:
      'Manufacture of corrugated paper and paperboard, sacks and bags',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17219',
    Description: 'Manufacture of other paper and paperboard containers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17220',
    Description:
      'Manufacture of household and sanitary goods and of toilet requisites',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17230',
    Description: 'Manufacture of paper stationery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17240',
    Description: 'Manufacture of wallpaper',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '17290',
    Description: 'Manufacture of other articles of paper and paperboard n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18110',
    Description: 'Printing of newspapers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18121',
    Description: 'Manufacture of printed labels',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18129',
    Description: 'Printing n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18130',
    Description: 'Pre-press and pre-media services',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18140',
    Description: 'Binding and related services',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18201',
    Description: 'Reproduction of sound recording',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18202',
    Description: 'Reproduction of video recording',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '18203',
    Description: 'Reproduction of computer media',
    Industry: 'computer_services',
  },
  {
    Code: '19100',
    Description: 'Manufacture of coke oven products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '19201',
    Description: 'Mineral oil refining',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '19209',
    Description:
      'Other treatment of petroleum products (excluding petrochemicals manufacture)',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20110',
    Description: 'Manufacture of industrial gases',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20120',
    Description: 'Manufacture of dyes and pigments',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20130',
    Description: 'Manufacture of other inorganic basic chemicals',
    Industry: 'chemicals',
  },
  {
    Code: '20140',
    Description: 'Manufacture of other organic basic chemicals',
    Industry: 'chemicals',
  },
  {
    Code: '20150',
    Description: 'Manufacture of fertilizers and nitrogen compounds',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20160',
    Description: 'Manufacture of plastics in primary forms',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20170',
    Description: 'Manufacture of synthetic rubber in primary forms',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20200',
    Description: 'Manufacture of pesticides and other agrochemical products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20301',
    Description:
      'Manufacture of paints, varnishes and similar coatings, mastics and sealants',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20302',
    Description: 'Manufacture of printing ink',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20411',
    Description: 'Manufacture of soap and detergents',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20412',
    Description: 'Manufacture of cleaning and polishing preparations',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20420',
    Description: 'Manufacture of perfumes and toilet preparations',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20510',
    Description: 'Manufacture of explosives',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20520',
    Description: 'Manufacture of glues',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20530',
    Description: 'Manufacture of essential oils',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20590',
    Description: 'Manufacture of other chemical products n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '20600',
    Description: 'Manufacture of man-made fibres',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '21100',
    Description: 'Manufacture of basic pharmaceutical products',
    Industry: 'pharmaceuticals',
  },
  {
    Code: '21200',
    Description: 'Manufacture of pharmaceutical preparations',
    Industry: 'pharmaceuticals',
  },
  {
    Code: '22110',
    Description:
      'Manufacture of rubber tyres and tubes; retreading and rebuilding of rubber tyres',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '22190',
    Description: 'Manufacture of other rubber products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '22210',
    Description: 'Manufacture of plastic plates, sheets, tubes and profiles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '22220',
    Description: 'Manufacture of plastic packing goods',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '22230',
    Description: 'Manufacture of builders ware of plastic',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '22290',
    Description: 'Manufacture of other plastic products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23110',
    Description: 'Manufacture of flat glass',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23120',
    Description: 'Shaping and processing of flat glass',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23130',
    Description: 'Manufacture of hollow glass',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23140',
    Description: 'Manufacture of glass fibres',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23190',
    Description:
      'Manufacture and processing of other glass, including technical glassware',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23200',
    Description: 'Manufacture of refractory products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23310',
    Description: 'Manufacture of ceramic tiles and flags',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23320',
    Description:
      'Manufacture of bricks, tiles and construction products, in baked clay',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23410',
    Description: 'Manufacture of ceramic household and ornamental articles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23420',
    Description: 'Manufacture of ceramic sanitary fixtures',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23430',
    Description: 'Manufacture of ceramic insulators and insulating fittings',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23440',
    Description: 'Manufacture of other technical ceramic products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23490',
    Description: 'Manufacture of other ceramic products n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23510',
    Description: 'Manufacture of cement',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23520',
    Description: 'Manufacture of lime and plaster',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23610',
    Description: 'Manufacture of concrete products for construction purposes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23620',
    Description: 'Manufacture of plaster products for construction purposes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23630',
    Description: 'Manufacture of ready-mixed concrete',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23640',
    Description: 'Manufacture of mortars',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23650',
    Description: 'Manufacture of fibre cement',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23690',
    Description:
      'Manufacture of other articles of concrete, plaster and cement',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23700',
    Description: 'Cutting, shaping and finishing of stone',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23910',
    Description: 'Production of abrasive products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '23990',
    Description: 'Manufacture of other non-metallic mineral products n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24100',
    Description: 'Manufacture of basic iron and steel and of ferro-alloys',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24200',
    Description:
      'Manufacture of tubes, pipes, hollow profiles and related fittings, of steel',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24310',
    Description: 'Cold drawing of bars',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24320',
    Description: 'Cold rolling of narrow strip',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24330',
    Description: 'Cold forming or folding',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24340',
    Description: 'Cold drawing of wire',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24410',
    Description: 'Precious metals production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24420',
    Description: 'Aluminium production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24430',
    Description: 'Lead, zinc and tin production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24440',
    Description: 'Copper production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24450',
    Description: 'Other non-ferrous metal production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24460',
    Description: 'Processing of nuclear fuel',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24510',
    Description: 'Casting of iron',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24520',
    Description: 'Casting of steel',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24530',
    Description: 'Casting of light metals',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '24540',
    Description: 'Casting of other non-ferrous metals',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25110',
    Description: 'Manufacture of metal structures and parts of structures',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25120',
    Description: 'Manufacture of doors and windows of metal',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25210',
    Description: 'Manufacture of central heating radiators and boilers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25290',
    Description:
      'Manufacture of other tanks, reservoirs and containers of metal',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25300',
    Description:
      'Manufacture of steam generators, except central heating hot water boilers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25400',
    Description: 'Manufacture of weapons and ammunition',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25500',
    Description:
      'Forging, pressing, stamping and roll-forming of metal; powder metallurgy',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25610',
    Description: 'Treatment and coating of metals',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25620',
    Description: 'Machining',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25710',
    Description: 'Manufacture of cutlery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25720',
    Description: 'Manufacture of locks and hinges',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25730',
    Description: 'Manufacture of tools',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25910',
    Description: 'Manufacture of steel drums and similar containers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25920',
    Description: 'Manufacture of light metal packaging',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25930',
    Description: 'Manufacture of wire products, chain and springs',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25940',
    Description: 'Manufacture of fasteners and screw machine products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '25990',
    Description: 'Manufacture of other fabricated metal products n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26110',
    Description: 'Manufacture of electronic components',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26120',
    Description: 'Manufacture of loaded electronic boards',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26200',
    Description: 'Manufacture of computers and peripheral equipment',
    Industry: 'computer_hardware',
  },
  {
    Code: '26301',
    Description:
      'Manufacture of telegraph and telephone apparatus and equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26309',
    Description:
      'Manufacture of communication equipment other than telegraph, and telephone apparatus and equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26400',
    Description: 'Manufacture of consumer electronics',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26511',
    Description:
      'Manufacture of electronic measuring, testing etc. equipment, not for industrial process control',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26512',
    Description:
      'Manufacture of electronic industrial process control equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26513',
    Description:
      'Manufacture of non-electronic measuring, testing etc. equipment, not for industrial process control',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26514',
    Description:
      'Manufacture of non-electronic industrial process control equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26520',
    Description: 'Manufacture of watches and clocks',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26600',
    Description:
      'Manufacture of irradiation, electromedical and electrotherapeutic equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26701',
    Description: 'Manufacture of optical precision instruments',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26702',
    Description: 'Manufacture of photographic and cinematographic equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '26800',
    Description: 'Manufacture of magnetic and optical media',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27110',
    Description: 'Manufacture of electric motors, generators and transformers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27120',
    Description:
      'Manufacture of electricity distribution and control apparatus',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27200',
    Description: 'Manufacture of batteries and accumulators',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27310',
    Description: 'Manufacture of fibre optic cables',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27320',
    Description:
      'Manufacture of other electronic and electric wires and cables',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27330',
    Description: 'Manufacture of wiring devices',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27400',
    Description: 'Manufacture of electric lighting equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27510',
    Description: 'Manufacture of electric domestic appliances',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27520',
    Description: 'Manufacture of non-electric domestic appliances',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '27900',
    Description: 'Manufacture of other electrical equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28110',
    Description:
      'Manufacture of engines and turbines, except aircraft, vehicle and cycle engines',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28120',
    Description: 'Manufacture of fluid power equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28131',
    Description: 'Manufacture of pumps',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28132',
    Description: 'Manufacture of compressors',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28140',
    Description: 'Manufacture of taps and valves',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28150',
    Description: 'Manufacture of bearings, gears, gearing and driving elements',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28210',
    Description: 'Manufacture of ovens, furnaces and furnace burners',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28220',
    Description: 'Manufacture of lifting and handling equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28230',
    Description:
      'Manufacture of office machinery and equipment (except computers and peripheral equipment)',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28240',
    Description: 'Manufacture of power-driven hand tools',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28250',
    Description:
      'Manufacture of non-domestic cooling and ventilation equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28290',
    Description: 'Manufacture of other general-purpose machinery n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28301',
    Description: 'Manufacture of agricultural tractors',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28302',
    Description:
      'Manufacture of agricultural and forestry machinery other than tractors',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28410',
    Description: 'Manufacture of metal forming machinery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28490',
    Description: 'Manufacture of other machine tools',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28910',
    Description: 'Manufacture of machinery for metallurgy',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28921',
    Description: 'Manufacture of machinery for mining',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28922',
    Description: 'Manufacture of earthmoving equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28923',
    Description:
      'Manufacture of equipment for concrete crushing and screening and roadworks',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28930',
    Description:
      'Manufacture of machinery for food, beverage and tobacco processing',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28940',
    Description:
      'Manufacture of machinery for textile, apparel and leather production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28950',
    Description: 'Manufacture of machinery for paper and paperboard production',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28960',
    Description: 'Manufacture of plastics and rubber machinery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '28990',
    Description: 'Manufacture of other special-purpose machinery n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29100',
    Description: 'Manufacture of motor vehicles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29201',
    Description:
      'Manufacture of bodies (coachwork) for motor vehicles (except caravans)',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29202',
    Description: 'Manufacture of trailers and semi-trailers',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29203',
    Description: 'Manufacture of caravans',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29310',
    Description:
      'Manufacture of electrical and electronic equipment for motor vehicles and their engines',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '29320',
    Description:
      'Manufacture of other parts and accessories for motor vehicles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30110',
    Description: 'Building of ships and floating structures',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30120',
    Description: 'Building of pleasure and sporting boats',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30200',
    Description: 'Manufacture of railway locomotives and rolling stock',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30300',
    Description: 'Manufacture of air and spacecraft and related machinery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30400',
    Description: 'Manufacture of military fighting vehicles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30910',
    Description: 'Manufacture of motorcycles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30920',
    Description: 'Manufacture of bicycles and invalid carriages',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '30990',
    Description: 'Manufacture of other transport equipment n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '31010',
    Description: 'Manufacture of office and shop furniture',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '31020',
    Description: 'Manufacture of kitchen furniture',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '31030',
    Description: 'Manufacture of mattresses',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '31090',
    Description: 'Manufacture of other furniture',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32110',
    Description: 'Striking of coins',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32120',
    Description: 'Manufacture of jewellery and related articles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32130',
    Description: 'Manufacture of imitation jewellery and related articles',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32200',
    Description: 'Manufacture of musical instruments',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32300',
    Description: 'Manufacture of sports goods',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32401',
    Description: 'Manufacture of professional and arcade games and toys',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32409',
    Description: 'Manufacture of other games and toys, n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32500',
    Description: 'Manufacture of medical and dental instruments and supplies',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32910',
    Description: 'Manufacture of brooms and brushes',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '32990',
    Description: 'Other manufacturing n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33110',
    Description: 'Repair of fabricated metal products',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33120',
    Description: 'Repair of machinery',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33130',
    Description: 'Repair of electronic and optical equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33140',
    Description: 'Repair of electrical equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33150',
    Description: 'Repair and maintenance of ships and boats',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33160',
    Description: 'Repair and maintenance of aircraft and spacecraft',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33170',
    Description: 'Repair and maintenance of other transport equipment n.e.c.',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33190',
    Description: 'Repair of other equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '33200',
    Description: 'Installation of industrial machinery and equipment',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '35110',
    Description: 'Production of electricity',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35120',
    Description: 'Transmission of electricity',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35130',
    Description: 'Distribution of electricity',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35140',
    Description: 'Trade of electricity',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35210',
    Description: 'Manufacture of gas',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35220',
    Description: 'Distribution of gaseous fuels through mains',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35230',
    Description: 'Trade of gas through mains',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '35300',
    Description: 'Steam and air conditioning supply',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '36000',
    Description: 'Water collection, treatment and supply',
    Industry: 'energy_and_utilities',
  },
  { Code: '37000', Description: 'Sewerage', Industry: 'energy_and_utilities' },
  {
    Code: '38110',
    Description: 'Collection of non-hazardous waste',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '38120',
    Description: 'Collection of hazardous waste',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '38210',
    Description: 'Treatment and disposal of non-hazardous waste',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '38220',
    Description: 'Treatment and disposal of hazardous waste',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '38310',
    Description: 'Dismantling of wrecks',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '38320',
    Description: 'Recovery of sorted materials',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '39000',
    Description: 'Remediation activities and other waste management services',
    Industry: 'energy_and_utilities',
  },
  {
    Code: '41100',
    Description: 'Development of building projects',
    Industry: 'construction',
  },
  {
    Code: '41201',
    Description: 'construction of commercial buildings',
    Industry: 'construction',
  },
  {
    Code: '41202',
    Description: 'construction of domestic buildings',
    Industry: 'construction',
  },
  {
    Code: '42110',
    Description: 'construction of roads and motorways',
    Industry: 'construction',
  },
  {
    Code: '42120',
    Description: 'construction of railways and underground railways',
    Industry: 'construction',
  },
  {
    Code: '42130',
    Description: 'construction of bridges and tunnels',
    Industry: 'construction',
  },
  {
    Code: '42210',
    Description: 'construction of utility projects for fluids',
    Industry: 'construction',
  },
  {
    Code: '42220',
    Description:
      'construction of utility projects for electricity and telecommunications',
    Industry: 'construction',
  },
  {
    Code: '42910',
    Description: 'construction of water projects',
    Industry: 'construction',
  },
  {
    Code: '42990',
    Description: 'construction of other civil engineering projects n.e.c.',
    Industry: 'construction',
  },
  { Code: '43110', Description: 'Demolition', Industry: 'construction' },
  { Code: '43120', Description: 'Site preparation', Industry: 'construction' },
  {
    Code: '43130',
    Description: 'Test drilling and boring',
    Industry: 'construction',
  },
  {
    Code: '43210',
    Description: 'Electrical installation',
    Industry: 'construction',
  },
  {
    Code: '43220',
    Description: 'Plumbing, heat and air-conditioning installation',
    Industry: 'construction',
  },
  {
    Code: '43290',
    Description: 'Other construction installation',
    Industry: 'construction',
  },
  { Code: '43310', Description: 'Plastering', Industry: 'construction' },
  {
    Code: '43320',
    Description: 'Joinery installation',
    Industry: 'construction',
  },
  {
    Code: '43330',
    Description: 'Floor and wall covering',
    Industry: 'construction',
  },
  { Code: '43341', Description: 'Painting', Industry: 'construction' },
  { Code: '43342', Description: 'Glazing', Industry: 'construction' },
  {
    Code: '43390',
    Description: 'Other building completion and finishing',
    Industry: 'construction',
  },
  {
    Code: '43910',
    Description: 'Roofing activities',
    Industry: 'construction',
  },
  { Code: '43991', Description: 'Scaffold erection', Industry: 'construction' },
  {
    Code: '43999',
    Description: 'Other specialised construction activities n.e.c.',
    Industry: 'construction',
  },
  {
    Code: '45111',
    Description: 'Sale of new cars and light motor vehicles',
    Industry: 'retail',
  },
  {
    Code: '45112',
    Description: 'Sale of used cars and light motor vehicles',
    Industry: 'retail',
  },
  {
    Code: '45190',
    Description: 'Sale of other motor vehicles',
    Industry: 'retail',
  },
  {
    Code: '45200',
    Description: 'Maintenance and repair of motor vehicles',
    Industry: 'retail',
  },
  {
    Code: '45310',
    Description: 'Wholesale trade of motor vehicle parts and accessories',
    Industry: 'retail',
  },
  {
    Code: '45320',
    Description: 'retail trade of motor vehicle parts and accessories',
    Industry: 'retail',
  },
  {
    Code: '45400',
    Description:
      'Sale, maintenance and repair of motorcycles and related parts and accessories',
    Industry: 'retail',
  },
  {
    Code: '46110',
    Description:
      'Agents selling agricultural raw materials, livestock, textile raw materials and semi-finished goods',
    Industry: 'retail',
  },
  {
    Code: '46120',
    Description:
      'Agents involved in the sale of fuels, ores, metals and industrial chemicals',
    Industry: 'chemicals',
  },
  {
    Code: '46130',
    Description: 'Agents involved in the sale of timber and building materials',
    Industry: 'retail',
  },
  {
    Code: '46140',
    Description:
      'Agents involved in the sale of machinery, industrial equipment, ships and aircraft',
    Industry: 'retail',
  },
  {
    Code: '46150',
    Description:
      'Agents involved in the sale of furniture, household goods, hardware and ironmongery',
    Industry: 'retail',
  },
  {
    Code: '46160',
    Description:
      'Agents involved in the sale of textiles, clothing, fur, footwear and leather goods',
    Industry: 'retail',
  },
  {
    Code: '46170',
    Description: 'Agents involved in the sale of food, beverages and tobacco',
    Industry: 'retail',
  },
  {
    Code: '46180',
    Description: 'Agents specialised in the sale of other particular products',
    Industry: 'retail',
  },
  {
    Code: '46190',
    Description: 'Agents involved in the sale of a variety of goods',
    Industry: 'retail',
  },
  {
    Code: '46210',
    Description:
      'Wholesale of grain, unmanufactured tobacco, seeds and animal feeds',
    Industry: 'retail',
  },
  {
    Code: '46220',
    Description: 'Wholesale of flowers and plants',
    Industry: 'retail',
  },
  {
    Code: '46230',
    Description: 'Wholesale of live animals',
    Industry: 'retail',
  },
  {
    Code: '46240',
    Description: 'Wholesale of hides, skins and leather',
    Industry: 'retail',
  },
  {
    Code: '46310',
    Description: 'Wholesale of fruit and vegetables',
    Industry: 'retail',
  },
  {
    Code: '46320',
    Description: 'Wholesale of meat and meat products',
    Industry: 'retail',
  },
  {
    Code: '46330',
    Description: 'Wholesale of dairy products, eggs and edible oils and fats',
    Industry: 'retail',
  },
  {
    Code: '46341',
    Description:
      'Wholesale of fruit and vegetable juices, mineral water and soft drinks',
    Industry: 'retail',
  },
  {
    Code: '46342',
    Description:
      'Wholesale of wine, beer, spirits and other alcoholic beverages',
    Industry: 'retail',
  },
  {
    Code: '46350',
    Description: 'Wholesale of tobacco products',
    Industry: 'retail',
  },
  {
    Code: '46360',
    Description: 'Wholesale of sugar and chocolate and sugar confectionery',
    Industry: 'retail',
  },
  {
    Code: '46370',
    Description: 'Wholesale of coffee, tea, cocoa and spices',
    Industry: 'retail',
  },
  {
    Code: '46380',
    Description:
      'Wholesale of other food, including fish, crustaceans and molluscs',
    Industry: 'retail',
  },
  {
    Code: '46390',
    Description: 'Non-specialised wholesale of food, beverages and tobacco',
    Industry: 'retail',
  },
  { Code: '46410', Description: 'Wholesale of textiles', Industry: 'retail' },
  {
    Code: '46420',
    Description: 'Wholesale of clothing and footwear',
    Industry: 'retail',
  },
  {
    Code: '46431',
    Description:
      'Wholesale of audio tapes, records, CDs and video tapes and the equipment on which these are played',
    Industry: 'retail',
  },
  {
    Code: '46439',
    Description:
      "Wholesale of radio, television goods & electrical household appliances (other than records, tapes, CD's & video tapes and the equipment used for playing them)",
    Industry: 'retail',
  },
  {
    Code: '46440',
    Description: 'Wholesale of china and glassware and cleaning materials',
    Industry: 'retail',
  },
  {
    Code: '46450',
    Description: 'Wholesale of perfume and cosmetics',
    Industry: 'retail',
  },
  {
    Code: '46460',
    Description: 'Wholesale of pharmaceutical goods',
    Industry: 'pharmaceuticals',
  },
  {
    Code: '46470',
    Description: 'Wholesale of furniture, carpets and lighting equipment',
    Industry: 'retail',
  },
  {
    Code: '46480',
    Description: 'Wholesale of watches and jewellery',
    Industry: 'retail',
  },
  {
    Code: '46491',
    Description: 'Wholesale of musical instruments',
    Industry: 'retail',
  },
  {
    Code: '46499',
    Description:
      'Wholesale of household goods (other than musical instruments) n.e.c',
    Industry: 'retail',
  },
  {
    Code: '46510',
    Description:
      'Wholesale of computers, computer peripheral equipment and software',
    Industry: 'retail',
  },
  {
    Code: '46520',
    Description:
      'Wholesale of electronic and telecommunications equipment and parts',
    Industry: 'retail',
  },
  {
    Code: '46610',
    Description: 'Wholesale of agricultural machinery, equipment and supplies',
    Industry: 'retail',
  },
  {
    Code: '46620',
    Description: 'Wholesale of machine tools',
    Industry: 'retail',
  },
  {
    Code: '46630',
    Description:
      'Wholesale of mining, construction and civil engineering machinery',
    Industry: 'retail',
  },
  {
    Code: '46640',
    Description:
      'Wholesale of machinery for the textile industry and of sewing and knitting machines',
    Industry: 'retail',
  },
  {
    Code: '46650',
    Description: 'Wholesale of office furniture',
    Industry: 'retail',
  },
  {
    Code: '46660',
    Description: 'Wholesale of other office machinery and equipment',
    Industry: 'retail',
  },
  {
    Code: '46690',
    Description: 'Wholesale of other machinery and equipment',
    Industry: 'retail',
  },
  {
    Code: '46711',
    Description: 'Wholesale of petroleum and petroleum products',
    Industry: 'retail',
  },
  {
    Code: '46719',
    Description: 'Wholesale of other fuels and related products',
    Industry: 'retail',
  },
  {
    Code: '46720',
    Description: 'Wholesale of metals and metal ores',
    Industry: 'retail',
  },
  {
    Code: '46730',
    Description:
      'Wholesale of wood, construction materials and sanitary equipment',
    Industry: 'retail',
  },
  {
    Code: '46740',
    Description:
      'Wholesale of hardware, plumbing and heating equipment and supplies',
    Industry: 'retail',
  },
  {
    Code: '46750',
    Description: 'Wholesale of chemical products',
    Industry: 'retail',
  },
  {
    Code: '46760',
    Description: 'Wholesale of other intermediate products',
    Industry: 'retail',
  },
  {
    Code: '46770',
    Description: 'Wholesale of waste and scrap',
    Industry: 'retail',
  },
  {
    Code: '46900',
    Description: 'Non-specialised wholesale trade',
    Industry: 'retail',
  },
  {
    Code: '47110',
    Description:
      'retail sale in non-specialised stores with food, beverages or tobacco predominating',
    Industry: 'retail',
  },
  {
    Code: '47190',
    Description: 'Other retail sale in non-specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47210',
    Description: 'retail sale of fruit and vegetables in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47220',
    Description: 'retail sale of meat and meat products in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47230',
    Description:
      'retail sale of fish, crustaceans and molluscs in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47240',
    Description:
      'retail sale of bread, cakes, flour confectionery and sugar confectionery in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47250',
    Description: 'retail sale of beverages in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47260',
    Description: 'retail sale of tobacco products in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47290',
    Description: 'Other retail sale of food in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47300',
    Description: 'retail sale of automotive fuel in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47410',
    Description:
      'retail sale of computers, peripheral units and software in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47421',
    Description: 'retail sale of mobile telephones',
    Industry: 'retail',
  },
  {
    Code: '47429',
    Description:
      'retail sale of telecommunications equipment other than mobile telephones',
    Industry: 'retail',
  },
  {
    Code: '47430',
    Description:
      'retail sale of audio and video equipment in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47510',
    Description: 'retail sale of textiles in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47520',
    Description:
      'retail sale of hardware, paints and glass in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47530',
    Description:
      'retail sale of carpets, rugs, wall and floor coverings in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47540',
    Description:
      'retail sale of electrical household appliances in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47591',
    Description: 'retail sale of musical instruments and scores',
    Industry: 'retail',
  },
  {
    Code: '47599',
    Description:
      'retail of furniture, lighting, and similar (not musical instruments or scores) in specialised store',
    Industry: 'retail',
  },
  {
    Code: '47610',
    Description: 'retail sale of books in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47620',
    Description:
      'retail sale of newspapers and stationery in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47630',
    Description:
      'retail sale of music and video recordings in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47640',
    Description:
      'retail sale of sports goods, fishing gear, camping goods, boats and bicycles',
    Industry: 'retail',
  },
  {
    Code: '47650',
    Description: 'retail sale of games and toys in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47710',
    Description: 'retail sale of clothing in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47721',
    Description: 'retail sale of footwear in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47722',
    Description: 'retail sale of leather goods in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47730',
    Description: 'Dispensing chemist in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47741',
    Description: 'retail sale of hearing aids',
    Industry: 'retail',
  },
  {
    Code: '47749',
    Description:
      'retail sale of medical and orthopaedic goods in specialised stores (not incl. hearing aids) n.e.c.',
    Industry: 'retail',
  },
  {
    Code: '47750',
    Description:
      'retail sale of cosmetic and toilet articles in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47760',
    Description:
      'retail sale of flowers, plants, seeds, fertilizers, pet animals and pet food in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47770',
    Description: 'retail sale of watches and jewellery in specialised stores',
    Industry: 'retail',
  },
  {
    Code: '47781',
    Description: 'retail sale in commercial art galleries',
    Industry: 'retail',
  },
  {
    Code: '47782',
    Description: 'retail sale by opticians',
    Industry: 'retail',
  },
  {
    Code: '47789',
    Description:
      'Other retail sale of new goods in specialised stores (not commercial art galleries and opticians)',
    Industry: 'retail',
  },
  {
    Code: '47791',
    Description: 'retail sale of antiques including antique books in stores',
    Industry: 'retail',
  },
  {
    Code: '47799',
    Description:
      'retail sale of other second-hand goods in stores (not incl. antiques)',
    Industry: 'retail',
  },
  {
    Code: '47810',
    Description:
      'retail sale via stalls and markets of food, beverages and tobacco products',
    Industry: 'retail',
  },
  {
    Code: '47820',
    Description:
      'retail sale via stalls and markets of textiles, clothing and footwear',
    Industry: 'retail',
  },
  {
    Code: '47890',
    Description: 'retail sale via stalls and markets of other goods',
    Industry: 'retail',
  },
  {
    Code: '47910',
    Description: 'retail sale via mail order houses or via Internet',
    Industry: 'retail',
  },
  {
    Code: '47990',
    Description: 'Other retail sale not in stores, stalls or markets',
    Industry: 'retail',
  },
  {
    Code: '49100',
    Description: 'Passenger rail transport, interurban',
    Industry: 'transportation_services',
  },
  {
    Code: '49200',
    Description: 'Freight rail transport',
    Industry: 'transportation_services',
  },
  {
    Code: '49311',
    Description:
      'Urban and suburban passenger railway transportation by underground, metro and similar systems',
    Industry: 'transportation_services',
  },
  {
    Code: '49319',
    Description:
      'Other urban, suburban or metropolitan passenger land transport (not underground, metro or similar)',
    Industry: 'transportation_services',
  },
  {
    Code: '49320',
    Description: 'Taxi operation',
    Industry: 'transportation_services',
  },
  {
    Code: '49390',
    Description: 'Other passenger land transport',
    Industry: 'transportation_services',
  },
  {
    Code: '49410',
    Description: 'Freight transport by road',
    Industry: 'transportation_services',
  },
  {
    Code: '49420',
    Description: 'Removal services',
    Industry: 'transportation_services',
  },
  {
    Code: '49500',
    Description: 'Transport via pipeline',
    Industry: 'transportation_services',
  },
  {
    Code: '50100',
    Description: 'Sea and coastal passenger water transport',
    Industry: 'transportation_services',
  },
  {
    Code: '50200',
    Description: 'Sea and coastal freight water transport',
    Industry: 'transportation_services',
  },
  {
    Code: '50300',
    Description: 'Inland passenger water transport',
    Industry: 'transportation_services',
  },
  {
    Code: '50400',
    Description: 'Inland freight water transport',
    Industry: 'transportation_services',
  },
  {
    Code: '51101',
    Description: 'Scheduled passenger air transport',
    Industry: 'transportation_services',
  },
  {
    Code: '51102',
    Description: 'Non-scheduled passenger air transport',
    Industry: 'transportation_services',
  },
  {
    Code: '51210',
    Description: 'Freight air transport',
    Industry: 'transportation_services',
  },
  {
    Code: '51220',
    Description: 'Space transport',
    Industry: 'transportation_services',
  },
  {
    Code: '52101',
    Description:
      'Operation of warehousing and storage facilities for water transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52102',
    Description:
      'Operation of warehousing and storage facilities for air transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52103',
    Description:
      'Operation of warehousing and storage facilities for land transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52211',
    Description: 'Operation of rail freight terminals',
    Industry: 'transportation_services',
  },
  {
    Code: '52212',
    Description: 'Operation of rail passenger facilities at railway stations',
    Industry: 'transportation_services',
  },
  {
    Code: '52213',
    Description:
      'Operation of bus and coach passenger facilities at bus and coach stations',
    Industry: 'transportation_services',
  },
  {
    Code: '52219',
    Description:
      'Other service activities incidental to land transportation, n.e.c.',
    Industry: 'transportation_services',
  },
  {
    Code: '52220',
    Description: 'Service activities incidental to water transportation',
    Industry: 'transportation_services',
  },
  {
    Code: '52230',
    Description: 'Service activities incidental to air transportation',
    Industry: 'transportation_services',
  },
  {
    Code: '52241',
    Description: 'Cargo handling for water transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52242',
    Description: 'Cargo handling for air transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52243',
    Description: 'Cargo handling for land transport activities',
    Industry: 'transportation_services',
  },
  {
    Code: '52290',
    Description: 'Other transportation support activities',
    Industry: 'transportation_services',
  },
  {
    Code: '53100',
    Description: 'Postal activities under universal service obligation',
    Industry: 'transportation_services',
  },
  {
    Code: '53201',
    Description: 'Licensed carriers',
    Industry: 'transportation_services',
  },
  {
    Code: '53202',
    Description: 'Unlicensed carriers',
    Industry: 'transportation_services',
  },
  {
    Code: '55100',
    Description: 'Hotels and similar accommodation',
    Industry: 'leisure',
  },
  {
    Code: '55201',
    Description: 'Holiday centres and villages',
    Industry: 'leisure',
  },
  { Code: '55202', Description: 'Youth hostels', Industry: 'leisure' },
  {
    Code: '55209',
    Description: 'Other holiday and other collective accommodation',
    Industry: 'leisure',
  },
  {
    Code: '55300',
    Description:
      'Recreational vehicle parks, trailer parks and camping grounds',
    Industry: 'leisure',
  },
  { Code: '55900', Description: 'Other accommodation', Industry: 'leisure' },
  { Code: '56101', Description: 'Licenced restaurants', Industry: 'food' },
  {
    Code: '56102',
    Description: 'Unlicenced restaurants and cafes',
    Industry: 'food',
  },
  {
    Code: '56103',
    Description: 'Take-away food shops and mobile food stands',
    Industry: 'food',
  },
  { Code: '56210', Description: 'Event catering activities', Industry: 'food' },
  { Code: '56290', Description: 'Other food services', Industry: 'food' },
  { Code: '56301', Description: 'Licenced clubs', Industry: 'food' },
  {
    Code: '56302',
    Description: 'Public houses and bars',
    Industry: 'beverages',
  },
  { Code: '58110', Description: 'Book publishing', Industry: 'media' },
  {
    Code: '58120',
    Description: 'Publishing of directories and mailing lists',
    Industry: 'media',
  },
  { Code: '58130', Description: 'Publishing of newspapers', Industry: 'media' },
  {
    Code: '58141',
    Description: 'Publishing of learned journals',
    Industry: 'media',
  },
  {
    Code: '58142',
    Description: 'Publishing of consumer and business journals and periodicals',
    Industry: 'media',
  },
  {
    Code: '58190',
    Description: 'Other publishing activities',
    Industry: 'media',
  },
  {
    Code: '58210',
    Description: 'Publishing of computer games',
    Industry: 'media',
  },
  {
    Code: '58290',
    Description: 'Other software publishing',
    Industry: 'media',
  },
  {
    Code: '59111',
    Description: 'Motion picture production activities',
    Industry: 'media',
  },
  {
    Code: '59112',
    Description: 'Video production activities',
    Industry: 'media',
  },
  {
    Code: '59113',
    Description: 'Television programme production activities',
    Industry: 'media',
  },
  {
    Code: '59120',
    Description:
      'Motion picture, video and television programme post-production activities',
    Industry: 'media',
  },
  {
    Code: '59131',
    Description: 'Motion picture distribution activities',
    Industry: 'media',
  },
  {
    Code: '59132',
    Description: 'Video distribution activities',
    Industry: 'media',
  },
  {
    Code: '59133',
    Description: 'Television programme distribution activities',
    Industry: 'media',
  },
  {
    Code: '59140',
    Description: 'Motion picture projection activities',
    Industry: 'media',
  },
  {
    Code: '59200',
    Description: 'Sound recording and music publishing activities',
    Industry: 'media',
  },
  { Code: '60100', Description: 'Radio broadcasting', Industry: 'media' },
  {
    Code: '60200',
    Description: 'Television programming and broadcasting activities',
    Industry: 'media',
  },
  {
    Code: '61100',
    Description: 'Wired telecommunications activities',
    Industry: 'media',
  },
  {
    Code: '61200',
    Description: 'Wireless telecommunications activities',
    Industry: 'media',
  },
  {
    Code: '61300',
    Description: 'Satellite telecommunications activities',
    Industry: 'media',
  },
  {
    Code: '61900',
    Description: 'Other telecommunications activities',
    Industry: 'media',
  },
  {
    Code: '62011',
    Description:
      'Ready-made interactive leisure and entertainment software development',
    Industry: 'media',
  },
  {
    Code: '62012',
    Description: 'Business and domestic software development',
    Industry: 'media',
  },
  {
    Code: '62020',
    Description: 'Information technology consultancy activities',
    Industry: 'media',
  },
  {
    Code: '62030',
    Description: 'Computer facilities management activities',
    Industry: 'computer_services',
  },
  {
    Code: '62090',
    Description: 'Other information technology service activities',
    Industry: 'media',
  },
  {
    Code: '63110',
    Description: 'Data processing, hosting and related activities',
    Industry: 'media',
  },
  { Code: '63120', Description: 'Web portals', Industry: 'media' },
  { Code: '63910', Description: 'News agency activities', Industry: 'media' },
  {
    Code: '63990',
    Description: 'Other information service activities n.e.c.',
    Industry: 'media',
  },
  { Code: '64110', Description: 'Central banking', Industry: 'banking' },
  { Code: '64191', Description: 'Banks', Industry: 'banking' },
  {
    Code: '64192',
    Description: 'Building societies',
    Industry: 'financial_services',
  },
  {
    Code: '64201',
    Description: 'Activities of agricultural holding businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64202',
    Description: 'Activities of production holding businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64203',
    Description: 'Activities of construction holding businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64204',
    Description: 'Activities of distribution holding businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64205',
    Description: 'Activities of financial services holding businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64209',
    Description: 'Activities of other holding businesses n.e.c.',
    Industry: 'financial_services',
  },
  {
    Code: '64301',
    Description: 'Activities of investment trusts',
    Industry: 'financial_services',
  },
  {
    Code: '64302',
    Description: 'Activities of unit trusts',
    Industry: 'financial_services',
  },
  {
    Code: '64303',
    Description: 'Activities of venture and development capital businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64304',
    Description: 'Activities of open-ended investment businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64305',
    Description: 'Activities of property unit trusts',
    Industry: 'financial_services',
  },
  {
    Code: '64306',
    Description: 'Activities of real estate investment trusts',
    Industry: 'financial_services',
  },
  {
    Code: '64910',
    Description: 'Financial leasing',
    Industry: 'financial_services',
  },
  {
    Code: '64921',
    Description:
      'Credit granting by non-deposit taking finance houses and other specialist consumer credit grantors',
    Industry: 'financial_services',
  },
  {
    Code: '64922',
    Description: 'Activities of mortgage finance businesses',
    Industry: 'financial_services',
  },
  {
    Code: '64929',
    Description: 'Other credit granting n.e.c.',
    Industry: 'financial_services',
  },
  {
    Code: '64991',
    Description: 'Security dealing on own account',
    Industry: 'financial_services',
  },
  { Code: '64992', Description: 'Factoring', Industry: 'financial_services' },
  {
    Code: '64999',
    Description: 'Financial intermediation not elsewhere classified',
    Industry: 'financial_services',
  },
  { Code: '65110', Description: 'Life insurance', Industry: 'insurance' },
  { Code: '65120', Description: 'Non-life insurance', Industry: 'insurance' },
  { Code: '65201', Description: 'Life reinsurance', Industry: 'insurance' },
  { Code: '65202', Description: 'Non-life reinsurance', Industry: 'insurance' },
  {
    Code: '65300',
    Description: 'Pension funding',
    Industry: 'financial_services',
  },
  {
    Code: '66110',
    Description: 'Administration of financial markets',
    Industry: 'financial_services',
  },
  {
    Code: '66120',
    Description: 'Security and commodity contracts dealing activities',
    Industry: 'financial_services',
  },
  {
    Code: '66190',
    Description: 'Activities auxiliary to financial intermediation n.e.c.',
    Industry: 'financial_services',
  },
  {
    Code: '66210',
    Description: 'Risk and damage evaluation',
    Industry: 'financial_services',
  },
  {
    Code: '66220',
    Description: 'Activities of insurance agents and brokers',
    Industry: 'financial_services',
  },
  {
    Code: '66290',
    Description: 'Other activities auxiliary to insurance and pension funding',
    Industry: 'financial_services',
  },
  {
    Code: '66300',
    Description: 'Fund management activities',
    Industry: 'financial_services',
  },
  {
    Code: '68100',
    Description: 'Buying and selling of own real estate',
    Industry: 'real_estate',
  },
  {
    Code: '68201',
    Description: 'Renting and operating of Housing Association real estate',
    Industry: 'real_estate',
  },
  {
    Code: '68202',
    Description: 'Letting and operating of conference and exhibition centres',
    Industry: 'real_estate',
  },
  {
    Code: '68209',
    Description: 'Other letting and operating of own or leased real estate',
    Industry: 'real_estate',
  },
  {
    Code: '68310',
    Description: 'Real estate agencies',
    Industry: 'real_estate',
  },
  {
    Code: '68320',
    Description: 'Management of real estate on a fee or contract basis',
    Industry: 'real_estate',
  },
  {
    Code: '69101',
    Description: 'Barristers at law',
    Industry: 'business_services',
  },
  { Code: '69102', Description: 'Solicitors', Industry: 'business_services' },
  {
    Code: '69109',
    Description:
      'Activities of patent and copyright agents; other legal activities n.e.c.',
    Industry: 'business_services',
  },
  {
    Code: '69201',
    Description: 'Accounting and auditing activities',
    Industry: 'business_services',
  },
  {
    Code: '69202',
    Description: 'Bookkeeping activities',
    Industry: 'business_services',
  },
  {
    Code: '69203',
    Description: 'Tax consultancy',
    Industry: 'business_services',
  },
  {
    Code: '70100',
    Description: 'Activities of head offices',
    Industry: 'business_services',
  },
  {
    Code: '70210',
    Description: 'Public relations and communications activities',
    Industry: 'business_services',
  },
  {
    Code: '70221',
    Description: 'Financial management',
    Industry: 'business_services',
  },
  {
    Code: '70229',
    Description:
      'Management consultancy activities other than financial management',
    Industry: 'business_services',
  },
  {
    Code: '71111',
    Description: 'Architectural activities',
    Industry: 'business_services',
  },
  {
    Code: '71112',
    Description: 'Urban planning and landscape architectural activities',
    Industry: 'business_services',
  },
  {
    Code: '71121',
    Description:
      'Engineering design activities for industrial process and production',
    Industry: 'business_services',
  },
  {
    Code: '71122',
    Description:
      'Engineering related scientific and technical consulting activities',
    Industry: 'business_services',
  },
  {
    Code: '71129',
    Description: 'Other engineering activities',
    Industry: 'business_services',
  },
  {
    Code: '71200',
    Description: 'Technical testing and analysis',
    Industry: 'business_services',
  },
  {
    Code: '72110',
    Description: 'Research and experimental development on biotechnology',
    Industry: 'business_services',
  },
  {
    Code: '72190',
    Description:
      'Other research and experimental development on natural sciences and engineering',
    Industry: 'business_services',
  },
  {
    Code: '72200',
    Description:
      'Research and experimental development on social sciences and humanities',
    Industry: 'business_services',
  },
  {
    Code: '73110',
    Description: 'Advertising agencies',
    Industry: 'business_services',
  },
  {
    Code: '73120',
    Description: 'media representation services',
    Industry: 'business_services',
  },
  {
    Code: '73200',
    Description: 'Market research and public opinion polling',
    Industry: 'business_services',
  },
  {
    Code: '74100',
    Description: 'specialised design activities',
    Industry: 'business_services',
  },
  {
    Code: '74201',
    Description: 'Portrait photographic activities',
    Industry: 'business_services',
  },
  {
    Code: '74202',
    Description: 'Other specialist photography',
    Industry: 'business_services',
  },
  {
    Code: '74203',
    Description: 'Film processing',
    Industry: 'business_services',
  },
  {
    Code: '74209',
    Description: 'Photographic activities not elsewhere classified',
    Industry: 'business_services',
  },
  {
    Code: '74300',
    Description: 'Translation and interpretation activities',
    Industry: 'business_services',
  },
  {
    Code: '74901',
    Description: 'Environmental consulting activities',
    Industry: 'business_services',
  },
  {
    Code: '74902',
    Description: 'Quantity surveying activities',
    Industry: 'business_services',
  },
  {
    Code: '74909',
    Description:
      'Other professional, scientific and technical activities n.e.c.',
    Industry: 'business_services',
  },
  {
    Code: '74990',
    Description: 'Non-trading business',
    Industry: 'business_services',
  },
  {
    Code: '75000',
    Description: 'Veterinary activities',
    Industry: 'business_services',
  },
  {
    Code: '77110',
    Description: 'Renting and leasing of cars and light motor vehicles',
    Industry: 'automotive_and_transport',
  },
  {
    Code: '77120',
    Description: 'Renting and leasing of trucks and other heavy vehicles',
    Industry: 'automotive_and_transport',
  },
  {
    Code: '77210',
    Description: 'Renting and leasing of recreational and sports goods',
    Industry: 'automotive_and_transport',
  },
  {
    Code: '77220',
    Description: 'Renting of video tapes and disks',
    Industry: 'consumer_services',
  },
  {
    Code: '77291',
    Description: 'Renting and leasing of media entertainment equipment',
    Industry: 'media',
  },
  {
    Code: '77299',
    Description: 'Renting and leasing of other personal and household goods',
    Industry: 'consumer_services',
  },
  {
    Code: '77310',
    Description: 'Renting and leasing of agricultural machinery and equipment',
    Industry: 'agriculture',
  },
  {
    Code: '77320',
    Description:
      'Renting and leasing of construction and civil engineering machinery and equipment',
    Industry: 'construction',
  },
  {
    Code: '77330',
    Description:
      'Renting and leasing of office machinery and equipment (including computers)',
    Industry: 'computer_hardware',
  },
  {
    Code: '77341',
    Description: 'Renting and leasing of passenger water transport equipment',
    Industry: 'transportation_services',
  },
  {
    Code: '77342',
    Description: 'Renting and leasing of freight water transport equipment',
    Industry: 'transportation_services',
  },
  {
    Code: '77351',
    Description: 'Renting and leasing of air passenger transport equipment',
    Industry: 'transportation_services',
  },
  {
    Code: '77352',
    Description: 'Renting and leasing of freight air transport equipment',
    Industry: 'transportation_services',
  },
  {
    Code: '77390',
    Description:
      'Renting and leasing of other machinery, equipment and tangible goods n.e.c.',
    Industry: 'consumer_services',
  },
  {
    Code: '77400',
    Description:
      'Leasing of intellectual property and similar products, except copyright works',
    Industry: 'business_services',
  },
  {
    Code: '78101',
    Description:
      'Motion picture, television and other theatrical casting activities',
    Industry: 'media',
  },
  {
    Code: '78109',
    Description: 'Other activities of employment placement agencies',
    Industry: 'business_services',
  },
  {
    Code: '78200',
    Description: 'Temporary employment agency activities',
    Industry: 'business_services',
  },
  {
    Code: '78300',
    Description:
      'Human resources provision and management of human resources functions',
    Industry: 'business_services',
  },
  {
    Code: '79110',
    Description: 'Travel agency activities',
    Industry: 'leisure',
  },
  {
    Code: '79120',
    Description: 'Tour operator activities',
    Industry: 'leisure',
  },
  {
    Code: '79901',
    Description: 'Activities of tourist guides',
    Industry: 'leisure',
  },
  {
    Code: '79909',
    Description: 'Other reservation service activities n.e.c.',
    Industry: 'leisure',
  },
  {
    Code: '80100',
    Description: 'Private security activities',
    Industry: 'Security Products & Services',
  },
  {
    Code: '80200',
    Description: 'Security systems service activities',
    Industry: 'Security Products & Services',
  },
  {
    Code: '80300',
    Description: 'Investigation activities',
    Industry: 'Security Products & Services',
  },
  {
    Code: '81100',
    Description: 'Combined facilities support activities',
    Industry: 'business_services',
  },
  {
    Code: '81210',
    Description: 'General cleaning of buildings',
    Industry: 'business_services',
  },
  {
    Code: '81221',
    Description: 'Window cleaning services',
    Industry: 'business_services',
  },
  {
    Code: '81222',
    Description: 'Specialised cleaning services',
    Industry: 'business_services',
  },
  {
    Code: '81223',
    Description: 'Furnace and chimney cleaning services',
    Industry: 'business_services',
  },
  {
    Code: '81229',
    Description: 'Other building and industrial cleaning activities',
    Industry: 'business_services',
  },
  {
    Code: '81291',
    Description: 'Disinfecting and exterminating services',
    Industry: 'business_services',
  },
  {
    Code: '81299',
    Description: 'Other cleaning services',
    Industry: 'business_services',
  },
  {
    Code: '81300',
    Description: 'Landscape service activities',
    Industry: 'business_services',
  },
  {
    Code: '82110',
    Description: 'Combined office administrative service activities',
    Industry: 'business_services',
  },
  {
    Code: '82190',
    Description:
      'Photocopying, document preparation and other specialised office support activities',
    Industry: 'business_services',
  },
  {
    Code: '82200',
    Description: 'Activities of call centres',
    Industry: 'business_services',
  },
  {
    Code: '82301',
    Description: 'Activities of exhibition and fair organisers',
    Industry: 'business_services',
  },
  {
    Code: '82302',
    Description: 'Activities of conference organisers',
    Industry: 'business_services',
  },
  {
    Code: '82911',
    Description: 'Activities of collection agencies',
    Industry: 'business_services',
  },
  {
    Code: '82912',
    Description: 'Activities of credit bureaus',
    Industry: 'business_services',
  },
  {
    Code: '82920',
    Description: 'Packaging activities',
    Industry: 'business_services',
  },
  {
    Code: '82990',
    Description: 'Other business support service activities n.e.c.',
    Industry: 'business_services',
  },
  {
    Code: '84110',
    Description: 'General public administration activities',
    Industry: 'government',
  },
  {
    Code: '84120',
    Description:
      'Regulation of health care, education, cultural and other social services, not incl. social security',
    Industry: 'government',
  },
  {
    Code: '84130',
    Description:
      'Regulation of and contribution to more efficient operation of businesses',
    Industry: 'government',
  },
  { Code: '84210', Description: 'Foreign affairs', Industry: 'government' },
  {
    Code: '84220',
    Description: 'Defence activities',
    Industry: 'aerospace_and_defense',
  },
  {
    Code: '84230',
    Description: 'Justice and judicial activities',
    Industry: 'government',
  },
  {
    Code: '84240',
    Description: 'Public order and safety activities',
    Industry: 'aerospace_and_defense',
  },
  {
    Code: '84250',
    Description: 'Fire service activities',
    Industry: 'aerospace_and_defense',
  },
  {
    Code: '84300',
    Description: 'Compulsory social security activities',
    Industry: 'government',
  },
  {
    Code: '85100',
    Description: 'Pre-primary education',
    Industry: 'education',
  },
  { Code: '85200', Description: 'Primary education', Industry: 'education' },
  {
    Code: '85310',
    Description: 'General secondary education',
    Industry: 'education',
  },
  {
    Code: '85320',
    Description: 'Technical and vocational secondary education',
    Industry: 'education',
  },
  {
    Code: '85410',
    Description: 'Post-secondary non-tertiary education',
    Industry: 'education',
  },
  {
    Code: '85421',
    Description: 'First-degree level higher education',
    Industry: 'education',
  },
  {
    Code: '85422',
    Description: 'Post-graduate level higher education',
    Industry: 'education',
  },
  {
    Code: '85510',
    Description: 'Sports and recreation education',
    Industry: 'education',
  },
  { Code: '85520', Description: 'Cultural education', Industry: 'education' },
  {
    Code: '85530',
    Description: 'Driving school activities',
    Industry: 'education',
  },
  {
    Code: '85590',
    Description: 'Other education n.e.c.',
    Industry: 'education',
  },
  {
    Code: '85600',
    Description: 'educational support services',
    Industry: 'education',
  },
  { Code: '86101', Description: 'Hospital activities', Industry: 'healthcare' },
  {
    Code: '86102',
    Description: 'Medical nursing home activities',
    Industry: 'healthcare',
  },
  {
    Code: '86210',
    Description: 'General medical practice activities',
    Industry: 'healthcare',
  },
  {
    Code: '86220',
    Description: 'Specialists medical practice activities',
    Industry: 'healthcare',
  },
  {
    Code: '86230',
    Description: 'Dental practice activities',
    Industry: 'healthcare',
  },
  {
    Code: '86900',
    Description: 'Other human health activities',
    Industry: 'healthcare',
  },
  {
    Code: '87100',
    Description: 'Residential nursing care facilities',
    Industry: 'healthcare',
  },
  {
    Code: '87200',
    Description:
      'Residential care activities for learning difficulties, mental health and substance abuse',
    Industry: 'healthcare',
  },
  {
    Code: '87300',
    Description: 'Residential care activities for the elderly and disabled',
    Industry: 'healthcare',
  },
  {
    Code: '87900',
    Description: 'Other residential care activities n.e.c.',
    Industry: 'healthcare',
  },
  {
    Code: '88100',
    Description:
      'Social work activities without accommodation for the elderly and disabled',
    Industry: 'healthcare',
  },
  {
    Code: '88910',
    Description: 'Child day-care activities',
    Industry: 'healthcare',
  },
  {
    Code: '88990',
    Description: 'Other social work activities without accommodation n.e.c.',
    Industry: 'healthcare',
  },
  { Code: '90010', Description: 'Performing arts', Industry: 'leisure' },
  {
    Code: '90020',
    Description: 'Support activities to performing arts',
    Industry: 'leisure',
  },
  { Code: '90030', Description: 'Artistic creation', Industry: 'leisure' },
  {
    Code: '90040',
    Description: 'Operation of arts facilities',
    Industry: 'leisure',
  },
  { Code: '91011', Description: 'Library activities', Industry: 'leisure' },
  { Code: '91012', Description: 'Archives activities', Industry: 'leisure' },
  { Code: '91020', Description: 'Museums activities', Industry: 'leisure' },
  {
    Code: '91030',
    Description:
      'Operation of historical sites and buildings and similar visitor attractions',
    Industry: 'leisure',
  },
  {
    Code: '91040',
    Description:
      'Botanical and zoological gardens and nature reserves activities',
    Industry: 'leisure',
  },
  {
    Code: '92000',
    Description: 'Gambling and betting activities',
    Industry: 'leisure',
  },
  {
    Code: '93110',
    Description: 'Operation of sports facilities',
    Industry: 'leisure',
  },
  {
    Code: '93120',
    Description: 'Activities of sport clubs',
    Industry: 'leisure',
  },
  { Code: '93130', Description: 'Fitness facilities', Industry: 'leisure' },
  {
    Code: '93191',
    Description: 'Activities of racehorse owners',
    Industry: 'leisure',
  },
  {
    Code: '93199',
    Description: 'Other sports activities',
    Industry: 'leisure',
  },
  {
    Code: '93210',
    Description: 'Activities of amusement parks and theme parks',
    Industry: 'leisure',
  },
  {
    Code: '93290',
    Description: 'Other amusement and recreation activities n.e.c.',
    Industry: 'leisure',
  },
  {
    Code: '94110',
    Description:
      'Activities of business and employers membership organisations',
    Industry: 'membership_organizations',
  },
  {
    Code: '94120',
    Description: 'Activities of professional membership organisations',
    Industry: 'membership_organizations',
  },
  {
    Code: '94200',
    Description: 'Activities of trade unions',
    Industry: 'membership_organizations',
  },
  {
    Code: '94910',
    Description: 'Activities of religious organisations',
    Industry: 'membership_organizations',
  },
  {
    Code: '94920',
    Description: 'Activities of political organisations',
    Industry: 'membership_organizations',
  },
  {
    Code: '94990',
    Description: 'Activities of other membership organisations n.e.c.',
    Industry: 'membership_organizations',
  },
  {
    Code: '95110',
    Description: 'Repair of computers and peripheral equipment',
    Industry: 'computer_hardware',
  },
  {
    Code: '95120',
    Description: 'Repair of communication equipment',
    Industry: 'consumer_services',
  },
  {
    Code: '95210',
    Description: 'Repair of consumer electronics',
    Industry: 'consumer_services',
  },
  {
    Code: '95220',
    Description: 'Repair of household appliances and home and garden equipment',
    Industry: 'consumer_services',
  },
  {
    Code: '95230',
    Description: 'Repair of footwear and leather goods',
    Industry: 'consumer_services',
  },
  {
    Code: '95240',
    Description: 'Repair of furniture and home furnishings',
    Industry: 'consumer_services',
  },
  {
    Code: '95250',
    Description: 'Repair of watches, clocks and jewellery',
    Industry: 'consumer_services',
  },
  {
    Code: '95290',
    Description: 'Repair of personal and household goods n.e.c.',
    Industry: 'consumer_services',
  },
  {
    Code: '96010',
    Description: 'Washing and (dry-)cleaning of textile and fur products',
    Industry: 'consumer_services',
  },
  {
    Code: '96020',
    Description: 'Hairdressing and other beauty treatment',
    Industry: 'consumer_services',
  },
  {
    Code: '96030',
    Description: 'Funeral and related activities',
    Industry: 'consumer_services',
  },
  {
    Code: '96040',
    Description: 'Physical well-being activities',
    Industry: 'consumer_services',
  },
  {
    Code: '96090',
    Description: 'Other service activities n.e.c.',
    Industry: 'consumer_services',
  },
  {
    Code: '97000',
    Description: 'Activities of households as employers of domestic personnel',
    Industry: 'leisure',
  },
  {
    Code: '98000',
    Description: 'Residents property management',
    Industry: 'real_estate',
  },
  {
    Code: '98100',
    Description:
      'Undifferentiated goods-producing activities of private households for own use',
    Industry: 'industrial_manufacturing',
  },
  {
    Code: '98200',
    Description:
      'Undifferentiated service-producing activities of private households for own use',
    Industry: 'business_services',
  },
];

export default companyIndustries;
