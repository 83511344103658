import { useLocation } from 'react-router-dom';
import Banner from 'components/composed/Banner';
import { ButtonTheme } from 'components/elements/Button';
import { useBannerConfig } from './utils';

type Props = {
  isAdmin: boolean;
  openActivateCompany: () => void;
  className?: string;
};

const PendingBanner = ({ isAdmin, openActivateCompany, className }: Props) => {
  const { pathname } = useLocation();
  const { routeName, label, description, expanded, onShrink } = useBannerConfig(
    pathname,
    isAdmin,
  );

  return (
    <Banner
      id={`company_pending_activation_banner_${routeName}`}
      label={label}
      description={description}
      actions={[
        {
          label: isAdmin ? 'Later' : 'Understood',
          onClick: onShrink,
          theme: ButtonTheme.Outlined,
        },
      ]}
      mainAction={
        isAdmin && {
          label: 'Complete setup now',
          onClick: openActivateCompany,
        }
      }
      expanded={expanded}
      className={className}
    />
  );
};

PendingBanner.defaultProps = {
  className: undefined,
};

export default PendingBanner;
