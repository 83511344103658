import colors from 'styles/colors';
import styles from './Loader.module.css';

type LoaderProps = {
  color?: string;
  borderWidth?: string;
  size?: string;
};

const Loader = ({ color, borderWidth, size }: LoaderProps) => {
  const style = {
    borderWidth,
    borderTopColor: color,
    borderRightColor: color,
    borderBottomColor: color,
    borderLeftColor: 'transparent',
    width: size,
    height: size,
  };
  return <div className={styles.loader} style={style} />;
};

Loader.defaultProps = {
  color: colors.gray700,
  borderWidth: '7px',
  size: '65px',
};

export default Loader;
