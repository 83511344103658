import { UUID_REGEX_AS_STRING } from '@neo1/client/lib/entities/index';

export const ROUTE_PATH_PURCHASE = '/purchase';
export const ROUTE_PATH_PURCHASE_SUPPLIES =
  ROUTE_PATH_PURCHASE.concat('/supplies');
export const ROUTE_PATH_PURCHASE_SUPPLIES_DETAILS =
  ROUTE_PATH_PURCHASE_SUPPLIES.concat(`/:supplyId(${UUID_REGEX_AS_STRING})?`);

export const ROUTE_NAME_PURCHASE_SUPPLIES = 'purchase';

export const PURCHASE_SUPPLIES_PENDING_BANNER_USER = {
  label:
    'Amazon integration will be enabled as soon as your business is fully active',
};
export const PURCHASE_SUPPLIES_PENDING_BANNER_ADMIN = {
  label:
    'Amazon integration will be enabled as soon as your business setup is completed',
  description:
    'You will be able to set up your Amazon Punchout credentials as soon as your business is fully active.',
};
