import { AccountingConfigStatus } from '@neo1/client/lib/entities/company/constants';
import { Action, IntegrationActions, State } from './types';

export const initialState: State = {
  xero: {
    isConfigured: true,
    vendors: [],
    config: {
      configStatus: AccountingConfigStatus.NotStarted,
      cardTxMappingPreferences: undefined,
      cashTxMappingPreferences: undefined,
      singleCardTxVendorId: undefined,
      singleCashTxVendorId: undefined,
    },
    cashTxsConfig: {
      usersVendors: [],
    },
  },
  quickbooks: {
    isConfigured: true,
    vendors: [],
    config: {
      configStatus: AccountingConfigStatus.NotStarted,
      cardTxMappingPreferences: undefined,
      cashTxMappingPreferences: undefined,
      singleCardTxVendorId: undefined,
      singleCashTxVendorId: undefined,
    },
    cashTxsConfig: {
      usersVendors: [],
    },
  },
  sage: {
    isConfigured: true,
    vendors: [],
    config: {
      configStatus: AccountingConfigStatus.NotStarted,
      cardTxMappingPreferences: undefined,
      cashTxMappingPreferences: undefined,
      singleCardTxVendorId: undefined,
      singleCashTxVendorId: undefined,
    },
    cashTxsConfig: {
      usersVendors: [],
    },
  },
  standard: {
    isConfigured: true,
    vendors: [],
    config: {
      configStatus: AccountingConfigStatus.NotStarted,
      cardTxMappingPreferences: undefined,
      cashTxMappingPreferences: undefined,
      singleCardTxVendorId: undefined,
      singleCashTxVendorId: undefined,
    },
    cashTxsConfig: {
      usersVendors: [],
    },
  },
};

export default (state = initialState, action: IntegrationActions = null) => {
  switch (action?.type) {
    case Action.SET:
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          isConfigured: action.isConfigured,
        },
      };
    case Action.SET_VENDORS:
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          vendors: action.vendors,
        },
      };
    case Action.SET_USERS_VENDORS:
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          cashTxsConfig: {
            ...state[action.service].cashTxsConfig,
            usersVendors: action.usersVendors,
          },
        },
      };
    case Action.SET_CONFIG:
      return {
        ...state,
        [action.service]: {
          ...state[action.service],
          config: {
            ...state[action.service].config,
            ...action.config,
          },
        },
      };
    default:
      return state;
  }
};
