import { Sizes } from 'components/elements/Grid';
import { ROUTE_PATH_PROFILE } from '../constants';

export const gridSizes: Sizes = {
  lg: 3,
  md: 4,
  sm: 12,
  xs: 12,
};
export const ROUTE_PATH_VEHICLES = `${ROUTE_PATH_PROFILE}/vehicles`;
export const ROUTE_NAME_VEHICLES = 'profileVehicles';
