import { useState } from 'react';
import { useIntl } from 'react-intl';
import { getConfigValue } from 'config';
import Button, { ButtonTheme, ButtonSize } from 'components/elements/Button';
import { useAmplitude } from 'contexts/instrumentation';
import Banner from 'components/composed/Banner';
import Modal from 'components/composed/Modal';
import Icon from 'components/elements/Icon';
import messages from './messages';
import styles from './Update.module.css';

const UpdateHandler = () => {
  const intl = useIntl();
  const [openModal, setOpenModal] = useState(true);
  const { logEvent } = useAmplitude();

  const onClose = () => {
    setOpenModal(false);
    setTimeout(
      () => setOpenModal(true),
      getConfigValue('delayDisplayUpdateModal'),
    );

    logEvent({
      event: 'new_version_available',
      data: { action: 'skip', time: Date.now() },
    });
  };

  const onUpgrade = () => {
    logEvent({
      event: 'new_version_available',
      data: { action: 'reload', time: Date.now() },
    });
    window.location.reload();
  };

  return (
    <>
      {openModal && (
        <Modal
          id="updateModal"
          title={intl.formatMessage(messages.title)}
          className={styles.modal}
          onClose={onClose}
          isOpen
          footer={
            <>
              <Button
                label="Later"
                theme={ButtonTheme.Outlined}
                size={ButtonSize.Large}
                onClick={onClose}
              />
              <Button
                label="Update"
                ariaLabel="Upgrade"
                size={ButtonSize.Large}
                onClick={onUpgrade}
              />
            </>
          }
        >
          <div className={styles.iconWrapper}>
            <Icon name="warning" className={styles.icon} />
          </div>
          {intl.formatMessage(messages.description)}
        </Modal>
      )}
      {!openModal && (
        <Banner
          id="updateBanner"
          icon={null}
          className={styles.banner}
          label={intl.formatMessage(messages.title)}
          description={intl.formatMessage(messages.description)}
          descriptionClassName={styles.description}
          infoWrapperClassName={styles.infoWrapper}
          actionTheme={ButtonTheme.Outlined}
          mainAction={{
            label: 'Update',
            onClick: onUpgrade,
          }}
        />
      )}
    </>
  );
};

export default UpdateHandler;
