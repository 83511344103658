import { EntityId } from '@neo1/client/lib/entities/types';
import { UUID } from '@neo1/client';
import { AppCoreState } from 'redux/types';
import { EntityType } from 'redux/entities/types';

export const selectEntityById =
  <T>(type: EntityType, id: EntityId) =>
  (state: AppCoreState): T | null => {
    if (state.entities[type]) {
      return state.entities[type][id] || null;
    }
    return null;
  };

export const selectEntitiesByIds =
  <T>(type: EntityType, ids: EntityId[] | number[]) =>
  (state: AppCoreState): T[] =>
    ids
      ? ids.map((id: UUID | number) => selectEntityById<T>(type, id)(state))
      : null;
