import classNames from 'classnames';
import { getConfigValue } from 'config';
import Image from 'components/elements/Image';
import { ReactComponent as Neo1Logo } from '../../../../../public/images/logos/neo1.svg';
import styles from './Logo.module.css';

type Props = {
  className?: string;
  height?: number;
};

const Logo = ({ className, height }: Props) => {
  const { logo } = getConfigValue('branding') || {};

  return (
    <div className={classNames(styles.container, className)}>
      {logo && <Image path={logo} height={height} />}
      <Neo1Logo style={{ height }} />
    </div>
  );
};

Logo.defaultProps = {
  className: undefined,
  height: 64,
};

export default Logo;
