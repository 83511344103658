import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { matchPath, useHistory, useLocation } from 'react-router';
import isNil from 'lodash/isNil';

import { enumToLabel } from '@neo1/core/utils/strings';
import { AppCoreState } from 'redux/types';
import { selectCounters } from 'redux/counter/selector';
import { AppMenuRoute } from 'config/routes/utils';
import { useAmplitude } from 'contexts/instrumentation';
import { useWindowContext, DeviceSize } from 'contexts/window';

import MenuItem from '../../MenuItem';
import messages from '../messages';
import getBadgeCountForRouteName from './getBadgeCountForRouteName';
import styles from './styles.module.css';

type Props = {
  menu: AppMenuRoute[];
  onNavigation: () => void;
};

const Body = ({ menu, onNavigation }: Props) => {
  const { logEvent } = useAmplitude();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();
  const { deviceSize } = useWindowContext();
  const counters = useSelector((state: AppCoreState) => selectCounters(state));

  return (
    <div className={styles.container}>
      {menu.map(({ menu: menuItem, path, exact, name }) => {
        const handleClick = () => {
          logEvent({ event: `sidemenu_${name}` });
          history.push(menuItem.path || path);
          onNavigation();
        };

        const label = messages.menu[name]
          ? intl.formatMessage(messages.menu[name])
          : enumToLabel(name);

        return (
          <MenuItem
            key={name}
            label={label}
            icon={menuItem.iconClass}
            active={!isNil(matchPath(location.pathname, { path, exact }))}
            onClick={handleClick}
            counter={getBadgeCountForRouteName(counters, name)}
            dark
            compact={deviceSize === DeviceSize.desktop}
          />
        );
      })}
    </div>
  );
};

export default Body;
