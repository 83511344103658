import { matchPath } from 'react-router';
import authRoutes from 'modules/Authentification/routes';
import showroomRoute from 'modules/Showroom/route';

const publicRoutes = [...authRoutes, showroomRoute];

export const isPublicRoute = (pathname: string) =>
  publicRoutes.find((route) => matchPath(pathname, route));

export default publicRoutes;
