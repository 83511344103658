import { IconName } from 'components/elements/Icon';

export enum Actions {
  SET = 'one/app/notifications/flash/SET',
  CLEAR = 'one/app/notifications/flash/CLEAR',
}

export type FlashNotification = {
  targetId: string;
  message: string;
  icon?: IconName;
  kind?: 'success' | 'warning' | 'alert';
};

export type SetAction = {
  type: typeof Actions.SET;
  payload: FlashNotification;
};

export type ClearAction = {
  type: typeof Actions.CLEAR;
};

export type State = FlashNotification;

export type Action = SetAction | ClearAction;
