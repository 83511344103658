const compare = (leftChunks: string[], rightChunks: string[]): number => {
  // If either chunk list is empty, determine an order directly
  if (!leftChunks.length || !rightChunks.length) {
    return leftChunks.length - rightChunks.length;
  }

  const [leftChunk, ...leftOtherChunks] = leftChunks;
  const [rightChunk, ...rightOtherChunks] = rightChunks;

  // If the first chunks are equal, recursively compare the rest of the lists
  if (leftChunk === rightChunk) {
    return compare(leftOtherChunks, rightOtherChunks);
  }

  // If both chunks are numbers, compare them numerically
  if (!Number.isNaN(+leftChunk) && !Number.isNaN(+rightChunk)) {
    return +leftChunk - +rightChunk;
  }

  // If chunks are not both numbers, compare them lexically
  return leftChunk < rightChunk ? -1 : 1;
};

const alphanumSort = (left: string, right: string) => {
  const leftChunks = String(left).toLowerCase().split(/\s+/);
  const rightChunks = String(right).toLowerCase().split(/\s+/);
  return compare(leftChunks, rightChunks);
};

export default alphanumSort;
