import Icon from 'components/elements/Icon';
import StepContent from 'components/composed/MultiStepModalForm/StepContent';
import styles from './FinishStep.module.css';

const Finish = () => (
  <StepContent submitTitle="Finish">
    <div className={styles.container}>
      <Icon className={styles.successIcon} name="check" />
      <div> Congratulations! Your configuration is now complete!</div>
    </div>
  </StepContent>
);

export default Finish;
