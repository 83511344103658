import { compose } from 'redux';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import round from 'lodash/round';
import lowerCase from 'lodash/lowerCase';

/**
 * Converts input to a slug string
 */
export const slugify = (str: string) =>
  (str || '')
    .trim()
    .toLowerCase()
    .replace(/ +/g, '-')
    .replace(/[^\w-]+/g, '');

/**
 * Outputs an empty string if input is not a string
 */
const normalizeString = (str: any) => {
  if (isString(str)) {
    return str;
  }
  return '';
};

/**
 * Replaces string underscores with spaces
 */
export const replaceUnderscoresWithSpaces = (str: string) =>
  normalizeString(str).replace(/_/g, ' ');

/**
 * Add spaces before each capital of given string
 */
const prefixCapitalsWithSpaces = (str: string) =>
  normalizeString(str).replace(/([A-Z])/g, ' $1');

export function capitalizeFirstWord(str: string) {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Converts an enum value in snake case to presentation label
 */
export const enumToLabel = compose(
  capitalizeFirstWord,
  lowerCase,
  replaceUnderscoresWithSpaces,
);

/**
 * Converts a camel case word to presentation label
 */
export const camelCaseToLabel = compose(
  capitalizeFirstWord,
  lowerCase,
  prefixCapitalsWithSpaces,
);

/**
 * Asserts if given string is a blank string
 */
export const isBlankString = (str: string) =>
  isString(str) && isEmpty(str.trim());

/**
 * Converts bytes size to friendly shortened size label
 */
export const bytesToSize = (bytes: number, base: number = 1024) => {
  if (isNumber(bytes)) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const sizeIndex = Math.floor(Math.log(bytes) / Math.log(base));
    return round(bytes / Math.pow(base, sizeIndex), 2) + ' ' + sizes[sizeIndex];
  }
  return '0 Bytes';
};

/**
 * Splits full name and concatenate initials
 */
export function getFullnameInitials(fullName: string) {
  const [firstName = '', lastName = ''] = fullName.split(' ');
  const firstNameInitial = firstName[0] || '';
  const lastNameInitial = lastName[0] || firstName[1] || '';
  return firstNameInitial.concat(lastNameInitial).toUpperCase();
}

/**
 * Replaces unicode zero-width characters with an empty string
 */

export function replaceZeroWidthChars(s: string): string {
  return s.replace(/[\u200B|\u200D|\uFEFF|\u200C]/g, '');
}

/**
 * Formats a boolean to a nice text
 */
export const formatBoolean = (val: boolean) => (val ? 'Yes' : 'No');

export const isValidString = (obj: any) =>
  typeof obj === 'string' && !isBlankString(obj);
