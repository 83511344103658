import { UUID } from '@neo1/client';

export enum Actions {
  SET_CREDIT_CARDS = 'one/profile/paymentMethods/SET_CREDIT_CARDS',
  SET_CREDIT_CARD = 'one/profile/paymentMethods/SET_CREDIT_CARD',
  SET_PAYMENT_INSTRUMENTS = 'one/profile/paymentMethods/SET_PAYMENT_INSTRUMENTS',
  SET_PAYMENT_INSTRUMENT = 'one/profile/paymentMethods/SET_PAYMENT_INSTRUMENT',
  SET_ACCOUNTS = 'one/profile/paymentMethods/SET_ACCOUNTS',
  DEL_CREDIT_CARD = 'one/profile/paymentMethods/DEL_CREDIT_CARD',
}

type SetCreditCards = {
  type: Actions.SET_CREDIT_CARDS;
  creditCardsIds: UUID[];
};
type SetCreditCard = {
  type: Actions.SET_CREDIT_CARD;
  creditCardId: UUID;
};
type DeleteCreditCard = {
  type: Actions.DEL_CREDIT_CARD;
  creditCardId: UUID;
};
type SetPaymentInstruments = {
  type: Actions.SET_PAYMENT_INSTRUMENTS;
  paymentInstrumentsIds: UUID[];
};
type SetPaymentInstrument = {
  type: Actions.SET_PAYMENT_INSTRUMENT;
  paymentInstrumentId: UUID;
};

type SetAccounts = {
  type: Actions.SET_ACCOUNTS;
  accountsIds: UUID[];
};

export function setCreditCards(creditCardsIds: UUID[]): SetCreditCards {
  return {
    type: Actions.SET_CREDIT_CARDS,
    creditCardsIds,
  };
}
export function setCreditCard(creditCardId: UUID): SetCreditCard {
  return {
    type: Actions.SET_CREDIT_CARD,
    creditCardId,
  };
}
export function deleteCreditCard(creditCardId: UUID): DeleteCreditCard {
  return {
    type: Actions.DEL_CREDIT_CARD,
    creditCardId,
  };
}
export function setPaymentInstruments(
  paymentInstrumentsIds: UUID[],
): SetPaymentInstruments {
  return {
    type: Actions.SET_PAYMENT_INSTRUMENTS,
    paymentInstrumentsIds,
  };
}
export function setPaymentInstrument(
  paymentInstrumentId: UUID,
): SetPaymentInstrument {
  return {
    type: Actions.SET_PAYMENT_INSTRUMENT,
    paymentInstrumentId,
  };
}
export function setAccouts(accountsIds: UUID[]): SetAccounts {
  return {
    type: Actions.SET_ACCOUNTS,
    accountsIds,
  };
}

export type PaymentMethodActions =
  | SetAccounts
  | SetCreditCards
  | SetPaymentInstruments
  | SetCreditCard
  | SetPaymentInstrument
  | DeleteCreditCard;
