import { User } from '@neo1/client/lib/entities/user/types';
import { Company } from '@neo1/client/lib/entities/company/types';
import { AuthState } from './types';

export enum AuthActions {
  LOGIN = 'one/auth/LOGIN',
  LOGOUT = 'one/auth/LOGOUT',
  LOGIN_AS = 'one/auth/LOGIN_AS',
  LOGIN_AS_SUCCESS = 'one/auth/LOGIN_AS_SUCCESS',
  LOGIN_AS_FAIL = 'one/auth/LOGIN_AS_FAIL',
  LOGIN_AS_COMPANY = 'one/auth/LOGIN_AS_COMPANY',
  LOGIN_AS_COMPANY_SUCCESS = 'one/auth/LOGIN_AS_COMPANY_SUCCESS',
  LOGIN_AS_COMPANY_FAIL = 'one/auth/LOGIN_AS_COMPANY_FAIL',
  LOGOUT_AS = 'one/auth/LOGOUT_AS',
  SET_TOKEN = 'one/auth/SET_TOKEN',
  SET_AUTH_COMPANY = 'one/auth/SET_AUTH_COMPANY',
  SET_COMPANY_FUNDING_APPROVERS_DATA = 'one/auth/SET_COMPANY_FUNDING_APPROVERS_DATA',
  ADD_COMPANY_USER = 'one/auth/ADD_COMPANY_USER',
  DELETE_COMPANY_USER = 'one/auth/DELETE_COMPANY_USER',
  SET_PROCESSING = 'one/auth/SET_PROCESSING',
  UNSET_PROCESSING = 'one/auth/UNSET_PROCESSING',
}

export function login(
  userId: User['id'],
  delegatedUsersIds: User['id'][],
): AuthActionsType {
  return {
    type: AuthActions.LOGIN,
    userId,
    delegatedUsersIds,
  };
}

export function logout(): AuthActionsType {
  return {
    type: AuthActions.LOGOUT,
  };
}

export function loginAs(
  userId: User['id'],
  returnPath: string,
): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS,
    userId,
    returnPath,
  };
}

export function loginAsSuccess(): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS_SUCCESS,
  };
}

export function loginAsFail(): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS_FAIL,
  };
}

export function loginAsCompany(
  companyId: Company['id'],
  returnPath: string,
): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS_COMPANY,
    companyId,
    returnPath,
  };
}

export function loginAsCompanySuccess(): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS_COMPANY_SUCCESS,
  };
}

export function loginAsCompanyFail(): AuthActionsType {
  return {
    type: AuthActions.LOGIN_AS_COMPANY_FAIL,
  };
}

export function logoutAs(): AuthActionsType {
  return {
    type: AuthActions.LOGOUT_AS,
  };
}

export function setToken(token: string): AuthActionsType {
  return {
    type: AuthActions.SET_TOKEN,
    token,
  };
}

export function setAuthCompany(payload: AuthState['company']): AuthActionsType {
  return {
    type: AuthActions.SET_AUTH_COMPANY,
    payload,
  };
}

export function setCompanyFundingApproversData(
  payload: AuthState['company']['fundingApproversData'],
): AuthActionsType {
  return {
    type: AuthActions.SET_COMPANY_FUNDING_APPROVERS_DATA,
    payload,
  };
}

export function addCompanyUser(userId: User['id']): AuthActionsType {
  return {
    type: AuthActions.ADD_COMPANY_USER,
    userId,
  };
}

export function deleteCompanyUser(userId: User['id']): AuthActionsType {
  return {
    type: AuthActions.DELETE_COMPANY_USER,
    userId,
  };
}

export function setProcessing(): AuthActionsType {
  return {
    type: AuthActions.SET_PROCESSING,
  };
}

export function unsetProcessing(): AuthActionsType {
  return {
    type: AuthActions.UNSET_PROCESSING,
  };
}

export type AuthActionsType =
  | {
      type: AuthActions.LOGIN;
      userId: User['id'];
      delegatedUsersIds: User['id'][];
    }
  | {
      type: AuthActions.LOGOUT;
    }
  | {
      type: AuthActions.LOGIN_AS;
      userId: User['id'];
      returnPath: string;
    }
  | {
      type: AuthActions.LOGIN_AS_SUCCESS;
    }
  | {
      type: AuthActions.LOGIN_AS_FAIL;
    }
  | {
      type: AuthActions.LOGIN_AS_COMPANY;
      companyId: Company['id'];
      returnPath: string;
    }
  | {
      type: AuthActions.LOGIN_AS_COMPANY_SUCCESS;
    }
  | {
      type: AuthActions.LOGIN_AS_COMPANY_FAIL;
    }
  | {
      type: AuthActions.LOGOUT_AS;
    }
  | {
      type: AuthActions.SET_TOKEN;
      token: string;
    }
  | {
      type: AuthActions.SET_AUTH_COMPANY;
      payload: AuthState['company'];
    }
  | {
      type: AuthActions.SET_COMPANY_FUNDING_APPROVERS_DATA;
      payload: AuthState['company']['fundingApproversData'];
    }
  | {
      type: AuthActions.ADD_COMPANY_USER;
      userId: User['id'];
    }
  | {
      type: AuthActions.DELETE_COMPANY_USER;
      userId: User['id'];
    }
  | {
      type: AuthActions.SET_PROCESSING;
    }
  | {
      type: AuthActions.UNSET_PROCESSING;
    };
