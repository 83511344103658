import { combineReducers } from 'redux';
import accounting from '../Accounting/redux/reducer';
import expenseTypesReducer from '../ExpenseTypes/redux/reducer';
import mileage from '../Mileage/redux/reducer';
import tax from '../Taxes/redux/reducer';
import spendPolicy from '../SpendPolicies/redux/reducer';
import users from '../Users/redux/reducer';
import amexVirtualAccounts from '../General/Integrations/AmexVirtualAccounts/redux/reducer';
import paymentMethods from '../PaymentMethods/redux/reducer';

export default combineReducers({
  accounting,
  mileage,
  tax,
  spendPolicy,
  expenseType: expenseTypesReducer,
  users,
  myCompany: combineReducers({
    amexVirtualAccounts,
  }),
  paymentMethods,
});
