import { Action, Actions } from './actions';
import { AmazonConfigState } from './types';

const INITIAL_STATE: AmazonConfigState = null;

export default function reducer(
  state: AmazonConfigState = INITIAL_STATE,
  action: Action = null,
) {
  switch (action?.type) {
    case Actions.SetAmazonConfig: {
      return action.settings;
    }
    case Actions.UnsetAmazonConfig: {
      return null;
    }
    default:
      return state;
  }
}
