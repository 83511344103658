let instance = null;

export default class Observer {
  static get() {
    if (instance) {
      return instance;
    }

    instance = new Observer();
    return instance;
  }

  private observer: ResizeObserver;

  private callbacks: Map<Element, Function>;

  constructor() {
    this.observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const element = entry.target;
        const callback = this.callbacks.get(element);
        if (callback && typeof callback === 'function') {
          callback(element);
        }
      });
    });
    this.callbacks = new Map();
  }

  observe(element, callback) {
    this.callbacks.set(element, callback);
    this.observer.observe(element);
  }

  unobserve(element) {
    this.callbacks.delete(element);
    this.observer.unobserve(element);
  }
}
