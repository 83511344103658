import { UUID } from '@neo1/client';

export type SearchViewState = {
  searchText: string;
  usersIds: UUID[];
  companiesIds: UUID[];
  managedDomains: UUID[];
};

export enum Actions {
  SET_MANAGED_DOMAINS = 'one/admin/search/SET_MANAGED_DOMAINS',
  SET_COMPANIES = 'one/admin/search/SET_COMPANIES',
  SET_USERS = 'one/admin/search/SET_USERS',
  SET_SEARCH_TEXT = 'one/admin/search/SET_SEARCH_TEXT',
  SET_SEARCHED = 'one/admin/search/SET_SEARCHED',
  REINIT = 'one/admin/search/REINIT',
}

type SetManagedDomains = {
  type: Actions.SET_MANAGED_DOMAINS;
  ids: UUID[];
};

type SetSearchText = {
  type: Actions.SET_SEARCH_TEXT;
  searchText: string;
};

type SetCompanies = { type: Actions.SET_COMPANIES; ids: string[] };

type SetUsers = { type: Actions.SET_USERS; ids: string[] };

type Reinit = {
  type: Actions.REINIT;
};

export type Action =
  | SetManagedDomains
  | SetSearchText
  | SetCompanies
  | SetUsers
  | Reinit;
