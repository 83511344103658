import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import Icon, { IconName } from 'components/elements/Icon';

import { ButtonTheme, ButtonSize, ButtonKind } from './types';
import styles from './Button.module.css';
import Tooltip from '../Tooltip';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  ariaLabel?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
  wrapperClassName?: string;
  theme?: ButtonTheme;
  size?: ButtonSize;
  kind?: ButtonKind;
  disabled?: boolean;
  loading?: boolean;
  dark?: boolean;
  floating?: boolean;
  alignment?: 'left' | 'center' | 'right';
  iconName?: IconName;
  iconPosition?: 'leading' | 'trailing';
  iconOnly?: boolean;
  testId?: string;
}

const Button = ({
  label,
  ariaLabel,
  type,
  className,
  wrapperClassName,
  theme,
  size,
  kind,
  disabled,
  loading,
  dark,
  floating,
  alignment,
  iconName,
  iconPosition,
  iconOnly,
  testId,
  ...otherProps
}: ButtonProps) => {
  if (theme === ButtonTheme.Filled && dark) {
    throw new Error('Cannot have a filled button on a dark background.');
  }

  if (theme === ButtonTheme.Filled && kind === ButtonKind.Neutral) {
    throw new Error('Cannot have a filled neutral button.');
  }

  const Element = (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      title={label}
      aria-label={ariaLabel ?? label}
      className={classNames(
        styles.button,
        styles[theme],
        styles[size],
        styles[kind],
        loading ? styles.loading : null,
        disabled ? styles.disabled : null,
        dark ? styles.dark : null,
        floating ? styles.floating : null,
        iconOnly ? styles.iconOnly : wrapperClassName,
        className,
      )}
      disabled={disabled || loading}
      data-testid={testId}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
    >
      <div
        className={classNames(
          styles.content,
          alignment ? styles[alignment] : null,
          iconPosition === 'trailing' ? styles.reverse : null,
        )}
      >
        {iconName ? (
          <Icon
            name={loading ? 'spinner' : iconName}
            className={loading ? styles.spinning : null}
          />
        ) : null}

        {!iconOnly ? (
          <div
            className={loading && !iconName ? styles.invisible : null}
            aria-hidden={loading && !iconName}
          >
            {label}
          </div>
        ) : null}

        {loading && !iconName ? (
          <div className={styles.centered}>
            <Icon name="spinner" className={styles.spinning} />
          </div>
        ) : null}
      </div>
    </button>
  );

  if (iconOnly) {
    return (
      <Tooltip
        content={label}
        targetClassName={wrapperClassName}
        testId="buttonTooltip"
      >
        {Element}
      </Tooltip>
    );
  }

  return Element;
};

Button.defaultProps = {
  ariaLabel: undefined,
  wrapperClassName: undefined,
  type: 'button',
  theme: ButtonTheme.Filled,
  size: ButtonSize.Medium,
  kind: ButtonKind.Normal,
  disabled: false,
  loading: false,
  dark: false,
  floating: false,
  alignment: 'center',
  iconName: undefined,
  iconPosition: 'leading',
  iconOnly: false,
  testId: undefined,
};

export default Button;

export { ButtonTheme, ButtonSize, ButtonKind };
