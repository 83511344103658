import {
  isAddressName,
  isCity,
  isCountryState,
  isStreet,
  isZipCode,
  reify,
} from '@neo1/client';
import { isValidString } from '@neo1/core/utils/strings';
import {
  ADDRESS_MANDATORY_FIELDS,
  ADDRESS_ENTITY_NAME,
  HOME_ADDRESS_ID,
} from './constants';
import { Address } from './types';

export function normalizeAddress(rawData: any): Address | null {
  if (!rawData) return null;

  const data = reify(rawData);

  return {
    entityName: ADDRESS_ENTITY_NAME,
    id: data.getUuid('address_id', HOME_ADDRESS_ID),
    zipCode: data.getString('zip_code', ''),
    city: data.getString('city', ''),
    name: data.getString('name', ''),
    state: data.getString('state', ''),
    countryCode: data.getString('country_code', ''),
    streetLine1: data.getString('street_line_1', ''),
    streetLine2: data.getString('street_line_2', ''),
  };
}

export function serializeAddress(address: Address) {
  const {
    id,
    zipCode,
    city,
    name,
    state,
    countryCode,
    streetLine1,
    streetLine2,
  } = address;

  return {
    address_id: id,
    zip_code: zipCode || null,
    city: city || null,
    name: name || null,
    state: state || null,
    country_code: countryCode || null,
    street_line_1: streetLine1 || null,
    street_line_2: streetLine2 || null,
  };
}

export const serializePartialAddress = (data: Partial<Address>) => {
  if (!data) {
    return null;
  }

  const address: Partial<Address> = {
    city: data.city,
    streetLine1: data.streetLine1,
    streetLine2: data.streetLine2,
    state: data.state,
    zipCode: data.zipCode,
    countryCode: data.countryCode,
  };

  return isValidAddress(address) ? serializeAddress(address) : null;
};

/**
 * Asserts if object is an address
 */
export const isValidAddress = (obj: any): obj is Address => {
  if (typeof obj !== 'object') {
    return false;
  }
  if (!ADDRESS_MANDATORY_FIELDS.every((field) => isValidString(obj[field]))) {
    return false;
  }
  if (obj.name && !isAddressName(obj.name)) {
    return false;
  }
  if (obj.state && !isCountryState(obj.state)) {
    return false;
  }
  if (obj.city && !isCity(obj.city)) {
    return false;
  }
  if (obj.zipCode && !isZipCode(obj.zipCode)) {
    return false;
  }
  if (obj.streetLine1 && !isStreet(obj.streetLine1)) {
    return false;
  }
  if (obj.streetLine2 && !isStreet(obj.streetLine2)) {
    return false;
  }
  return true;
};

/**
 * Returns assert function to compare 2 addresses
 */
export const matchesAddress = (address: Address) => (otherAddress: Address) =>
  otherAddress.id === address.id;
