import { defineMessages } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'New_version',
    defaultMessage: 'A new version is now available',
  },
  description: {
    id: 'description',
    defaultMessage:
      'With this new version you will have access to brand new features and improvements.',
  },
});

export default messages;
