import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isNumber from 'lodash/isNumber';
import prop from 'lodash/property';
import { isBlankString } from '../../../core/utils/strings';
import superagent from 'superagent';

export enum RpcErrorCode {
  ParseError = -32700,
  InvalidRequest = -32600,
  MethodNotFound = -32601,
  InvalidParams = -32602,
  InternalError = -32603,
  ServerError = -32099,
  Forbidden = -32098,
  NeedAuthentication = -32097,
  ObjectNotFound = -32096,
  Locked = -32095,
  PasswordPolicyViolation = -32094,
  ImportError = -32093,
  TooManyLoginAttempts = -32092,
  PasswordExpired = -32091,
  CannotSyncEnrollment = -32081,
  ExternalServiceFailure = -32084,
  ExtractError = -32085,
  QBOConfigError = -32080,
}

export interface SuperAgentResponseError extends Error {
  response?: superagent.Response;
}

export interface RpcClientError extends Error {
  rpcClientError: true;
  httpStatus: number;
  code?: RpcErrorCode;
  data?: any;
  messages?: string[];
}

export type RpcCommandParams = Record<string, any>;

export type RpcCommand<P = RpcCommandParams> = {
  jsonrpc?: string;
  method: string;
  params: P;
  id?: number;
};

export type PayloadMapper = (data: any) => RpcCommand;

export type RpcBatchCommand = Array<RpcCommand>;

export type RpcPayload = RpcCommand | RpcBatchCommand;

export type RpcSuccessResult = {
  id?: number;
  jsonrpc: '2.0';
  result: any;
  error: undefined;
};

export type RpcErrorResult = {
  jsonrpc: '2.0';
  error: { message: string };
  data?: any;
};

export type RpcNotifyResult = undefined;

export type RpcResponse = RpcResult | RpcResult[];

export type RpcResult = RpcSuccessResult | RpcErrorResult | RpcNotifyResult;

export type RpcBatchResult = RpcResult[];

/**
 * Asserts if response is an rpc response
 */
export const isRpcResponse = (response: superagent.Response) => {
  return typeof response?.body === 'object' && response.body?.jsonrpc === '2.0';
};
/**
 * Will append given result to given rpc result list
 */
export function concatBatchResults(aggregatedResults: any[], result: any) {
  const commandResult = get(result, 'result', []);
  return aggregatedResults.concat(commandResult);
}

/**
 * Assets if rpc result is an error result or not
 */
export function isRpcErrorResult(result: any): result is RpcErrorResult {
  return !isNil(result?.error);
}

/**
 * Assets if rpc result is a success result or not
 */
export function isRpcSuccessResult(result: any): result is RpcSuccessResult {
  return !isRpcErrorResult(result);
}

/**
 * Access if rpc response is a rpc batch response
 */
export function isRpcBatchResult(
  response: RpcResponse,
): response is RpcBatchResult {
  return Array.isArray(response);
}

/**
 * Asserts if given payload is rpc batch payload
 */
export function isRpcBatchPayload(
  payload: RpcPayload,
): payload is RpcBatchCommand {
  return Array.isArray(payload) && payload.every(isRpcCommand);
}

/**
 * Asserts if given object is a valid rpc command
 */
export function isRpcCommand(command: any): command is RpcCommand {
  return isString(command.method) && typeof command.params === 'object';
}

/**
 * Will force blank strings present in rpc params payload to null values
 */
export function consolidateParams(params: RpcCommandParams): RpcCommandParams {
  return Object.entries(params).reduce((consolidatedParams, [key, value]) => {
    return {
      ...consolidatedParams,
      [key]: isBlankString(value) ? null : value,
    };
  }, params);
}

/**
 * For batch payloads, it will ensure proper ids for each commands
 * and consolidate each command params
 */
export function consolidatePayload(payload: RpcPayload): RpcPayload {
  if (Array.isArray(payload)) {
    const payloadIds: any[] = payload.map(prop('id'));
    const maxId = Math.max(...payloadIds.filter((c) => isNumber(c)), 0);
    return payload.map((command, index) => {
      const id = isNaN(command.id) ? maxId + index + 1 : command.id;
      return {
        ...command,
        params: consolidateParams(command.params),
        id,
        jsonrpc: '2.0',
      };
    });
  }
  return {
    ...payload,
    params: consolidateParams(payload.params),
    id: 1,
    jsonrpc: '2.0',
  };
}

/**
 * Asserts if given error is an RPC Client Error
 */
export const isRpcClientError = (error: any): error is RpcClientError =>
  error?.rpcClientError === true;

/**
 * Extends an error object with JSON-RPC error data
 */
export const mapErrorToClientError = (
  error: SuperAgentResponseError,
): RpcClientError => {
  const { response, name, message = 'Unexpected error' } = error;
  if (isRpcResponse(response)) {
    return {
      name,
      rpcClientError: true,
      code: response.body.error.code,
      httpStatus: response.status,
      message: response.body.error?.message,
      data: response.body.error?.data,
    };
  }
  return {
    name,
    message,
    rpcClientError: true,
    httpStatus: response?.status,
  };
};

/**
 * Checks if given value is valid payload value
 */
const isValidPayloadValue = (value: any, optional: boolean = true) =>
  !isBlankString(value) &&
  value !== undefined &&
  typeof value !== 'function' &&
  (optional || value !== null);

const nullifyEmptyValues = (payload: any, defaults: object = {}) =>
  Object.keys(payload).reduce((resultPayload, k) => {
    let payloadValue = payload[k];

    if (!isValidPayloadValue(payloadValue)) {
      payloadValue = (<any>resultPayload)[k] || null;
    }

    return {
      ...resultPayload,
      [k]: payloadValue,
    };
  }, defaults);

/**
 * Constructs a JSON-RPC paylod
 */
export const makePayload = (payload: any = {}, defaults: object = {}) =>
  nullifyEmptyValues(payload, defaults);

export const nullIfMissing = (payload: any) =>
  Object.entries(payload).reduce((result, [key, value]) => {
    return {
      ...result,
      [key]: value || null,
    };
  }, {});
