import classNames from 'classnames';
import styles from './HeaderDivider.module.css';

type Props = {
  className?: string;
};

const HeaderDivider = ({ className }: Props) => (
  <div className={classNames(styles.divider, className)} />
);

HeaderDivider.defaultProps = {
  className: undefined,
};

export default HeaderDivider;
