import { UUID } from '@neo1/client';

export enum Action {
  SET_SUPPLY = 'one/SET_AUTHORIZE_SUPPLY',
  SET_SUPPLIES = 'one/SET_AUTHORIZE_SUPPLIES',
  DELETE_SUPPLY = 'one/DELETE_AUTHORIZE_SUPPLY',
}

export type SetSupply = {
  type: typeof Action.SET_SUPPLY;
  id: UUID;
};

export type SetSupplies = {
  type: typeof Action.SET_SUPPLIES;
  ids: UUID[];
};

export type DeleteSupply = {
  type: typeof Action.DELETE_SUPPLY;
  id: UUID;
};

export type SupplyActions = SetSupply | SetSupplies | DeleteSupply;

export type SupplyState = {
  ids: UUID[];
};
