import { defineMessages } from 'react-intl';

const messages = defineMessages({
  settingsTitle: {
    id: 'settingsTitle',
    defaultMessage: 'Settings navigation',
  },
  myCompanyPage: {
    id: 'myCompanyPage',
    defaultMessage: 'Business info',
  },
  accountingPage: {
    id: 'accountingPage',
    defaultMessage: 'Fields',
  },
  taxesPage: {
    id: 'taxesPage',
    defaultMessage: 'Taxes',
  },
  paymentMethodsPage: {
    id: 'paymentMethodsPage',
    defaultMessage: 'Payment methods',
  },
  expenseTypesPage: {
    id: 'expenseTypesPage',
    defaultMessage: 'Expense types',
  },
  generalConfigurationPage: {
    id: 'generalConfigurationPage',
    defaultMessage: 'General configuration',
  },
  spendPoliciesPage: {
    id: 'spendPoliciesPage',
    defaultMessage: 'Spend policies',
  },
  mileagePage: {
    id: 'mileagePage',
    defaultMessage: 'Mileage',
  },
  dataPage: {
    id: 'dataPage',
    defaultMessage: 'Data',
  },
  users: {
    id: 'settingsUsers',
    defaultMessage: 'Users',
  },
});

export default messages;
