import {
  isDark,
  hexToRgbaString,
  lightenDarkenColor,
} from '@neo1/core/utils/colors';
import { getConfigValue } from 'config';

const branding = getConfigValue('branding');

type ColorPalette = {
  brightBlue100: string;
  brightBlue200: string;
  brightBlue300: string;
  brightBlue400: string;
  brightBlue500: string;
  brightBlue600: string;

  deepBlue100: string;
  deepBlue200: string;
  deepBlue300: string;
  deepBlue400: string;
  deepBlue500: string;
  deepBlue600: string;

  red100: string;
  red200: string;
  red300: string;
  red400: string;
  red500: string;
  red600: string;

  orange100: string;
  orange200: string;
  orange300: string;
  orange400: string;
  orange500: string;
  orange600: string;

  green100: string;
  green200: string;
  green300: string;
  green400: string;
  green500: string;
  green600: string;

  white: string;
  white020: string;

  black: string;
  black020: string;
  black050: string;

  gray100: string;
  gray200: string;
  gray300: string;
  gray400: string;
  gray500: string;
  gray600: string;
  gray700: string;
  gray800: string;
  gray900: string;

  ink400: string;
  ink500: string;
  ink600: string;

  darkInk100: string;
  darkInk200: string;
  darkInk300: string;
};

type PartnerColors = {
  xeroDark: string;
  xeroBg: string;
  xeroLogo: string;

  quickbooksDark: string;
  quickbooksBg: string;
  quickbooksLogo: string;
  quickbooksGrey1: string;
  quickbooksGrey2: string;
  quickbooksGradient: string;

  sageDark: string;
  sagePrimary: string;
  sageLogo: string;
};

type StatusColors = {
  blockingRedLight: string;
  blockingRed: string;
  blockingRedDark: string;
  blockingRedBg: string;

  alertOrangeLight: string;
  alertOrange: string;
  alertOrangeDark: string;
  alertOrangeBg: string;

  progressGreenLight: string;
  progressGreen: string;
  progressGreenDark: string;
  progressGreenBg: string;
};

type ThemeColors = {
  primaryBg: string;
  primaryBgHover: string;
  primaryOnDark: string;
  primaryBgLight: string;
  primaryBgDark: string;
  primaryDark: string;
  primaryText: string;
  primaryTextHover: string;
  textOnPrimaryBg: string;
};

type DataVisColors = {
  dataBrightBlue400: string;
  dataBrightBlue500: string;
  dataBrightBlue600: string;

  dataDeepBlue400: string;
  dataDeepBlue500: string;
  dataDeepBlue600: string;

  dataViolet400: string;
  dataViolet500: string;
  dataViolet600: string;

  dataOrange400: string;
  dataOrange500: string;
  dataOrange600: string;

  dataAqua400: string;
  dataAqua500: string;
  dataAqua600: string;

  dataMagenta400: string;
  dataMagenta500: string;
  dataMagenta600: string;
};

type ChartColors = string[];

type ChartTextColors = string[];

const colorPalette: ColorPalette = {
  brightBlue100: '#ecf6ff',
  brightBlue200: '#cee8ff',
  brightBlue300: '#9dd1ff',
  brightBlue400: '#67b8ff',
  brightBlue500: '#006fcf',
  brightBlue600: '#0056a0',

  deepBlue100: '#eaefff',
  deepBlue200: '#c4d1f8',
  deepBlue300: '#99ace3',
  deepBlue400: '#5270c6',
  deepBlue500: '#00175a',
  deepBlue600: '#001245',

  red100: '#ffebe8',
  red200: '#ffa394',
  red300: '#ff7f6b',
  red400: '#ed5138',
  red500: '#d21e01',
  red600: '#801200',

  orange100: '#fef3e1',
  orange200: '#fbd09a',
  orange300: '#f5ad67',
  orange400: '#ec8a41',
  orange500: '#e05708',
  orange600: '#ca400a',

  green100: '#dff2ee',
  green200: '#b1ded2',
  green300: '#80cab6',
  green400: '#28a486',
  green500: '#007759',
  green600: '#004b31',

  white: '#ffffff',
  white020: 'rgba(255, 255, 255, 0.2)',

  black: '#000000',
  black020: 'rgba(0, 0, 0, 0.2)',
  black050: 'rgba(0, 0, 0, 0.5)',

  gray100: '#fafafa',
  gray200: '#f7f8f9',
  gray300: '#ecedee',
  gray400: '#dbdbdb',
  gray500: '#c8c9c7',
  gray600: '#8e9092',
  gray700: '#53565a',
  gray800: '#333333',
  gray900: '#17181a',

  ink400: '#97999b',
  ink500: '#53565a',
  ink600: '#333333',

  darkInk100: '#f2f2f2',
  darkInk200: '#abacad',
  darkInk300: '#6a6d73',
};

export const partnerColors: PartnerColors = {
  xeroDark: '#467e93',
  xeroBg: '#0c82ab',
  xeroLogo: '#1ec0e7',

  quickbooksDark: '#427c2e',
  quickbooksBg: '#2c8318',
  quickbooksLogo: '#2ca01c',
  quickbooksGrey1: '#393a3d',
  quickbooksGrey2: '#686c72',
  quickbooksGradient: 'linear-gradient(135deg, #53b700 0%, #00c1bf 100%)',

  sageDark: '#2e3456',
  sagePrimary: '#07265f',
  sageLogo: '#0adc00',
};

const statusColors: StatusColors = {
  blockingRedLight: colorPalette.red300,
  blockingRed: colorPalette.red500,
  blockingRedDark: colorPalette.red600,
  blockingRedBg: colorPalette.red100,

  alertOrangeLight: colorPalette.orange300,
  alertOrange: colorPalette.orange500,
  alertOrangeDark: colorPalette.orange600,
  alertOrangeBg: colorPalette.orange100,

  progressGreenLight: colorPalette.green300,
  progressGreen: colorPalette.green500,
  progressGreenDark: colorPalette.green600,
  progressGreenBg: colorPalette.green100,
};

const defaultTheme: ThemeColors = {
  /* default theme colors, can be override by config */
  primaryBg: colorPalette.brightBlue500,
  primaryBgHover: colorPalette.brightBlue600,
  primaryOnDark: colorPalette.brightBlue300,
  primaryBgLight: colorPalette.brightBlue100,
  primaryBgDark: colorPalette.deepBlue500,
  primaryDark: colorPalette.deepBlue600,
  primaryText: colorPalette.brightBlue500,
  primaryTextHover: colorPalette.brightBlue600,
  textOnPrimaryBg: colorPalette.white,
};

export const dataVisColors: DataVisColors = {
  dataBrightBlue400: '#008ef0',
  dataBrightBlue500: '#006fcf',
  dataBrightBlue600: '#1b3d83',

  dataDeepBlue400: '#4b6ed4',
  dataDeepBlue500: '#294aac',
  dataDeepBlue600: '#00175a',

  dataViolet400: '#666aff',
  dataViolet500: '#4a4bff',
  dataViolet600: '#0801b6',

  dataOrange400: '#ee7015',
  dataOrange500: '#cf6000',
  dataOrange600: '#8c2100',

  dataAqua400: '#00a79a',
  dataAqua500: '#007d75',
  dataAqua600: '#025252',

  dataMagenta400: '#fe5f5a',
  dataMagenta500: '#cf4d43',
  dataMagenta600: '#921414',
};

export const chartColors: ChartColors = [
  dataVisColors.dataBrightBlue500,
  dataVisColors.dataDeepBlue600,
  dataVisColors.dataMagenta400,
  dataVisColors.dataAqua400,
  dataVisColors.dataAqua600,
  dataVisColors.dataOrange400,
  dataVisColors.dataOrange600,
  dataVisColors.dataDeepBlue400,
  dataVisColors.dataBrightBlue600,
  dataVisColors.dataMagenta500,
  dataVisColors.dataViolet400,
  dataVisColors.dataViolet600,
  dataVisColors.dataBrightBlue400,
  dataVisColors.dataAqua500,
  dataVisColors.dataViolet500,
  dataVisColors.dataOrange500,
  dataVisColors.dataMagenta600,
  dataVisColors.dataDeepBlue500,
];

export const chartTextColors: ChartTextColors = [
  colorPalette.white,
  colorPalette.white,
  colorPalette.black,
  colorPalette.black,
  colorPalette.white,
  colorPalette.black,
  colorPalette.white,
  colorPalette.white,
  colorPalette.white,
  colorPalette.black,
  colorPalette.black,
  colorPalette.white,
  colorPalette.black,
  colorPalette.white,
  colorPalette.white,
  colorPalette.black,
  colorPalette.white,
  colorPalette.white,
];

let themeColors: ThemeColors = { ...defaultTheme };

if (branding) {
  const { primaryColor } = branding;
  const isDarkPrimary = isDark(primaryColor);
  themeColors = {
    ...themeColors,
    primaryBg: primaryColor,
    primaryBgHover: lightenDarkenColor(primaryColor, -30),
    primaryBgLight: hexToRgbaString(primaryColor, 0.2),
    primaryText: isDarkPrimary ? primaryColor : colorPalette.ink600,
    textOnPrimaryBg: isDarkPrimary ? colorPalette.white : colorPalette.ink600,
  };
  chartColors[0] = primaryColor;
  chartTextColors[0] = isDarkPrimary ? colorPalette.white : colorPalette.black;
}

const colors = {
  ...colorPalette,
  ...statusColors,
  ...themeColors,
  ...partnerColors,
};

export default colors;
