import memoize from 'lodash/memoize';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  catchError,
  flatMap,
  switchMap,
  throttleTime,
  withLatestFrom,
  startWith,
} from 'rxjs/operators';
import { DEFAULT_LOCALE } from 'config/i18n/constants';
import { fetchJson, getMessagesFileUrl } from '@neo1/core/utils/files';
import {
  InternationalizationAction,
  loadMessages,
  loadMessagesErr,
  loadMessagesSuccess,
} from 'modules/App/redux/i18n/actions';
/**
 * Fetches from CDN static messages file
 */
export const fetchMessagesFile = memoize((locale: string) => {
  if (locale !== DEFAULT_LOCALE) {
    // only fetch translation messages for non default locale
    return fetchJson(getMessagesFileUrl(locale));
  }
  return Promise.resolve();
});

/**
 * Epic handling loading of locales file
 * @param action$
 * @param state$
 */
export const loadFile = (action$, state$) =>
  action$.pipe(
    ofType(InternationalizationAction.SetLocale),
    throttleTime(300),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      from(fetchMessagesFile(state.app.i18n.locale)).pipe(
        flatMap((messages: any) => of(loadMessagesSuccess(messages))),
        startWith(loadMessages()),
        catchError((err: Error) => of(loadMessagesErr(err))),
      ),
    ),
  );
