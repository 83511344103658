import { State, Action, Actions } from './types';

const initialState: State = {
  ids: [],
};

const reducer = (state: State = initialState, action?: Action): State => {
  switch (action.type) {
    case Actions.SET_RECEIPTS:
      return {
        ...state,
        ids: action.ids,
      };

    default:
      return state;
  }
};

export default reducer;
