import { useLayoutEffect, useState, useRef, ReactNode } from 'react';
import LoaderObserver from 'utils/LoaderObserver';
import LoadPlaceHolder from 'components/elements/LoadPlaceholder';
import styles from './TableCell.module.css';

type Props = {
  children: ReactNode;
  className?: string;
  colSpan?: number;
  width?: string;
};

const TableCell = ({ children, className, colSpan, width }: Props) => {
  const ref = useRef(null);
  const [isVisible, setVisible] = useState(false);
  const showContent = isVisible || process.env.NODE_ENV === 'test';

  const handleGettingVisible = () => {
    setVisible(true);
  };

  useLayoutEffect(() => {
    const element = ref.current;
    LoaderObserver.get().observe(element, handleGettingVisible);
    return () => {
      LoaderObserver.get().unobserve(element);
    };
  }, []);

  return (
    <td
      ref={ref}
      className={className}
      colSpan={colSpan}
      style={{ width }}
      data-testid="tableCell"
    >
      {showContent ? children : <LoadPlaceHolder className={styles.load} />}
    </td>
  );
};

TableCell.defaultProps = {
  className: undefined,
  colSpan: undefined,
  width: undefined,
};

export default TableCell;
