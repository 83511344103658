import Button, { ButtonSize } from 'components/elements/Button';

import styles from './FloatingCtaButton.module.css';
import { Cta } from '../types';

type Props = {
  cta: Cta;
};

const FloatingCtaButton = ({ cta }: Props) => {
  const { label, iconName, onClick } = cta;

  return (
    <div className={styles.container} data-testid="floatingCtaBtn">
      <Button
        label={label}
        onClick={onClick}
        size={ButtonSize.Large}
        iconName={iconName ?? 'plus'}
        iconOnly
        floating
      >
        {label}
      </Button>
    </div>
  );
};

export default FloatingCtaButton;
