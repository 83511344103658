import { useIntl } from 'react-intl';
import { FormikErrors } from 'formik';
import { CompanyGroupUser } from '@neo1/client/lib/entities/user/types';
import useLogAs from 'modules/Authentification/useLogAs';
import Modal from 'components/composed/EditForm';
import FormField from 'components/elements/form/Field';
import SelectBox from 'components/elements/form/fields/SelectBox';
import messages from './messages';

type Props = {
  users: CompanyGroupUser[];
  isOpen: boolean;
  onClose: () => void;
};

type Values = {
  userId: string;
};

const initialValues: Values = {
  userId: undefined,
};

const LoggingAsModal = ({ users, isOpen, onClose }: Props) => {
  const { execute: logAs, isLoading } = useLogAs();
  const intl = useIntl();

  const validate = (values: Values) => {
    const errors: FormikErrors<Values> = {};

    if (!values.userId) {
      errors.userId = intl.formatMessage(messages.errorRequiredUserId);
    }

    return errors;
  };

  const onSubmit = (values: Values) => {
    const user = users.find(({ id }) => id === values.userId);
    if (user) {
      logAs(user.id, user.email);
    }
  };

  return (
    <Modal
      title={intl.formatMessage(messages.modalTitle)}
      initialValues={initialValues}
      validate={validate}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={isLoading}
    >
      <p>{intl.formatMessage(messages.helperText)}</p>
      <FormField
        title={intl.formatMessage(messages.labelUserId)}
        name="userId"
        required
      >
        <SelectBox
          options={users.map(({ id, displayName }) => ({
            label: displayName,
            value: id,
          }))}
        />
      </FormField>
    </Modal>
  );
};

export default LoggingAsModal;
