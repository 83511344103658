import { useHistory, useLocation } from 'react-router-dom';

import isString from 'lodash/isString';
import { User } from '@neo1/client/lib/entities/user/types';
import { AppMenuRoute } from 'config/routes/utils';
import { useAmplitude } from 'contexts/instrumentation';
import { DeviceSize, useWindowContext } from 'contexts/window';
import isFunction from 'lodash/isFunction';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import { useSelector } from 'react-redux';
import styles from './styles.module.css';
import getProfileRoutes from './getProfileRoutes';
import MenuItem from '../../MenuItem';

interface Props {
  user: User;
  onNavigation: Function;
}

const Body = ({ user, onNavigation }: Props) => {
  const routes = getProfileRoutes(user);
  const { logEvent } = useAmplitude();
  const history = useHistory();
  const { pathname: currentPath } = useLocation();
  const { deviceSize } = useWindowContext();
  const company = useSelector(selectActingCompany);

  const onNavigateRoute = (route: AppMenuRoute) => {
    logEvent({
      event: `profile_${
        isString(route.title) ? route.title : String(route.title())
      }`,
    });
    if (onNavigation) {
      onNavigation();
    }
    history.push(route.path);
  };

  return (
    <div className={styles.container}>
      {routes
        .filter(({ show, path }) =>
          isFunction(show) ? show({ pathname: path, user, company }) : true,
        )
        .map((route) => (
          <MenuItem
            key={route.name}
            label={route.title.toString()}
            buttonTitle={`Go to ${route.title}`}
            icon={route.menu?.iconClass}
            selected={
              deviceSize === DeviceSize.desktop && currentPath === route.path
            }
            active={
              deviceSize !== DeviceSize.desktop && currentPath === route.path
            }
            dark={deviceSize !== DeviceSize.desktop}
            onClick={() => {
              onNavigateRoute(route);
            }}
          />
        ))}
    </div>
  );
};

export default Body;
