import uniq from 'lodash/uniq';
import { Action, TxState, TxAction } from './types';

export const initialState: TxState = {
  ids: [],
};

const txReducer = (
  state: TxState = initialState,
  action?: TxAction,
): TxState => {
  switch (action.type) {
    case Action.SET_TX:
      return {
        ids: uniq([...state.ids, action.id]),
      };

    case Action.SET_TXS:
      return {
        ids: uniq([...state.ids, ...action.ids]),
      };

    case Action.SET_TXS_OVERRIDE:
      return {
        ids: action.ids,
      };

    default:
      return state;
  }
};

export default txReducer;
