import { ReactNode } from 'react';
import classNames from 'classnames';
import { SortTypes } from '@neo1/core/utils/collections';
import Icon from 'components/elements/Icon';
import { TableProps } from '../types';
import styles from './SortButton.module.css';

type Props = {
  children: ReactNode;
  prop: string;
  sort?: TableProps['sort'];
  onSortChange: TableProps['onSortChange'];
};

const defaultProps: Partial<Props> = {
  sort: undefined,
};

const SortButton = ({ children, prop, sort, onSortChange }: Props) => {
  const isSorted = sort ? sort.prop === prop : false;
  const isAsc = sort ? sort.dir === SortTypes.ASC : false;

  const handleClick = () => {
    onSortChange(prop, isSorted && isAsc ? SortTypes.DESC : SortTypes.ASC);
  };

  return (
    <button type="button" onClick={handleClick} className={styles.container}>
      <span
        className={classNames(styles.text, {
          [styles.isHighlighted]: isSorted,
        })}
      >
        {children}
      </span>
      {isSorted ? (
        <Icon name={isAsc ? 'up' : 'down'} className={styles.icon} />
      ) : null}
    </button>
  );
};

SortButton.defaultProps = defaultProps;

export default SortButton;
