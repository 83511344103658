/* eslint-disable import/prefer-default-export */

import { Action, Actions, State } from './types';

export const fetchCounters = (): Action => ({
  type: Actions.FETCH_COUNTERS,
});

export const setCounters = (state: State): Action => ({
  type: Actions.SET_COUNTERS,
  state,
});
