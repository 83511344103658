import TextInput from 'components/elements/form/fields/TextInput';
import { Filter } from '../types';
import styles from './TextFilter.module.css';

type Props = {
  filter: Filter;
};

const TextFilter = ({ filter }: Props) => {
  const { id, placeholder, value, onChange, disabled } = filter;

  return (
    <TextInput
      type="text"
      id={id}
      name={id}
      value={value ?? ''}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      className={styles.input}
    />
  );
};

export default TextFilter;
