import { combineReducers, Reducer } from 'redux';
import notifications from './toaster/reducer';
import flashNotification from './flashNotification/reducer';
import { FlashNotification } from './flashNotification/types';
import { ToastersState } from './toaster/types';

export type NotificationsState = {
  toaster: ToastersState;
  flash: FlashNotification;
};

export const notifReducer: Reducer<NotificationsState> = combineReducers({
  toaster: notifications,
  flash: flashNotification,
});

export default notifReducer;
