import Snippets from './Snippets';

const TagManager = {
  dataScript(dataLayer) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm(args) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = (nonce) => {
      const scriptTag = document.createElement('script');
      scriptTag.innerHTML = snippets.script;
      if (nonce) {
        scriptTag.setAttribute('nonce', `${nonce}`);
      }
      return scriptTag;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize({
    gtmId,
    events = {},
    dataLayer = undefined,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
    nonce = '',
  }) {
    const gtm = this.gtm({
      id: gtmId,
      events,
      dataLayer,
      dataLayerName,
      auth,
      preview,
    });
    if (dataLayer) document.head.appendChild(gtm.dataScript);
    document.head.insertBefore(gtm.script(nonce), document.head.childNodes[0]);
    document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
  },
  dataLayer({ dataLayer, dataLayerName = 'dataLayer' }) {
    if (window[dataLayerName]) {
      window[dataLayerName].push(dataLayer);
      return;
    }
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.appendChild(dataScript);
  },
};

export default TagManager;
