import { generatePath, matchPath } from 'react-router';
import wrapAsync from 'utils/wrapAsync';
import { RouteContext } from 'config/routes/utils';
import {
  ROUTE_NAME_ADMIN_HOME,
  ROUTE_PATH_ADMIN_HOME,
  ROUTE_PATH_ADMIN_SYSTEM,
  ROUTE_PATH_ADMIN_SYSTEM_ADMINS,
  ROUTE_PATH_ADMIN_DOMAIN,
  ROUTE_PATH_ADMIN_DOMAIN_ADMINS,
  ROUTE_PATH_ADMIN_COMPANY,
  ROUTE_PATH_ADMIN_COMPANY_CHILDREN,
} from './constants';

const getNavLevel = (pathname: string) => {
  if (
    matchPath(pathname, { path: ROUTE_PATH_ADMIN_COMPANY }) ||
    matchPath(pathname, { path: ROUTE_PATH_ADMIN_COMPANY_CHILDREN })
  ) {
    return 3;
  }
  if (
    matchPath(pathname, { path: ROUTE_PATH_ADMIN_DOMAIN }) ||
    matchPath(pathname, { path: ROUTE_PATH_ADMIN_DOMAIN_ADMINS })
  ) {
    return 2;
  }
  if (
    pathname === ROUTE_PATH_ADMIN_SYSTEM ||
    matchPath(pathname, { path: ROUTE_PATH_ADMIN_SYSTEM_ADMINS })
  ) {
    return 1;
  }
  return 0;
};

const route = {
  name: ROUTE_NAME_ADMIN_HOME,
  path: [
    ROUTE_PATH_ADMIN_HOME,
    ROUTE_PATH_ADMIN_SYSTEM,
    ROUTE_PATH_ADMIN_SYSTEM_ADMINS,
    ROUTE_PATH_ADMIN_DOMAIN,
    ROUTE_PATH_ADMIN_DOMAIN_ADMINS,
    ROUTE_PATH_ADMIN_COMPANY,
    ROUTE_PATH_ADMIN_COMPANY_CHILDREN,
  ],
  exact: true,
  menu: { iconClass: 'home', path: ROUTE_PATH_ADMIN_HOME },
  children: [
    {
      name: 'adminDomains',
      path: ROUTE_PATH_ADMIN_SYSTEM,
      title: 'Domains',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 1,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminDomains" */
            './views/System/Domains'
          ),
      ),
    },
    {
      name: 'adminDomainAdministrators',
      path: ROUTE_PATH_ADMIN_SYSTEM_ADMINS,
      title: 'Administrators',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 1,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminDomainAdministrators" */
            './views/System/Administrators'
          ),
      ),
    },
    {
      name: 'adminBusinesses',
      path: ROUTE_PATH_ADMIN_DOMAIN,
      generatePath: ({ domainId }) =>
        generatePath(ROUTE_PATH_ADMIN_DOMAIN, { domainId }),
      title: 'Businesses',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 2,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminBusinesses" */
            './views/Domain/Companies'
          ),
      ),
    },
    {
      name: 'adminBusinessesAdministrators',
      path: ROUTE_PATH_ADMIN_DOMAIN_ADMINS,
      generatePath: ({ domainId }) =>
        generatePath(ROUTE_PATH_ADMIN_DOMAIN_ADMINS, { domainId }),
      title: 'Administrators',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 2,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminBusinessesAdministrators" */
            './views/Domain/Administrators'
          ),
      ),
    },
    {
      name: 'adminUsers',
      path: ROUTE_PATH_ADMIN_COMPANY,
      generatePath: ({ domainId, companyId }) =>
        generatePath(ROUTE_PATH_ADMIN_COMPANY, { domainId, companyId }),
      title: 'Users',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 3,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminUsers" */
            './views/Company/Users'
          ),
      ),
    },
    {
      name: 'adminLinkedBusinesses',
      path: ROUTE_PATH_ADMIN_COMPANY_CHILDREN,
      generatePath: ({ domainId, companyId }) =>
        generatePath(ROUTE_PATH_ADMIN_COMPANY_CHILDREN, {
          domainId,
          companyId,
        }),
      title: 'Linked businesses',
      exact: true,
      show: ({ pathname }: RouteContext) => getNavLevel(pathname) === 3,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "adminLinkedBusinesses" */
            './views/Company/Children'
          ),
      ),
    },
  ],
};

export default route;
