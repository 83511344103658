import { User, CompanyGroupUser } from '@neo1/client/lib/entities/user/types';
import { Domain } from '@neo1/client/lib/entities/domain/types';
import { Company } from '@neo1/client/lib/entities/company/types';
import { AdminView, FiltersData } from './types';

export enum Actions {
  SET_SYSTEM_ADMINS = 'one/admin/SET_SYSTEM_ADMINS',
  SET_SYSTEM_ADMIN = 'one/admin/SET_SYSTEM_ADMIN',
  SET_DOMAINS = 'one/admin/SET_DOMAINS',
  SET_DOMAIN = 'one/admin/SET_DOMAIN',
  SET_COMPANIES = 'one/admin/SET_COMPANIES',
  SET_COMPANY = 'one/admin/SET_COMPANY',
  SET_DOMAIN_ADMINS = 'one/admin/SET_DOMAIN_ADMINS',
  SET_DOMAIN_ADMIN = 'one/admin/SET_DOMAIN_ADMIN',
  SET_USERS = 'one/admin/SET_USERS',
  SET_COMPANY_GROUP_USERS = 'one/admin/SET_COMPANY_GROUP_USERS',
  SET_USER = 'one/admin/SET_USER',
  DELETE_USER = 'one/admin/DELETE_USER',
  SET_VIEW_FILTERS = 'one/admin/SET_VIEW_FILTERS',
}

export type Action =
  | {
      type: Actions.SET_SYSTEM_ADMINS;

      adminsIds: User['id'][];
    }
  | {
      type: Actions.SET_SYSTEM_ADMIN;
      adminId: User['id'];
    }
  | {
      type: Actions.SET_DOMAINS;

      domainsIds: Domain['id'][];
    }
  | {
      type: Actions.SET_DOMAIN;
      domainId: Domain['id'];
    }
  | {
      type: Actions.SET_DOMAIN_ADMINS;

      domainAdminsIds: User['id'][];
    }
  | {
      type: Actions.SET_DOMAIN_ADMIN;
      domainAdminId: User['id'];
    }
  | {
      type: Actions.SET_COMPANIES;
      companiesIds: Company['id'][];
    }
  | {
      type: Actions.SET_COMPANY;
      companyId: Company['id'];
    }
  | {
      type: Actions.SET_USERS;
      usersIds: User['id'][];
    }
  | {
      type: Actions.SET_COMPANY_GROUP_USERS;
      companyGroupUserIds: User['id'][];
    }
  | { type: Actions.SET_USER; userId: User['id'] }
  | {
      type: Actions.DELETE_USER;
      userId: User['id'];
    }
  | {
      type: Actions.SET_VIEW_FILTERS;
      subView: string;
      filters: Partial<FiltersData>;
      view: AdminView;
    };

export function setSysAdmins(adminsIds: User['id'][]): Action {
  return {
    type: Actions.SET_SYSTEM_ADMINS,
    adminsIds,
  };
}
export function setSysAdmin(adminId: User['id']): Action {
  return {
    type: Actions.SET_SYSTEM_ADMIN,
    adminId,
  };
}
export function setDomains(domainsIds: Domain['id'][]): Action {
  return {
    type: Actions.SET_DOMAINS,
    domainsIds,
  };
}
export function setDomain(domainId: Domain['id']): Action {
  return {
    type: Actions.SET_DOMAIN,
    domainId,
  };
}
export function setDomainAdmins(domainAdminsIds: User['id'][]): Action {
  return {
    type: Actions.SET_DOMAIN_ADMINS,
    domainAdminsIds,
  };
}
export function setDomainAdmin(domainAdminId: User['id']): Action {
  return {
    type: Actions.SET_DOMAIN_ADMIN,
    domainAdminId,
  };
}
export function setCompanies(companiesIds: Company['id'][]): Action {
  return {
    type: Actions.SET_COMPANIES,
    companiesIds,
  };
}
export function setCompany(companyId: Company['id']): Action {
  return {
    type: Actions.SET_COMPANY,
    companyId,
  };
}
export function setUsers(usersIds: User['id'][]): Action {
  return {
    type: Actions.SET_USERS,
    usersIds,
  };
}
export function setUser(userId: User['id']): Action {
  return {
    type: Actions.SET_USER,
    userId,
  };
}
export function deleteUser(userId: User['id']): Action {
  return {
    type: Actions.DELETE_USER,
    userId,
  };
}

export function setCompanyGroupUsers(
  companyGroupUserIds: CompanyGroupUser['id'][],
): Action {
  return {
    type: Actions.SET_COMPANY_GROUP_USERS,
    companyGroupUserIds,
  };
}

export function setViewFilters(
  view: AdminView,
  subView: string,
  filters: Partial<FiltersData>,
): Action {
  return {
    type: Actions.SET_VIEW_FILTERS,
    subView,
    filters,
    view,
  };
}
