import classNames from 'classnames';

import styles from './Group.module.css';

interface Props {
  className?: string;
  children: any;
}

const FormGroup = ({ children, className }: Props) => (
  <div className={classNames(styles.groupContainer, className)}>{children}</div>
);

FormGroup.defaultProps = {
  className: undefined,
};

export default FormGroup;
