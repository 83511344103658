import { useIntl } from 'react-intl';
import classNames from 'classnames';
import messages from './messages';
import styles from './Disclaimer.module.css';

const Disclaimer = () => {
  const intl = useIntl();
  return (
    <div className={classNames(styles.container, 'textXS')}>
      {intl.formatMessage(messages.disclaimerMessage)}
    </div>
  );
};

export default Disclaimer;
