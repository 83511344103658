import { FunctionComponent } from 'react';
import classNames from 'classnames';
import Modal from 'components/composed/Modal';
import InfoBox from 'components/elements/InfoBox';
import Form from 'components/elements/form/Form';

import styles from './Modal.module.css';
import { Presenter } from './types';

const ModalForm: FunctionComponent<Presenter> = ({
  className,
  contentClassName,
  children,
  title,
  error,
  noAutoFocus,
  noScroll,
  showErrors,
  onSubmit,
  isOpen,
  onClose,
  formClassName,
  id,
  footer,
  showHeader,
  testId,
  onDismissInfoBox,
}: Presenter) => (
  <Modal
    id={id}
    isOpen={isOpen}
    onClose={onClose}
    error={error}
    title={title}
    className={className}
    showHeader={showHeader}
    contentClassName={classNames(contentClassName, noScroll && styles.noScroll)}
    footer={footer}
    wrapperComponent={Form}
    wrapperProps={{
      onSubmit,
      noAutoFocus,
      className: formClassName,
    }}
    testId={testId}
  >
    {error && showErrors ? (
      // passing String(new Date()) as key forces the infobox to remount if the infobox was closed and the same error is passed as prop.
      <InfoBox
        type="error"
        testId="error"
        key={String(new Date())}
        onDismiss={onDismissInfoBox}
      >
        {error}
      </InfoBox>
    ) : null}
    {children}
  </Modal>
);
ModalForm.defaultProps = {
  showErrors: true,
  noAutoFocus: false,
};

export default ModalForm;
