import { defineMessages } from 'react-intl';

export default defineMessages({
  ein: {
    defaultMessage: 'Employee Identification Number',
    id: 'EditRegistrationNumber__Type__EIN',
  },
  duns: {
    defaultMessage: 'D-U-N-S Ⓡ',
    id: 'EditRegistrationNumber__Type__DUNS',
  },
  companyHouseNumber: {
    defaultMessage: 'Companies House number',
    id: 'EditRegistrationNumber__Type__CHN',
  },
});
