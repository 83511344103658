import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import matchesProperty from 'lodash/matchesProperty';
import not from 'lodash/negate';
import isNil from 'lodash/isNil';
import { useFormikContext } from 'formik';
import { CompanyActivationData } from '@neo1/client/lib/entities/persistedUserData/types';
import { enumToLabel } from '@neo1/core/utils/strings';
import FormField from 'components/elements/form/Field';
import FormGroup from 'components/elements/form/Group';
import TextInput from 'components/elements/form/fields/TextInput';
import SelectBox from 'components/elements/form/fields/SelectBox';
import { useAmplitude, Events } from 'contexts/instrumentation';
import EditRegistrationNumber from './EditRegistrationNumber';
import companyIndustries from './industries';
import TipBox from '../../TipBox';
import {
  COMPANY_KIND_OPTIONS,
  INDUSTRIES_OPTIONS,
  EMPLOYEES_COUNT_OPTIONS,
} from '../../utils';
import { CompanyHouseRegistrationDetails } from './EditRegistrationNumber/utils';
import messages from './messages';

import styles from '../../ActivateCompany.module.css';

const getKindOptionsForCountry = (country: string) =>
  COMPANY_KIND_OPTIONS.reduce((options, kind) => {
    if (kind.countryCodes.includes(country)) {
      return options.concat({
        label: enumToLabel(kind.value),
        value: kind.value,
      });
    }
    return options;
  }, []);

function AboutCompany() {
  const { logEvent } = useAmplitude();

  const intl = useIntl();

  useEffect(() => {
    logEvent({ event: Events.CompanyInfoViewed });
  }, []);

  const {
    setFieldValue,
    values: { dismissedTips = [] },
    values,
  } = useFormikContext<CompanyActivationData>();

  const isTipDismissed = dismissedTips.includes('about');
  const isGBCompany = values.country === 'GB';
  const isUSCompany = values.country === 'US';
  const companyKindOptions = getKindOptionsForCountry(values.country);

  const onSelectCompany = (company: CompanyHouseRegistrationDetails) => {
    setFieldValue('name', company.name);
    setFieldValue('yearOfCreation', company.yearOfCreation);
    setFieldValue('companyKind', company.kind);
    setFieldValue('state', company.region);
    setFieldValue('city', company.city);
    setFieldValue('zipCode', company.zipCode);
    setFieldValue(
      'address',
      [company.addressLine1, company.addressLine2]
        .filter(not(isNil))
        .join(', '),
    );

    const matchedIndustry = companyIndustries.find(
      matchesProperty('Code', company.industry),
    );

    if (matchedIndustry) {
      setFieldValue('industry', matchedIndustry.Industry);
    }
  };

  const onDismissTip = async () => {
    setFieldValue('dismissedTips', dismissedTips.concat('about'));
  };

  return (
    <>
      <main className={styles.formMain}>
        {!isTipDismissed && isGBCompany && (
          <TipBox message="about" onDismiss={onDismissTip} />
        )}
        <FormField
          title={intl.formatMessage(messages.registrationNumber)}
          name="registrationNumber"
          required={isUSCompany}
        >
          <EditRegistrationNumber
            onSelectCompany={onSelectCompany}
            withRegistrationDetails={isGBCompany}
          />
        </FormField>
        <FormField
          title={intl.formatMessage(messages.confirmCompanyName)}
          name="name"
          required
        >
          <TextInput />
        </FormField>
        <FormGroup>
          <FormField
            title={intl.formatMessage(messages.type)} // Has filled "Business Type"
            name="companyKind"
            required
            canLogInstrumentation
            instrumentationEventNameOnBlur={Events.HasFilledCompanyType}
          >
            <SelectBox sorted={false} options={companyKindOptions} />
          </FormField>
          <FormField
            title={intl.formatMessage(messages.yearOfRegistration)}
            name="yearOfCreation"
            required
          >
            <TextInput />
          </FormField>
        </FormGroup>
        <FormField
          title={intl.formatMessage(messages.tradingName)}
          name="tradeName"
        >
          <TextInput />
        </FormField>
        <FormField
          title={intl.formatMessage(messages.industry)}
          name="industry"
          canLogInstrumentation
          required
        >
          <SelectBox options={INDUSTRIES_OPTIONS} menuPlacement="top" />
        </FormField>
        <FormGroup>
          <FormField
            title={intl.formatMessage(messages.vatNumber)}
            name="vatNumber"
          >
            <TextInput />
          </FormField>
          <FormField
            title={intl.formatMessage(messages.numberOfEmployees)}
            name="numberOfEmployee"
            required
            canLogInstrumentation
            instrumentationEventNameOnBlur={Events.HasFilledEmployeeCount}
          >
            <SelectBox
              sorted={false}
              menuPlacement="top"
              options={EMPLOYEES_COUNT_OPTIONS}
            />
          </FormField>
        </FormGroup>
      </main>
    </>
  );
}
export default AboutCompany;
