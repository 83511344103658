/* eslint-disable import/prefer-default-export */

import { useState } from 'react';
import { matchPath } from 'react-router-dom';
import {
  ROUTE_PATH_PURCHASE_SUPPLIES,
  ROUTE_NAME_PURCHASE_SUPPLIES,
  PURCHASE_SUPPLIES_PENDING_BANNER_USER,
  PURCHASE_SUPPLIES_PENDING_BANNER_ADMIN,
} from 'modules/Supplies/constants';
import {
  ROUTE_PATH_SPEND,
  ROUTE_NAME_SPEND,
  SPEND_PENDING_BANNER_USER,
  SPEND_PENDING_BANNER_ADMIN,
} from 'modules/Spend/constants';
import {
  ROUTE_PATH_AUTHORIZE,
  ROUTE_NAME_AUTHORIZE,
  AUTHORIZE_PENDING_BANNER_USER,
  AUTHORIZE_PENDING_BANNER_ADMIN,
} from 'modules/Authorize/constants';
import {
  ROUTE_PATH_RECONCILIATION,
  ROUTE_NAME_RECONCILIATION,
  RECONCILIATION_PENDING_BANNER_ADMIN,
} from 'modules/Reconciliation/constants';
import {
  ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO,
  ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO,
  SETTINGS_GENERAL_BUSINESS_INFO_PENDING_BANNER_ADMIN,
} from 'modules/Settings/constants';
import {
  ROUTE_NAME_TRIPS,
  ROUTE_PATH_TRIPS,
  TRIPS_PENDING_BANNER_USER,
  TRIPS_PENDING_BANNER_ADMIN,
} from 'modules/Travel/constants';

const DEFAULT_ADMIN_LABEL = 'Business setup not completed yet!';
const DEFAULT_ADMIN_DESCRIPTION =
  'You have limited access to some features as your business is not active yet.';

const DEFAULT_USER_LABEL = 'Business not active yet!';
const DEFAULT_USER_DESCRIPTION =
  'As soon as your business admin finalizes set up all features will be unlocked.';

const GENERAL_ROUTE = 'general';

type RouteName =
  | typeof ROUTE_NAME_PURCHASE_SUPPLIES
  | typeof ROUTE_NAME_TRIPS
  | typeof ROUTE_NAME_SPEND
  | typeof ROUTE_NAME_AUTHORIZE
  | typeof ROUTE_NAME_RECONCILIATION
  | typeof ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO
  | typeof GENERAL_ROUTE;

type BannerConfig = {
  routeName: RouteName;
  label: string;
  description: string;
  expanded: boolean;
  onShrink: () => void;
};

export const useBannerConfig = (
  path: string,
  isAdmin: boolean,
): BannerConfig => {
  const [bannersExpanded, setBannersExpanded] = useState<
    Record<RouteName, boolean>
  >({
    [ROUTE_NAME_PURCHASE_SUPPLIES]: true,
    [ROUTE_NAME_TRIPS]: true,
    [ROUTE_NAME_SPEND]: true,
    [ROUTE_NAME_AUTHORIZE]: true,
    [ROUTE_NAME_RECONCILIATION]: true,
    [ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO]: true,
    [GENERAL_ROUTE]: true,
  });

  const shrink = (routeName: RouteName) => {
    setBannersExpanded({
      ...bannersExpanded,
      [routeName]: false,
      [GENERAL_ROUTE]: false,
    });
  };

  const config: BannerConfig = {
    routeName: GENERAL_ROUTE,
    label: isAdmin ? DEFAULT_ADMIN_LABEL : DEFAULT_USER_LABEL,
    description: isAdmin ? DEFAULT_ADMIN_DESCRIPTION : DEFAULT_USER_DESCRIPTION,
    expanded: bannersExpanded[GENERAL_ROUTE],
    onShrink: () => shrink(GENERAL_ROUTE),
  };

  if (matchPath(path, { path: ROUTE_PATH_PURCHASE_SUPPLIES })) {
    return {
      ...config,
      ...(isAdmin
        ? PURCHASE_SUPPLIES_PENDING_BANNER_ADMIN
        : PURCHASE_SUPPLIES_PENDING_BANNER_USER),
      routeName: ROUTE_NAME_PURCHASE_SUPPLIES,
      expanded: bannersExpanded[ROUTE_NAME_PURCHASE_SUPPLIES],
      onShrink: () => shrink(ROUTE_NAME_PURCHASE_SUPPLIES),
    };
  }

  if (matchPath(path, { path: ROUTE_PATH_TRIPS })) {
    return {
      ...config,
      ...(isAdmin ? TRIPS_PENDING_BANNER_ADMIN : TRIPS_PENDING_BANNER_USER),
      routeName: ROUTE_NAME_TRIPS,
      expanded: bannersExpanded[ROUTE_NAME_TRIPS],
      onShrink: () => shrink(ROUTE_NAME_TRIPS),
    };
  }

  if (matchPath(path, { path: ROUTE_PATH_SPEND })) {
    return {
      ...config,
      ...(isAdmin ? SPEND_PENDING_BANNER_ADMIN : SPEND_PENDING_BANNER_USER),
      routeName: ROUTE_NAME_SPEND,
      expanded: bannersExpanded[ROUTE_NAME_SPEND],
      onShrink: () => shrink(ROUTE_NAME_SPEND),
    };
  }

  if (matchPath(path, { path: ROUTE_PATH_AUTHORIZE })) {
    return {
      ...config,
      ...(isAdmin
        ? AUTHORIZE_PENDING_BANNER_ADMIN
        : AUTHORIZE_PENDING_BANNER_USER),
      routeName: ROUTE_NAME_AUTHORIZE,
      expanded: bannersExpanded[ROUTE_NAME_AUTHORIZE],
      onShrink: () => shrink(ROUTE_NAME_AUTHORIZE),
    };
  }

  if (
    matchPath(path, { path: ROUTE_PATH_RECONCILIATION, exact: true }) &&
    isAdmin
  ) {
    return {
      ...config,
      ...RECONCILIATION_PENDING_BANNER_ADMIN,
      routeName: ROUTE_NAME_RECONCILIATION,
      expanded: bannersExpanded[ROUTE_NAME_RECONCILIATION],
      onShrink: () => shrink(ROUTE_NAME_RECONCILIATION),
    };
  }

  if (
    matchPath(path, {
      path: ROUTE_PATH_SETTINGS_GENERAL_BUSINESS_INFO,
      exact: true,
    }) &&
    isAdmin
  ) {
    return {
      ...config,
      ...SETTINGS_GENERAL_BUSINESS_INFO_PENDING_BANNER_ADMIN,
      routeName: ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO,
      expanded: bannersExpanded[ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO],
      onShrink: () => shrink(ROUTE_NAME_SETTINGS_GENERAL_BUSINESS_INFO),
    };
  }

  return config;
};
