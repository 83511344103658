import { useEffect, useState, useCallback } from 'react';
import { getViewerLocation, LatLong } from '@neo1/core/utils/geo';

const getCountryOrViewerLocation = async (
  country: string,
): Promise<LatLong> => {
  try {
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ address: country });
    if (response.results.length < 1) {
      throw new Error(`No result found for country ${country}`);
    }
    const { location } = response.results[0].geometry;
    return { lat: location.lat(), lng: location.lng() };
  } catch (e) {
    return getViewerLocation();
  }
};

const useCountryCenterOrViewerLocation = (country: string) => {
  const [location, setLocation] = useState<LatLong>(null);

  const getLocation = useCallback(async () => {
    setLocation(
      country
        ? await getCountryOrViewerLocation(country)
        : await getViewerLocation(),
    );
  }, [country]);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return location;
};

export default useCountryCenterOrViewerLocation;
