import { SegmentActions, SegmentsState, Actions } from './types';

const initialState: SegmentsState = [];

export default function segmentsReducer(
  state = initialState,
  action: SegmentActions = null,
): SegmentsState {
  switch (action?.type) {
    case Actions.SET_SEGMENTS:
      return action.segments;

    case Actions.SET_SEGMENT: {
      return [
        ...state.filter(({ id }) => id !== action.segment.id),
        action.segment,
      ];
    }

    case Actions.DELETE_SEGMENT: {
      return state.filter(({ id }) => id !== action.id);
    }

    default:
      return state;
  }
}
