/* eslint-disable import/prefer-default-export */

import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import {
  ADDRESS_NAME_RULES,
  CITY_RULES,
  COUNTRY_STATE_RULES,
  isAddressName,
  isCity,
  isCountryState,
  isStreet,
  isZipCode,
  STREET_RULES,
  ZIP_CODE_RULES,
} from '@neo1/client';
import { isValidString } from '@neo1/core/utils/strings';
import { Address } from '@neo1/client/lib/entities/address/types';
import { ADDRESS_MANDATORY_FIELDS } from '@neo1/client/lib/entities/address/constants';
import fieldMessages from 'i18n/fields';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';

const DEFAULT_STATE_LABEL = 'Region/State';
const US_STATE_LABEL = 'State';
const DEFAULT_ZIP_CODE_LABEL = 'Postcode';
const US_ZIP_CODE_LABEL = 'ZIP code';

const fieldRequiredMsg = (fieldName: string) => `${fieldName} is required`;

export const useAddressUtils = () => {
  const intl = useIntl();
  if (!intl) {
    throw new Error('this hook cannot be use outside of a IntlProvider');
  }

  const company = useSelector(selectActingCompany);
  const companyCountryCode = company?.countryCode;

  // When the value for the country code is not given,
  // we fallback to the value of the business country code when possible, in the method that produce a label depending on a country code.
  // this is to minimize inconsistencies for the label for the field or error message, for companyUsers. For example,
  // to prevent US users to see the wrong label when the have not yet set the country for the address being validated or a label being shown. We asume
  // that the address will be in the business country, unless the value for the address being validated has the country code set, in which case the argument.

  function getStateFieldLabel(countryCode: string = companyCountryCode) {
    return (countryCode || companyCountryCode) === 'US'
      ? US_STATE_LABEL
      : DEFAULT_STATE_LABEL;
  }

  function getZipCodeFieldLabel(countryCode: string = companyCountryCode) {
    return (countryCode || companyCountryCode) === 'US'
      ? US_ZIP_CODE_LABEL
      : DEFAULT_ZIP_CODE_LABEL;
  }

  const validateAddressValues = (values: Partial<Address>) => {
    const errors: any = {};

    const zipCodeLabel = getZipCodeFieldLabel(values?.countryCode);

    ADDRESS_MANDATORY_FIELDS.forEach((field) => {
      const value = values[field];

      if (!isValidString(value)) {
        const fieldName =
          field === 'zipCode'
            ? zipCodeLabel
            : intl.formatMessage(fieldMessages.address[field]);
        errors[field] = fieldRequiredMsg(fieldName);
      }
    });

    if (values.name && !isAddressName(values.name)) {
      errors.name = ADDRESS_NAME_RULES;
    }

    if (values.countryCode === 'US' && !values.state) {
      errors.state = fieldRequiredMsg(US_STATE_LABEL);
    }

    if (values.state && !isCountryState(values.state)) {
      errors.state = COUNTRY_STATE_RULES;
    }

    if (values.city && !isCity(values.city)) {
      errors.city = CITY_RULES;
    }

    if (values.zipCode && !isZipCode(values.zipCode)) {
      errors.zipCode = ZIP_CODE_RULES;
    }

    if (values.streetLine1 && !isStreet(values.streetLine1)) {
      errors.streetLine1 = STREET_RULES;
    }

    if (values.streetLine2 && !isStreet(values.streetLine2)) {
      errors.streetLine2 = STREET_RULES;
    }

    return errors;
  };

  function isValidAddress(address: Partial<Address> = {}): boolean {
    return isEmpty(validateAddressValues(address));
  }

  return {
    validateAddressValues,
    isValidAddress,
    getStateFieldLabel,
    getZipCodeFieldLabel,
  };
};
