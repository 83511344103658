import classNames from 'classnames';

import styles from './ProgressBar.module.css';

export type ProgressBarKind = 'error' | 'success' | 'warning' | 'default';

interface Props {
  value: number;
  className?: string;
  kind?: ProgressBarKind;
  height?: number;
}

function getProgressStyles(percentile: number) {
  const clampedPercentile = Math.max(0, Math.min(percentile, 100));
  return {
    right: `${100 - clampedPercentile}%`,
  };
}

function ProgressBar({ className, value, height, kind }: Props) {
  return (
    <div className={classNames(styles.container, className)} style={{ height }}>
      <div
        className={classNames(styles.progress, styles[kind])}
        style={getProgressStyles(value)}
      />
    </div>
  );
}

ProgressBar.defaultProps = {
  className: undefined,
  height: 30,
  kind: 'default',
};

export default ProgressBar;
