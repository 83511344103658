import sizes from 'styles/sizes';
import { Tax } from '@neo1/client/lib/entities/tax/types';
import { useWindowContext, WinSize } from 'contexts/window';
import isNil from 'lodash/isNil';
import SelectBox from 'components/elements/form/fields/SelectBox';
import { SelectBoxProps } from 'components/elements/form/fields/SelectBox/utils';

export const mapTaxToOption = (tax: Tax) => {
  const desc = !isNil(tax.taxRate)
    ? ` (${tax.taxRate}%) - ${tax.description}`
    : ` - ${tax.description}`;
  return {
    value: tax.taxCode,
    label: `${tax.taxCode}${desc}`,
  };
};

interface Props extends SelectBoxProps {
  taxes: Tax[];
}

function SelectTax({ taxes, ...props }: Props) {
  const options = taxes.map(mapTaxToOption);
  const { winSize } = useWindowContext();
  return (
    <SelectBox
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      options={options}
      menuPlacement="auto"
      styles={{
        menu: (provided: Record<string, any>) => ({
          ...provided,
          width: [WinSize.sm, WinSize.xs].includes(winSize) ? '100%' : 'auto',
          minWidth: '100%',
        }),
        option: (provided: Record<string, any>) => ({
          ...provided,
          minHeight: '35px',
          padding: 'unset',
          borderTopWidth: `${sizes.spacing0500}px`,
          borderRightWidth: `${sizes.spacing0750}px`,
          borderBottomWidth: `${sizes.spacing0500}px`,
          borderLeftWidth: `${sizes.spacing0750}px`,
          borderStyle: 'solid',
          borderColor: 'transparent',
          display: '-webkit-box',
          overflow: 'hidden',
          '-webkit-line-clamp': '2',
          '-webkit-box-orient': 'vertical',
        }),
      }}
    />
  );
}

export default SelectTax;
