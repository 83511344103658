import { useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { StringLengths } from '@neo1/client';
import TextInput, { TextInputProps } from '../TextInput';
import Button, { ButtonKind, ButtonSize, ButtonTheme } from '../../../Button';
import FieldWrapper, { FieldWrapperProps } from '../../FieldWrapper';
import messages from './messages';
import styles from './PasswordInput.module.css';

interface Props
  extends Omit<FieldWrapperProps, 'children'>,
    Pick<
      TextInputProps,
      'autoComplete' | 'onChange' | 'onFocus' | 'onBlur' | 'placeholder'
    > {
  value?: string;
}

const TYPE_PASSWORD = 'password';
const TYPE_TEXT = 'text';

const PasswordInput = ({
  // props from FieldWrapper
  id,
  name,
  title,
  info,
  errorText,
  successText,
  helperText,
  keepBottomSpace,
  isInvalid,
  isRequired,
  isSuccess,
  noBorder,
  disabled,
  readOnly,
  className,
  // props from TextInput
  onChange,
  onFocus,
  onBlur,
  autoComplete,
  placeholder,
  value,
}: Props) => {
  const [type, setType] = useState(TYPE_PASSWORD);
  const intl = useIntl();
  const handleTypeChange = () => {
    if (type === TYPE_PASSWORD) {
      setType(TYPE_TEXT);
    } else {
      setType(TYPE_PASSWORD);
    }
  };

  return (
    <FieldWrapper
      id={id}
      name={name}
      title={title}
      info={info}
      errorText={errorText}
      successText={successText}
      helperText={helperText}
      keepBottomSpace={keepBottomSpace}
      isInvalid={isInvalid}
      isRequired={isRequired}
      isSuccess={isSuccess}
      noBorder={noBorder}
      disabled={disabled}
      readOnly={readOnly}
      readOnlyValue={new Array(value.length).fill('*').join('')}
      className={classNames(styles.container, className)}
    >
      <TextInput
        placeholder={placeholder}
        autoComplete={autoComplete}
        id={id}
        inputClassName={styles.input}
        maxLength={StringLengths.password}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        type={type}
        value={value}
        noBorder
        disabled={disabled}
        withClearBtn={false}
      />
      <Button
        label={
          type === TYPE_PASSWORD
            ? intl.formatMessage(messages.showLabel)
            : intl.formatMessage(messages.hideLabel)
        }
        iconName={type === TYPE_PASSWORD ? 'eye' : 'eyeOff'}
        iconOnly
        wrapperClassName={styles.toggleVisibilityBtn}
        theme={ButtonTheme.Ghost}
        size={ButtonSize.Small}
        kind={ButtonKind.Neutral}
        onClick={handleTypeChange}
        testId={id ? `${id}_toggleVisibilityBtn` : undefined}
        disabled={disabled}
      />
    </FieldWrapper>
  );
};

PasswordInput.defaultProps = {
  value: '',
};

export default PasswordInput;
