import { AmazonConfigState } from './types';

export enum Actions {
  SetAmazonConfig = 'one/amazonConfig/SET_AMAZON_CONFIG',
  UnsetAmazonConfig = 'one/amazonConfig/UNSET_AMAZON_CONFIG',
}

type SetAmazonConfigAction = {
  type: Actions.SetAmazonConfig;
  settings: AmazonConfigState;
};

type UnsetAmazonConfigAction = {
  type: Actions.UnsetAmazonConfig;
};

/**
 * Set Amazon Punchout settings
 */
export function setAmazonConfig(
  settings: AmazonConfigState,
): SetAmazonConfigAction {
  return {
    type: Actions.SetAmazonConfig,
    settings,
  };
}

export function unsetAmazonConfig(): UnsetAmazonConfigAction {
  return {
    type: Actions.UnsetAmazonConfig,
  };
}

export type Action = SetAmazonConfigAction | UnsetAmazonConfigAction;
