import uniq from 'lodash/uniq';
import { State, Action, Actions } from './types';

export const initialState: State = {
  ids: [],
};

const reducer = (state: State = initialState, action?: Action): State => {
  switch (action.type) {
    case Actions.SET_VEHICLE_TYPE:
      return {
        ...state,
        ids: uniq([...state.ids, action.id]),
      };

    case Actions.SET_VEHICLE_TYPES:
      return {
        ...state,
        ids: action.ids,
      };

    case Actions.DELETE_VEHICLE_TYPE: {
      return {
        ...state,
        ids: state.ids.filter((id) => id !== action.id),
      };
    }

    default:
      return state;
  }
};

export default reducer;
