import async from 'utils/wrapAsync';
import { hasSpendManagement, AppMenuRoute } from 'config/routes/utils';
import { ROUTE_PATH_VEHICLES, ROUTE_NAME_VEHICLES } from './constants';

const route: AppMenuRoute = {
  title: 'Vehicles',
  show: hasSpendManagement,
  exact: true,
  menu: {
    iconClass: 'car',
  },
  path: ROUTE_PATH_VEHICLES,
  name: ROUTE_NAME_VEHICLES,
  component: async(
    () =>
      import(
        /* webpackChunkName: "vehicles" */
        '.'
      ),
  ),
};

export default route;
