import { createContext, useEffect, useState, useContext } from 'react';
import throttle from 'lodash/throttle';

import { calculateState } from './utils';
import { Props, State, WinSize, DeviceSize } from './types';

const defaultValue = {
  winWidth: 1366,
  winHeight: 768,
  winSize: WinSize.xl,
  deviceSize: DeviceSize.desktop,
};

export const WindowContext = createContext<State>(defaultValue);

export const WindowContextProvider = ({ children }: Props) => {
  const [state, setState] = useState<State>(defaultValue);

  useEffect(() => {
    // Handler to call on window resize
    const handleResize = () => {
      setState(calculateState());
    };

    // Throttled resize event handler
    const throttled = throttle(handleResize, 100);

    // Add event listener
    window.addEventListener('resize', throttled);

    // Initialize the state
    setState(calculateState());

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', throttled);
  }, []);

  return (
    <WindowContext.Provider value={state}>{children}</WindowContext.Provider>
  );
};

export const useWindowContext = () => useContext(WindowContext);

export * from './constants';
export * from './types';
