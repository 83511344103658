import { defineMessages } from 'react-intl';

const messages = defineMessages({
  headline: {
    id: 'PublicPage__headline',
    defaultMessage: 'Get your business where it needs to be',
  },
  disclaimer: {
    id: 'PublicPage__disclaimer',
    defaultMessage: `American Express Global Business Travel (“GBT”) is a joint venture that is not wholly-owned by American Express Company or any of its subsidiaries (“American Express”). “American Express Global Business Travel”, “American Express” and the American Express logo are trademarks of American Express, ${'and are used under limited license.'.replace(
      /\s/g,
      '<nbsp></nbsp>',
    )}`,
  },
});

export default messages;
